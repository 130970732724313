<p style="text-align: center;">
    <span *ngIf="progress < 100 && (!uploadFailed && !downloadFailed) ">
        Chargement en cours
    </span>
    <span *ngIf="progress === 100 && (!uploadFailed && !downloadFailed) ">
        Chargement terminé!
    </span>
    <span *ngIf="uploadFailed">
        Si vous voyez cette fenêtre, l'envoi des fichiers vers le serveur a échoué.
        <br />
        Si le problème persiste, contactez le support informatique.
    </span>
    <span *ngIf="downloadFailed">
        Si vous voyez cette fenêtre, le téléchargement des données depuis Google Drive a échoué.
        <br />
        Si le problème persiste, contactez le support informatique.
    </span>
    <mat-card *ngIf="downloadFailed" color="warn">{{downloadErrMsg}}</mat-card>
</p>
<mat-progress-bar *ngIf="progress" mode="determinate" [value]="progress"
    [color]="(uploadFailed || downloadFailed) ? 'warn' :'primary'"></mat-progress-bar>