import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilterDemandeDTO } from '../shared/dtos/filterDemandeDTO';
import { FilterDemandeAgenceDTO } from '../shared/dtos/filterDemandeAgenceDTO';
import { FiltreRechercheCollaborateurDTO } from '../shared/dtos/filtreRechercheCollaborateurDTO';

@Injectable({
  providedIn: 'root'
})
export class FiltreService {

  private filtreSourceDemande = new BehaviorSubject<FilterDemandeDTO>(new FilterDemandeDTO());
  private filtreSourceCollab = new BehaviorSubject<FiltreRechercheCollaborateurDTO>(new FiltreRechercheCollaborateurDTO());
  private filtreSourceAgence = new BehaviorSubject<FilterDemandeAgenceDTO>(new FilterDemandeAgenceDTO());

  public filtreDemande = this.filtreSourceDemande.asObservable();
  public filtreAgence = this.filtreSourceAgence.asObservable();
  public filtreCollab = this.filtreSourceCollab.asObservable();

  constructor() {
    const localFlDemande = localStorage.getItem('flDemande');
    const localFlCollaborateur = localStorage.getItem('flCollaborateur');
    const localFlDemandeAgence = localStorage.getItem('flDemandeAgence');

    if (localFlDemande) {
      var localDem = this.writeJsonValues(new FilterDemandeDTO(), JSON.parse(localFlDemande));
      localStorage.setItem('flDemande', JSON.stringify(localDem));
      this.filtreSourceDemande.next(localDem);
    }
    if (localFlDemandeAgence) {
      var localDemAg = this.writeJsonValues(new FilterDemandeAgenceDTO(), JSON.parse(localFlDemandeAgence));
      localStorage.setItem('flDemandeAgence', JSON.stringify(localDemAg));
      this.filtreSourceAgence.next(localDemAg);
    }
    if (localFlCollaborateur) {
      var localFlCollab = this.writeJsonValues(new FiltreRechercheCollaborateurDTO(), JSON.parse(localFlCollaborateur));
      localStorage.setItem('flCollaborateur', JSON.stringify(localFlCollab));
      this.filtreSourceCollab.next(localFlCollab);
    }
  }

  changeFiltre(fl: FilterDemandeDTO): void {
    this.filtreSourceDemande.next(this.writeJsonValues(new FilterDemandeDTO(), fl));
    localStorage.setItem('flDemande', JSON.stringify(fl));
  }

  changeFiltreAgence(fl: FilterDemandeAgenceDTO): void {
    this.filtreSourceAgence.next(this.writeJsonValues(new FilterDemandeAgenceDTO(), fl));
    localStorage.setItem('flDemandeAgence', JSON.stringify(fl));
  }

  changeFiltreCollaborateur(fl: FiltreRechercheCollaborateurDTO): void {
    this.filtreSourceCollab.next(this.writeJsonValues(new FiltreRechercheCollaborateurDTO(), fl));
    localStorage.setItem('flCollaborateur', JSON.stringify(fl));
  }

  writeJsonValues(dto, jsonObject) {
    // Itérez sur les propriétés de l'objet parsé
    for (let key in jsonObject) {
      if (jsonObject.hasOwnProperty(key) && this.isValidValue(jsonObject[key])) {
          // Si la propriété existe dans localFl et a une valeur valide, mettez-la à jour
          if (key in dto) {
            dto[key] = jsonObject[key];
          }
      }
    }
    return dto;
  }

  isValidValue(value) {
    return value !== null && value !== undefined;
  }

}
