<!-------------------------------------------- HEADER ------------------------------------------------------------>
<mat-toolbar class="toolbar" [ngClass]="envName" >
   <button (click)="snav.toggle()" mat-icon-button *ngIf="user !== null && user !== undefined">
      <mat-icon>menu</mat-icon>
    </button>
    <a><img class="logo" src="assets/imgs/logo_sarp_veolia.png" class="background" alt="Sarp Veolia Logo"></a>

    <span class="spacer"></span>
    <span class="title">GUS</span>
    <span class="spacer"></span>
    
    <button style="margin-top: -15px;" type="button" aria-label="Toggle sidenav" mat-icon-button [matMenuTriggerFor]="aboveMenu" *ngIf="user">
      <mat-icon aria-label="Side nav toggle icon">account_box</mat-icon>
    </button>
    <h5 *ngIf="user" style="margin-top: -15px;">{{user.nom +' '+ user.prenom}}</h5>

    <mat-menu type="button" #aboveMenu="matMenu" yPosition="above">
      <button *ngIf="droitAdminUser" type="button" (click)="manager_user()" mat-menu-item >Gestion utilisateur</button>       
      <button *ngIf="droitAdminRefAndInterface" type="button" mat-menu-item (click)="manager_referentiel()"> Gestion référentiel </button>      
      <button type="button" mat-menu-item (click)="logout()">Déconnexion</button>
    </mat-menu>
</mat-toolbar>