import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DemandeComponentsService } from '../../demande/demande-components.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { ApplicationUserModel } from 'src/app/shared/models/simple-types/application-user.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { BehaviorSubject } from 'rxjs';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { ModalInfoOkComponent } from 'src/app/shared/components/modals/info-ok/modal-info-ok.component';
import { SessionService } from 'src/app/shared/services/session.service';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
@Component({
  selector: 'app-read-collaborateur',
  templateUrl: './read-collaborateur.component.html',
  styleUrls: ['./read-collaborateur.component.scss']
})
export class ReadCollaborateurComponent implements OnInit {

  demandeForm: UntypedFormGroup;
  
  demande: DemandeModel = new DemandeModel();
  collaborateur: CollaborateurModel = new CollaborateurModel();
  collabApplications: ApplicationUserModel[] = [];
  observableDemande = new BehaviorSubject<DemandeModel>(null);

  matDataSourceDemande: MatTableDataSource<DemandeModel> = new MatTableDataSource<DemandeModel>();
  displayedColumnsDemande: string[] = ['Action','Demandeur','Date', 'Modification']

  listDemande: DemandeModel[] = [];
  createur: CollaborateurModel;
  selectedDemandes: DemandeModel[] = [];
  selection = new SelectionModel<DemandeModel>(true, []);
  selectedRowsEmit = new EventEmitter<DemandeModel[]>();

  onlyViewDroits = true;
  allApplications : ApplicationModel[] = [];


  constructor(private demandeService: DemandeService,              
    private applicationService: ApplicationService,
    private router: Router,
    private demandeComponentsService: DemandeComponentsService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private title: Title,
    private agenceService: AgenceService,
    private collabService: CollaborateurService,
    private sessionService: SessionService,
    public dialog: MatDialog) { 
      this.title.setTitle('GUS - Visualiser Collaborateur');
      this.route.params.subscribe((params: any) => {
        if (params.id === '0') {
          this.toastrService.error('Erreur durant la récupération du collaborateur.', 'Erreur');
          this.router.navigate(['/']);
        } else {
          this.collabService.get(params.id).subscribe(collaborateur => {
            this.collaborateur = collaborateur;
            if (this.collaborateur.dDesactivation !== null) {
              this.collaborateur.agence = null;
              this.collaborateur.idAgence = -1;
            }
            this.initWithCollabInfo();
            this.demandeForm = this.demandeComponentsService.getVisualizeDemandeFormGroup(this.demande);
            if (this.collaborateur.dDesactivation == null) { //Post création de demandeForm
              this.demandeForm.get('adresseAgence').setValue(this.collaborateur.agence.adresse);
              this.demandeForm.get('numeroAgence').setValue(this.collaborateur.agence.telephone);
              this.agenceService.get(this.demande.idAgence).subscribe((res) => this.demande.agence = res);
            }
            this.observableDemande.next(this.demande);
   
            // get demandes where collab id = id
            this.demandeService.getAllDemandeByIdCollab(this.collaborateur.id).subscribe(res => {

              this.listDemande = res;    
              this.matDataSourceDemande = new MatTableDataSource(this.listDemande);  
             
              this.matDataSourceDemande.data.forEach(row => { 
                this.collabService.get(row.idCreateur).subscribe(res => {
                  row.createur = res;
                })
              });

            });

            this.sessionService.currentUser.subscribe(x=> {
              if (x.droits.filter(x => x.agenceId == this.collaborateur.agence?.id && x.niveau == 2 && x.type=="Demande" ).length != 0 || this.collaborateur.dDesactivation != null)
              {
                this.onlyViewDroits = false
              }else{
                this.onlyViewDroits = true                
              }
            })
            
            
          }, (error) => {
            toastrService.error('Le collaborateur n\'existe pas');
            this.router.navigate(['/not-found']);
          });
        }
  
      });
    }

    initWithCollabInfo(){
        if(this.collaborateur){
          this.demande.idCollaborateur = this.collaborateur.id;
          this.demande.nom = this.collaborateur.nom;
          this.demande.prenom = this.collaborateur.prenom;
          this.demande.agence = this.collaborateur.agence;
          this.demande.idAgence = this.collaborateur.idAgence;
          this.demande.email = this.collaborateur.email;
          this.demande.typeContrat = this.collaborateur.typeContrat;
          this.demande.responsableCollaborateur = this.collaborateur.responsable;
          this.demande.idResponsableCollaborateur = this.collaborateur.idResponsable;
          this.demande.login = this.collaborateur.login;
          this.demande.tel = this.collaborateur.tel;
          this.demande.dateFin = this.collaborateur.dateFin;
          this.demande.dateDebut = this.collaborateur.dateDebut;
          this.demande.cds = this.collaborateur.cds;
          this.collabApplications = this.collaborateur.applications;
          this.demande.dDesactivation = this.collaborateur.dDesactivation;
        }
    }


  ngOnInit(): void {
    this.applicationService.getAll().subscribe((apps: ApplicationModel[]) => {
      this.allApplications = apps;
    });
  }

  deleteCollaborateur() {
      const dialogConfirmation = this.dialog.open(ModalInfoOkComponent, {
        data: {
          title: 'Demande de suppression du collaborateur ',
          type: 'Demande',
          message: 'Souhaitez-vous créer une demande de suppression de ce collaborateur? ',
          icone: 'warning',
          iconColor: 'warn',
          color: 'danger',
        }
      });
      return dialogConfirmation.afterClosed().subscribe((resultat) => {
        if (resultat) {
          this.router.navigate(["create-demande"], {queryParams:  {typeDemande: 'suppression', idCollab: this.collaborateur.id}});
        }
      });
  }


  updateCollaborateur(){
    this.router.navigate(["create-demande"], {queryParams:  {typeDemande: "modification", idCollab: this.collaborateur.id}});
  }


  close(): void {
    this.router.navigate(['/recherche-collaborateur/']);
  }

  editDemande(demande: DemandeModel): void {
    this.router.navigate(['read-demande/' + demande.id]);
  }

  selectRow(row: DemandeModel): void {
    this.selection.toggle(row);
    let currentDemande: DemandeModel;
    if (row) {
      currentDemande = this.selectedDemandes.find(model => model === row);
    }
    if (!currentDemande) {
      this.setRowToDemandes(row);
    } else {
      this.deleteDemandeRow(row);
    }
    this.selectedRowsEmit.emit(this.selectedDemandes);
  }

  setRowToDemandes(row: DemandeModel): void {
    this.selectedDemandes.push(row);
  }
   
  deleteDemandeRows(): void {
    this.selectedDemandes = [];
  }

  /* Remove item in demandeArray */
  deleteDemandeRow(row: DemandeModel): void {
    this.selectedDemandes.splice(this.selectedDemandes.findIndex(r => r === row), 1);
  }
}
