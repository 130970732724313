import { SimpleQueryResponse } from './../dtos/simpleQueryResponse';
import { SimpleQueryUser } from './../dtos/simpleQueryUser';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { APICONSTANTS } from './../constants/api-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericCrudService } from './basic/generic.crud.service';
import { ToastrService } from 'ngx-toastr';
import { HostnameService } from './basic/hostname.service';
import { CollaborateurModel } from '../models/utilisateur/collaborateur.model';
import { FiltreRechercheCollaborateurDTO } from '../dtos/filtreRechercheCollaborateurDTO';
import { SimpleQuery } from '../dtos/simple_query';
import { CollaborateurModelForExport } from '../models/utilisateur/collaborateurForExport';

@Injectable()
export class CollaborateurService extends GenericCrudService<CollaborateurModel> {
  protected header: HttpHeaders;
  constructor(
    protected http: HttpClient,
    protected toastrService: ToastrService,
    protected hostnameService: HostnameService
  ) {
        super(http, toastrService, APICONSTANTS.ROUTECOLLABORATEUR, hostnameService);
  }


  getFromSimpleQuery(objet: SimpleQueryUser): Observable<SimpleQueryResponse<CollaborateurModel>> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<SimpleQueryResponse<CollaborateurModel>>(APICONSTANTS.URLAPI.value + this.route + 'GetFromSimpleQuery/', objet, {headers: this.header});
  }

  getAllFromDemande(idAgence: number, niveau: number): Observable<CollaborateurModel[]> {
    return this.http.get<CollaborateurModel[]>(APICONSTANTS.URLAPI.value + this.route + 'getAllFromDemande?idAgence='+ idAgence)
        .pipe(catchError(err => this.requestCatchError(err, 'getAllFromDemande : Collaborateur')));
  }

  getByEmail(email: string): Observable<CollaborateurModel> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<CollaborateurModel>(APICONSTANTS.URLAPI.value + this.route + 'getByEmail?email=' + email)
    .pipe(catchError( err => this.requestCatchError(err, 'get')));
  }

  GetCollaborateurFromObjetWithPagination(objet: SimpleQuery<FiltreRechercheCollaborateurDTO>): Observable<SimpleQueryResponse<CollaborateurModelForExport>> {
    return this.http.post<SimpleQueryResponse<CollaborateurModel>>(APICONSTANTS.URLAPI.value + this.route +  'GetCollaborateurFromObjetWithPagination/', objet)
    .pipe(catchError(err => this.requestCatchError(err, 'GetCollaborateurFromObjetWithPagination : Collaborateur')));
  }

  getFastQuery(objet: SimpleQueryUser): Observable<CollaborateurModel[]> {
    return this.http.post<CollaborateurModel[]>(APICONSTANTS.URLAPI.value + this.route  + 'GetFastQuery/',objet);
  }
  getAllByAgenceId(idAgence: number): Observable<CollaborateurModel[]> {
    return this.http.get<CollaborateurModel[]>(APICONSTANTS.URLAPI.value + this.route + 'GetAllByAgenceId?idAgence='+ idAgence)
        .pipe(catchError(err => this.requestCatchError(err, 'GetAllByAgenceId : Collaborateur')));
  }
  
  exportFacturation(objet: FiltreRechercheCollaborateurDTO)  {
    return this.http.post(APICONSTANTS.URLAPI.value + this.route + 'exportForFacturation', objet, {responseType: 'text'}).pipe(catchError(err => this.requestCatchError(err, 'FiltreRechercheCollaborateurDTO')));
  }

  
  exportApplications(objet: FiltreRechercheCollaborateurDTO)  {
    return this.http.post(APICONSTANTS.URLAPI.value + this.route + 'exportForApplication', objet, {responseType: 'text'}).pipe(catchError(err => this.requestCatchError(err, 'exportForApplication')));
  }
}
