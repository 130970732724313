import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FiltreService } from '../filtre.service';
import { DashboardModel } from 'src/app/shared/dtos/dashboard.model';
import { StatusDemande, getStatusDemande } from 'src/app/shared/constants/status';
import { UtilisateurModel } from 'src/app/shared/models/utilisateur/utilisateur.model';
import { SessionService } from 'src/app/shared/services/session.service';
import { DemandeAgenceService } from 'src/app/shared/services/agence-linked/demande-agence.service';
import { FilterDemandeAgenceDTO } from 'src/app/shared/dtos/filterDemandeAgenceDTO';

@Component({
  selector: 'app-gestion-referentiel',
  templateUrl: './gestion-referentiel.component.html',
  styleUrls: ['./gestion-referentiel.component.scss']
})
export class GestionReferentielComponent implements OnInit {
  myOpenOrderCount = 0;
  toValideurWaitValidationByMe = 0;
  filtre: any;
  demandeDashboard = new DashboardModel();
  user : UtilisateurModel;
  viewAgence: boolean = true;
  viewReferentiel: boolean = false;

  constructor(
    private filtreService: FiltreService,
    private demandeService: DemandeAgenceService,
    private sessionService: SessionService,
    private router: Router,
    private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle('Gestion référentiel');
    this.demandeService.getDashboard().subscribe(value => {
      this.demandeDashboard = value;
    });
    this.filtreService.filtreAgence.subscribe(result => {
      this.filtre = result;
    });
    
    this.sessionService.currentUser.subscribe(user => {
      this.user = user;
      if(user.droits.filter(x=> x.niveau > 0 && x.type.toUpperCase()=="REFAGE").length != 0)
      {
        this.viewAgence = true;
      }else{
        this.viewAgence = false;             
      } 
      if(user.droits.filter(x=> x.niveau == 2 && x.type.toUpperCase()=="ADMIN").length != 0)
      {
        this.viewReferentiel = true;
      }else{
        this.viewReferentiel = false;             
      } 
    }); 

  }

  goToGestion(route: string): void {
    this.router.navigate([route]);
  }

  goToWaitValidationByMe(route: string): void {
    this.filtre =  new FilterDemandeAgenceDTO();
    this.filtre.status = [
      getStatusDemande(StatusDemande.AValider)
    ];
    this.filtre.order = 'DCreation';
    this.filtre.descending = true;
    this.filtre.type = 'v_wait_validation_by_me';
    this.filtreService.changeFiltreAgence(this.filtre);
    this.router.navigate([route]);
  }

  close(): void {
    this.router.navigate(['/']);
  }
}
