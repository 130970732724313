import { APICONSTANTS } from '../../constants/api-constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {  Observable, Subscription } from 'rxjs';
import { HostnameService } from '../basic/hostname.service';
import { catchError } from 'rxjs/operators';
import { ResponsableAppliModel } from '../../models/simple-types/responsable-appli.model';
import { ResponsableDTO } from '../../dtos/responsableDTO';
import { ResponsableModelDTO } from '../../dtos/responsableModelDTO';
import { AgenceLinkedService } from './agence-linked.service';


@Injectable()
export class ResponsableAppliService extends AgenceLinkedService<ResponsableAppliModel> {
    private subscriptions: Subscription[] = [];

    constructor(http: HttpClient,
                protected toastrService: ToastrService,
                protected hostnameService: HostnameService) {
        super(http, toastrService, APICONSTANTS.ROUTERESPONSABLEAPPLI, hostnameService);
  }
 
  getAllFromApplication(idApplication: number, idCollaborateur: number): Observable<ResponsableDTO[]> {
    return this.http.get<ResponsableDTO[]>(APICONSTANTS.URLAPI.value + this.route + 'getAllFromApplication?idApplication='+ idApplication + '&idCollaborateur=' + idCollaborateur)
      .pipe(catchError(err => this.requestCatchError(err, 'Le chargement des responsable appli')));
  }

  updateResponsable(objet: ResponsableModelDTO): Observable<ResponsableAppliModel[]> {
    return this.http.post<ResponsableAppliModel[]>(APICONSTANTS.URLAPI.value + this.route + 'updateResponsable/', objet)
      .pipe(catchError(err => this.requestCatchError(err, 'Mise à jour des responsables appli')));
  }

  replaceResponsable(objet: ResponsableModelDTO): Observable<ResponsableAppliModel[]> {
    return this.http.post<ResponsableAppliModel[]>(APICONSTANTS.URLAPI.value + this.route + 'replaceResponsable/', objet)
      .pipe(catchError(err => this.requestCatchError(err, 'Mise à jour des responsables appli')));
  }

  deleteAll(idApplication: number, idCollaborateur: number): Observable<ResponsableAppliModel[]> {
    return this.http.delete<ResponsableAppliModel[]>(APICONSTANTS.URLAPI.value + this.route + 'deleteAll?idApplication='+ idApplication + '&idCollaborateur=' + idCollaborateur)
      .pipe(catchError(err => this.requestCatchError(err, 'Mise à jour des responsables appli')));
  }
}