import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-telephone',
  templateUrl: './input-telephone.component.html',
  styleUrls: ['./input-telephone.component.scss']
})
export class InputTelephoneComponent implements OnChanges{

  @Input() phoneNumber: string;
  @Input() libelle: string;
  @Input() parent: UntypedFormGroup;
  @Output() phoneNumberChange = new EventEmitter<string>();

  constructor() { }

  ngOnChanges(): void {
    this.parent.controls.tel.valueChanges
      .subscribe((value) => {
        this.phoneChangeEvent(value);
      });
  }

  phoneChangeEvent(phone): void {
    const tel1 = phone.replace(/[^\d]/g, '');
    let tel2 = '';
    for (let i = 0; i < tel1.length; i = i + 2 ) {
      tel2 += tel1.substr(i, 2) + '.';
    }
    let tel3 = tel2.substr(0, tel2.length - 1);
    // separate with ;
    if (tel2.length > 15) {
      tel3 = tel3.substr(0, 14) + ';' + tel3.substr(15, tel3.length - 1);
    }
    if (tel3 === this.phoneNumber) {
      this.phoneNumber = tel3.substr(0, 29) + ' ';
    } else {
      this.phoneNumber = tel3.substr(0, 29)  ;
    }
    this.phoneNumberChange.emit(this.phoneNumber);
  }


}
