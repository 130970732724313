import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';
import { GestionApplicationComponent } from 'src/app/core/gestion-referentiel/gestion-application/gestion-application.component';

export interface DialogData {
  form: UntypedFormGroup;
  result: boolean;
}

@Component({
  selector: 'app-modal-add-application',
  templateUrl: './modal-add-application.component.html',
  styleUrls: ['./modal-add-application.component.scss']
})
export class ModalAddApplicationComponent implements OnInit {
  
  application: ApplicationModel;
  form: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<GestionApplicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private applicationService: ApplicationService,
    private router: Router,
    private toastService: ToastrService) { }

  ngOnInit(): void {
    this.form = this.data.form
    this.application = new ApplicationModel();
  }

  closeDialog(val: boolean): void {
    this.dialogRef.close(val);
  }

  create_application(): void {
    this.application = this.form.getRawValue();
    this.application.id = 0;
    if (this.form.valid) {
      this.applicationService.save(this.application).subscribe((application: ApplicationModel) => {
        if (application) {
            this.toastService.success('Vous avez créé l\'application ' + application.nom);
            this.closeDialog(true);
            this.router.navigate(['/fiche-application/' + application.id]);
        } else {
            this.toastService.error('L\'application existe déjà');
        }
      });
    } else {
      this.toastService.error('Veuillez remplir les champs obligatoires');
    }
  }

}
