import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Type } from 'src/app/shared/constants/type';
import { TableDTO } from 'src/app/shared/dtos/tableDTO';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { SpinnerModalComponent } from '../spinner/spinner-modal.component';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { MatDialog } from '@angular/material/dialog';
import { CellDTO, ResponsableDTO } from 'src/app/shared/dtos/responsableDTO';
import { ResponsableAppliModel } from 'src/app/shared/models/simple-types/responsable-appli.model';
import { ResponsableAppliService } from 'src/app/shared/services/agence-linked/responsableAppli.service';
import { ResponsableModelDTO } from 'src/app/shared/dtos/responsableModelDTO';

export interface DialogData {
  agences: AgenceModel[] ;
  collabs: CollaborateurModel[];
  application: ApplicationModel;
  responsables: ResponsableAppliModel[];
  responsable: ResponsableAppliModel;
}

@Component({
  selector: 'app-modal-add-responsable',
  templateUrl: './modal-add-responsable.component.html',
  styleUrls: ['./modal-add-responsable.component.scss']
})
export class ModalAddResponsableComponent implements OnInit {
  application: ApplicationModel;
  responsable: ResponsableAppliModel;
  responsables: ResponsableAppliModel[];
  agences: AgenceModel[] = [];
  collabs: CollaborateurModel[] = [];
  // Table columns
  displayedColumns: any[] = [
    {
      variableName: 'libelleRegion',
      displayName: 'Région',
      type: Type.TEXT,   
    },
    {
      variableName: 'libelleSociete',
      displayName: 'Societe',
      type: Type.TEXT     },
    {
      variableName: 'libelleAgence',
      displayName: 'Agence',
      type: Type.TEXT 
    },
    {
      variableName: 'isResponsable',
      displayName: 'Responsable applicatif',
      type: Type.CHECKBOXE,     
      // droit a confirmé 
      droit: {
        type: "ADMIN",
        niveau: 2
      }  
    },
  ];
  tableDTO: TableDTO = new TableDTO();
  responsableDTO = new ResponsableModelDTO();
  dataSource: ResponsableDTO[];
  oldResponsableId: number;

  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private collaborateurService: CollaborateurService,
    private responsableAppliService: ResponsableAppliService,
    public dialog: MatDialog,
    private toastr: ToastrService) { 
       
      this.tableDTO.libelle = "Responsables"
      this.tableDTO.columns = this.displayedColumns;
      this.tableDTO.rows = [];
    }

  ngOnInit(): void {    
    this.agences = this.data.agences;
    this.collabs = this.data.collabs;
    this.responsables = this.data.responsables;
    this.application = this.data.application;
    
    if(this.data.responsable){
      this.responsable = this.data.responsable;
      this.oldResponsableId = this.data.responsable.idResponsable;
    }else{
      this.responsable = new ResponsableAppliModel();
      this.responsable.id = 0;
      this.responsable.idResponsable = 0;
    }
    this.responsable.idApplication = this.application.id;

    
    const dialogRef = this.dialog.open(SpinnerModalComponent);
    this.responsableAppliService.getAllFromApplication(this.application.id, this.responsable.idResponsable).subscribe((res: ResponsableDTO[]) => {
      this.tableDTO.rows = res;
      this.dataSource = res;
      this.tableDTO = Object.assign({}, this.tableDTO);
      dialogRef.close();
    }, error => dialogRef.close());
  }


  onResponsableChange(id: number){
    this.responsable.idResponsable = id;
    this.responsable.responsable = this.collabs.find(collab => collab.id === id);
    
  }

  updateResponsable(event: ResponsableDTO[]): void {
    if(event){
      this.dataSource = event;
    }
  }

  submit(){
    this.responsableDTO.responsable = this.collabs.find(c => c.id === this.responsable.idResponsable);
    if (!this.responsableDTO.responsable) {
      this.responsableDTO.responsable = this.application.responsables.find(x => x.idResponsable === this.responsable.idResponsable)?.responsable;
    }
    this.responsableDTO.application = this.application;
    this.responsableDTO.responsablesDTO = this.dataSource;
    if (this.responsableDTO.responsablesDTO.every(element=>element.isResponsable.value == true))
    {
      //If responsable sur toutes agences, add element with idAgence null
      let newResponsableDto = new ResponsableDTO();
      newResponsableDto.agenceId = null;
      newResponsableDto.idApplication = this.responsableDTO.application.id;
      newResponsableDto.isResponsable = new CellDTO();
      newResponsableDto.isResponsable.value = true;      
      this.responsableDTO.responsablesDTO.splice(0,this.responsableDTO.responsablesDTO.length,newResponsableDto);
    };
    if(this.responsable.idResponsable && this.responsable.idResponsable !== 0){
      const dialogRef = this.dialog.open(SpinnerModalComponent);
      if(this.responsable.idResponsable === this.oldResponsableId){
        this.responsableAppliService.updateResponsable(this.responsableDTO).subscribe((res: ResponsableAppliModel[]) => {
          dialogRef.close();
          this.toastr.success('', 'Modifications sauvegarder')
  
          this.dialogRef.close(res);
        }, 
        error => dialogRef.close());    
      }else{
        this.responsableDTO.oldResponsableId = this.oldResponsableId;
        this.responsableAppliService.replaceResponsable(this.responsableDTO).subscribe((res: ResponsableAppliModel[]) => {
          dialogRef.close();
          this.toastr.success('', 'Modifications sauvegarder')
  
          this.dialogRef.close(res);
        }, 
        error => dialogRef.close());    
      }
      
    }
    else {
      this.toastr.warning('', 'Le responsable est obligatoire')
    }
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  isResponsableByIdDisabled(id: number){
    var collab = this.collabs.find(ag => ag.id === id);
    if (collab)
      return collab.dDesactivation !== null;
    var resp = this.application.responsables.find(x => x.idResponsable === id);
    if (resp)
      return resp.responsable.dDesactivation !== null;
    return false;
  }

}
