import { AddCommentModalComponent } from '../../../shared/components/modals/add-comment/add-comment-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DemandeValidationModel } from 'src/app/shared/dtos/demandeValidationModel';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { DemandeComponentsService } from '../../demande/demande-components.service';
import { DemandeAgenceModel } from 'src/app/shared/models/agence-linked/demande/demande-agence.model';
import { CycleDemandeAgenceModel } from 'src/app/shared/models/simple-types/cycle-demande-agence.model';
import { DemandeAgenceService } from 'src/app/shared/services/agence-linked/demande-agence.service';
import { CycleDemandeAgenceService } from 'src/app/shared/services/simple/cycle-demande-agence.service';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';

@Component({
  selector: 'app-validate-demande-agence',
  templateUrl: './validate-demande-agence.component.html',
  styleUrls: ['./validate-demande-agence.component.scss']
})
export class ValidateDemandeAgenceComponent implements OnInit, OnDestroy {
  demandeForm: UntypedFormGroup;
  observableDemande = new BehaviorSubject<DemandeAgenceModel>(null);
  demande: DemandeAgenceModel = new DemandeAgenceModel();
  idDemande: number;
  typeDemande: string = "";
  childHeight: number = 0;
  parentWidth: number = 0;

  cyclesValidation: CycleDemandeAgenceModel[] = [];


  constructor(private demandeService: DemandeAgenceService,
              protected toastr: ToastrService,
              private router: Router,
              private dialog: MatDialog,
              private demandeComponentsService: DemandeComponentsService,
              private route: ActivatedRoute,
              private toastrService: ToastrService,
              private agenceService: AgenceService,
              private collabService: CollaborateurService,
              private cycleDemandeAgenceService: CycleDemandeAgenceService
              ) {
    this.route.params.subscribe((params: any) => {
      if (params.id === '0') {
        this.demandeService.demandeSubscriber.subscribe((demande: DemandeAgenceModel) => {
          if (demande !== null) {
            this.demande = Object.assign({}, demande);
          } else {
            this.toastrService.error('Erreur durant la récupération de la demande.', 'Erreur');
            this.router.navigate(['/']);
          }
        });
      } else {
        this.demandeService.get(params.id).subscribe(demande => {
          this.demande = demande;
          switch (this.demande.typeDemande) {
            case 0:
              this.typeDemande = 'Création';
              break;
            case 1:
              this.typeDemande = 'Modification';
              break;
            case 2:
              this.typeDemande = 'Suppression';
              break;
          }
          
          this.demandeForm = this.demandeComponentsService.getValidateDemandeAgenceFormGroup(this.demande);

          // get cycles by demande ID
          this.cycleDemandeAgenceService.getAllFromDemande(this.demande.id).subscribe(res => {
            this.cyclesValidation = res;            
          })
        }, (error) => {
          toastrService.error('La demande n\'existe pas');
          this.router.navigate(['/not-found']);
        });
      }

    });
  }

  ngOnInit(): void {
    this.verifyDemandeStatusToRedirect();
    this.getChildHeight();
    this.getParentWidth();  
    
  }

  ngOnDestroy(): void {
    this.demandeComponentsService.purgeDetailDemandeInfo();
  }

  verifyDemandeStatusToRedirect(): void {
    if (this.observableDemande) {
      this.observableDemande.subscribe((demande: DemandeAgenceModel) => {
        if (demande) {
          if (demande.statusNum !== 2) {
            this.router.navigate(['/']);
            this.toastr.warning('Cette demande est en brouillon ou a déjà été validée.', 'Erreur');
          }
        }
      });
    }
  }

  close(): void {
    this.router.navigate(['/list-demande-agence/']);
  }

  retour(): void {
    const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
      data: {
        title: 'Commentaire de retour',
        color: 'primary',
        icon_color: 'accent',
        required : true
      }
    });
    dialogConfirmation.afterClosed().subscribe(res => {
      if (res !== '' && res) {
        const dialogRef = this.dialog.open(SpinnerModalComponent);
        const obj = new DemandeValidationModel(this.demande);
        obj.commentaire = res;
        this.demandeComponentsService.retourDemandeAgence(obj).subscribe(x => {
          dialogRef.close();
          this.router.navigate(['/list-demande-agence/']);
        }, (error) => {
          this.toastrService.warning("Erreur survenue pendant le retour de la demande : " + error.Message);
          dialogRef.close();
        });
      }
      else if (res === '') {
        this.toastr.error('Commentaire obligatoire');
      }

    });
  }
  validate(): void {
    const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
      data: {
        title: 'Commentaire de validation',
        color: 'primary',
        icon_color: 'accent',
      }
    });
    dialogConfirmation.afterClosed().subscribe(res => {
      if (res === '' || res) {
        const obj = new DemandeValidationModel(this.demande);
        obj.commentaire = res;
        const dialogRef = this.dialog.open(SpinnerModalComponent);
        this.demandeComponentsService.validateDemandeAgence(obj).subscribe(x => {
          dialogRef.close();
          this.router.navigate(['/list-demande-agence/']);
        },error => {
          dialogRef.close();
        }
        );
      }
    });
  }

  traiter(): void {
    const dialogRef = this.dialog.open(SpinnerModalComponent);
    this.demandeComponentsService.traiterDemandeAgence(this.demande).subscribe(x => {
      dialogRef.close();
      this.router.navigate(['/list-demande-agence/']);
    }, error =>{
      dialogRef.close();
    });
  }

  refus(): void {
    const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
      data: {
        title: 'Commentaire de refus',
        color: 'primary',
        icon_color: 'accent',
        required: true
      }
    });
    dialogConfirmation.afterClosed().subscribe(res => {
      if (res !== '' && res) {
        const dialogRef = this.dialog.open(SpinnerModalComponent);
        const obj = new DemandeValidationModel(this.demande);
        obj.commentaire = res;
        this.demandeComponentsService.refusDemandeAgence(obj).subscribe(x => {
          dialogRef.close();
          this.router.navigate(['/list-demande-agence/']);
        }, (error) => {
          this.toastrService.warning("Erreur survenue pendant le refus de la demande : " + error.Message);
          dialogRef.close();
        });
      }
      else if (res === '') {
        this.toastr.error('Commentaire obligatoire');
      }
    });
  }

  getChildHeight(): any {
    // setTimeout(() => {
    //   const childHeight = document.querySelector('#heigthChild') !== null ? document.querySelector('#heigthChild').clientHeight : null;
    //   if (childHeight > 0) {
    //     this.childHeight = childHeight;
    //   }
    // }, 200);
  }

  getParentWidth(): any {
    // setTimeout(() => {
    //   // tslint:disable-next-line: max-line-length
    //   const parentWidth = document.querySelector('#validate-demande-container') !== null ? document.querySelector('#validate-demande-container').clientWidth : null;
    //   if (parentWidth > 0) {
    //     this.parentWidth = parentWidth;
    //   }
    // }, 200);
  }

}


