


import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

export class UserInfo {
  info: {
    sub: string
    email: string,
    name: string,
    picture: string
  };
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  authImplicitFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: 'https://accounts.google.com',
    // strict discovery document disallows urls which not start with issuers url
    strictDiscoveryDocumentValidation: false,

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/login',
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    silentRefreshTimeout: 5000, // For faster testing
    sessionChecksEnabled: true,
    clearHashAfterLogin: false,
    useSilentRefresh: true,
    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    clientId: environment.google_client_id,
    // set the scope for the permissions the client should request
    showDebugInformation: true,
    scope: 'openid profile email',
  };
  gmail = 'https://gmail.googleapis.com';
  private state: string;
  private askedScope: string [];
  userProfileSubject = new BehaviorSubject<UserInfo>(null);
  constructor(private readonly oAuthService: OAuthService) {
    this.oAuthService.events.subscribe(event => {
      switch (event.type) {
        case 'token_received' : {
          console.log('subscribe', event);
          this.oAuthService.loadUserProfile()
          .then((profile: any) => {
            if (profile.info.email !== localStorage.getItem('USER_EMAIL')) {
              this.setUserEmail(profile.info.email);
            } else {
              this.userProfileSubject.next(profile as unknown as UserInfo);
            }
          })
          .catch(e => this.oAuthService.logOut());
          break;
        }
        case 'session_error' : {
          console.log('session_error', event);
          this.oAuthService.logOut();
          break;
        }
        case 'silent_refresh_timeout' : {
          console.log('silent_refresh_timeout', event);
          this.oAuthService.logOut();
          break;
        }
        case 'invalid_nonce_in_state' : {
          console.log('invalid_nonce_in_state', event);
          this.oAuthService.logOut();
          break;
        }
      }
    });
    this.launchLogin();
  }



  launchLogin(): void {
    // manually configure a logout url, because googles discovery document does not provide it
    const scope = localStorage.getItem('scope');
    if (scope && scope !== '') {
      this.authImplicitFlowConfig.scope = scope;
    }
    const oldEmail = localStorage.getItem("USER_EMAIL");
    if (oldEmail) {
      this.setUserEmail(oldEmail);
    } else {
      this.oAuthService.configure(this.authImplicitFlowConfig);
      this.oAuthService.logoutUrl = environment.logoutUrl;
      this.startLogin();
    }
  }

  startLogin() {
    this.oAuthService.loadDiscoveryDocumentAndLogin().then(hasReceivedTokens => {
      if (hasReceivedTokens) {
        // carry on with your app
        return Promise.resolve(true);
      }
      // may want to check if you were previously authenticated
      if (this.oAuthService.hasValidAccessToken() && this.oAuthService.hasValidIdToken() && this.containsAskedScope()) {
        return Promise.resolve(true);
      } else {
        return new Promise(resolve => {
          this.oAuthService.initLoginFlow();
          window.addEventListener('unload', () => {
            console.log('UNLOAD');
            return Promise.resolve();
          });
        });
      }
    }).then((res) => {
      if (res){
        console.log('Promise');
        this.oAuthService.loadUserProfile().then((profile: any) => {
          if (profile.info.email !== localStorage.getItem('USER_EMAIL')) {
            this.setUserEmail(profile.info.email);
          } else {
            this.userProfileSubject.next(profile as unknown as UserInfo);
          }
        }).catch(e => this.oAuthService.logOut());
      }
    });
  }


  setUserEmail(email: string) {
    this.authImplicitFlowConfig.customQueryParams =  {
      login_hint: email
    };
    localStorage.setItem('USER_EMAIL', email);
    this.oAuthService.stopAutomaticRefresh();
    this.oAuthService.configure(this.authImplicitFlowConfig);
    this.oAuthService.logoutUrl = environment.logoutUrl;
    this.oAuthService.setupAutomaticSilentRefresh();
    this.startLogin();
  }


  containsAskedScope(): boolean {
    const scopeSplit = this.authImplicitFlowConfig.scope.split(' ');
    for (const scope of scopeSplit) {
      if (this.oAuthService.getGrantedScopes().toString().indexOf(scope) === -1) {
        return false;
      }
    }
    return true;
  }

  containScope(scope: string): boolean {
    const scopeSplit = scope.split(' ');
    for (const scope of scopeSplit) {
      if (this.oAuthService.getGrantedScopes().toString().indexOf(scope) === -1) {
        return false;
      }
    }
    return true;
  }

  addScope(scope: string): void {
    if (!this.containScope(scope)) {
      this.authImplicitFlowConfig.scope += ' ' + scope;
      localStorage.setItem('scope', this.authImplicitFlowConfig.scope);
      this.launchLogin();
    }
  }

  setState(state: string): void {
    this.state = state;
  }

  isLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  refreshAuth(): Observable<TokenResponse> {
    return from(this.oAuthService.silentRefresh().catch(result => {
          this.oAuthService.initImplicitFlow();
          return Promise.resolve();
    }).then(e => {
      return this.getTokens();
    }));
  }

  getIdToken(): string {
    return this.oAuthService.getIdToken();
  }

  getAccessToken(): string {
    return this.oAuthService.getAccessToken();
  }

  getScope(): string {
    if (this.oAuthService.getGrantedScopes()) {
      return this.oAuthService.getGrantedScopes().toString();
    }
    return "";
  }

  getTokens(): TokenResponse {
    let tokens = {
      access_token: this.getAccessToken(),
      id_token: this.getIdToken(),
      token_type: this.oAuthService.responseType,
      expires_in: this.oAuthService.getIdTokenExpiration(),
      refresh_token: this.oAuthService.getRefreshToken(),
      scope: this.getScope(),
      state: this.oAuthService.state,
    };
    return tokens;
  }

  signOut() {
    try {
      localStorage.removeItem('USER_EMAIL')
      this.oAuthService.stopAutomaticRefresh();
      this.oAuthService.revokeTokenAndLogout().catch(e => 
        this.oAuthService.logOut()
      );
    } catch (exception) {
      this.oAuthService.logOut();
    }
  }

  observableUserInfo(): Observable<UserInfo> {
    return this.userProfileSubject;
  }
}
