import { getStatusDemande, StatusDemande } from "../constants/status";
import { getTypeDemande, TypeDemande } from "../constants/typeDemande";
import { AgenceModel } from "../models/rsa/agence.model";
import { RegionModel } from "../models/rsa/region.model";
import { SocieteModel } from "../models/rsa/societe.model";


export class FilterDemandeDTO {

  public agence: AgenceModel = null;
  public agences: AgenceModel[] = [];
  public idAgence: number = null;

  public societeLibelle: string = "";
  public societeId?: number = null;
  public societeObj: SocieteModel = null;
  public societeObjs: SocieteModel[] = [];

  public region: RegionModel = null;
  public regions: RegionModel[] = [];
  public idRegion: number = null;
  
  public status: string[] = [
    getStatusDemande(StatusDemande.Brouillon),
    getStatusDemande(StatusDemande.AValider), 
    getStatusDemande(StatusDemande.Validate),
    getStatusDemande(StatusDemande.Refuse),
    getStatusDemande(StatusDemande.ATraiterAD),
    getStatusDemande(StatusDemande.Traite),
    getStatusDemande(StatusDemande.Annule)]; // statut coché par défaut .

  public typeDemande : string[] = [
    getTypeDemande(TypeDemande.Creation),
    getTypeDemande(TypeDemande.Modification), 
    getTypeDemande(TypeDemande.Suppression),
    getTypeDemande(TypeDemande.Transfert)];   // types coché par défaut .
  
  public numDemande: string = ""; // N° DEMANDE du
  
  public nom: string = null;
  public prenom: string = null;

  public libelle: string = "";

  public dateDemande: Date = null; // Date du

  public startDCreationDemande: Date = null; // Date de création du
  public endDCreationDemande: Date = null; // Date de création au

  public idCreateur: number = null; // id créateur du DEMANDE
  public idValidateur: number = null; // id validateur du DEMANDE
  
  public retour = [true, false];


  public type: string = null;
  public descending = false;
  public pageNumber = 0;
  public pageSize = 25;
  public order = 'dCreation';

  }
