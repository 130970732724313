import { SimpleQueryResponse } from './../dtos/simpleQueryResponse';
import { SimpleQueryUser } from './../dtos/simpleQueryUser';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { APICONSTANTS } from './../constants/api-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericCrudService } from './basic/generic.crud.service';
import { UtilisateurModel } from '../models/utilisateur/utilisateur.model';
import { ToastrService } from 'ngx-toastr';
import { HostnameService } from './basic/hostname.service';
import { UserModelDTO } from '../dtos/userModelDTO';

@Injectable()
export class UserService extends GenericCrudService<UtilisateurModel> {
  protected header: HttpHeaders;

    constructor(http: HttpClient,
                protected toastrService: ToastrService,
                protected hostnameService: HostnameService) {
        super(http, toastrService, APICONSTANTS.ROUTEUSER, hostnameService);
  }

  GetFromSimpleQuery(objet: SimpleQueryUser): Observable<SimpleQueryResponse<UtilisateurModel>> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<SimpleQueryResponse<UtilisateurModel>>(APICONSTANTS.URLAPI.value + this.route + 'GetFromSimpleQuery/', objet, {headers: this.header});
  }
  getAllActif(): Observable<UtilisateurModel[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<UtilisateurModel[]>(APICONSTANTS.URLAPI.value + this.route + 'GetAllActif/').pipe(catchError( err => this.requestCatchError(err, 'GetAllActif')));
  }

  updateUserAndDroit(objet: UserModelDTO): Observable<UserModelDTO> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<UserModelDTO>(APICONSTANTS.URLAPI.value + this.route + 'updateUserAndDroit/', objet)
      .pipe(catchError(err => this.requestCatchError(err, 'Mise à jour de l\'utilisateur')));
  }

  getAllFromDemande(idAgence: number, niveau: number): Observable<UtilisateurModel[]> {
    return this.http.get<UtilisateurModel[]>(APICONSTANTS.URLAPI.value + this.route + 'getAllFromDemande?idAgence='+ idAgence + '&niveau=' + niveau)
        .pipe(catchError(err => this.requestCatchError(err, 'getAllFromDemande : Utilisateurs')));
  }
}
