import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APICONSTANTS } from '../../constants/api-constants';
import { HostnameService } from '../basic/hostname.service';
import { DemandeAgenceModel } from '../../models/agence-linked/demande/demande-agence.model';
import { GenericCrudService } from '../basic/generic.crud.service';
import { DemandeValidationModel } from '../../dtos/demandeValidationModel';
import { FilterDemandeAgenceDTO } from '../../dtos/filterDemandeAgenceDTO';
import { SimpleQueryResponse } from '../../dtos/simpleQueryResponse';
import { DashboardModel } from '../../dtos/dashboard.model';

@Injectable()
export class DemandeAgenceService extends GenericCrudService<DemandeAgenceModel> {
    observer = new BehaviorSubject<DemandeAgenceModel>(null);
    public demandeSubscriber = this.observer.asObservable();

    constructor(protected http: HttpClient,
                protected toastr: ToastrService,
                protected hostnameService: HostnameService) {
        super(http, toastr, APICONSTANTS.ROUTEDEMANDEAGENCE, hostnameService);
    }

    
    getDashboard(): Observable<DashboardModel> {
        return this.http.get<DashboardModel>(APICONSTANTS.URLAPI.value + this.route + 'GetDashboard')
            .pipe(catchError(err => this.requestCatchError(err, 'L\'affichage')));
    }

    getFiltredDemande(fitredto: FilterDemandeAgenceDTO): Observable<SimpleQueryResponse<DemandeAgenceModel>> {
        return super.getAllFromObjet('GetfiltredData/', fitredto).pipe(catchError(err => this.requestCatchError(err, 'Le chargement')));
    }

    saveDemandeAgence(t: DemandeAgenceModel, action: string): Observable<DemandeAgenceModel> {
        return this.http.post<DemandeAgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'PostDemandeAgence?action=' + action, t)
            .pipe(catchError(err => this.requestCatchError(err, 'L\'enregistrement')));
    }

    getModifications(demande: DemandeAgenceModel): Observable<string> {
        return this.http.post<DemandeAgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'GetDemandeAgenceModifications', demande)
            .pipe(catchError(err => this.requestCatchError(err, 'La recherche de modification')));
    }


    toAValider(t: DemandeAgenceModel): Observable<DemandeAgenceModel> {
        return this.http.post<DemandeAgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'toAValider', t)
            .pipe(catchError(err => this.requestCatchError(err, 'L\'envoie en validation')));
    }

    traiterDemande(t: DemandeAgenceModel): Observable<DemandeAgenceModel> {
        return this.http.post<DemandeAgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'TraitementDemande', t)
            .pipe(catchError(err => this.requestCatchError(err, 'Traitement demande')));
    }

    getAllDemandeByIdAgence(id: number): Observable<DemandeAgenceModel[]> {
        return this.http.get<DemandeAgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'getAllDemandeByIdAgence?id=' + id)
            .pipe(catchError(err => this.requestCatchError(err, 'getAllDemandeByIdAgence')));
   }

   annulerDemande(demandeForAnnulation: DemandeValidationModel): Observable<DemandeAgenceModel> {
    return this.http.put<DemandeAgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'Annuler', demandeForAnnulation)
        .pipe(catchError(err => this.requestCatchError(err, 'L\'annulation')));
    }

    validateDemande(demandeForValidation: DemandeValidationModel): Observable<DemandeAgenceModel> {
        return this.http.put<DemandeAgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'Validate', demandeForValidation)
            .pipe(catchError(err => this.requestCatchError(err, 'La validation')));
    }
    
    refusDemande(demandeForRefus: DemandeValidationModel): Observable<DemandeAgenceModel> {
        return this.http.put<DemandeAgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'Refus', demandeForRefus)
            .pipe(catchError(err => this.requestCatchError(err, 'Le refus')));
    }

    retourDemande(demandeForRetour: DemandeValidationModel): Observable<DemandeAgenceModel> {
        return this.http.put<DemandeAgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'Retour', demandeForRetour)
            .pipe(catchError(err => this.requestCatchError(err, 'Le retour')));
    }

    getAuthorisation(): Observable<number[]> {
        // tslint:disable-next-line: max-line-length
        return this.http.get<DemandeAgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'getAuthorisation')
            .pipe(catchError(err => this.requestCatchError(err, 'Le chargement des droits')));
    }
   
}
