<div class="menuEntier" [class.FunctionMenu]="mobileQuery.matches">
    
    <app-header [snav]="snav" class="header"></app-header>

    <mat-sidenav-container class="sidenav-container" autosize>
        <mat-sidenav #snav  opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" 
        [mode]="mobileQuery.matches ? 'side' : 'side'" 
        [fixedInViewport]="mobileQuery.matches" 
        fixedTopGap="56">
            <div *ngIf="!router.url.includes('/login')">
                <app-sidebar [isExpanded]="isExpanded" [isShowing]="isShowing"></app-sidebar>
            </div>
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

