<div class="col-sm-12">
    <h5>Création d'un nouveau utilisateur</h5>
  </div>
  <div *ngIf="form">
    <form focusInvalidInput [formGroup]="form">
      <div class="col-sm-12 text-center"  style="display: flex; justify-content: flex-start; align-items: flex-start;">
        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <mat-label>Nom</mat-label>
            <input matInput placeholder="Ex: Dupont" formControlName="nom" name="nom" required>
            <mat-error *ngIf="this.form.get('nom').hasError('required')">{{getNomErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <mat-label>Prénom</mat-label>
            <input matInput placeholder="Ex: Dupont" formControlName="prenom" name="prenom" required>
            <mat-error *ngIf="this.form.get('prenom').hasError('required')">{{getPrenomErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-sm-12 text-center"  style="display: flex; justify-content: flex-start; align-items: flex-start;">
        <div class="col-sm-6" style="display: flex; justify-content: flex-start; align-items: flex-start;">
          <mat-form-field class="full-width">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Ex: Fred.dupont@veolia.com" formControlName="email" name="email" required>
            <mat-error *ngIf="this.form.get('email').hasError('required')">{{getEmailErrorMessage()}}</mat-error>
            <mat-error *ngIf="this.form.get('email').hasError('pattern')">{{getEmailErrorMessage()}}</mat-error>

          </mat-form-field>
        </div>

        <div class="col-sm-6" style="display: flex; justify-content: flex-start; align-items: flex-start;">
          <mat-form-field class="full-width">
            <mat-label>Téléphone</mat-label>
            <input matInput placeholder="Ex: 0555555555" formControlName="tel" name="tel" type="tel">
            <mat-error *ngIf="this.form.get('tel').hasError('pattern') || this.form.get('tel').errors?.maxlength|| this.form.get('tel').errors?.minlength">{{getTelephoneErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="col-sm-12">
        </div>
      </div>
      <div class="col-sm-12" style="display: flex; justify-content: flex-start; align-items: flex-start;">
        <div class="col-sm-6 text-center">
          <button mat-raised-button color="default" (click)= "closeDialog(false)">Annuler</button>
        </div>
        <div class="col-sm-6 text-center">
          <button mat-raised-button color="success" (click)="create_user()">Enregistrer</button>
        </div>
      </div>
    </form>
  </div>
  