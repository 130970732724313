import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MaterialModule } from 'src/app/material/material.module';
import { AuthGuard } from 'src/app/shared/services/auth.guard';
import { JwtHelper } from 'src/app/shared/services/jwtHelper';
import { SharedModule } from 'src/app/shared/shared.module';
import { DemandeComponentsService } from './demande-components.service';
import { DemandeFormComponent } from './demande-form/demande-form.component';
import { ListeDemandeComponent } from './liste-demande/liste-demande.component';
import { CyclesDemandeComponent } from './cycles-demande/cycles-demande.component';
import { ModalGooglePickerComponent } from './modals/modal-google-picker/modal-google-picker.component';
import { ModalCycleValidationComponent } from './modals/modal-cycle-validation/modal-cycle-validation.component';
import { CycleValidationListComponent } from './table/cycle-validation-list/cycle-validation-list.component';
import { GoogleApiService } from 'src/app/shared/services/gapiServices/googleApiService';
import { CreateDemandeComponent } from './create-demande/create-demande.component';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { DemandeListComponent } from './table/demande-list/demande-list.component';
import { ReadDemandeComponent } from './read-demande/read-demande.component';
import { ValidateDemandeComponent } from './validate-demande/validate-demande.component';
import { ApplicationFixedComponent } from './application-form/application-fixed/application-fixed.component';
import { EditDemandeComponent } from './edit-demande/edit-demande.component';
import { ReadCollaborateurComponent } from '../user/read-collaborateur/read-collaborateur.component';
import { TraitementDsiDemandeComponent } from './traitement-dsi-demande/traitement-dsi-demande.component';
import { CreateDemandeAgenceComponent } from '../demande-agence/create-demande-agence/create-demande-agence.component';
import { DemandeAgenceFormComponent } from '../demande-agence/demande-agence-form/demande-agence-form.component';
import { ReadDemandeAgenceComponent } from '../demande-agence/read-demande-agence/read-demande-agence.component';
import { ValidateDemandeAgenceComponent } from '../demande-agence/validate-demande-agence/validate-demande-agence.component';
import { ListDemandeAgenceComponent } from '../demande-agence/list-demande-agence/list-demande-agence.component';
import { DemandeAgenceListComponent } from '../demande-agence/table/demande-agence-list/demande-agence-list.component';
import { ApplicationFormGroupedComponent } from './application-form/application-grouped/application-form-grouped/application-form-grouped.component';



@NgModule({
  declarations: [
    ListeDemandeComponent,
    DemandeFormComponent,
    CyclesDemandeComponent,
    ModalGooglePickerComponent,
    ModalCycleValidationComponent,
    CycleValidationListComponent,
    CreateDemandeComponent,
    ReadDemandeComponent,
    ReadCollaborateurComponent,
    ValidateDemandeComponent,
    DemandeFormComponent,
    ApplicationFormComponent,
    ApplicationFormGroupedComponent,
    DemandeListComponent,
    ApplicationFixedComponent,
    EditDemandeComponent,
    ReadCollaborateurComponent,
    TraitementDsiDemandeComponent,
    CreateDemandeAgenceComponent,
    DemandeAgenceFormComponent,
    ReadDemandeAgenceComponent,
    ValidateDemandeAgenceComponent,
    ListDemandeAgenceComponent,
    DemandeAgenceListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatCardModule,
    MatIconModule
    ],
  providers:  [
    DemandeComponentsService,
    GoogleApiService,
    AuthGuard,
    JwtHelper
  ],
  exports: [
    CyclesDemandeComponent,]
})
export class DemandeModule { }
