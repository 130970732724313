import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { APICONSTANTS } from 'src/app/shared/constants/api-constants';
import { GenericCrudService } from '../basic/generic.crud.service';
import { HostnameService } from '../basic/hostname.service';
import { AgenceLinkedModel } from '../../models/agence-linked/agence-linked.model';

@Injectable()
export abstract class AgenceLinkedService<T> extends GenericCrudService<T> {
  constructor(http: HttpClient,
              protected toastrService: ToastrService,
              protected route: string,
              protected hostnameService: HostnameService) {
              super(http, toastrService, route, hostnameService);
  }
  
  getFromAgence(idSoc: any): Observable<T[]> {
    return this.http.get<AgenceLinkedModel[]>(APICONSTANTS.URLAPI.value + this.route + 'GetFromAgence/' + idSoc)
      .pipe(catchError(err => this.requestCatchError(err, 'Le chargement')));
  }

  getAllFiltred(value: string, idSoc: number): Observable<T[]> {
    let param = new HttpParams();
    param = param.append('value', value);
    param = param.append('idSoc', idSoc ? idSoc.toString() : null);
    return this.http.get<AgenceLinkedModel[]>(APICONSTANTS.URLAPI.value + this.route + 'GetAllFiltred', {params: param})
      .pipe(catchError(err => this.requestCatchError(err, 'Le chargement')));
  }

  getFromCodeAndAgence(code: string, idSoc: number): Observable<AgenceLinkedModel> {
    return this.http.get<AgenceLinkedModel>
      (APICONSTANTS.URLAPI.value + this.route + 'getFromCodeAndAgence?code=' + code + '&idSoc=' + idSoc)
      .pipe(catchError(err => this.requestCatchError(err, 'Le chargement')));
  }

  /*loadFromSimpleQueryRef(objet: ReferentielDTO): Observable<SimpleQueryResponse<T>> {
    return this.http.post<SimpleQueryResponse<T>>(APICONSTANTS.URLAPI.value + this.route + 'loadFromSimpleQueryRef', objet)
      .pipe(catchError(err => this.requestCatchError(err, 'Le chargement')));
  }*/
}
