import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { RegionService } from 'src/app/shared/services/rsa/region.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-autocomplete-agence-multiple-select',
  templateUrl: './autocomplete-agence-multiple-select.component.html',
  styleUrls: ['./autocomplete-agence-multiple-select.component.scss'],
})
export class AutocompleteAgenceMultipleSelectComponent implements OnInit {
  @Input() agenceIds: string;
  @Input() agencesIdsArray;
  @Input() selectedAgenceIds;
  @Input() inputRegionsString : string;
  @Input() inputSocietesString : string;
  @Input() untouchedAllAgences : AgenceModel[];
  @Input() untouchedAllRegions : RegionModel[];

  _val: BehaviorSubject<Set<number>>;
  @Input()
  set eventsSubjectAgenceArray(val: BehaviorSubject<Set<number>>) {
    this._val = val;
  }
  
  @Output() updated = new EventEmitter<number[]>();
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  agenceCtrl = new UntypedFormControl();
  filteredAgences: Observable<string[]>;

  inputAgenceArray = new Set();
  eventsSubscription: Subscription;

  


// Input / Output
// toute les agences
  selectableAgencesString: string[] = [];
  allAgences: AgenceModel[] = [];
  selectedAgenceString: string[] = [];
  InputRegions : number[];
  regions : RegionModel[] = []
  
 
  
  @ViewChild('agenceInput', { static: true }) agenceInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor(   private regionService: RegionService, private agenceService: AgenceService
    ) {

   }
   ngOnDestroy(){
    this.eventsSubscription.unsubscribe()
  }

   ngOnInit() {
    this.loadAgences(Object.assign([],this.untouchedAllAgences as AgenceModel[]));
    this.regions = Object.assign([],this.untouchedAllRegions as RegionModel[]);

    this.eventsSubscription = this._val.subscribe((x) => {
      this.inputAgenceArray = x;
      this.selectableAgencesString  = [];
      this.allAgences = [];
      this.loadAgences(Object.assign([],this.untouchedAllAgences as AgenceModel[]));
   });

  }


  ngOnChanges(changes: SimpleChanges): void {
    this.selectableAgencesString  = [];
    this.allAgences = [];
    this.selectedAgenceString = [];
    this.loadAgences(Object.assign([],this.untouchedAllAgences as AgenceModel[]));
  }
  
    /* Charger les agences  */
    loadAgences(agences: AgenceModel[]) {
      this.allAgences = agences;
      let listRegions = this.inputRegionsString.split(',').filter(x => x).map(Number);
      let listSocietes: number[]  = this.inputSocietesString ? this.inputSocietesString.split(',').filter(x => x).map(Number) : [];
      this.agencesIdsArray = [...this.inputAgenceArray];
      if (this.agencesIdsArray.length != 0){
        this.agencesIdsArray.forEach(idInput => {
          if (agences.find(agence => agence.id == idInput) != undefined){
            let agenceFound = agences.find(agence => agence.id == idInput)
            if (!this.selectedAgenceString.find(libelle => libelle ==  this.agenceName(agenceFound))
                && (
                    (listRegions.includes(agenceFound.regionId) && listSocietes.includes(agenceFound.societeId))
                    || (listRegions.includes(agenceFound.regionId) && listSocietes.length == 0)
                    || (listSocietes.includes(agenceFound.societeId) && listRegions.length == 0)
                    || (listRegions.length == 0 && listSocietes.length == 0)
                  )
                ){
              this.selectedAgenceString.push(this.agenceName(agenceFound))
            }
          }
        })
        let tempAgences = Object.assign([], agences);
        tempAgences = tempAgences.filter(agence => !this.agencesIdsArray.includes(agence.id))
        tempAgences.forEach(agence => {
          if (!this.selectableAgencesString.find(libelle => libelle ==  this.agenceName(agence))
          && (
              (listRegions.includes(agence.regionId) && listSocietes.includes(agence.societeId))
              || (listRegions.includes(agence.regionId) && listSocietes.length == 0)
              || (listSocietes.includes(agence.societeId) && listRegions.length == 0)
              || (listRegions.length == 0 && listSocietes.length == 0)
            )
          ){
          this.selectableAgencesString.push(this.agenceName(agence))
        }})
      }else{
          agences.forEach(agence =>{
            if (!this.selectableAgencesString.find(libelle => libelle ==  this.agenceName(agence))
                && (
                    (listRegions.includes(agence.regionId) && listSocietes.includes(agence.societeId))
                    || (listRegions.includes(agence.regionId) && listSocietes.length == 0)
                    || (listSocietes.includes(agence.societeId) && listRegions.length == 0)
                    || (listRegions.length == 0 && listSocietes.length == 0)
                  )
                ){
              this.selectableAgencesString.push(this.agenceName(agence))
            }
          })
      }
      this.selectableAgencesString.sort();
      this.selectedAgenceString.sort();
      this.filteredAgences = this.agenceCtrl.valueChanges.pipe(
        startWith(null),
        map((agence: string | null) => agence ? this._filter(agence) : this.selectableAgencesString.slice()));
  }
  

  
  
  
  /* Supression d'une agence*/
    remove(agence: string): void {
      let id;
      const index = this.selectedAgenceString.indexOf(agence);
      if (index >= 0) {
        this.selectableAgencesString.push(this.selectedAgenceString[index]);
        this.selectableAgencesString.sort();
        this.selectedAgenceString.splice(index, 1);
        this.selectedAgenceString.sort();
      }
  
      this.agenceService.getAll().subscribe((agences: AgenceModel[]) => {
      this.agencesIdsArray.splice(this.agencesIdsArray.indexOf(agences.find(x=>  this.agenceName(x) == agence).id),1)
      this.updated.emit(this.agencesIdsArray);

      });
      this.filteredAgences = this.agenceCtrl.valueChanges.pipe(
        startWith(null),
        map((myAgence: string | null) => myAgence ? this._filter(myAgence) : this.selectableAgencesString.slice()));
  
    }
    addElement(inputValue,inputIndex){
      const value = inputValue;
      const index = inputIndex;

      this.agenceService.getAll().subscribe((agences: AgenceModel[]) => {
        if ((value || '').trim() && agences.find(x=>  this.agenceName(x) == value) != undefined) {
          this.selectedAgenceString.push(value.trim());
          this.selectableAgencesString.splice(index, 1);
          this.selectableAgencesString.sort();
          this.selectedAgenceString.sort();
          this.agencesIdsArray.push(agences.find(x=>  this.agenceName(x) == value).id)
        }

      this.agenceCtrl.setValue(null);
      this.updated.emit(this.agencesIdsArray);
      });

      this.filteredAgences = this.agenceCtrl.valueChanges.pipe(
        startWith(null),
      map((agence: string | null) => agence ? this._filter(agence) : this.selectableAgencesString.slice()));
      this.updated.emit(this.agencesIdsArray);
      this.agenceInput.nativeElement.blur();
      this.agenceInput.nativeElement.focus();
    }

  
    /* Ajout d'une agence avec selection */
    selected(event: MatAutocompleteSelectedEvent): void {
      this.addElement(event.option.value,this.selectableAgencesString.indexOf(event.option.value))
      this.agenceInput.nativeElement.value = '';
      this.agenceInput.nativeElement.blur();
      }
  

          /* Ajout d'une agence avec Entrer */
    add(event: MatChipInputEvent): void {
      const input = event.input;
      this.addElement(event.value,this.selectableAgencesString.indexOf(event.value))
      if (input) {
        input.value = '';
      }
    }

    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
      const stringFolded = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return this.selectableAgencesString.filter(agence => agence.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf( stringFolded) >= 0);
    }

    agenceName(agence: AgenceModel) {
      return ((agence.societeObj ? agence.societeObj.libelle : "") + " / " + agence.code + "-" + agence.libelle).trim();
    }
  
}