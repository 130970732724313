import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AgenceModel } from '../models/rsa/agence.model';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private agenceSource = new BehaviorSubject<AgenceModel>(null);
  agence = this.agenceSource.asObservable();
  private userSource = new BehaviorSubject<string>("christelle.gosset@veolia.com");
  user = this.userSource.asObservable();

  changeAgence(agence: AgenceModel): void {
    this.agenceSource.next(agence);
  }
}
