import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-historique-demandes',
  templateUrl: './historique-demandes.component.html',
  styleUrls: ['./historique-demandes.component.scss']
})
export class HistoriqueDemandesComponent implements OnChanges {

  @Input() idCollaborateur: number;
  @Input() listDemande:  DemandeModel[];
  selection = new SelectionModel<DemandeModel>(true, []);
  selectedDemandes: DemandeModel[] = [];  
  matDataSourceDemande: MatTableDataSource<DemandeModel> = new MatTableDataSource<DemandeModel>();
  displayedColumnsDemande: string[] = ['Action','Demandeur','Date de création', 'dModification', 'Statut',  'Modification']
  @ViewChild(MatSort) sort: MatSort;

  constructor(   
    private router: Router,
    private demandeService: DemandeService,
    private collabService: CollaborateurService,
    public dialog: MatDialog
    ) {
      
     }

  ngOnChanges(changes: SimpleChanges): void { 

        // Si l'appel est depuis le modal de validation / traitement demande on affiche que les demandes validé / traité
        if(this.listDemande) {
          this.matDataSourceDemande = new MatTableDataSource(this.listDemande);  
          this.matDataSourceDemande.sort = this.sort; 
        }
        // Si l'appel est depuis la page Details du collaborateur on affiche toutes les demandes
        else if(this.idCollaborateur) {
         // get demandes where collab id = id
         this.demandeService.getAllDemandeByIdCollab(this.idCollaborateur).subscribe(res => {
           this.listDemande = res;                    
           this.matDataSourceDemande = new MatTableDataSource(this.listDemande);      
           this.matDataSourceDemande.sort = this.sort;         
          })
        }  

        this.matDataSourceDemande.data.forEach(row => { 
          this.collabService.get(row.idCreateur).subscribe(res => {
            row.createur = res;
          })
        }); 
  }

  ngOnInit(): void {
    
  }
  editDemande(demande: DemandeModel): void {
      this.demandeService.get(demande.id).subscribe(res => { //On ouvre un onglet que si on a les droits, sinon toast erreur
        const url = this.router.createUrlTree(['read-demande/', demande.id])
        window.open(url.toString(), '_blank')
      }); 
  }

  sortData(sort: Sort) {
    const data = this.matDataSourceDemande.data.slice(); // Make a copy of the data
    if (!sort.active || sort.direction === '') {
      this.matDataSourceDemande.data = data;
      return;
    }

    this.matDataSourceDemande.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dModification':
          return this.compare(a.dModification, b.dModification, isAsc);
        // Add cases for other sortable columns
        default:
          return 0;
      }
    });
  }

  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
