import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CycleValidationDTO } from 'src/app/shared/dtos/cycleValidationDTO';

@Component({
  selector: 'app-cycle-validation-list',
  templateUrl: './cycle-validation-list.component.html',
  styleUrls: ['./cycle-validation-list.component.scss']
})
export class CycleValidationListComponent implements OnChanges {

  @Input() cyclesValidation: CycleValidationDTO[]

  displayedColumnsDemande: string[] = ['Qui','Étape initiale','Étape finale', 'Quand', 'Commentaire']
  matDataSourceDemande: MatTableDataSource<CycleValidationDTO> = new MatTableDataSource<CycleValidationDTO>();

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
     this.matDataSourceDemande = new MatTableDataSource(this.cyclesValidation);  
  }

  ngOnInit() {
  }
}
