

export enum TypeDemande {
  Creation,
  Modification,
  Suppression,
  Transfert
}


export function getTypeDemande(num: number): string {
  let lbl = "";
  switch (num)
  {
      case TypeDemande.Creation: lbl = "Creation"; break;
      case TypeDemande.Modification: lbl = "Modification"; break;
      case TypeDemande.Suppression: lbl = "Suppression"; break;
      case TypeDemande.Transfert: lbl = "Transfert"; break;
      default: break;
  }
  return lbl;
}

