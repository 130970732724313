import { Injectable } from '@angular/core';

@Injectable()
export class SimpleQueryResponse<T> {

  public descending: boolean;
  public pageNumber: number;
  public pageSize: number;
  public count: number;
  public coutTotal? : number;
  public objets: T[];
}
