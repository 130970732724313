<div class="col-md-12" style="display: flex; justify-content: flex-start; align-items: flex-start;">
    <div class="col-md-11 body-class">
      <h1>Gestion des applications</h1>
    </div>
    <div class="col-md-1 body-class" style="padding-top: 15px;">
      <button class="pull-right" color="warn" mat-raised-button type="button" (click)="close()">
        <mat-icon>cancel</mat-icon> Fermer
      </button>
    </div>
  </div>
  <div class="col-md-12" style="display: flex; justify-content: flex-start; align-items: flex-start;">
    <div class="col-md-8">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Rechercher une application</mat-label>
        <input matInput [(ngModel)]="objet.query" (change)="search()" (keydown.enter)="search()" placeholder="Nom de l'application">
      </mat-form-field>
    </div>
    <div class="col-md-1 top">
      <button type="button" matTooltip="Créer une application" mat-raised-button color="primary" (click)="onCreate()" mat-icon-button><mat-icon>add</mat-icon></button>
    </div>
    <div class="col-md-1 top">
      <button mat-raised-button matTooltip="Rechercher une application" color="primary" type="button" (click)="search()" style="color: white;"mat-icon-button><mat-icon>search</mat-icon></button>
    </div>
  </div>  
  <div class="col-md-12">
    <div class="mat-table">
      <div class="table_principale">
        <table mat-table matSort [dataSource]="matDataSource" multiTemplateDataRows class="mat-elevation-z8" (matSortChange)="sortData($event)" matSortActive="code">
          <caption>Liste des applications</caption>

          <ng-container matColumnDef="nom">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Nom </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> {{element.nom}} </td>
          </ng-container>
      
          <ng-container matColumnDef="groupe">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Groupe </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> <div class="left">{{element.groupe}}</div> </td>
          </ng-container>
  
          <ng-container matColumnDef="categorie">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Categorie </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> <div class="left">{{element.categorie}}</div> </td>
          </ng-container>

          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef> Active </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> 
              <div class="left" *ngIf="element.dDesactivation === null "><mat-icon class="green-icon">check</mat-icon></div> 
              <div class="left" *ngIf="element.dDesactivation !== null "><mat-icon class="red-icon">close</mat-icon></div> 
            </td>
          </ng-container>
      
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element" style="padding: 0;">
              <div class="center">
                 <mat-icon
                 matTooltip="Désactiver"
                 class="red-icon"
                 *ngIf="element.dDesactivation === null"
                 (click)="onDelete(element)">delete</mat-icon>
              </div>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></tr>
        </table>
      
        <div class="footer">
          <mat-paginator
            #paginator
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50]"
            (page)="changePage($event)">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
  