<div class="col-sm-12">
    <h5>Création d'une nouvelle application</h5>
  </div>
  <div *ngIf="form">
    <form focusInvalidInput [formGroup]="form">
        <div class="col-md-12">
            <mat-form-field class="full-width">
              <mat-label>Nom</mat-label>
              <input matInput placeholder="Ex: GUS" formControlName="nom" required>
              <mat-error *ngIf="form.get('nom').hasError('required')">
                Le nom est obligatoire.
            </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field class="full-width">
              <mat-label>Groupe AD</mat-label>
              <input matInput placeholder="Ex: GRP-SAOS-APPLICATION-PRD-Users" formControlName="groupe" >
            </mat-form-field>
          </div>
          
          <div class="col-md-12">
            <mat-form-field class="full-width">
              <mat-label>Chemin du groupe AD</mat-label>
              <input matInput placeholder="Ex : groupe-sarposis.net/groupe-sarposis/Groupes/Applications/ %" formControlName="path" >
            </mat-form-field>
          </div>
      

     
      <div class="col-sm-12" style="display: flex; justify-content: flex-start; align-items: flex-start;">
        <div class="col-sm-6 text-center">
          <button mat-raised-button color="default" (click)= "closeDialog(false)">Annuler</button>
        </div>
        <div class="col-sm-6 text-center">
          <button mat-raised-button color="success" (click)="create_application()">Enregistrer</button>
        </div>
      </div>
    </form>
  </div>
  