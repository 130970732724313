import { DemandeModel } from "../agence-linked/demande/demande.model";
import { BasicModel } from "../basic/basic.model";
import { ApplicationModel } from "./application.model";

export class ApplicationDemandeModel extends BasicModel {

    idDemande: number = 0;
    demande: DemandeModel;

    idApplication: number;
    application: ApplicationModel;

    // 1 -> Ajout / 0 -> Suppression
    action: boolean;
}