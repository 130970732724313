import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe as AngularTitleCasePipe } from '@angular/common';

@Pipe({
  name: 'extendedTitleCase'
})
export class ExtendedTitleCasePipe implements PipeTransform {
  angularTitleCase = new AngularTitleCasePipe();
  transform(value: any): any {
    const regex = /[-.'’;,:!%*()@? ]/; 
    if (!regex.test(value)) {
      return this.angularTitleCase.transform(value);
    } else {
      let transformedValue = '';
      let capitalizeNext = false;
      for (let i = 0; i < value.length; i++) {
        if (capitalizeNext) {
          transformedValue += value.charAt(i).toUpperCase();
          capitalizeNext = false;
        } else {
          transformedValue += value.charAt(i);
        }
        if (regex.test(value.charAt(i))) {
          capitalizeNext = true;
        }
      }
      return transformedValue;
    }
  }
}