
export enum WorkflowStatus {
  CompteDesactive,  // Vérification compte désactivé
  CompteActifSurPerimetre, // Vérification compte actif sur une agence hors perimetre de l'utilisateur
  CompteActifHorsPerimetre, // Vérification compte non actif sur une agence hors perimetre de l'utilisateur
  CompteOtherDesactive, // désactiver email ext/Interne
  CompteOtherActifSurPerimetre, // Compte actif sur une agence du perimetre de l'utilisateur  email ext/Interne
  CompteOtherActifHorsPerimetre, // Compte actif hors perimetre de l'utilisateur  email ext/Interne
  EmailNonExistant // Vérification si email non existant dans la BDD
}

  export function getWorkflowStatus(num: number): string {
    let lbl = "";
    switch (num)
    {
        case WorkflowStatus.CompteDesactive: lbl = "Compte désactivé"; break;
        case WorkflowStatus.CompteActifSurPerimetre: lbl = "Compte actif"; break;
        case WorkflowStatus.CompteActifHorsPerimetre: lbl = "Compte non actif"; break;

        case WorkflowStatus.CompteOtherDesactive: lbl = "Compte désactivé"; break;
        case WorkflowStatus.CompteOtherActifSurPerimetre: lbl = "Compte actif"; break;
        case WorkflowStatus.CompteOtherActifHorsPerimetre: lbl = "Compte non actif"; break;

        case WorkflowStatus.EmailNonExistant: lbl = "Email non existant"; break;
        default: break;
    }
    return lbl;
  }