 
         <mat-form-field class="full-width">
            <mat-label> Liste des régions <mat-icon  aria-label="help icon">help</mat-icon> </mat-label> 
            <mat-chip-list #chipList aria-label="region selection">
              <mat-chip
                *ngFor="let region of selectedRegionString"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(region)">
                {{region}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                matTooltip="Sélectionnez les régions dans la liste" 
                #regionInput
                [formControl]="regionCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let region of filteredRegions | async" [value]="region">
                {{region}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
   
  
   
   