
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HostnameService } from '../basic/hostname.service';
import { APICONSTANTS } from '../../constants/api-constants';
import { RSAService } from './rsa.service';
import { SocieteModel } from '../../models/rsa/societe.model';

@Injectable()
export class SocieteService extends RSAService<SocieteModel> {
    private subscriptions: Subscription[] = [];

    constructor(http: HttpClient,
                protected toastrService: ToastrService,
                protected hostnameService: HostnameService) {
        super(http, toastrService, APICONSTANTS.ROUTESOCIETE, hostnameService);
    }
  }