import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ApplicationUserModel } from 'src/app/shared/models/simple-types/application-user.model';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { BehaviorSubject } from 'rxjs';
import { SessionService } from 'src/app/shared/services/session.service';
import { DemandeAgenceModel } from 'src/app/shared/models/agence-linked/demande/demande-agence.model';
import { CycleDemandeAgenceModel } from 'src/app/shared/models/simple-types/cycle-demande-agence.model';
import { DemandeAgenceService } from 'src/app/shared/services/agence-linked/demande-agence.service';
import { DemandeComponentsService } from '../../demande/demande-components.service';
import { CycleDemandeAgenceService } from 'src/app/shared/services/simple/cycle-demande-agence.service';
import { AddCommentModalComponent } from 'src/app/shared/components/modals/add-comment/add-comment-modal.component';
import { DemandeValidationModel } from 'src/app/shared/dtos/demandeValidationModel';

@Component({
  selector: 'app-read-demande-agence',
  templateUrl: './read-demande-agence.component.html',
  styleUrls: ['./read-demande-agence.component.scss']
})
export class ReadDemandeAgenceComponent implements OnInit, OnDestroy {
  demandeForm: UntypedFormGroup;
  demande: DemandeAgenceModel = new DemandeAgenceModel();
  idDemande: number;
  childHeight: number = 0;
  parentWidth: number = 0;
  agenceApplications: ApplicationUserModel[] = [];
  currentAgence: AgenceModel;
  observableDemande = new BehaviorSubject<DemandeAgenceModel>(null);
  typeDemande: string = "";

  cyclesValidation: CycleDemandeAgenceModel[] = [];

  showButtons = false;

  constructor(private demandeService: DemandeAgenceService,
              private router: Router,
              private demandeComponentsService: DemandeComponentsService,
              private route: ActivatedRoute,
              private toastrService: ToastrService,
              private title: Title,
              private agenceService: AgenceService,
              public dialog: MatDialog,
              private cycleDemandeService: CycleDemandeAgenceService,
              private sessionService: SessionService) {
    this.route.params.subscribe((params: any) => {
      if (params.id === '0') {
        this.demandeService.demandeSubscriber.subscribe((demande: DemandeAgenceModel) => {
          if (demande !== null) {
            this.demande = Object.assign({}, demande);
          } else {
            this.toastrService.error('Erreur durant la récupération de la demande.', 'Erreur');
            this.router.navigate(['/']);
          }
        });
      } else 
      {
        //Affichage boutons abandonner, modifier si on est créateur et demande non validé
        this.demandeService.get(params.id).subscribe(demande => {
          this.sessionService.currentUser.subscribe(x=> {
            if(x.id == demande.idCreateur && (demande.status == 'En attente de validation')){
              this.showButtons =true;
            }else{
              this.showButtons =false;
            }
          })
          this.demande = demande;
          this.title.setTitle('GUS - Visualisation demande ' + this.demande.code);
          switch (this.demande.typeDemande) {
            case 0:
              this.typeDemande = 'Création';
              break;
            case 1:
              this.typeDemande = 'Modification';
              break;
            case 2:
              this.typeDemande = 'Suppression';
              break;
          }
          this.demandeForm = this.demandeComponentsService.getVisualizeDemandeAgenceFormGroup(this.demande);

          // get cycles by demande ID
          this.cycleDemandeService.getAllFromDemande(this.demande.id).subscribe(res => {
            this.cyclesValidation = res;            
          })
        }, (error) => {
          toastrService.error('La demande n\'existe pas');
          this.router.navigate(['/not-found']);
        });
      }
    });
  }


  cancelDemande(){
    //Changer statut de la demande en "Annulé"
    const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
      data: {
        title: 'Êtes-vous sûr de vouloir abandonner cette demande',
        color: 'danger',
        icon_color: 'accent',
       
      }
    });
    dialogConfirmation.afterClosed().subscribe(res => {
      if(res || res === '') {
        const obj = new DemandeValidationModel(this.demande);
        obj.commentaire = res;
         this.demandeComponentsService.annulerDemandeAgence(obj).subscribe(x => {
           this.router.navigate(['/list-demande-agence/']);
         });  
      }
    });
  }
  
  updateDemande(){
    //Meme chose que le bouton retour pour complement mais sans le commentaire
    if(this.demande.status == "Brouillon"){//Si la demande est déja en brouillon
      this.router.navigate(['/edit-demande-agence/'+this.demande.id]);
    }else{
      const obj = new DemandeValidationModel(this.demande);
      obj.commentaire = '';
      obj.isModif = true;
      this.demandeComponentsService.retourDemande(obj).subscribe(x => {
        this.router.navigate(['/edit-demande-agence/'+this.demande.id]);
        });
    }
  }


  ngOnInit(): void {
    this.getChildHeight();
    this.getParentWidth();

  }
  
  close(): void {
    this.router.navigate(['/list-demande-agence/']);
  }

  ngOnDestroy(): void {
    this.demandeComponentsService.purgeDetailDemandeInfo();
  }

  getChildHeight(): any {
    // setTimeout(() => {
    //   const childHeight = document.querySelector('#heigthChild').clientHeight;
    //   if (childHeight > 0) {
    //     this.childHeight = childHeight;
    //   }
    // }, 200);
  }

  getParentWidth(): any {
    // setTimeout(() => {
    //   const parentWidth = document.querySelector('#parent-container').clientWidth;
    //   if (parentWidth > 0) {
    //     this.parentWidth = parentWidth;
    //   }
    // }, 200);
  }
  
 
}


