import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilisateurModel } from 'src/app/shared/models/utilisateur/utilisateur.model';
import { UserService } from 'src/app/shared/services/user.service';
import { UserComponentsService } from '../user-components.service';
import { ToastrService } from 'ngx-toastr';
import { DroitEffectifService } from 'src/app/shared/services/simple/droit.service';
import { CellDTO, DroitModelDTO } from 'src/app/shared/dtos/droitModelDTO';
import { UserModelDTO } from 'src/app/shared/dtos/userModelDTO';
import { EntiteSARPNode } from 'src/app/shared/models/rsa/entite-sarp.model';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { Title } from '@angular/platform-browser';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DroitDTO, TableDTO } from 'src/app/shared/dtos/tableDTO';
import { Type } from 'src/app/shared/constants/type';
import { SessionService } from 'src/app/shared/services/session.service';


@Component({
  selector: 'app-user-record',
  templateUrl: './user-record.component.html',
  styleUrls: ['./user-record.component.scss']
})
export class UserRecordComponent implements OnInit {

  user: UtilisateurModel;
  types: string[];
  niveaux: string[];
  agences: AgenceModel[];
  form: UntypedFormGroup;
  agence: AgenceModel;
  droits: DroitModelDTO[];
  droitsForm: UntypedFormArray;

  // Table columns
  displayedColumnsDroit: any[] = [
    {
      variableName: 'libelleRegion',
      displayName: 'Région',
      type: Type.TEXT,   
    },
    {
      variableName: 'libelleSociete',
      displayName: 'Societe',
      type: Type.TEXT     },
    {
      variableName: 'libelleAgence',
      displayName: 'Agence',
      type: Type.TEXT 
    },
    {
      variableName: 'visualiseDemande',
      displayName: 'Visualisation collab',
      type: Type.CHECKBOXE,     
      droit: {
        type: "ADMIN",
        niveau: 1
      }  
    },
    {
      variableName: 'createDemande',
      displayName: 'Création / Modification / Suppression collab',
      type: Type.CHECKBOXE,
      droit: {
        type: "ADMIN",
        niveau: 1
      }       
    },
    {
      variableName: 'valid1',
      displayName: 'Validation collab',
      type: Type.CHECKBOXE,
      droit: {
        type: "ADMIN",
        niveau: 1
      }      
    },
    {
      variableName: 'valid2',
      displayName: 'Traitement DSI collab',
      type: Type.CHECKBOXE,
      droit: {
        type: "ADMIN",
        niveau: 1
      }  
    },
    
    {
      variableName: 'visualiseDemandeAgence',
      displayName: 'Visualisation agence',
      type: Type.CHECKBOXE,  
      droit: {
        type: "ADMIN",
        niveau: 2
      }  
    },
    {
      variableName: 'createDemandeAgence',
      displayName: 'Création / Modification / Suppression agence',
      type: Type.CHECKBOXE,
      droit: {
        type: "ADMIN",
        niveau: 2
      }    
    },
    {
      variableName: 'validateDemandeAgence',
      displayName: 'Validation agence',
      type: Type.CHECKBOXE, 
      droit: {
        type: "ADMIN",
        niveau: 2
      }     
    },
    {
      variableName: 'repositoriesManager',
      displayName: 'Admin référentiel',
      type: Type.CHECKBOXE,
      droit: {
        type: "ADMIN",
        niveau: 2
      }  
    },
    {
      variableName: 'usersManager',
      displayName: 'Admin utilisateur',
      type: Type.CHECKBOXE, 
      droit: {
        type: "ADMIN",
        niveau: 1
      }    
    },
  ];
  tableDTO: TableDTO = new TableDTO();
  currentUser: UtilisateurModel;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private userService: UserService,
    private sessionService: SessionService,
    private agenceService: AgenceService,
    private toastr: ToastrService,
    private userComponentService: UserComponentsService,
    private droitService: DroitEffectifService,
    private router: Router,
    private title: Title
  ) { 
    this.tableDTO.libelle = "Droits"
    this.tableDTO.columns = this.displayedColumnsDroit;
    this.tableDTO.rows = [];
  }

  ngOnInit(): void {
    this.title.setTitle('GUS - Modification utilisateur');
    this.sessionService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.route.params.subscribe((params: any) => {
      this.agenceService.getAll().subscribe(agences => {
        this.agences = agences;
      });
      const dialogRef = this.dialog.open(SpinnerModalComponent);
      this.onLoadUser(params.id, dialogRef).subscribe((user: UtilisateurModel) => {
      });
      }); 
  }

  hasChild = (_: number, node:  EntiteSARPNode) => !!node.childrens && node.childrens.length > 0;

  onLoadUser(id: number, dialogRef: any): Observable<UtilisateurModel> {
    return this.userService.get(id).pipe(tap((user: UtilisateurModel) => {
      if (user !== null && user !== undefined) {
        this.user = Object.assign({}, user);
        this.droitService.getAllFromUserDTO(user).subscribe(res => {
          this.droits = res;
          this.tableDTO.rows = this.convertDroitDtoToRows(this.droits);
          this.form = this.userComponentService.getEditUserFormGroup(user, this.droits);
          this.form.addControl('agence', new UntypedFormControl('', Validators.required));
          this.form.get('agence').setValue(user.agence);
          this.agence = user.agence;
          dialogRef.close();
        });
        
      }else{
        dialogRef.close();
      }
    }, error => dialogRef.close()));
  }

  convertDroitDtoToRows(droits : DroitModelDTO[]): any[]{
    var rows = []
    for(var droit of droits){
      for(var col of this.displayedColumnsDroit){
        if(col.type === Type.CHECKBOXE){
          var cell = new CellDTO();
          cell.value = droit[col.variableName];
          cell.disabled = this.shouldDisable(droit, col.droit);
          droit[col.variableName] = cell;
        }
      }
      rows.push(droit);
    }
    return rows;
  }

  convertRowsToDroitDto(rows : any[]): DroitModelDTO[]{
    var droits = []
    for(var row of rows){
      for(var col of this.displayedColumnsDroit){
        if(col.type === Type.CHECKBOXE){
          row[col.variableName] = row[col.variableName].value;
        }
      }
      droits.push(row);
    }
    return droits;
  }

  shouldDisable(data, droit: DroitDTO) {
    if (data.agenceId && droit) {
      var droits = this.currentUser.droits.filter(x => x.type.toUpperCase() == droit.type.toUpperCase() && x.niveau == droit.niveau)
      return droits.find(d => d.agenceId === data.agenceId) === undefined;
    } 
    return false;
  }


  validate(): void {
    const userDTO = new UserModelDTO();
    userDTO.user = this.user;
    userDTO.user.password = null;
    userDTO.user.agence = null;
    userDTO.user.applications = null;
    userDTO.droits = this.form.get('droitsDTO').value;
    const dialogRef = this.dialog.open(SpinnerModalComponent);
    this.userService.updateUserAndDroit(userDTO).subscribe(res => {
      this.user = res.user;
      this.droits = res.droits;
      this.form = this.userComponentService.getEditUserFormGroup(this.user, this.droits);
      this.toastr.success('Enregitrement des modifications', 'Modification réussie.');
      dialogRef.close();
    }, (error) => {
      dialogRef.close();
    });
  }

  onChangeAgence(agence: AgenceModel): void {
    if (agence) {
      this.agence = this.agences.find((soc: AgenceModel) =>  soc.id === agence.id);
      this.form.get('idAgence').setValue(agence.id);
      this.form.get('agence').setValue(agence);
      this.user.idAgence = agence.id;
    }
  }

  onChangeNotif(notif: any): void {
    this.form.get('notification').setValue(notif.checked);
    this.user.notification = notif.checked;
  }

  changedroit(){
    const dialogRef = this.dialog.open(SpinnerModalComponent);
   this.droitService.getAllFromUserDTO(this.user).subscribe(res => {
      this.droits = res;
      this.form = this.userComponentService.getEditUserFormGroup(this.user, this.droits);
      this.form.addControl('agence', new UntypedFormControl('', Validators.required));
      this.form.get('agence').setValue(this.user.agence);
      this.agence = this.user.agence;
      dialogRef.close();
    });
  }

 
  updateDroit(event: DroitModelDTO[]): void {
    const user = this.form.getRawValue();
    this.form = this.userComponentService.getEditUserFormGroup(user, this.droits);
  }


  close(): void {
    this.router.navigate(['/list-user/']);
  }

}
