import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DROITDTOVALIDATORS, DROITVALIDATORS, FormInfo, USERVALIDATORS } from 'src/app/shared/constants/user-validators';
import { DroitModelDTO } from 'src/app/shared/dtos/droitModelDTO';
import { DroitEffectifModel } from 'src/app/shared/models/utilisateur/droit.model';
import { UtilisateurModel } from 'src/app/shared/models/utilisateur/utilisateur.model';

@Injectable()
export class UserComponentsService {

  user: UtilisateurModel;

  constructor() {
  }

  getCreateUserFormGroup(): UntypedFormGroup {
    const user = new UtilisateurModel();
    const droitsDTO = [];
    const form = this.getUserForm(user, droitsDTO, USERVALIDATORS.CREATE, DROITVALIDATORS.CREATE);
    return form;
  }

  getEditUserFormGroup(user: UtilisateurModel, droitsDTO: DroitModelDTO[]): UntypedFormGroup {
    const form = this.getUserForm(user, droitsDTO, USERVALIDATORS.EDIT, DROITVALIDATORS.CREATE);
    return form;
  }

  // tslint:disable-next-line: max-line-length
  getUserForm(user: UtilisateurModel, droitsDTO: DroitModelDTO[], userValidators: Map<string, FormInfo>, droitValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, value] of userValidators.entries()) {
      if (key !== 'droits' && key !== 'droitsDTO') {
        // tslint:disable-next-line: max-line-length
        const control = new UntypedFormControl({ value: user[key], disabled: userValidators.get(key).disabled }, userValidators.get(key).validators);
        form.addControl(key, control);
      }
    }

    let droitsFormArray = new UntypedFormArray([]);
    if (user.droits && user.droits.length > 0) {
      let i = 0;
      for (const droit of user.droits) {
        droitsFormArray.push(this.getDroitForm(droit, droitValidators, i));
        i++;
      }
    }
    droitsFormArray.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    form.addControl('droits', droitsFormArray);
    droitsFormArray = this.getDroitDTOFormArray(droitsDTO);
    form.addControl('droitsDTO', droitsFormArray);
    return form;
  }

  getDroitForm(droit: DroitEffectifModel, droitValidators: Map<string, FormInfo>, i: number): UntypedFormGroup {
    const form = this.getCreateDroitForm(droit, droitValidators);
    return form;
  }

  getCreateDroitForm(droit: DroitEffectifModel, droitValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, _] of droitValidators.entries()) {
      const control =
        new UntypedFormControl({ value: droit[key], disabled: droitValidators.get(key).disabled }, droitValidators.get(key).validators);
      form.addControl(key, control);
    }
    return form;
  }

  getDroitDTOFormArray(droits: DroitModelDTO[]): UntypedFormArray {
    const droitsFormArray = new UntypedFormArray([]);
    if (droits && droits.length > 0) {
      let i = 0;
      for (const droit of droits) {
        droitsFormArray.push(this.getDroitDTOForm(droit, DROITDTOVALIDATORS.EDIT, i));
        i++;
      }
    }
    droitsFormArray.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    return droitsFormArray;
  }

  getDroitDTOForm(droit: DroitModelDTO, droitValidators: Map<string, FormInfo>, i: number): UntypedFormGroup {
    const form = this.getCreateDroitDTOForm(droit, droitValidators);
    return form;
  }

  getCreateDroitDTOForm(droit: DroitModelDTO, droitValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, _] of droitValidators.entries()) {
      if( typeof droit[key] === 'object'){
        const control = new UntypedFormControl({ value: droit[key].value, disabled: droitValidators.get(key).disabled }, droitValidators.get(key).validators);
        form.addControl(key, control);
      }else{
        const control = new UntypedFormControl({ value: droit[key], disabled: droitValidators.get(key).disabled }, droitValidators.get(key).validators);
        form.addControl(key, control);
      }
      
    }
    return form;
  }

  createDroitDTO(userForm: UntypedFormGroup, droit: DroitModelDTO): UntypedFormGroup {
    const row = this.getDroitDTOForm(droit, DROITDTOVALIDATORS.EDIT, 0);
    row.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    return row;
  }
}
