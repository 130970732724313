import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GlobalSettingsService } from 'src/app/core/gobal-settings.service';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { getStatusDemande, StatusDemande } from "../../../constants/status";

export interface DialogData {
  icone: string;
  title: string;
  code: string;
  message: string;
  color: string;
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  type: string;
  statut: string;
  result: boolean;
  idAgence: number;
  nivStatus: number;
  bBloquer: boolean;
  fAnnule: boolean;

}
@Component({
  selector: 'app-modal-update-confirmation',
  templateUrl: './modal-update-confirmation.component.html',
  styleUrls: ['./modal-update-confirmation.component.scss']
})

export class ModalUpdateConfirmationComponent implements OnInit {
  icone: string;
  title: string;
  message: string;
  color: string;
  iconColor: string;
  dialogWidth: string;
  dialogHeight: string;
  
  canValidateBool = false;
  canEditOrCreateBool = false;
  canReadBool = false;
  canTraitBool = false;
  canCancel = false;

  ValidationStatus: string[] = [,
    getStatusDemande(StatusDemande.AValider)];

  currentRoute: any;
  constructor(public dialog: MatDialog,
              private toastr: ToastrService,
              public router: Router,
              private demandeService: DemandeService,
              public dialogRef: MatDialogRef<Component>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private globalSettingsService: GlobalSettingsService) {
  }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.icone = this.data.icone !== undefined ? this.data.icone : '';
    this.title = this.data.title !== undefined ? this.data.title : '';
    this.iconColor = this.data.icon_color !== undefined ? this.data.icon_color : '';
    this.dialogWidth = this.data.dialogWidth !== undefined ? this.data.dialogWidth : '';
    this.dialogHeight = this.data.dialogHeight !== undefined ? this.data.dialogHeight : '';
    this.message = this.data.message;
    this.color = this.data.color;

    // - Visualisation demande -> niveau 1 type demande
    // - Création demande -> niveau 2 type demande
    // - Validation demande -> niveau 3 type demande
    // - Traitement Support -> niveau 5 type demande

    this.demandeService.getAuthorisation(this.data.idAgence).subscribe((res: number[]) => {
      
      if (res.find(d => (d >= 1))) {
        this.canReadBool = true; // Visualisation
      }
      if (res.find(d => d === 2 )) {
        this.canEditOrCreateBool = true; // Création
      }
      if (res.find(d => d === 3 )) {
        this.canValidateBool = true; // Validation
      }
      if (res.find(d => d === 5 )) {
        this.canTraitBool = true; // Traitement
      }
    });
  }

  canEditOrCreate(): boolean {
    return this.canEditOrCreateBool && this.data.statut === getStatusDemande(StatusDemande.Brouillon);
  }

  canTrait(): boolean {
    // 'En attente de traitement'
    return this.canTraitBool && this.data.statut === getStatusDemande(StatusDemande.Validate);
  }

  canValidate(): boolean {
    // 'En attente de validation'
    return this.canValidateBool && this.data.statut === getStatusDemande(StatusDemande.AValider);
  }

  canRead(): boolean {
    return this.canReadBool;
  }


  onModalCancel(): void {
    this.dialogRef.close(false);
  }

  onModalConfirm(action): void {
    this.data.result = true;
    this.dialogRef.close(action);
  }

  onModalConfirmAnnulation(): void {
    if (this.data.type === 'Demande') 
    {
      this.data.result = true;
      this.dialogRef.close('Annuler');
    } else {
      this.data.result = true;
      this.dialogRef.close(null);
    }
  }

}
