import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FiltreService } from 'src/app/core/filtre.service';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';
import { FiltreRechercheCollaborateurDTO } from 'src/app/shared/dtos/filtreRechercheCollaborateurDTO';
import { SimpleQueryResponse } from 'src/app/shared/dtos/simpleQueryResponse';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { UtilisateurModel } from 'src/app/shared/models/utilisateur/utilisateur.model';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { RegionService } from 'src/app/shared/services/rsa/region.service';
import { StateService } from 'src/app/shared/services/state.service';
import { UserService } from 'src/app/shared/services/user.service';
import { saveAs } from 'file-saver';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { SocieteModel } from 'src/app/shared/models/rsa/societe.model';
import { SocieteService } from 'src/app/shared/services/rsa/societe.service';


@Component({
  selector: 'app-recherche-collaborateur',
  templateUrl: './recherche-collaborateur.component.html',
  styleUrls: ['./recherche-collaborateur.component.scss']
})
export class RechercheCollaborateurComponent implements OnInit {
 
  agences: AgenceModel[];
  user: string;
  users: UtilisateurModel[] = [];

  filtreData: FiltreRechercheCollaborateurDTO = new FiltreRechercheCollaborateurDTO();;
  simpleQueryDemandeResponse = new SimpleQueryResponse<DemandeModel>();
  agence: AgenceModel;
  private filteredSubscribe: Subscription = new Subscription();
  currentRoute: any;
  regions: RegionModel[];
  societes: SocieteModel[];
  untouchedAllAgences: AgenceModel[] = [];
  untouchedAllRegions: RegionModel[] = [];
  untouchedAllSocietes: SocieteModel[] = [];
  regionsIdsa :string;
  societesIdsa :string;
  agenceIdsa :string;
  filterApplied :boolean = true;


  listTypeContrat = ["CDI","Prestation","CDD / Stage / Alternance","Intérim"];  
  listTypeCompte = ["Workspace", "Gdrive seul (Cloud Identity)"];  
  listCompteActif = ["Actif", "Desactive"];  
  listTypeContratUpdated = new BehaviorSubject<string[]>(this.listTypeContrat);
  listTypeCompteUpdated = new BehaviorSubject<string[]>(this.listTypeCompte);
  listCompteActifUpdated = new BehaviorSubject<string[]>(this.listCompteActif);



  regionsIdsArray:Set<number>  = new Set();
  societesIdsArray:Set<number>  = new Set();
  agencesIdsArray:Set<number>  = new Set();

  eventsSubjectAgenceArray: BehaviorSubject<Set<number>> = new BehaviorSubject(this.agencesIdsArray);
  eventsSubjectRegionArray: BehaviorSubject<Set<number>> = new BehaviorSubject(this.regionsIdsArray);
  eventsSubjectSocieteArray: BehaviorSubject<Set<number>> = new BehaviorSubject(this.societesIdsArray);
  inputRegionsString : string = "";
  inputSocietesString : string = "";


  constructor(public dialog: MatDialog,
              private router: Router,
              private titleService: Title,
              private userService: UserService,
              public demandeService: DemandeService,
              private state: StateService,
              private filtreService: FiltreService,
              private agenceService: AgenceService,
              private regionService: RegionService,
              private societeService: SocieteService,
              public collaborateurService: CollaborateurService,private cdr:ChangeDetectorRef) {

              }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.state.agence.subscribe(result => {
      this.agence = result;
      if (result) {
        this.filtreData.idAgence = this.agence.id;
      }
    });
    this.state.user.subscribe(result => {
      this.user = result;
    });
    this.filteredSubscribe = this.filtreService.filtreCollab.subscribe(result => {
      this.filtreData = result;
      
      this.regionsIdsArray.clear();
      this.filtreData.regions.forEach((region) => this.regionsIdsArray.add(region.id));
      this.inputRegionsString  = "";
      this.filtreData.regions.forEach(x => this.inputRegionsString += x.id + ',');
      this.eventsSubjectRegionArray.next(this.regionsIdsArray);

      this.societesIdsArray.clear();
      this.filtreData.societeObjs.forEach(x => this.societesIdsArray.add(x.id));
      this.inputSocietesString  = "";
      this.filtreData.societeObjs.forEach(x => this.inputSocietesString += x.id + ',');
      this.eventsSubjectSocieteArray.next(this.societesIdsArray);

      this.agencesIdsArray.clear();
      this.filtreData.agences.forEach(x=> this.agencesIdsArray.add(x.id));
      this.eventsSubjectAgenceArray.next(this.agencesIdsArray);
      this.listTypeContratUpdated.next(result.typeContrat);
      this.listTypeCompteUpdated.next(result.typeCompte);
      this.listCompteActifUpdated.next(result.compteActif);
      this.filterApplied = this.isAnyFilterApplied();
    });



      this.agenceService.getAll().subscribe(agences => {
        this.agences = agences.sort((a, b) => a.code.localeCompare(b.code));
        this.untouchedAllAgences = agences;
      });
      this.regionService.getAll().subscribe(regions => {
        this.regions = regions.sort((a, b) => a.code.localeCompare(b.code));
        this.untouchedAllRegions = regions;
      });
      this.societeService.getAll().subscribe(societes => {
        this.societes = societes.sort((a, b) => a.code.localeCompare(b.code));
        this.untouchedAllSocietes = societes;
      });
  }

  ngOnDestroy(): void {
    if(this.router.url != '/recherche-collaborateur' && !this.router.url.includes("/read-collaborateur/")){
        this.filtreData = new FiltreRechercheCollaborateurDTO();
        this.filtreData.agences = [];
        this.filtreData.regions = [];
        this.filtreData.societeObjs = [];
        this.agencesIdsArray.clear();
        this.regionsIdsArray.clear();
        this.societesIdsArray.clear();
        this.agencesIdsArray = new Set();
        this.regionsIdsArray = new Set();
        this.societesIdsArray = new Set();
        this.filtreData.compteActif = ['Actif'];
        this.filtreService.changeFiltreCollaborateur(this.filtreData);
        this.filteredSubscribe.unsubscribe();
    }
  }
  onChange(event): void {
    this.filtreData.pageNumber = 0;
    this.filtreData.regions.forEach(x => this.regionsIdsArray.add(x.id));
    this.inputRegionsString  = "";
    this.filtreData.regions.forEach(x => this.inputRegionsString += x.id + ',');

    this.filtreData.agences.forEach(x => this.agencesIdsArray.add(x.id));

    this.filtreData.societeObjs.forEach(x => this.societesIdsArray.add(x.id));
    this.inputSocietesString  = "";
    this.filtreData.societeObjs.forEach(x => this.inputSocietesString += x.id + ',');
    this.filterApplied = this.isAnyFilterApplied();
    this.filtreService.changeFiltreCollaborateur(this.filtreData);
  }

  isAnyFilterApplied(): boolean {
    return (
      this.filtreData.nom.trim() !== '' ||
      this.filtreData.prenom.trim() !== '' ||
      this.filtreData.email.trim() !== '' ||
      this.filtreData.agence !== null ||
      this.filtreData.agences.length > 0 ||
      this.filtreData.idAgence !== null ||
      this.filtreData.societeLibelle.trim() !== '' ||
      this.filtreData.societeId !== null ||
      this.filtreData.societeObj !== null ||
      this.filtreData.societeObjs.length > 0 ||
      this.filtreData.region !== null ||
      this.filtreData.regions.length > 0 ||
      this.filtreData.idRegion !== null ||
      (this.filtreData.typeContrat.length > 0 && this.filtreData.typeContrat.length != 4 )||
      (this.filtreData.typeCompte.length > 0 && this.filtreData.typeCompte.length != 2 )||
      (this.filtreData.compteActif.length > 0  && this.filtreData.compteActif.length != 2)
    );
  }
  clearFiltre(): void {
    this.filtreData = new FiltreRechercheCollaborateurDTO();
    this.filtreData.agences = [];
    this.filtreData.regions = [];
    this.filtreData.societeObjs = [];
    this.agencesIdsArray.clear();
    this.regionsIdsArray.clear();
    this.societesIdsArray.clear();
    this.agencesIdsArray = new Set();
    this.regionsIdsArray = new Set();
    this.societesIdsArray = new Set();
    this.filtreData.compteActif = ['Actif'];
    this.onChange(this.filtreData);
  }

  onFiltreChange(event, state): void {
    switch (state) {
      case 'Type de contrat':
        this.filtreData.typeContrat = event;
        break;
      case 'Type de compte':
        this.filtreData.typeCompte = event;
        break;  
      case 'Compte actif':
        this.filtreData.compteActif = event;
        break;    
    }
    this.onChange(event);
  } 


  onExport(): void {
    const dialogRef = this.dialog.open(SpinnerModalComponent);
    this.collaborateurService.exportFacturation(this.filtreData).subscribe((res: BlobPart)  => {
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), res], {type: 'text/csv;charset=utf-8'});
      saveAs(blob, 'Export_Collaborateur_Facturation' + new Date().toISOString() + '.csv');
      dialogRef.close();
    }, (error) => dialogRef.close());
  }

  onExportApplications(): void {
    const dialogRef = this.dialog.open(SpinnerModalComponent);
    this.collaborateurService.exportApplications(this.filtreData).subscribe((res: BlobPart)  => {
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), res], {type: 'text/csv;charset=utf-8'});
      saveAs(blob, 'Export_Collaborateur_Application' + new Date().toISOString() + '.csv');
      dialogRef.close();
    }, (error) => dialogRef.close());
  }

  regionChange(event){
    let array = event;
    if (array != undefined && array.length != 0){
      let regions : RegionModel[] = [];
      regions = this.regions.filter(item => array.indexOf(item.id) !== -1);
      this.filtreData.regions = [];
      this.filtreData.regions = regions;
      let agences : AgenceModel[] = [];
      let agencesReg : AgenceModel[] = [];
      let agencesSoc : AgenceModel[] = [];
      agencesReg = this.filtreData.agences.filter(item => array.includes(item.regionId));
      agencesSoc = this.filtreData.agences.filter(item => this.societesIdsArray.has(item.societeId));
      for (let i = 0 ; i < agencesReg.length ; i++) {
        let agReg = agencesReg[i];
        if (agences.indexOf(agReg) == -1 && agencesSoc.indexOf(agReg) != -1) {
          agences.push(agReg);
        }
      }
      this.filtreData.agences = [];
      this.filtreData.agences = agences;
      this.onChange(this.filtreData);
    }else{
      this.filtreData.regions = [];
      this.onChange(this.filtreData);
    }
  }

  societeChange(event){
    let array = event
    if (array != undefined && array.length != 0){
      let societes : SocieteModel[] = [];
      societes = this.societes.filter(item => array.indexOf(item.id) !== -1);
      this.filtreData.societeObjs = []
      this.filtreData.societeObjs = societes
      let agences : AgenceModel[] = [];
      let agencesReg : AgenceModel[] = [];
      let agencesSoc : AgenceModel[] = [];
      agencesReg = this.filtreData.agences.filter(item => this.regionsIdsArray.has(item.regionId));
      agencesSoc = this.filtreData.agences.filter(item => array.includes(item.societeId));
      for (let i = 0 ; i < agencesReg.length ; i++) {
        let agReg = agencesReg[i];
        if (agences.indexOf(agReg) == -1 && agencesSoc.indexOf(agReg) != -1) {
          agences.push(agReg);
        }
      }
      this.filtreData.agences = [];
      this.filtreData.agences = agences;
      this.onChange(this.filtreData);
    }else{
      this.filtreData.societeObjs = [];
      this.onChange(this.filtreData);
    }
  }

  agenceChange(event){
    let array = event;
    if (array != undefined && array.length != 0){
      let x : AgenceModel[] = [];
      x = this.agences.filter(item => array.indexOf(item.id) !== -1);
      this.filtreData.agences.splice(0,this.filtreData.agences.length);
      this.filtreData.agences = x;
      this.onChange(this.filtreData);
    }else{
      this.filtreData.agences = [];
      this.onChange(this.filtreData);
    }
  }
}