<div class="col-sm-12">
  <h1>Gestion utilisateur</h1>
 
  <div class="row">
    <div class="col-sm-12" style="display: flex; justify-content: flex-start; align-items: flex-start;">
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Rechercher un utilisateur</mat-label>
          <input matInput placeholder="Ex: Dupont" [(ngModel)]="objet.query" (keydown.enter)="search()">
        </mat-form-field>
      </div>
     
      <div class="col-sm-2 text-center">
        <button mat-raised-button color="primary" type="button" (click)="search()" style="color: white;"mat-icon-button><mat-icon>search</mat-icon></button>
      </div>


      <div class="col-sm-4" style="margin-top: -20px;">
        <div class="row">
        <div class="col-md-6">
          <button color="success" mat-raised-button type="button" (click)="export('perimetre')">
            <mat-icon>import_export</mat-icon>Export perimetre
          </button>
      </div>
    </div>
    <div class="row">
  
        <div class="col-md-6">
          <button color="success" mat-raised-button type="button" (click)="export('user')">
            <mat-icon>import_export</mat-icon>Export utilisateur
          </button> 
        </div>
    </div>
  </div>
      

    </div>  
  </div>
  <div class="mat-table">
    <div class="table_principale">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
        <caption>Liste des utilisateurs</caption>

        <ng-container matColumnDef="nom">
          <th mat-header-cell *matHeaderCellDef> Nom </th>
          <td mat-cell *matCellDef="let element"> {{element.nom}} </td>
        </ng-container>
    
        <ng-container matColumnDef="prenom">
          <th mat-header-cell *matHeaderCellDef> Prénom </th>
          <td mat-cell *matCellDef="let element"> {{element.prenom}} </td>
        </ng-container>
    
        <ng-container matColumnDef="tel">
          <th mat-header-cell *matHeaderCellDef> Téléphone </th>
          <td mat-cell *matCellDef="let element"> {{element.tel}} </td>
        </ng-container>
    
        <ng-container matColumnDef="agence">
          <th mat-header-cell *matHeaderCellDef> Agence </th>
          <td mat-cell *matCellDef="let element"> 
              <div *ngIf="element.agence"> {{element.agence.societeObj ? element.agence.societeObj.libelle : ""}} / {{element.agence.code}}-{{element.agence.libelle}} {{element.agence.code}} - {{element.agence.libelle}}</div> 
            </td>
        </ng-container>
  
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="openEvent(row)"></tr>
      </table>
    
      <div class="footer">
        <mat-paginator
          #paginator
          [length]="length"
          [pageSize]="pageSize"
          [pageSizeOptions]="[10, 25, 50]"
          (page)="changePage($event)">
        </mat-paginator>
      </div>
      
    </div>
  </div>
</div>