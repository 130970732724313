import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { LayoutsModule } from './layouts/layouts.module';
import { MaterialModule } from './material/material.module';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RechercheCollaborateurComponent } from './core/recherche/recherche-collaborateur/recherche-collaborateur/recherche-collaborateur.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListCollaborateurForExportsComponent } from './core/recherche/list-collaborateur-for-exports/list-collaborateur-for-exports.component';


@NgModule({
  declarations: [
    AppComponent,
    FourOhFourComponent,
    RechercheCollaborateurComponent,
    ListCollaborateurForExportsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    SharedModule,
    CoreModule,
    LayoutsModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
