import { Component, Inject,  OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';

export interface DialogData {
  observable: Observable<number>;
  total: number;
  result: boolean;
}
@Component({
  selector: 'app-modal-chargement',
  templateUrl: './modal-chargement.component.html',
  styleUrls: ['./modal-chargement.component.scss']
})
export class ModalChargementComponent implements OnInit {

  nb: number;
  total: number = 0;
  pourcent: number = 0;

  constructor(
    public dialogRef: MatDialogRef<Component>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    this.data.observable.subscribe(res => {
      this.nb = res;
      this.total = this.data.total;
      this.pourcent = (this.nb / this.total) * 100;
      if (this.pourcent === 100) {
        this.dialogRef.close(true);
      }
    }, (error) => this.dialogRef.close());
  }

}
