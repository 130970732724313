<mat-drawer-container class="container-position">

  <mat-drawer #sideNav mode="side" opened="false" position="end" class="drawer" style="Overflow-y:scroll;">

    <div>
      <br />
      <div class="header-class">

        <div class="col-6">
          <h6>Filtres</h6>
        </div>
        <div class="col-6">
          <button color="warn" mat-raised-button (click)="clearFiltre()">
            <mat-icon>cancel</mat-icon> Réinitialiser
          </button>

        </div>
      </div>
    </div>
    <br />
    <div class="data-class" *ngIf="filtreData">
      <br />
      <div class="row">
        <div class="col-sm-12 margin_bm_20" style="margin-bottom: 10px;" >
          <app-custom-select-multiple-option 
            (selected)="onFiltreChange($event, 'Statut')" [InputvaluesList]="listStatus" 
            [InputSelectedValues]="listStatusUpdated" [title]="'Statut'">
          </app-custom-select-multiple-option>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 margin_bm_20">
          <app-custom-select-multiple-option 
            (selected)="onFiltreChange($event, 'Type Demande')" [InputvaluesList]="listTypeDemande" 
            [InputSelectedValues]="listTypeDemandeUpdated" [title]="'Type Demande'">
          </app-custom-select-multiple-option>
        </div>
      </div>
      <div class="row "> 
        
      </div> 
      <div class="row "> 
        <div class="col-sm-12 margin_bm_20">
          <mat-form-field class="full-width">
            <mat-label>Numéro de la demande</mat-label>
            <input [(ngModel)]="filtreData.numDemande" matInput (ngModelChange)="onChange($event)" name="numDemande"
              style="font-size: small;" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
      </div>
      <div class="row "> 
        <div class="col-sm-12 margin_bm_20">
          <mat-form-field class="full-width">
            <mat-label>Nom</mat-label>
            <input [(ngModel)]="filtreData.nom" matInput (ngModelChange)="onChange($event)" name="nom"
              style="font-size: small;" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
      </div>
      <div class="row "> 
        <div class="col-sm-12 margin_bm_20">
          <mat-form-field class="full-width">
            <mat-label>Prénom</mat-label>
            <input [(ngModel)]="filtreData.prenom" matInput (ngModelChange)="onChange($event)" name="prenom"
              style="font-size: small;" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
      </div>
      <div class="row "> 
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-region-multiple-select [untouchedAllRegions]="untouchedAllRegions"  [eventsSubjectRegionArray]="eventsSubjectRegionArray" (updated)="regionChange($event)">
          </app-autocomplete-region-multiple-select>
        </div>
      </div>
      <div class="row "> 
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-societe-multiple-select [untouchedAllSocietes]="untouchedAllSocietes" [eventsSubjectSocieteArray]="eventsSubjectSocieteArray" (updated)="societeChange($event)">
          </app-autocomplete-societe-multiple-select>
        </div>
      </div>
       <div class="row "> 
        <div class="col-sm-12 margin_bm_20">
          <app-autocomplete-agence-multiple-select [untouchedAllRegions]="untouchedAllRegions"  [untouchedAllAgences]="untouchedAllAgences" [eventsSubjectAgenceArray]="eventsSubjectAgenceArray" [agencesIdsArray]="agencesIdsArray" [inputRegionsString]="inputRegionsString"
            [inputSocietesString]="inputSocietesString"
            (updated)="agenceChange($event)">
          </app-autocomplete-agence-multiple-select>
        </div>
      </div>
      <div class="row "> 
        
      </div> 
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div>
      <div class="row">
        <div class="col-sm-10 margin_bm_20">
          <h3> {{title}} </h3>
        </div>
        <div *ngIf="isListAValide" class="col-sm-2" style="display: flex; justify-content: flex-end;">
          <button color="success" mat-raised-button type="button" (click)="onValidationDemandes()" [disabled]="selectedDemandesChild.length === 0">
            <mat-icon>check</mat-icon>Valider des Demandes
          </button>
        </div>
      </div>
      <div class="col-sm-12">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Résultats :
            </mat-card-title>
            <span class="filter-button">
              <mat-card-title>
                
                <button color="primary" (click)="sideNav.toggle()" mat-stroked-button>
                  <mat-icon color="primary" id="icon">filter_list</mat-icon>filtres
                </button>
              </mat-card-title>
            </span>
          </mat-card-header>
          <mat-card-content style="display: flex;
          flex-direction: column;
          min-width: 400px;
          height: 100%;
          margin: 0 20px;">
            <app-demande-list style="flex: 1;min-height: 0;overflow: auto"[datasource]="demandeList" [ifListAValide]="isListAValide" 
            (selectedRowsEmit)="getSelectedDemandes($event)" [(filtreData)]="filtreData" [simpleQueryDemandeResponse]="simpleQueryDemandeResponse" >
            </app-demande-list>
            
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>











