import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-historique-demandes',
  templateUrl: './historique-demandes.component.html',
  styleUrls: ['./historique-demandes.component.scss']
})
export class HistoriqueDemandesComponent implements OnChanges {

  @Input() idCollaborateur: number;
  @Input() listDemande:  DemandeModel[];
  selection = new SelectionModel<DemandeModel>(true, []);
  selectedDemandes: DemandeModel[] = [];  
  matDataSourceDemande: MatTableDataSource<DemandeModel> = new MatTableDataSource<DemandeModel>();
  displayedColumnsDemande: string[] = ['Action','Demandeur','Date de création', 'Date de traitement', 'Statut',  'Modification']

  constructor(   
    private router: Router,
    private demandeService: DemandeService,
    private collabService: CollaborateurService,
    public dialog: MatDialog
    ) {
      
     }

  ngOnChanges(changes: SimpleChanges): void { 

        // Si l'appel est depuis le modal de validation / traitement demande on affiche que les demandes validé / traité
        if(this.listDemande) {
          this.matDataSourceDemande = new MatTableDataSource(this.listDemande);  
        }
        // Si l'appel est depuis la page Details du collaborateur on affiche toutes les demandes
        else if(this.idCollaborateur) {
         // get demandes where collab id = id
         this.demandeService.getAllDemandeByIdCollab(this.idCollaborateur).subscribe(res => {
           this.listDemande = res;                    
           this.matDataSourceDemande = new MatTableDataSource(this.listDemande);              
          })
        }  
        
        this.matDataSourceDemande.data.forEach(row => { 
          this.collabService.get(row.idCreateur).subscribe(res => {
            row.createur = res;
          })
        });  
  }

  ngOnInit(): void {
    
  }
  editDemande(demande: DemandeModel): void {
      this.demandeService.get(demande.id).subscribe(res => { //On ouvre un onglet que si on a les droits, sinon toast erreur
        const url = this.router.createUrlTree(['read-demande/', demande.id])
        window.open(url.toString(), '_blank')
      }); 
  }
}
