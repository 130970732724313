import { CollaborateurModel } from '../utilisateur/collaborateur.model';
import { EntiteSARPModel } from './entite-sarp.model';
import { RegionModel } from './region.model';
import { SocieteModel } from './societe.model';

export class AgenceModel extends EntiteSARPModel {
    public societeLibelle: string;
    public societeId?: number;
    public societeObj: SocieteModel;
    public region: RegionModel;
    public regionId: number;
    public adresse: string;
    public codePostal: string;
    public ville: string;
    public telephone: string;
    public email: string;
    public siret?: string;
    // nouveaux champs
    public codeConso: string;
    public idDirecteurAgence: string;
    public directeurAgence: CollaborateurModel;
    public idContact1: string;
    public contact1: CollaborateurModel;
    public idContact2: string;
    public contact2: CollaborateurModel;
    public infosDirecteurAgence: string;
    public infosContact: string;

}
