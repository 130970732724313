import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { APPLICATIONDEMANDEVALIDATORS } from 'src/app/shared/constants/application-demande-validators';
import { ApplicationDemandeModel } from 'src/app/shared/models/simple-types/application-demande.model';
import { ApplicationUserModel } from 'src/app/shared/models/simple-types/application-user.model';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';
import { DemandeComponentsService } from '../demande-components.service';
import { TypeDemande } from 'src/app/shared/constants/typeDemande';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() collabApplications: ApplicationUserModel[] = [];
  @Input() form: UntypedFormGroup;
  @Input() untouchedApps: ApplicationModel[];
  applicationsDemande: ApplicationDemandeModel[] = [];
  selectedApplication: ApplicationModel[] = [];
  appsAdd: ApplicationModel[] = [];
  appsDelete : ApplicationModel[] = [];
  applications : ApplicationModel[] = [];
  AllApplications : ApplicationModel[] = [];
  cdsChecked: boolean;
  csdSubscriber: Subscription;
  defaultApplications : ApplicationModel[] = [];
  invisibleApplications : ApplicationModel[] = [];
  lockAppsForModif: boolean = true;
  constructor(
    private applicationService: ApplicationService,
    private demandeComponentsService: DemandeComponentsService,
    public router: Router) { }

  ngOnInit(): void {
    // get CDS check event
    this.csdSubscriber = this.demandeComponentsService.cdsCheckSubject.subscribe(res => {
      this.cdsChecked = res;
      if (this.form 
            && this.form.get('typeDemande').value !== undefined 
            && this.form.get('typeDemande').value !== null 
            && this.form.get('typeDemande').value !== TypeDemande.Suppression
      ) {
        if (this.cdsChecked == true) {
          this.selectedApplication = this.selectedApplication.filter(x => x.cdsOn == true);
        }
        else {
          this.selectedApplication = [...this.selectedApplication, 
            ...this.AllApplications.filter(x => x.default == true 
                || (!x.cdsOn && this.collabApplications.find(appCollab => appCollab.idApplication === x.id)))]; //On deselectionne la supression des app non CDS 
        }
      }
       this.setApplicationDemandeForm();
       this.trieList();
    });
      this.defaultApplications = this.untouchedApps.slice().filter(app=> app.default === true && app.invisible === false);
      this.invisibleApplications = this.untouchedApps.slice().filter(app=> app.invisible === true);
  }
  
  ngOnDestroy(): void {
    this.csdSubscriber.unsubscribe();
    this.demandeComponentsService.cdsCheck(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // all apps
    this.lockAppsForModif = true;
    if(this.untouchedApps.length != 0){
      this.AllApplications = Object.assign([],this.untouchedApps);
      this.applications = Object.assign([],this.untouchedApps);
      this.applications.sort((a, b) => a.nom.localeCompare(b.nom))
      
      if (this.form) {
        this.applicationsDemande = (this.form.get('applications') as UntypedFormArray).getRawValue();
        if (this.form.get('typeDemande').value !== TypeDemande.Suppression){ // Pas suppression (Création, modification transfert)
          this.selectedApplication = this.applications.filter(app => 
            (
              (this.collabApplications.find(x => x.idApplication === app.id) !== undefined) && 
              (this.applicationsDemande.find(x => x.idApplication === app.id && x.action === false) === undefined) &&
              (!this.cdsChecked || app.cdsOn) //Selection des app déjà présentes sans demande de supression, et qui sont CDS si le compte est CDS
            ) ||
            (this.applicationsDemande.find(x => x.idApplication === app.id && x.action === true)) || 
            (app.default === true && !this.cdsChecked)
            );
          this.trieList();   
        }
        this.setApplicationDemandeForm();
      }
      this.lockAppsForModif = false;
    }else{
      this.lockAppsForModif = false;
    }
  }

  trieList(){
    let tempOldAppOrDefault : ApplicationModel[] = [];
    let tempCds : ApplicationModel[] = [];
    let tempOther : ApplicationModel[] = [];
    for (let i = 0; i < this.applications.length; i++) {
      if (this.collabApplications.find(app => app.idApplication === this.applications[i].id) !== undefined
      || this.applications[i].default && !this.cdsChecked) {//Les app déjà existantes en premier, et les app par défaut si non CDS
        tempOldAppOrDefault.push(this.applications[i]);
      } else if (this.applications[i].cdsOn && this.cdsChecked === true) {
        tempCds.push(this.applications[i]);
      } else {
        tempOther.push(this.applications[i]);
      }
    }
    tempOldAppOrDefault.sort((a, b) => {return a.default === b.default ? a.nom.localeCompare(b.nom) : (a.default ? -1 : 1);});
    tempCds.sort((a, b) => {return a.nom.localeCompare(b.nom)});
    tempOther.sort((a, b) => {return a.nom.localeCompare(b.nom)});
    this.applications = [...tempOldAppOrDefault, ...tempCds, ...tempOther];
  }

  isChecked(id: number): string {
    const existCollabApp = this.collabApplications.find(app => app.idApplication === id) !== undefined;
    const existDemandeAppDelete = this.applicationsDemande.find(app => app.idApplication === id && app.action === false) !== undefined;
    const existDemandeAppAdd = this.applicationsDemande.find(app => app.idApplication === id && app.action) !== undefined;
    const isDefault = this.applications.find(app=>app.id === id && app.default === true);
    const isCollabCDS = this.cdsChecked;

    if (isDefault && !isCollabCDS){
      return "default-app";
    }
    if (existCollabApp){ //Application deja existante
      if (!existDemandeAppDelete){ //applicationDemande a supprimer = action == true
        return 'warn'; //aficher en orange comme quoi c'est deja ajouté
      }else { //aplication a supprimer action == true
        return 'red';//afificher en rouge en mode on va le supprimer
      }
    }else {
      if (existDemandeAppAdd){ //ajout
        return 'green';
      }
    }
    return 'default';
  }

  isCDS(id: number) {
    if (this.applications.find(app=>app.id === id && (app.cdsOn === true))) {
      return true;
    }
  }

  add(app){
    this.selectedApplication.push(app);
    this.setApplicationDemandeForm();
  }

  delete(app){
    var index = this.selectedApplication.findIndex((obj) => obj.id === app.id);
    if (index > -1){
      this.selectedApplication.splice(index, 1);
    }
    this.setApplicationDemandeForm();
  }

  setApplicationDemandeForm(){
    this.applications = this.applications.filter(app=>app.invisible === false);

    if (this.form.get('typeDemande').value === TypeDemande.Suppression) {
      this.selectedApplication = []; //delete all
    }

    //Find data that are in applicationSelected but not in collabApplications (application added)
    var appToAdd = this.selectedApplication.filter((objOne) => {
      return !this.collabApplications.some((objTwo) => {
          return objOne.id == objTwo.idApplication;
      });
    });

    //Find data that are in collabApplications but not in applicationSelected (application deleted)
    var appToRemove = this.collabApplications.filter((objOne) => {
      return !this.selectedApplication.some((objTwo) => {
          return objOne.idApplication == objTwo.id;
      }) && objOne.application.dDesactivation === null; //Conserve les app desactivées
    });

    var demandeApps = [];
    // convert appToAdd to AppliDemandeModel
    for (var i = 0; i < appToAdd.length; i++) {
      var demandeAppToAdd = new ApplicationDemandeModel();
      demandeAppToAdd.id = 0;
      demandeAppToAdd.action = true;
      demandeAppToAdd.idApplication = appToAdd[i].id;
      demandeApps.push(demandeAppToAdd);
    }
    // convert appToRemove to AppliDemandeModel
    for (var i = 0; i < appToRemove.length; i++) {
      var demandeAppToDelete = new ApplicationDemandeModel();
      demandeAppToDelete.id = 0;
      demandeAppToDelete.action = false;
      demandeAppToDelete.idApplication = appToRemove[i].idApplication;
      demandeApps.push(demandeAppToDelete);
    }
    // create array form 
    var formArray = this.demandeComponentsService.createArrayAppliDemande(demandeApps, APPLICATIONDEMANDEVALIDATORS.CREATE);
    this.form.setControl('applications', formArray);
    
    this.applicationsDemande = (this.form.get('applications') as UntypedFormArray).getRawValue(); 

  }

  filterApp(query){
    if (query.target.value && query.target.value !== ""){
      this.applications = this.AllApplications.filter(app => app.nom.includes(query.target.value)); 
    }else {
      this.applications = this.AllApplications;
    }
  }

}
