import { Validators } from '@angular/forms';

export class FormInfo {
  validators: Validators;
  disabled: boolean;
  constructor(required: boolean, validator: Validators) {
    this.disabled = required;
    this.validators = validator;
  }
}

export const REGIONVALIDATORS = {
  CREATE: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['code', new FormInfo(false, [Validators.required])],
    ['libelle', new FormInfo(false, [Validators.required])],
    ['type', new FormInfo(false, [])],
    ['groupe_win', new FormInfo(false, [])],

  ]),
  EDIT: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['code', new FormInfo(true, [Validators.required])],
    ['libelle', new FormInfo(false, [Validators.required])],
    ['type', new FormInfo(false, [])],
    ['groupe_win', new FormInfo(false, [])],
  ])
};

export const SOCIETEVALIDATORS = {
  CREATE: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['code', new FormInfo(false, [Validators.maxLength(7), Validators.required])],
    ['libelle', new FormInfo(false, [Validators.maxLength(30), Validators.required])],
    ['siret', new FormInfo(false, [Validators.maxLength(15), Validators.required])],
  ]),
  EDIT: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['code', new FormInfo(true, [Validators.maxLength(7), Validators.required])],
    ['libelle', new FormInfo(false, [Validators.maxLength(30), Validators.required])],
    ['siret', new FormInfo(false, [Validators.maxLength(15), Validators.required])],

  ]),
  VISUALIZE: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])], 
    ['dDesactivation', new FormInfo(true, [])],
    ['code', new FormInfo(true, [Validators.maxLength(7)])],
    ['libelle', new FormInfo(true, [Validators.maxLength(30)])],
    ['siret', new FormInfo(true, [Validators.maxLength(15)])],

  ])
};

export const AGENCEVALIDATORS = {
  CREATE: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['code', new FormInfo(false, [Validators.required])],
    ['libelle', new FormInfo(false, [Validators.required])],
    ['type', new FormInfo(false, [])],
    ['societeId', new FormInfo(false, [])],
    ['regionId', new FormInfo(false, [])],
    ['adresse', new FormInfo(false, [])],
    ['codePostal', new FormInfo(false, [])],
    ['ville', new FormInfo(false, [])],
    ['telephone', new FormInfo(false, [])],
    ['email', new FormInfo(false, [])],
    ['siret', new FormInfo(false, [])],
    ['codeConso', new FormInfo(false, [])],
    ['idDirecteurAgence', new FormInfo(false, [])],
    ['infosDirecteurAgence', new FormInfo(false, [])],
    ['idContact1', new FormInfo(false, [])],
    ['idContact2', new FormInfo(false, [])],
    ['infosContact', new FormInfo(false, [])],
  ]),
  EDIT: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['code', new FormInfo(true, [Validators.required])],
    ['libelle', new FormInfo(false, [Validators.required])],
    ['type', new FormInfo(false, [])],
    ['societeId', new FormInfo(false, [])],
    ['regionId', new FormInfo(false, [])],
    ['adresse', new FormInfo(false, [])],
    ['codePostal', new FormInfo(false, [])],
    ['ville', new FormInfo(false, [])],
    ['telephone', new FormInfo(false, [])],
    ['email', new FormInfo(false, [])],
    ['siret', new FormInfo(false, [])],
    ['codeConso', new FormInfo(false, [])],
    ['idDirecteurAgence', new FormInfo(false, [])],
    ['infosDirecteurAgence', new FormInfo(false, [])],
    ['idContact1', new FormInfo(false, [])],
    ['idContact2', new FormInfo(false, [])],
    ['infosContact', new FormInfo(false, [])],
  ]),

  VISUALIZE: new Map<string, FormInfo>([
    ['id', new FormInfo(true, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['code', new FormInfo(true, [Validators.required])],
    ['libelle', new FormInfo(true, [Validators.required])],
    ['type', new FormInfo(true, [])],
    ['societeId', new FormInfo(true, [])],
    ['regionId', new FormInfo(true, [])],
    ['adresse', new FormInfo(true, [])],
    ['codePostal', new FormInfo(true, [])],
    ['ville', new FormInfo(true, [])],
    ['telephone', new FormInfo(true, [])],
    ['email', new FormInfo(true, [])],
    ['siret', new FormInfo(true, [])],
    ['codeConso', new FormInfo(true, [])],
    ['idDirecteurAgence', new FormInfo(true, [])],
    ['infosDirecteurAgence', new FormInfo(true, [])],
    ['idContact1', new FormInfo(true, [])],
    ['idContact2', new FormInfo(true, [])],
    ['infosContact', new FormInfo(true, [])],
  ])
};
