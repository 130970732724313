import { Router } from '@angular/router';
import { UserComponentsService } from './../user-components.service';
import { ListUserComponent } from './../list-user/list-user.component';
import { UserService } from 'src/app/shared/services/user.service';
import { UtilisateurModel } from 'src/app/shared/models/utilisateur/utilisateur.model';
import {Component, Inject, OnInit} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {  UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'src/app/shared/services/session.service';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';


export interface DialogData {
    email: string;
  }

@Component({
    selector: 'app-modal-add-user',
    templateUrl: './modal-add-user.component.html',
    styleUrls: ['./modal-add-user.component.scss']
  })
  export class ModalAddUserComponent implements OnInit {
    agence: AgenceModel = null;
    user: UtilisateurModel;
    form: UntypedFormGroup;
    agences: AgenceModel[] = [];

    constructor( public dialogRef: MatDialogRef<ListUserComponent>,
                 @Inject(MAT_DIALOG_DATA) public data: DialogData,
                 private agenceService: AgenceService,
                 private userService: UserService,
                 private sessionService: SessionService,
                 private router: Router,
                 private toastService: ToastrService,
                 private userComponentService: UserComponentsService) { }

    ngOnInit(): void {
      this.form = this.userComponentService.getCreateUserFormGroup();
      this.user = new UtilisateurModel();
      this.agenceService.getAllFromAdmin().subscribe(agences => {
        this.agences = agences.sort((a, b) => a.code.localeCompare(b.code));
      });
    }

    getEmailErrorMessage(): string {
      if (this.form.get('email').hasError('required')) {
        return 'Veuillez entrer un email';
      }
      return 'Veuillez entrer un email valide. Ex : test@veolia.com' ;
    }

    getNomErrorMessage(): string {
      return 'Veuillez entrer un nom';
    }

    getPrenomErrorMessage(): string {
      return 'Veuillez entrer un prenom';
    }

    getTelephoneErrorMessage(): string {
      return  'Veuillez entrer un téléphone valide. Ex : 0554545454' ;
    }

    closeDialog(val: boolean): void {
      this.dialogRef.close(val);
    }

    create_user(): void {
      this.user = this.form.getRawValue();
      this.user.id = 0;
      this.user.actif = true;
      if (!this.form.invalid) {
        this.user.nom = this.form.get('nom').value.toUpperCase();
        this.user.prenom = this.form.get('prenom').value.charAt(0).toUpperCase() + this.user.prenom.slice(1);
        this.user.agence = null;
        this.userService.save(this.user).subscribe((user: UtilisateurModel) => {
          if (user) {
              this.toastService.success('Vous avez créé l\'utilisateur ' + user.email);
              this.closeDialog(true);
              this.router.navigate(['/user-record/' + user.id]);
          } else {
              this.toastService.error('L\'utilisateur existe déjà');
          }
        });
      } else {
        this.toastService.error('Veuillez remplir les champs obligatoires');
      }
    }

    onChangeAgence(agence: AgenceModel): void {
      if (agence) {
        this.user.idAgence = agence.id;
        this.form.get('idAgence').setValue(agence.id);
      }
    }
  }


