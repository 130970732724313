export enum StatusDemande {
  Brouillon,
  AValider,
  Validate,
  Refuse,
  ATraiterAD,
  Traite,
  Annule
}

export function getStatusDemande(num: number): string {
  let lbl = "";
  switch (num)
  {
      case StatusDemande.Brouillon: lbl = "Brouillon"; break;
      case StatusDemande.AValider: lbl = "En attente de validation"; break;
      case StatusDemande.Validate: lbl = "En attente de traitement"; break;
      case StatusDemande.Refuse: lbl = "Refusé"; break;
      case StatusDemande.ATraiterAD: lbl = "Traitement DSI"; break;
      case StatusDemande.Traite: lbl = "Traité"; break;
      case StatusDemande.Annule: lbl = "Annulé"; break;
      default: break;
  }
  return lbl;
}