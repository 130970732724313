<form class="form">
  
  <mat-form-field class="full-width">        
         <mat-label>{{libelle}}</mat-label>
         <input type="text"
                 placeholder="Selectionner une societe"
                 matInput
                 [required]="required"            
                 [formControl]="myControl"
                 [matAutocomplete]="auto"
                 (change)="changeEvent()">
         <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeEvent()">
             <mat-option *ngFor="let option of filteredSocietes | async" [value]="option" [matTooltip]="option"> {{option}} </mat-option>
         </mat-autocomplete>
     </mat-form-field>
 </form>