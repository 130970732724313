import { BasicModel } from "../basic/basic.model";
import { DocumentModel } from "./document.model";
import { ResponsableAppliModel } from "./responsable-appli.model";

export class ApplicationModel extends BasicModel {
    // Nom application
    nom: string;
    // Group AD
    groupe: string;
    // Chemin vers le groupe AD
    path: string;
    // groupe de facturation
    groupeFact: string;

    documents: DocumentModel[] = [];

    responsables: ResponsableAppliModel[] = [];

    invisible : boolean;

    default : boolean;

    cdsOn : boolean;
    
    linkFormApplicatif : string;

    categorie : string;
}