import { Component, Inject, OnInit } from '@angular/core';
import { SimpleQueryResponse } from 'src/app/shared/dtos/simpleQueryResponse';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FiltreRechercheCollaborateurDTO } from 'src/app/shared/dtos/filtreRechercheCollaborateurDTO';
import { SimpleQuery } from 'src/app/shared/dtos/simple_query';

export interface DialogData {
  comingFrom: String;
}
@Component({
  selector: 'app-modal-update-collaborateur',
  templateUrl: './modal-update-collaborateur.component.html',
  styleUrls: ['./modal-update-collaborateur.component.scss']
})

export class ModalUpdateCollaborateurComponent implements OnInit {

  reponseCollaborateur= new SimpleQueryResponse<CollaborateurModel>();
  filtre: SimpleQuery<FiltreRechercheCollaborateurDTO>;

  constructor(              
    public dialogRef: MatDialogRef<Component>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    
  }

  collaborateurChange(reponse: SimpleQueryResponse<CollaborateurModel>){
    this.reponseCollaborateur = reponse; 
  }

  updateFiltre(filtre: SimpleQuery<FiltreRechercheCollaborateurDTO>){
    this.filtre = filtre;
  }
}
