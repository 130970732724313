<div class="row">
    <div class="col-sm-12 padding-0" style="display: flex; justify-content: space-between;">
        <h3 class="col-sm-6"> Details du collaborateur </h3>
        <div class="col-sm-6" style=" justify-content: space-between;">
            
            <button color="default" mat-raised-button type="button" (click)="close()">
                <mat-icon>arrow_back</mat-icon>Retour à la liste
            </button>
            <button color="success" mat-raised-button type="button" [disabled]="onlyViewDroits" (click)="updateCollaborateur()">
                <mat-icon>edit</mat-icon>Modification
            </button>
            <button color="danger" mat-raised-button type="button"  [disabled]="onlyViewDroits || this.collaborateur.dDesactivation != null" (click)="deleteCollaborateur()">
                <mat-icon>clear</mat-icon>Suppression
            </button>

        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-9">
        <div class="col-sm-12">
            <app-demande-form [form]="demandeForm" [agence]="demande.agence"></app-demande-form>
        </div>
        <div class="col-sm-12">
            <app-historique-demandes [idCollaborateur]="collaborateur.id" ></app-historique-demandes>
        </div>
    </div>
    <div class="col-sm-3">
        <app-application-fixed [untouchedApps]="allApplications" [form]="demandeForm" [collabApplications]="collabApplications"></app-application-fixed>
    </div>
</div>

