import { BasicModel } from "../basic/basic.model";
import { AgenceModel } from "../rsa/agence.model";
import { CollaborateurModel } from "../utilisateur/collaborateur.model";
import { ApplicationModel } from "./application.model";

export class ResponsableAppliModel extends BasicModel {
    agenceId: number;
    agence: AgenceModel;

    idResponsable: number;
    responsable: CollaborateurModel;

    idApplication: number;
    application: ApplicationModel;
}
