import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import {  tap } from 'rxjs/operators';
import { StateService } from '../../shared/services/state.service';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { ApplicationDemandeModel } from 'src/app/shared/models/simple-types/application-demande.model';
import { DEMANDEAGENCEVALIDATORS, DEMANDEVALIDATORS, FormInfo } from 'src/app/shared/constants/demande-validators';
import { DemandeValidationModel } from 'src/app/shared/dtos/demandeValidationModel';
import { APPLICATIONDEMANDEVALIDATORS } from 'src/app/shared/constants/application-demande-validators';
import { DemandeAgenceModel } from 'src/app/shared/models/agence-linked/demande/demande-agence.model';
import { DemandeAgenceService } from 'src/app/shared/services/agence-linked/demande-agence.service';

export class DetailDemandeinfos {
}


@Injectable()
export class DemandeComponentsService {
  private cdsCheckBehavior = new BehaviorSubject<boolean>(false);
  public cdsCheckSubject = this.cdsCheckBehavior.asObservable();
  agence: AgenceModel;
  user: string;
  private detailDemandeInfosSource = new BehaviorSubject<DetailDemandeinfos>(null);
  private subscriptions: Subscription[] = [];
  detailDemandeInfos = this.detailDemandeInfosSource.asObservable();

  constructor(
    public dialog: MatDialog,
    protected router: Router,
    protected toastr: ToastrService,
    protected demandeService: DemandeService,
    private demandeAgenceService: DemandeAgenceService,
    private state: StateService,
) {
    
      this.subscriptions.push(this.state.user.subscribe(result => {
      this.user = result;
    }));
  }
  
  private createApplicationForm(application: ApplicationDemandeModel, applicationValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, _] of applicationValidators.entries()) {
      const control =
        new UntypedFormControl({ value: application[key], disabled: applicationValidators.get(key).disabled }, applicationValidators.get(key).validators);
      form.addControl(key, control);
    }
    return form;
  }

  public getApplicationForm(application: ApplicationDemandeModel, applicationValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = this.createApplicationForm(application, applicationValidators);
    return form;
  }

  public getApplicationFormEdit(application: ApplicationDemandeModel, applicationValidators: Map<string, FormInfo>, i: number): UntypedFormGroup {
    const form = this.createApplicationForm(application, applicationValidators);
    return form;
  }

  private replaceString(s: string) {

    s = s.replace(/\./g, "");
    s = s.replace(/ /g, "-");
    s = s.replace(/é/g, "e");
    s = s.replace(/è/g, "e");
    s = s.replace(/ê/g, "e");
    s = s.replace(/ë/g, "e");
    s = s.replace(/à/g, "a");
    s = s.replace(/ä/g, "a");
    s = s.replace(/â/g, "a");
    s = s.replace(/ã/g, "a");
    s = s.replace(/î/g, "i");
    s = s.replace(/ï/g, "i");
    s = s.replace(/ù/g, "u");
    s = s.replace(/ü/g, "u");
    s = s.replace(/û/g, "u");
    s = s.replace(/ç/g, "c");
    s = s.replace(/ñ/g, "n");
    s = s.replace(/'/g, "-");
    s = s.replace(/’/g, "-");
    s = s.replace(/,/g, "");
    s = s.replace(/;/g, "");
    s = s.replace(/:/g, "");
    s = s.replace(/!/g, "");
    s = s.replace(/%/g, "");
    s = s.replace(/\*/g, "");
    s = s.replace(/\(/g, "");
    s = s.replace(/\)/g, "");
    s = s.replace(/@/g, "");
    s = s.replace(/\?/g, "");
    s = s.replace(/Œ/g, "oe");
    s = s.replace(/œ/g, "oe");
    s = s.replace(/Æ/g, "ae");
    s = s.replace(/æ/g, "ae");
    s = s.replace(/-+/g,"-");

    return s;
  }
  
  private getDemandeFormEvent(demandeForm: UntypedFormGroup): void {
    // set email automatique when nom/prenom/contrat change
    this.subscriptions.push(combineLatest([
      demandeForm.get('nom').valueChanges,
      demandeForm.get('prenom').valueChanges,
      demandeForm.get('typeContrat').valueChanges])
      .subscribe(([nom, prenom, type]) => {
       nom = this.replaceString(nom.trim().toLowerCase());
       prenom = this.replaceString(prenom.trim().toLowerCase());      
       if ((nom && prenom)) {        
           if(type === "Prestation" || type === "Intérim"){           
             demandeForm.get('email').setValue(prenom.toLowerCase() + '.' + nom.toLowerCase() + '.ext@veolia.com')
          }else {          
             demandeForm.get('email').setValue(prenom.toLowerCase() + '.' + nom.toLowerCase() + '@veolia.com')
           }
         }
      }));

      this.subscriptions.push(demandeForm.get('typeContrat').valueChanges
        .subscribe((type) => {
            if(type === "CDI"){
              demandeForm.get('dateFin').setValidators([]);
              demandeForm.get('dateFin').disable();
              demandeForm.get('dateFin').setValue(null);
            }else {
              demandeForm.get('dateFin').setValidators([Validators.required]);
              demandeForm.get('dateFin').enable();
            }
        }));
  }


  private getDemandeAgenceFormEvent(demandeForm: UntypedFormGroup): void {
    this.subscriptions.push(demandeForm.get('regionId').valueChanges
        .subscribe((regionId) => {
          if(regionId){
            demandeForm.get('societeId').enable();
          }else{
            demandeForm.get('societeId').setValue(null);
            demandeForm.get('societeId').disable();
          }
        }));
  }
  // DemandeForm + DefaultApplication
  public getDemandeForm(demande: DemandeModel, demandeValidators: Map<string, FormInfo>, applicationValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, value] of demandeValidators.entries()) {
      if (key !== 'applications' && key !== 'demandeCycles') {
        const control = new UntypedFormControl({ value: demande[key], disabled: demandeValidators.get(key).disabled }, demandeValidators.get(key).validators);
        form.addControl(key, control);
      }
    }
    const applicationFormArray = new UntypedFormArray([]);
    if (demande.applications && demande.applications.length > 0) {
      for (const application of demande.applications) {
        applicationFormArray.push(this.getApplicationForm(application, applicationValidators));
      }
    }
    applicationFormArray.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    form.addControl('applications', applicationFormArray);
    form.addControl('adresseAgence', new UntypedFormControl({ value: '', disabled: true }, []));
    form.addControl('numeroAgence', new UntypedFormControl({ value: '', disabled: true }, []));
    return form;
  }

  public getDemandeAgenceForm(demande: DemandeAgenceModel, demandeValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, value] of demandeValidators.entries()) {
      if (key !== 'demandeCycles') {
        const control = new UntypedFormControl({ value: demande[key], disabled: demandeValidators.get(key).disabled }, demandeValidators.get(key).validators);
        form.addControl(key, control);
      }
    }
    return form;
  }


  public getModifications(demande: DemandeModel): Observable<string> {
    return this.demandeService.getModifications(demande);
  }

  public getModificationsAgence(demande: DemandeAgenceModel): Observable<string> {
    return this.demandeAgenceService.getModifications(demande);
  }

  public createArrayAppliDemande(applications: ApplicationDemandeModel[], applicationValidators: Map<string, FormInfo>): UntypedFormArray{
    const applicationFormArray = new UntypedFormArray([]);
    if (applications && applications.length > 0) {
      for (const application of applications) {
        applicationFormArray.push(this.getApplicationForm(application, applicationValidators));
      }
    }
    applicationFormArray.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    return applicationFormArray;
  }

  public createApplication(demandeForm: UntypedFormGroup): UntypedFormGroup {
    const application = new ApplicationDemandeModel();
    const row = this.getApplicationForm(application, APPLICATIONDEMANDEVALIDATORS.CREATE);
    // Initializing designation field when article is selected in table field autocomplete
    //row.get('idagence').patchValue(demandeForm.get('idagence').value);
    row.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    return row;
  }

  removeApplication(demandeForm: UntypedFormGroup, i): void {
    const applicationForm = demandeForm.get('applications') as UntypedFormArray;
    applicationForm.removeAt(i);
  }

  purgeDetailDemandeInfo() {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
    this.state.changeAgence(null);
    this.detailDemandeInfosSource.next(null);
  }

  getCreateDemandeFormGroup(demandeValidators: Map<string, FormInfo>, applicationValidators: Map<string, FormInfo>): UntypedFormGroup {
    const demande = new DemandeModel();
    const demandeForm = this.getDemandeForm(demande, demandeValidators, applicationValidators);
    this.getDemandeFormEvent(demandeForm);
    return demandeForm;
  }

  getEditDemandeFormGroup(demande: DemandeModel, demandeValidators: Map<string, FormInfo>): UntypedFormGroup {
    const demandeForm = this.getDemandeForm(demande, demandeValidators, APPLICATIONDEMANDEVALIDATORS.EDIT);
    this.getDemandeFormEvent(demandeForm);
    demandeForm.get('nom').patchValue(demandeForm.get('nom').value);
    demandeForm.get('prenom').patchValue(demandeForm.get('prenom').value);
    demandeForm.get('typeContrat').patchValue(demandeForm.get('typeContrat').value);
    demandeForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    demandeForm.get('email').setValue(demande.email)
    return demandeForm;
  }

  getVisualizeDemandeFormGroup(demande: DemandeModel): UntypedFormGroup {
    const demandeForm = this.getDemandeForm(demande, DEMANDEVALIDATORS.VISUALIZE, APPLICATIONDEMANDEVALIDATORS.VISUALIZE);
    return demandeForm;
  }

  getValidateDemandeFormGroup(demande: DemandeModel): UntypedFormGroup {
    const demandeForm = this.getDemandeForm(demande, DEMANDEVALIDATORS.VALIDATE, APPLICATIONDEMANDEVALIDATORS.VALIDATE);
    this.getDemandeFormEvent(demandeForm);
    return demandeForm;
  }

  getTraiterDemandeFormGroup(demande: DemandeModel): UntypedFormGroup {
    const demandeForm = this.getDemandeForm(demande, DEMANDEVALIDATORS.TRAITER, APPLICATIONDEMANDEVALIDATORS.VISUALIZE);
    return demandeForm;
  }

  memorizeDemande(demande: DemandeModel): Observable<DemandeModel> { 
    return this.demandeService.toAValider(demande).pipe(tap(demandeUpd => {
      if(demandeUpd.statusNum == 3){
      this.toastr.success('Votre demande est maintenant en attente de traitement');
      } else {
      this.toastr.success('Votre demande est maintenant en attente de validation');
      }
    }));
  }

  public verifyWorkflowDemande(demande: DemandeModel): Observable<any> {
    return this.demandeService.verifyWorkflowDemande(demande);
  }


  retourDemande(demandeForRetour: DemandeValidationModel): Observable<DemandeModel> {
    return this.demandeService.retourDemande(demandeForRetour).pipe(
      tap(demandeUpd => {
        if( demandeForRetour.isModif){
          this.toastr.success('Demande remise en brouillon pour modifications');
        }else{
        this.toastr.success('Demande retourné pour complément');
        }
      }));
  }

  retourDemandeAgence(demandeForRetour: DemandeValidationModel): Observable<DemandeAgenceModel> {
    return this.demandeAgenceService.retourDemande(demandeForRetour).pipe(
      tap(demandeUpd => {
        if(demandeForRetour.isModif){
          this.toastr.success('Demande remise en brouillon pour modifications');
        }else{
        this.toastr.success('Demande retourné pour complément');
        }
      }));
  }

  traiterDemandeAgence(demande: DemandeAgenceModel): Observable<DemandeAgenceModel> {
    return this.demandeAgenceService.traiterDemande(demande).pipe(
      tap(demandeUpd => {
        this.toastr.success('Demande traité');

      }));
  }


  retourDSIDemande(demandeForRetour: DemandeValidationModel): Observable<DemandeModel> {
    return this.demandeService.retourDSIDemande(demandeForRetour).pipe(
      tap(demandeUpd => {
        this.toastr.success('Demande retourné pour complément DSI');
      }));
  }

  refusDemande(demandeForValidation: DemandeValidationModel): Observable<DemandeModel> {
    return this.demandeService.refusDemande(demandeForValidation).pipe(
      tap(demandeUpd => {
        this.toastr.success('Demande refusé');
      }));
  }

  refusDemandeAgence(demandeForValidation: DemandeValidationModel): Observable<DemandeAgenceModel> {
    return this.demandeAgenceService.refusDemande(demandeForValidation).pipe(
      tap(demandeUpd => {
        this.toastr.success('Demande refusé');
      }));
  }

  annulerDemande(demandeForAnnulation: DemandeValidationModel): Observable<DemandeModel> {
    return this.demandeService.annulerDemande(demandeForAnnulation).pipe(
      tap(demandeUpd => {
        this.toastr.success('Demande annulé');
      }));
  }

  annulerDemandeAgence(demandeForAnnulation: DemandeValidationModel): Observable<DemandeAgenceModel> {
    return this.demandeAgenceService.annulerDemande(demandeForAnnulation).pipe(
      tap(demandeUpd => {
        this.toastr.success('Demande annulé');
      }));
  }

  validateDemande(demandeForValidation: DemandeValidationModel): Observable<DemandeModel> {
    return this.demandeService.validateDemande(demandeForValidation).pipe(
      tap(demandeUpd => {
        if(localStorage.getItem('autoNext')=='true'){
          this.toastr.success('Demande validé, redirection automatique vers la prochaine demande à valider');
        }else{
        this.toastr.success('Demande validé');
        }
      }));
  }

  validateDemandeAgence(demandeForValidation: DemandeValidationModel): Observable<DemandeAgenceModel> {
    return this.demandeAgenceService.validateDemande(demandeForValidation).pipe(
      tap(demandeUpd => {
        this.toastr.success('Demande validé');
      }));
  }

  traiterDemande(demandeForTraitement: DemandeValidationModel): Observable<DemandeModel> {
    return this.demandeService.genFilesAD(demandeForTraitement).pipe(
      tap(demandeUpd => {
        this.toastr.success('Demande traité');
      }));
  }

  updateDemande(demande: DemandeModel, comment: string): Observable<DemandeModel> {
    if (demande.id) {
      demande.dModification = new Date(Date.now());
      const demandewithcomment = new DemandeValidationModel(demande);
      demandewithcomment.commentaire = comment;
      return this.demandeService.updateDemande(demandewithcomment).pipe(tap(demandeUpd => {
      }));
    }
    else {
      this.toastr.error('Erreur lors de la sauvegarde');
    }
  }


/*
  sendImportedDemande(file: File, state: number): Observable<HttpEvent<string>> {
    return this.demandeService.sendImportedDemande(file, state);
  }
*/
  setTodaySDate(): object {
    const todaySDate = new Date();
    const currentMonth = new Date(todaySDate.getFullYear(), todaySDate.getMonth(), todaySDate.getDate(), todaySDate.getHours());
    return currentMonth;
  }

  /**
   * Méthode qui subscribe au demandeForm et qui imprime à chaque modification du form les champs invalides
   * en prenant en compte toutes les demandeLignes du form.
   * @param form Le demandeForm dont on veut vérifier les champs invalides.
   */
  logInvalidControls(form: UntypedFormGroup): void {
    this.subscriptions.push(form.valueChanges.subscribe(_ => {
      const temp = {};
      for (const name in form.controls) {
        if (form.controls[name].invalid) {
          temp[name] = [];
        }
      }
    }));
  }

  getCreateDemandeAgenceFormGroup(demandeValidators: Map<string, FormInfo>): UntypedFormGroup {
    const demande = new DemandeAgenceModel();
    const demandeForm = this.getDemandeAgenceForm(demande, demandeValidators);
    return demandeForm;
  }

  getVisualizeDemandeAgenceFormGroup(demande: DemandeAgenceModel): UntypedFormGroup {
    const demandeForm = this.getDemandeAgenceForm(demande, DEMANDEAGENCEVALIDATORS.VISUALIZE);
    return demandeForm;
  }

  getValidateDemandeAgenceFormGroup(demande: DemandeAgenceModel): UntypedFormGroup {
    const demandeForm = this.getDemandeAgenceForm(demande, DEMANDEAGENCEVALIDATORS.VALIDATE);
    return demandeForm;
  }

  memorizeDemandeAgence(demande: DemandeAgenceModel): Observable<DemandeAgenceModel> { 
    return this.demandeAgenceService.toAValider(demande).pipe(tap(demandeUpd => {
      this.toastr.success('Demande Memorisé !');
    }));
  }
  
  cdsCheck(cdsChecked: any) {
    this.cdsCheckBehavior.next(cdsChecked);
  }
}
