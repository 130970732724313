<mat-form-field class="full-width">
  <mat-label >{{libelle}}</mat-label>
      <input
          matInput
          placeholder="Sélectionner le {{libelle.toLocaleLowerCase()}}"
          [formControl]="myControl"
          [matAutocomplete]="auto"
          [required]="required"
          (change)="$event.target.value === '' ? deleteIfEmptyOrNotFound(): null">
  <mat-autocomplete #auto="matAutocomplete"  (optionSelected)="intervenantChange($event.option.value)">
    <mat-option *ngFor="let user of listResp" [value]="user"> {{user.nom + ' ' + user.prenom}}</mat-option>
  </mat-autocomplete>
</mat-form-field>