<div *ngIf="demandeForm" class="row" id="parent-brouillon">
    <form focusInvalidInput [formGroup]="demandeForm">
        <div class="row">
            <div class="col-sm-12" style="display: flex; justify-content: space-between;">
                <h5 class="col-sm-6"> Validation de la demande de {{typeDemande}} N° {{this.demande.codeDemande}}</h5>
                <div class="col-sm-6" style="justify-content: space-between;">
                    
                    <button color="default" mat-raised-button type="button" (click)="close()">
                        <mat-icon>arrow_back</mat-icon>Retour
                    </button>

                    <button color="warn" matTooltip="Fonctionnalité A venir"
                    mat-raised-button type="button" (click)="retour()" disabled>
                        <mat-icon>not_interested</mat-icon>Retour pour complément
                    </button>

                    <button color="warn"
                    mat-raised-button type="button" (click)="traiter()" *ngIf="demande.status === 'En attente de traitement'">
                        <mat-icon>autorenew</mat-icon>Relancer
                    </button>

                    <button color="danger"  mat-raised-button type="button" (click)="refus()" *ngIf="demande.status === 'En attente de validation'">
                        <mat-icon>clear</mat-icon>Refuser
                    </button>

                    <button color="success" mat-raised-button type="button" (click)="validate()" *ngIf="demande.status === 'En attente de validation'">
                        <mat-icon>check</mat-icon>Valider
                    </button>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="col-sm-12">
                    <app-demande-agence-form [form]="demandeForm" [agence]="demande.agence"></app-demande-agence-form>
                </div>
                
                <div class="col-sm-12">
                    <app-cycle-validation-list [cyclesValidation]="cyclesValidation"></app-cycle-validation-list>
                </div>
            </div>
            
        </div>
    </form>
    
</div>
