<div *ngIf="form">
  <div fxLayout="column" fxLayoutAlign="start start" style="min-height: min-content;">
    <h5>Applications</h5>
    <mat-divider style="align-self: stretch;"></mat-divider>
    <div class="liste">
      <div *ngFor="let app of applications">
        <button  class="custom-button" *ngIf="isChecked(app.id) === 'default-app'"
          mat-raised-button 
          type="button" 
          color="info"
          class="default-app"
          matTooltipPosition="left"
          matTooltip="La création de compte {{app.nom}} est obligatoire pour tous les collaborateurs de la SARP."
          [disabled]="form.get('typeDemande').value === 2 || (cdsChecked === true && !isCDS(app.id)) || lockAppsForModif">
            <mat-icon>info</mat-icon>{{ app.nom}}
        </button>
        <button *ngIf="isChecked(app.id) === 'warn'"
        mat-raised-button 
        type="button" 
        (click)="delete(app)" 
        color="warn"
        [disabled]="form.get('typeDemande').value === 2 || (cdsChecked === true && !isCDS(app.id)) || lockAppsForModif">
          <mat-icon>check</mat-icon>{{ app.nom}}
      </button>
      <button *ngIf="isChecked(app.id) === 'default'"
        mat-raised-button 
        type="button" 
        (click)="add(app)" 
        color="default"
        [disabled]="form.get('typeDemande').value === 2 || (cdsChecked === true && !isCDS(app.id)) || lockAppsForModif">
          {{ app.nom}}
      </button>
      <button *ngIf="isChecked(app.id) === 'green'"
        mat-raised-button 
        type="button" 
        (click)="delete(app)" 
        color="success"
        [disabled]="form.get('typeDemande').value === 2 || (cdsChecked === true && !isCDS(app.id)) || lockAppsForModif">
        <mat-icon>add</mat-icon>{{ app.nom}}
      </button>
      <button *ngIf="isChecked(app.id) === 'red'"
        mat-raised-button 
        type="button" 
        (click)="add(app)" 
        color="danger"
        [disabled]="form.get('typeDemande').value === 2 || (cdsChecked === true && !isCDS(app.id)) || lockAppsForModif">
        <mat-icon>delete</mat-icon>{{ app.nom}}
      </button>
      </div>
    </div>
    
    <mat-divider style="align-self: stretch;"></mat-divider>
  </div>
</div>

