import { BasicModel } from "../basic/basic.model";
import { ApplicationModel } from "./application.model";

export class DocumentModel extends BasicModel {
    // max length 250
    libelle: string;
    path: string;
    type: string;
    idApplication: number;
    application: ApplicationModel;
}
