<mat-nav-list>
  <mat-list-item>
    <a [routerLink]="['']">
      <mat-icon mat-list-icon class="icon" matTooltip="Accueil" matTooltipPosition="right">home</mat-icon>
    </a>
  </mat-list-item>
  <mat-list-item>
    <a [routerLink]="['/list-demande']" (click)= "list_demande()">
      <mat-icon mat-list-icon class="icon" matTooltip="Liste demande" matTooltipPosition="right"> library_books</mat-icon>
    </a>
  </mat-list-item>
  <mat-list-item *ngIf="viewDemandesAgence">
    <a (click)= "list_demande_agence()">
      <mat-icon mat-list-icon class="icon" matTooltip="Liste demande agence" matTooltipPosition="right"> library_books</mat-icon>
    </a>
  </mat-list-item>
  <mat-list-item>
    <a [routerLink]="['/recherche-collaborateur']">
      <mat-icon mat-list-icon class="icon" matTooltip="Liste collaborateur" matTooltipPosition="right"> search</mat-icon>
    </a>
  </mat-list-item>

    <a style="position: fixed; bottom: 0; font-size:smaller; color: #F39800;" matTooltip={{version}}> V {{versionSmall}}</a>
</mat-nav-list>
