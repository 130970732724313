import { Component, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { DemandeComponentsService, DetailDemandeinfos } from '../demande-components.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { StateService } from 'src/app/shared/services/state.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { TypeDemande } from 'src/app/shared/constants/typeDemande';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';
import { APPLICATIONDEMANDEVALIDATORS } from 'src/app/shared/constants/application-demande-validators';
import { DEMANDEVALIDATORS } from 'src/app/shared/constants/demande-validators';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { ApplicationUserModel } from 'src/app/shared/models/simple-types/application-user.model';
import { CycleDemandeService } from 'src/app/shared/services/simple/cycle-demande.service';
import { ModalInfoOkComponent } from 'src/app/shared/components/modals/info-ok/modal-info-ok.component';
import { DialogDataQuestion, ModalQuestionComponent } from 'src/app/shared/components/modals/modal-question/modal-question.component';
import { WorkflowStatus } from 'src/app/shared/constants/workflowStatus';
import { map, switchMap } from 'rxjs/operators';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';

@Component({
  selector: 'app-create-demande',
  templateUrl: './create-demande.component.html',
  styleUrls: ['./create-demande.component.scss']
})
export class CreateDemandeComponent {
  collabApplications: ApplicationUserModel[] = [];
  selectedApplications: ApplicationModel[] = [];
  allApplications : ApplicationModel[] = [];
  typeDemande: string;
  demandeForm: UntypedFormGroup;
  currentAgence: AgenceModel;
  demande: DemandeModel = new DemandeModel();
  detailDemandeInfo: DetailDemandeinfos = new DetailDemandeinfos();
  observableDemande = new BehaviorSubject<DemandeModel>(new DemandeModel());
  selectedAgence: AgenceModel;
  idCollbaorateur: number;
  collaborateur = new CollaborateurModel();
  demandeLinesKeys = Object.keys;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private title: Title,
    private demandeComponentsService: DemandeComponentsService,
    private applicationService: ApplicationService,
    private state: StateService,
    protected toastr: ToastrService,
    private route: ActivatedRoute,
    private collabService: CollaborateurService,
    private cycleDemandeService: CycleDemandeService,
    private el: ElementRef) {
      this.route.queryParams
      .subscribe(params => {
        this.idCollbaorateur = params.idCollab;
        this.typeDemande = params.typeDemande;
      }
    );
      
  }

  ngOnInit(): void {
    this.observableDemande.subscribe(obj => {
      this.demande = obj;
      this.demande.agence = this.currentAgence;
      switch (this.typeDemande) {
        case 'création':
          //this.chooseAgence();
          this.demandeForm = this.demandeComponentsService.getCreateDemandeFormGroup(DEMANDEVALIDATORS.DEMANDEADD, APPLICATIONDEMANDEVALIDATORS.CREATE);
          this.demandeForm.patchValue(this.demande);
          this.demandeForm.get('typeDemande').setValue(0);
          break;
        case 'modification':
          this.demandeForm = this.demandeComponentsService.getCreateDemandeFormGroup(DEMANDEVALIDATORS.DEMANDEUPDATE, APPLICATIONDEMANDEVALIDATORS.CREATE);
          this.initWithCollabInfo();
          break;
        case 'suppression':
          this.demandeForm = this.demandeComponentsService.getCreateDemandeFormGroup(DEMANDEVALIDATORS.DEMANDEDELETE, APPLICATIONDEMANDEVALIDATORS.CREATE);
          this.initWithCollabInfo();
          break;
      }
    });
    this.title.setTitle('GUS - Créer Demande');
    this.applicationService.getAll().subscribe((apps: ApplicationModel[]) => {
      this.allApplications = apps;
    });
  } 

  removeTimeZone(dateInput){
    if(dateInput != null){
      let date = dateInput.toString().substring(0,10).split('-');
      return new Date(date[1] + '-' + date[2] + '-' + date[0]);
    }
  }

  initWithCollabInfo(){
    this.collabService.get(this.idCollbaorateur).subscribe((collab: CollaborateurModel) => {
      if(collab){
        if (collab.dDesactivation != null) {
          collab.agence = null;
          collab.idAgence = -1;
        }
        this.currentAgence = collab.agence;
        this.collaborateur = collab;
        this.demande.idCollaborateur = collab.id;
        this.demande.nom = collab.nom;
        this.demande.prenom = collab.prenom;
        this.demande.idAgence = collab.idAgence;
        this.demande.email = collab.email;
        this.demande.typeContrat = collab.typeContrat;
        this.demande.responsableCollaborateur = collab.responsable;
        this.demande.idResponsableCollaborateur = collab.idResponsable;
        this.demande.login = collab.login;
        this.demande.tel = collab.tel;
        this.demande.dateFin = this.removeTimeZone(collab.dateFin);
        this.demande.dateDebut = this.removeTimeZone(collab.dateDebut);
        this.demande.cds = collab.cds;
        this.demande.dDesactivation = this.collaborateur.dDesactivation;
        this.demandeForm.patchValue(this.demande);
        switch (this.typeDemande) {
          case 'modification':
            this.demandeForm.get('typeDemande').setValue(TypeDemande.Modification);
            break;
          case 'suppression':
            this.demandeForm.get('typeDemande').setValue(TypeDemande.Suppression);
            break;
        }
        if (collab.dDesactivation === null) { //Post patchvalue demandeForm
          this.demandeForm.get('adresseAgence').setValue(collab.agence.adresse);
          this.demandeForm.get('numeroAgence').setValue(collab.agence.telephone);
        }
        this.demandeForm.get('email').setValue(collab.email);
        this.collabApplications = collab.applications;
      }
    });
  }


  setValuesToDemande(): void {
    //let tmpDateDebut = this.demande.dateDebut;
    this.demande = this.demandeForm.getRawValue();
    if(this.demande.dateFin != null)
      this.demande.dateFin =  new Date(this.demande.dateFin.getTime() - this.demande.dateFin.getTimezoneOffset() * 60000);
    if(this.demande.dateDebut != null)
      this.demande.dateDebut =  new Date(this.demande.dateDebut.getTime() - this.demande.dateDebut.getTimezoneOffset() * 60000);
    this.demande.applications = (this.demandeForm.get('applications') as UntypedFormArray).getRawValue();
   // this.demande.idAgence = this.currentAgence.id;
    this.demande.code = this.demandeForm.controls.code.value;
    this.demande.agence = this.currentAgence;
  }

  // Observable<number> retourne -2 en cas d'annulation, -1 en cas de création, et un valeur >= 0 pour une modification
  verifyWorkflow(dialogRefSpinner: MatDialogRef<SpinnerModalComponent, any>): Observable<any> {
    return this.demandeComponentsService.verifyWorkflowDemande(this.demande).pipe(
      switchMap((responseReturned: any) => {
        dialogRefSpinner.close();
        if (responseReturned != WorkflowStatus.EmailNonExistant && this.typeDemande === 'création') {
          let title; // The title used for Question Modal
          let question; // the question used for Question Modal
          let footer;
          let buttonOui = "Oui";
          let buttonNon = "Non";
          if (responseReturned === WorkflowStatus.CompteDesactive) { // Vérification compte désactivé
            question = "Nous avons trouvé un collaborateur " + this.demande.email + " au statut désactivé, être vous sûr de la création d’un nouveau collaborateur ?";
            buttonOui = "Oui, je crée un nouveau collaborateur homonyme";
            buttonNon = "Non, je réactive l’ancien collaborateur";
          } else if (responseReturned === WorkflowStatus.CompteActifSurPerimetre) { // Vérification compte actif sur une agence sur perimetre de l'utilisateur
            question = "Collaborateur " + this.demande.email + " existe déjà, être vous sûr de la création d’un nouveau collaborateur ?";
            buttonOui = "Oui, je crée un nouveau collaborateur homonyme";
            buttonNon = "Non, je fais une modification sur le collaborateur existant";
          } else if (responseReturned === WorkflowStatus.CompteActifHorsPerimetre) { // Vérification compte actif sur une agence hors le perimetre de l'utilisateur
            question = "Collaborateur " + this.demande.email + " existe déjà, être vous sûr de la création d’un nouveau collaborateur ?";
            footer = 'La mobilité en dehors de votre périmètre n\'est pas disponible sur GUS veuillez faire une demande sur ServiceNow.';
            buttonOui = "Oui, je crée un nouveau collaborateur homonyme";
            buttonNon = "Non, je fais une modification sur le collaborateur existant";
          } else if (responseReturned === WorkflowStatus.CompteOtherDesactive) { // Vérification compte désactivé
            question = "Nous avons trouvé un collaborateur " + this.demande.email + " au statut désactivé, être vous sûr de la création d’un nouveau collaborateur ?";
            buttonOui = "Oui, je crée un nouveau collaborateur homonyme";
            buttonNon = "Non, je réactive l’ancien collaborateur";
          } else if (responseReturned === WorkflowStatus.CompteOtherActifSurPerimetre) { // Vérification compte actif sur une agence sur perimetre de l'utilisateur
            question = "Collaborateur " + this.demande.email + " existe déjà, être vous sûr de la création d’un nouveau collaborateur ?";
            buttonOui = "Oui, je crée un nouveau collaborateur homonyme";
            buttonNon = "Non, je fais une modification sur le collaborateur existant";
          } else if (responseReturned === WorkflowStatus.CompteOtherActifHorsPerimetre) { // Vérification compte actif sur une agence hors le perimetre de l'utilisateur
            question = "Collaborateur " + this.demande.email + " existe déjà, être vous sûr de la création d’un nouveau collaborateur ?";
            footer = 'La mobilité en dehors de votre périmètre n\'est pas disponible sur GUS veuillez faire une demande sur ServiceNow.';
            buttonOui = "Oui, je crée un nouveau collaborateur homonyme";
            buttonNon = "Non, je fais une modification sur le collaborateur existant";
          }

          let dialogQuestion = this.dialog.open(ModalQuestionComponent, {
            data: {
              title: title,    
              question: question,    
              footer: footer,
              buttonOui: buttonOui,
              buttonNon: buttonNon                        
            },
            autoFocus: false,
            maxHeight: '90vh'
          });
          // return 0 si annulé 1 si oui et -1 si non
          return dialogQuestion.afterClosed().pipe(switchMap((res: DialogDataQuestion) => {
            if (res) {
              let dialogRes: DialogDataQuestion = res;  
              if (dialogRes.result == 0) {  // si répond "annulé" Annulé l'action
                return of(-3);
              } else if (dialogRes.result == 1) { // si répond "Oui" Création nouveau Collab
                return of(-1);
              } else if (dialogRes.result == -1) {  // si répond "Non"// Redirection selon le résultat des vérifications
                if (responseReturned === WorkflowStatus.CompteDesactive) {
                  return this.collabService.getByEmail(this.demande.email).pipe(map(res => {
                    return res.id
                  }));
                } else if (responseReturned === WorkflowStatus.CompteActifSurPerimetre) {                         
                  return this.collabService.getByEmail(this.demande.email).pipe(map(res => {
                    return res.id
                  }));
                }
                else if (responseReturned === WorkflowStatus.CompteActifHorsPerimetre) {
                  this.toastr.error('Oups, une erreur s’est produite, vous n’avez pas le droit de modification sur ce collaborateur. Veuillez contacter le support via ServiceNow');
                  return of(-2);                                    
                }
                // Compte ext autre
                else {
                  let emailOther;
                  if (this.demande.typeContrat == "CDI")
                  {
                      var emailExtArray = this.demande.email.split('@');
                      emailOther = emailExtArray[0] + ".ext@veolia.com";
                  } else
                  {
                      var emailExtArray = this.demande.email.split(".ext@");
                      emailOther = emailExtArray[0] + "@veolia.com";
                  }
                  if (responseReturned === WorkflowStatus.CompteOtherDesactive) {
                    return this.collabService.getByEmail(emailOther).pipe(map(res => {
                      return res.id
                    }));
                  } else if (responseReturned === WorkflowStatus.CompteOtherActifSurPerimetre) {                         
                    return this.collabService.getByEmail(emailOther).pipe(map(res => {
                      return  res.id
                    }));
                  }
                  else if (responseReturned === WorkflowStatus.CompteOtherActifHorsPerimetre) {
                    this.toastr.error('Oups, une erreur s’est produite, vous n’avez pas le droit de modification sur ce collaborateur. Veuillez contacter le support via ServiceNow');
                    return of(-2);                                    
                  }
                }
                
              }
            }
            return of(-3);
          }));
        } else {
          return of(-1);
        }
    }));
  }


  memorize(): void {
    if (this.demandeForm.valid) {
      // Modification du Nom ou Prénom en Uppercase si une modification du champ est faite
      if (this.demande.nom != this.demandeForm.get('nom').value) {
        let nom = this.demandeForm.get('nom').value
        this.demandeForm.get('nom').setValue(nom.toUpperCase())
      }
      if (this.demandeForm.get('email').value && this.demandeForm.get('idAgence').value !== -1) {
        if (!this.demandeForm.get('idCollaborateur').value && this.demandeForm.get('typeDemande').value !== 0) {
          this.toastr.warning('Le collaborateur est obligatoire');
        } else {
          this.realSave();
        }
      } else if (!this.demandeForm.get('email').value) {
        this.toastr.warning('Le champ Email est obligatoire');
      } else {
        this.toastr.warning('Le champ Agence est obligatoire');
      }
    } else {
      this.displayErrors();
    }
  }

  realSave() {
    this.setValuesToDemande();
    let dialogRefModifs;
    const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
    this.verifyWorkflow(dialogRefSpinner).subscribe(result => {
      switch(result) {
        case -1 : { // Création 
          this.demandeComponentsService.getModifications(this.demande).subscribe((modifs: string) => {
            
            dialogRefModifs = this.getDialogRefModif(modifs);
            dialogRefModifs.afterClosed().subscribe((success) => {
              if (success) {
                const dialogRefSpinner2 = this.dialog.open(SpinnerModalComponent);
                this.demandeComponentsService.memorizeDemande(this.demande).subscribe((data: any) => 
                {
                  this.demande = data;
                  dialogRefSpinner2.close();
                  this.router.navigate(['list-demande/']);
                }, (error) => dialogRefSpinner2.close());
              }
            });
          });
          break;
        }
        case -2 : { // return to List
          this.router.navigate(['list-demande/']);
          break;
        }
        case -3 : { // annulation
          break;
        }
        default : { // Send to UpdateColab
          this.router.routeReuseStrategy.shouldReuseRoute = function () { return false;};
          this.router.navigate(["create-demande"], {queryParams:  {typeDemande: "modification", idCollab: result}});   
          break;
        }
      }
    }, (error: any) => {
      dialogRefSpinner.close();
      this.toastr.error('Vous n’avez pas les droits pour créer cette demande, merci de faire votre demande via ServiceNow.');
    });
  }


  getDialogRefModif(modifs) {
    if (this.demandeForm.get('typeDemande').value === TypeDemande.Creation) {
      return this.dialog.open(ModalInfoOkComponent, {
        data: {
          title: 'Confirmez-vous la création du collaborateur ?',
          footer: 'Je déclare avoir fait attention à la séparation des taches',
          type: 'Demande',
          message: modifs,
          icone: 'warning',
          iconColor: 'warn',
          color: 'success',
        },
        autoFocus: false,
        maxHeight: '90vh'
      });
    } else if (this.demandeForm.get('typeDemande').value === TypeDemande.Suppression) {
      return this.dialog.open(ModalInfoOkComponent, {
        data: {
          title: 'Confirmez-vous la suppression du collaborateur ?',
          type: 'Demande',
          message: 'Confirmez-vous la demande de suppression du collaborateur '+ this.demandeForm.get('nom').value +' '+ this.demandeForm.get('prenom').value+
                    ' ainsi que son retrait de toutes les applications référencées ?',
          icone: 'warning',
          iconColor: 'warn',
          color: 'success',
        },
        autoFocus: false,
        maxHeight: '90vh'
      });
    } else if (this.demandeForm.get('typeDemande').value === TypeDemande.Modification) {
      return this.dialog.open(ModalInfoOkComponent, {
        data: {
          title: 'Confirmez-vous la modification du collaborateur ?',    
          footer:'Je déclare avoir fait attention à la séparation des taches',           
          type: 'Demande',
          message: modifs,
          icone: 'warning',
          iconColor: 'warn',
          color: 'success',
        },
        autoFocus: false,
        maxHeight: '90vh'
      });
    }
  }

  displayErrors(): void {
    for (const demande in this.demandeForm.controls) {
      if (demande) {
        this.demandeForm.controls[demande].markAsTouched();
      }
    }
  }

  close(): void {
    this.router.navigate(['/']);
  }
  ngOnDestroy(): void {
    this.demandeComponentsService.purgeDetailDemandeInfo();
  }

}
