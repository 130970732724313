  <div class="col-sm-12">
    <h5 *ngIf="!form.get('id').value">Création d'une nouvelle société</h5>
    <h5 *ngIf="form.get('id').value">Modification de la société</h5>
  </div>
  <div *ngIf="form">
    <form focusInvalidInput [formGroup]="form">
        <div class="col-md-12">
            <mat-form-field class="full-width">
              <mat-label>Code</mat-label>
              <input matInput placeholder="Ex: SP000" formControlName="code" required>
              <mat-error *ngIf="form.get('code').hasError('required')">
                Le code est obligatoire.
              </mat-error>
              <mat-error *ngIf="form.get('code').hasError('maxlength')">
                Le code est trop long.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field class="full-width">
              <mat-label>Libelle</mat-label>
              <input matInput placeholder="Ex: Sarp Nanterre" formControlName="libelle" required>
              <mat-error *ngIf="form.get('libelle').hasError('required')">
                Le libelle est obligatoire.
              </mat-error>
              <mat-error *ngIf="form.get('libelle').hasError('maxlength')">
                Le libelle est trop long.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <mat-form-field class="full-width">
              <mat-label>Siren</mat-label>
              <input matInput formControlName="siret" required>
              <mat-error *ngIf="form.get('siret').hasError('required')">
                Le siren est obligatoire.
              </mat-error>
              <mat-error *ngIf="form.get('siret').hasError('maxlength')">
                Le siren est trop long.
              </mat-error>
            </mat-form-field>
          </div>

      <div class="col-sm-12" style="display: flex; justify-content: flex-start; align-items: flex-start;">
        <div class="col-sm-6 text-center">
          <button mat-raised-button color="default" (click)= "closeDialog(false)">Annuler</button>
        </div>
        <div class="col-sm-6 text-center">
          <button mat-raised-button color="success" (click)="onModalConfirm()">Enregistrer</button>
        </div>
      </div>
    </form>
  </div>
  