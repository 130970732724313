import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { from } from 'rxjs';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';
import { DemandeComponentsService } from '../../demande-components.service';

export interface DialogData {
  id: number;
  type: 'Demande';
}

const APP_ID = environment.APP_ID;
const DEV_KEY = environment.DEV_KEY;
const MIME_TYPES = `application/pdf,application/vnd.google-apps.document,application/vnd.google-apps.presentation,application/vnd.google-apps.spreadsheets,application/vnd.google-apps.files,application/vnd.google-apps.unknown`;

@Component({
  selector: 'app-modal-google-picker',
  templateUrl: './modal-google-picker.component.html',
  styleUrls: ['./modal-google-picker.component.scss']
})
export class ModalGooglePickerComponent implements OnInit {

  picker: google.picker.Picker;
  private accessToken: string;
  progress = 1;
  uploadFailed = false;
  downloadFailed = false;
  downloadErrMsg: string;

  constructor(
    private ngZone: NgZone,
    private sessionService: SessionService,
    private demandeComponentsService: DemandeComponentsService,
    public dialogRef: MatDialogRef<Component>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }


  onModalCancel(): void {
    this.dialogRef.close(false);
  }

  ngOnInit(): void {
    this.accessToken = this.sessionService.getRefreshToken().access_token;
    gapi.load('picker',
      () => this.ngZone.run(
        this.createPicker.bind(this) // Bind permet d'avoir accès à this à l'intérieur du callback
      )
    );
  }

  createPicker(): void {
    NgZone.assertInAngularZone();
    // créer la vue dans le picker: les DOCS qui contiennent les mimeTypes définis et avec la navigation dans les dossiers
    const fileView = new google.picker.DocsView().
      setMimeTypes(MIME_TYPES).
      setIncludeFolders(true).
      setParent('root').
      setOwnedByMe(true);
    const folderView = new google.picker.DocsView().
      setIncludeFolders(true).
      setSelectFolderEnabled(false).
      setEnableDrives(true).
      setMimeTypes(MIME_TYPES);
    const recentView = new google.picker.DocsView().
      setMimeTypes(MIME_TYPES);
    // créer le picker
    this.picker = new google.picker.PickerBuilder()
      .setLocale('fr')
      .setAppId(APP_ID)
      .setOAuthToken(this.accessToken)
      .addView(fileView)
      .addView(folderView)
      .addView(recentView)
      .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
      .setDeveloperKey(DEV_KEY)
      .setCallback((data) => this.pickerCallback(data, this))
      .setSelectableMimeTypes(MIME_TYPES)
      .build();
    this.picker.setVisible(true);
  }

  /**
   * Fonction qui est appelée à la sélection d'un fichier par le picker
   * @param data La réponse de l'api Google après la sélection d'un fichier
   * @param ctx le contexte de la modale
   */
  private pickerCallback(data: google.picker.ResponseObject, ctx: ModalGooglePickerComponent): void {
    if (data.action === google.picker.Action.PICKED) {
      const id = data.docs[0].id;
      gapi.load('client',
        () => gapi.client.load('drive', 'v3', () => downloadFile(id))
      );
    } else if (data.action === google.picker.Action.CANCEL) {
      ctx.ngZone.run(() => ctx.onModalCancel());
    }

    function downloadFile(fileId: string): void {
      const gDocRequest = from(gapi.client.drive.files.export({ // rqt fichier si c'est un googleDoc
        fileId,
        mimeType: 'application/pdf',
        oauth_token: ctx.accessToken
      }));

      const pdfRequest = from(gapi.client.drive.files.get({ // rqt fichier si c'est un pdf
        fileId,
        supportsAllDrives: true,
        alt: 'media',
        oauth_token: ctx.accessToken
      }));

      // selon le mimeType du document, on fait une requête get ou export
      switch (data.docs[0].mimeType) {
        case 'application/pdf':
          pdfRequest.subscribe(dataRes => ctx.ngZone.run(() => uploadFile([data.docs[0], dataRes])), logDownloadError);
          break;
        case 'application/vnd.google-apps.document':
        case 'application/vnd.google-apps.presentation':
        case 'application/vnd.google-apps.spreadsheets':
        case 'application/vnd.google-apps.files':
        case 'application/vnd.google-apps.unknown':
          gDocRequest.subscribe(dataRes => ctx.ngZone.run(() => uploadFile([data.docs[0], dataRes])), logDownloadError);
          break;
      }
    }

    /**
     * Cette fonction envoie les pdfs reçus vers l'api.
     * @param res un tableau qui contient les métadonnées et les données d'un fichier
     */
    function uploadFile(res: [google.picker.DocumentObject, gapi.client.Response<any>]): void {

      ctx.progress = 40;
      // Convert binary data to blob.
      const resBody = res[1].body;
      const len = resBody.length;
      const ar = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        ar[i] = resBody.charCodeAt(i);
      }

      const blob = new Blob([ar], { type: 'application/pdf' });
      const file = new File([blob], res[0].name, { type: 'application/pdf' });

    }

    function logDownloadError(error: any): void {
      ctx.downloadFailed = true;
      ctx.downloadErrMsg = error;
    }
  }

}
