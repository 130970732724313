import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { StateService } from 'src/app/shared/services/state.service';

export interface DialogData {
  icone: string;
  title: string;
  message: string;
  color: string;
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  result: boolean;
}

@Component({
  selector: 'app-select-agence-modal',
  templateUrl: './select-agence-modal.component.html',
  styleUrls: ['./select-agence-modal.component.scss']
})

export class SelectAgenceModalComponent implements OnInit {

  icone: string;
  title: string;
  message: string;
  color: string;
  // tslint:disable-next-line: variable-name
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  agenceList: AgenceModel[];
  agence = '';
  disabled = true;
  currentAgence: AgenceModel;
  @Input() idAgence: number;
  constructor(public dialog: MatDialog,
              private agenceService: AgenceService,
              public router: Router,
              public dialogRef: MatDialogRef<Component>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private state: StateService) {

    this.agenceService.getAllForDemande().subscribe(obj => {
      this.agenceList = obj;
      // On passe le choix si la personne a les droits sur une seule société
      if (this.agenceList.length === 1) {
        this.currentAgence = this.agenceList[0];
        //this.onModalConfirm();
      }
      else if (localStorage.getItem('agence')){
        // Si la personne vient de créer un demande, on passe
        this.currentAgence = JSON.parse(localStorage.getItem('agence'));
        localStorage.removeItem('agence');
        this.onModalConfirm();
      }
      const idSoc = JSON.parse(localStorage.getItem('CURRENT_USER')).idAgence;
      /*this.agenceService.get(idSoc).subscribe((res) => {
        if (res) {
          this.selectForm.controls.agence.setValue(`${res.code}-${res.libelle}`);
          this.disabled = false;
          this.currentAgence = res;
        }
      });*/
    });
  }

  ngOnInit(): void {
    this.icone = this.data.icone !== undefined ? this.data.icone : '';
    this.title = this.data.title !== undefined ? this.data.title : '';
    this.icon_color = this.data.icon_color !== undefined ? this.data.icon_color : '';
    this.dialogWidth = this.data.dialogWidth !== undefined ? this.data.dialogWidth : ''; 
    this.dialogHeight = this.data.dialogHeight !== undefined ? this.data.dialogHeight : '';
    this.color = this.data.color;
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(null);
    this.router.navigate(['/']);
  }

  onModalConfirm(): void {
    this.data.result = true;
    this.dialogRef.close(this.currentAgence);
    this.state.changeAgence(this.currentAgence);
  }

  updateAgence(event : AgenceModel): void {
    if (event) {
      this.currentAgence = event;
      this.disabled = false;
    } else {
      this.currentAgence = null;
      this.disabled = true;
    }
  }

}
