import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { FiltreRechercheCollaborateurDTO } from 'src/app/shared/dtos/filtreRechercheCollaborateurDTO';
import { SimpleQueryResponse } from 'src/app/shared/dtos/simpleQueryResponse';
import { SimpleQuery } from 'src/app/shared/dtos/simple_query';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';


@Component({
  selector: 'app-list-collaborateur',
  templateUrl: './list-collaborateur.component.html',
  styleUrls: ['./list-collaborateur.component.scss']
})
export class ListCollaborateurComponent implements OnInit, OnChanges {


  @Input() reponseCollaborateur: SimpleQueryResponse<CollaborateurModel>;
  @Input() comingFrom: String;
  @Input() filtre: SimpleQuery<FiltreRechercheCollaborateurDTO>;
  @Output() selectCollab = new EventEmitter<boolean>();

  collaborateurs: CollaborateurModel[] = [];
  displayedColumns: string[] = ['nom', 'dDesactivation', 'region', 'agence', 'email', 'mobile', 'typeContrat','responsable'];
  dataSource = new MatTableDataSource<CollaborateurModel>();
  length: number;
  pageIndex: number;
  pageSize = 25;
  pageEvent: PageEvent;
  regions: RegionModel[];
  agences: AgenceModel[];
  sortState: Sort = { active: 'nom', direction: 'asc' };
  icon = '<mat-icon>add</mat-icon>'

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public router: Router, 
    public collaborateurService: CollaborateurService,
  ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.reponseCollaborateur){
      this.collaborateurs = this.reponseCollaborateur.objets;
      this.length = this.reponseCollaborateur.count;
      this.pageSize = this.reponseCollaborateur.pageSize;
      this.pageIndex = this.reponseCollaborateur.pageNumber;
      this.dataSource = new MatTableDataSource<CollaborateurModel>(this.collaborateurs);
    }
  }

  trieTab(sort: Sort): void {
    if (sort.direction !== 'asc') {
      this.filtre.descending = false;
    } else {
      this.filtre.descending = true;
    }
    this.sortState = sort;
    this.filtre.order = sort.active;
    this.loadPage();
  }

  loadPage() {
    this.collaborateurService.GetCollaborateurFromObjetWithPagination(this.filtre).subscribe((response: SimpleQueryResponse<CollaborateurModel>) => {
      this.collaborateurs = response.objets;
      this.length = response.count;
      this.pageSize = response.pageSize;
      this.pageIndex = response.pageNumber
      this.dataSource = new MatTableDataSource<CollaborateurModel>(this.collaborateurs);
    });
  }

  orderUsers(users: CollaborateurModel[]): CollaborateurModel[] {
    users = users.sort((a, b) => {
      if (a.nom) {
        return a.nom.localeCompare(b.nom);
      } else {
        return -1;
      }
    });
    return users;
  }


  openEvent(user: CollaborateurModel): void {
    if(!this.comingFrom) {
      this.router.navigate(["read-collaborateur/" + user.id]);
    } else  {
      this.router.navigate(["create-demande"], {queryParams:  {typeDemande: this.comingFrom, idCollab: user.id}});
    }
    this.selectCollab.emit(true);
  }

  changePage(event?: PageEvent): void {
    this.filtre.pageNumber =  event.pageIndex;
    this.filtre.pageSize = event.pageSize;
    this.loadPage();
  }
}
