
    <!--------------------------------- Colonnes dynamiques ----------------------------------->
    <table mat-table  matSort [dataSource]="matDataSource" class="mat-elevation-z8">
    <caption></caption>
     <ng-container *ngFor="let column of tableDTO.columns; let colIndex = index"  [matColumnDef]="column.variableName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 
            <div>{{column.displayName}}</div>
            <div *ngIf="column.type === Type.TEXT"> 
              <button mat-button matSuffix mat-icon-button 
              (click)="openSelect(colIndex)"
              (click)="$event.stopPropagation()">
                <mat-icon *ngIf="this.filterValues[column.variableName] ? (this.filterValues[column.variableName].length > 0) : false">filter_alt_on</mat-icon>
                <mat-icon *ngIf="this.filterValues[column.variableName] ? (this.filterValues[column.variableName].length <= 0) : true">filter_list</mat-icon>
              </button>
                <div style="max-width: 1rem;">
                <mat-select class="filterSelect" #select multiple matNativeControl name="{{filterSelectObj[colIndex].columnProp}}" [(ngModel)]="filterSelectObj[colIndex].modelValue" (selectionChange)="filterChange(filterSelectObj[colIndex],$event,colIndex)">
                  <mat-option class="filter-option" disabled="disabled" >
                    <a class="filterSelectClear" href="#" (click)="toggleAllSelection(colIndex, true,filterSelectObj[colIndex].name); $event.preventDefault()">Tout sélectionner</a>
                    - 
                    <a class="filterSelectClear" href="#" (click)="toggleAllSelection(colIndex, false,filterSelectObj[colIndex].name); $event.preventDefault();">Effacer</a>
                  </mat-option>
                <mat-option [value]="item" *ngFor="let item of filterSelectObj[colIndex].options">{{item}}</mat-option>
                </mat-select>
              </div>
            </div>
            <input type="checkbox" *ngIf="column.type  === Type.CHECKBOXE"
                        (click)="$event.stopPropagation()"
                        (change)="$event ? masterToggle(colIndex) : null"
                        [checked]="selection[colIndex].hasValue() && isAllSelected(colIndex)"
                        [indeterminate]="hasValue(colIndex) && !isAllSelected(colIndex)"
                        (change)="onCheckboxChange(matDataSource)"
                        />
        </th>
        <td mat-cell *matCellDef="let element" class="centrer">
            <input type="checkbox" *ngIf="!isString(element[column.variableName])" 
                    [(ngModel)]="element[column.variableName].value" 
                    [checked]="element[column.variableName].value" 
                    (click)="$event.stopPropagation()"
                    (change)="$event ?  selection[colIndex].toggle(element) : null"
                    [checked]=" selection[colIndex].isSelected(element)"
                    [disabled]="element[column.variableName].disabled"
                    (change)="onCheckboxChange(matDataSource)"/>
            <div *ngIf="isString(element[column.variableName])"> {{element[column.variableName]}}</div>
        </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns ;sticky: true"></tr>
      <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"></tr> 
    </table>
    
