import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StateService } from '../../shared/services/state.service';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { FormInfo } from 'src/app/shared/constants/demande-validators';
import { Injectable } from '@angular/core';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
import { RESPONSABLEVALIDATORS } from 'src/app/shared/constants/responsable-validators';
import { ResponsableAppliModel } from 'src/app/shared/models/simple-types/responsable-appli.model';
import { APPLICATIONVALIDATORS } from 'src/app/shared/constants/application-validators';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { AGENCEVALIDATORS, REGIONVALIDATORS, SOCIETEVALIDATORS } from 'src/app/shared/constants/rsa-validators';
import { SocieteModel } from 'src/app/shared/models/rsa/societe.model';

@Injectable()
export class ReferentielComponentsService {
  private cdsCheckBehavior = new BehaviorSubject<boolean>(false);
  public cdsCheckSubject = this.cdsCheckBehavior.asObservable();
  agence: AgenceModel;
  user: string;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    protected router: Router,
    protected toastr: ToastrService,
    protected demandeService: DemandeService,
    private state: StateService,
    ) {
      this.subscriptions.push(this.state.user.subscribe(result => {
      this.user = result;
    }));
  }
  
  private createApplicationForm(application: ApplicationModel, applicationValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, _] of applicationValidators.entries()) {
      if (key !== 'responsables' && key !== 'documents') {
        const control =
        new UntypedFormControl({ value: application[key], disabled: applicationValidators.get(key).disabled }, applicationValidators.get(key).validators);
        form.addControl(key, control);
      }
    }
    return form;
  }

  private createResponsableForm(responsable: ResponsableAppliModel, responsableValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, _] of responsableValidators.entries()) {
      if(key !== 'agence' && key !== 'responsable' && key !== 'application'){
        const control =
        new UntypedFormControl({ value: responsable[key], disabled: responsableValidators.get(key).disabled }, responsableValidators.get(key).validators);
        form.addControl(key, control);
      }
    }
    /*const agenceControl = new FormControl({ value: (responsable['agence'] ? responsable['agence'].libelle : "Toutes"), disabled: false }, []);
    form.addControl('libelleAgence', agenceControl);
    const responsableControl = new FormControl({ value: responsable['responsable'].email, disabled: false }, []);
    form.addControl('emailResponsable', responsableControl);*/
    return form;
  }
  
  getEditApplicationFormGroup(application: ApplicationModel): UntypedFormGroup {
    const form = this.getApplicationForm(application, APPLICATIONVALIDATORS.EDIT, RESPONSABLEVALIDATORS.EDIT);
    this.getApplicationFormEvent(form);
    return form;
  }

  getCreateApplicationFormGroup(): UntypedFormGroup {
    var application = new ApplicationModel();
    const form = this.getApplicationForm(application, APPLICATIONVALIDATORS.CREATE, RESPONSABLEVALIDATORS.CREATE);
    this.getApplicationFormEvent(form);
    return form;
  }

  getCreateRegionFormGroup(): UntypedFormGroup {
    var region = new RegionModel();
    const form = this.getRegionForm(region, REGIONVALIDATORS.CREATE);
    //this.getRegionFormEvent(form);
    return form;
  }

  getCreateSocieteFormGroup(): UntypedFormGroup {
    var societe = new SocieteModel();
    const form = this.getSocieteForm(societe, SOCIETEVALIDATORS.CREATE);
    //this.getSocieteFormEvent(form);
    return form;
  }

  getEditRegionFormGroup(region: RegionModel): UntypedFormGroup {
    const form = this.getRegionForm(region, REGIONVALIDATORS.EDIT);
    //this.getRegionFormEvent(form);
    return form;
  }

  getEditSocieteFormGroup(societe: SocieteModel): UntypedFormGroup {
    const form = this.getSocieteForm(societe, SOCIETEVALIDATORS.EDIT);
    //this.getSocieteFormEvent(form);
    return form;
  }

  getEditAgenceFormGroup(agence: AgenceModel): UntypedFormGroup {
    const form = this.getAgenceForm(agence, AGENCEVALIDATORS.EDIT);
    //this.getAgenceFormEvent(form);
    return form;
  }

  getCreateAgenceFormGroup(): UntypedFormGroup {
    var agence = new AgenceModel();
    const form = this.getAgenceForm(agence, AGENCEVALIDATORS.CREATE);
    //this.getAgenceFormEvent(form);
    return form;
  }

  getVisualizeAgenceFormGroup(agence: AgenceModel): UntypedFormGroup {
    const form = this.getAgenceForm(agence, AGENCEVALIDATORS.VISUALIZE);
    //this.getAgenceFormEvent(form);
    return form;
  }

  public getRegionForm(region: RegionModel, regionValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, _] of regionValidators.entries()) {
      if(key !== 'agences'){
        const control =
        new UntypedFormControl({ value: region[key], disabled: regionValidators.get(key).disabled }, regionValidators.get(key).validators);
        form.addControl(key, control);
      }
    }
    return form;
  }

  public getSocieteForm(societe: SocieteModel, SocieteValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, _] of SocieteValidators.entries()) {
      if(key !== 'agences'){
        const control =
        new UntypedFormControl({ value: societe[key], disabled: SocieteValidators.get(key).disabled }, SocieteValidators.get(key).validators);
        form.addControl(key, control);
      }
    }
    return form;
  }

  public getAgenceForm(agence: AgenceModel, agenceValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    for (const [key, _] of agenceValidators.entries()) {
      if(key !== 'region' && key != 'societe'){
        const control =
        new UntypedFormControl({ value: agence[key], disabled: agenceValidators.get(key).disabled }, agenceValidators.get(key).validators);
        form.addControl(key, control);
      }
    }
    return form;
  }

  public getApplicationForm(application: ApplicationModel, applicationValidators: Map<string, FormInfo>, responsableValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = this.createApplicationForm(application, applicationValidators);
    let reponsablesFormArray = new UntypedFormArray([]);
    if (application.responsables && application.responsables.length > 0) {
      let i = 0;
      for (const app of application.responsables) {
        reponsablesFormArray.push(this.getResponsableForm(app, responsableValidators));
        i++;
      }
    }
    reponsablesFormArray.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    form.addControl('responsables', reponsablesFormArray);
    return form;
  }

  public getResponsableForm(responsable: ResponsableAppliModel, responsableValidators: Map<string, FormInfo>): UntypedFormGroup {
    const form = this.createResponsableForm(responsable, responsableValidators);
    return form;
  }



  private getApplicationFormEvent(applicationForm: UntypedFormGroup): void {
    
  }

  purgeDetailDemandeInfo() {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
    this.state.changeAgence(null);
  }

 

  setTodaySDate(): object {
    const todaySDate = new Date();
    const currentMonth = new Date(todaySDate.getFullYear(), todaySDate.getMonth(), todaySDate.getDate(), todaySDate.getHours());
    return currentMonth;
  }

  /**
   * Méthode qui subscribe au demandeForm et qui imprime à chaque modification du form les champs invalides
   * en prenant en compte toutes les demandeLignes du form.
   * @param form Le demandeForm dont on veut vérifier les champs invalides.
   */
  logInvalidControls(form: UntypedFormGroup): void {
    this.subscriptions.push(form.valueChanges.subscribe(_ => {
      const temp = {};
      for (const name in form.controls) {
        if (form.controls[name].invalid) {
          temp[name] = [];
        }
      }
    }));
  }


  cdsCheck(cdsChecked: any) {
    this.cdsCheckBehavior.next(cdsChecked);
  }
}
