<mat-card [ngStyle]="{'border': '5px solid '+ borderColor}">
    <mat-card-content>
        <div *ngIf="form">
            <div class="row" style="padding: 0px;"  [formGroup]="form">
                <div class="col-sm-5" style="padding: 0px;">
                    <div class="header-class">
                        Agence
                    </div>
                    <div class="data-class row">
                        <div class="col-sm-6">
                            <mat-form-field class="full-width">
                                <mat-label>Code</mat-label>
                                <input matInput name="gdso-code-demande" formControlName="code" required/>
                                <mat-error *ngIf="form.get('code').hasError('required')">
                                    Le code est obligatoire.
                                </mat-error>
                                <mat-error *ngIf="form.get('code').hasError('maxlength')">
                                    Maximun 30 caractères.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        
                        <div class="col-sm-6">
                            <mat-form-field class="full-width">
                                <mat-label>Libelle</mat-label>
                                <input matInput name="gdso-libelle-demande" formControlName="libelle"  required/>
                                <mat-error *ngIf="form.get('libelle').hasError('required')">
                                    Le libelle est obligatoire.
                                </mat-error>
                                <mat-error *ngIf="form.get('libelle').hasError('maxlength')">
                                    Maximun 30 caractères.
                                </mat-error>
                            </mat-form-field>
                        </div>
                       
                       
                       
                        
                        <div class="col-sm-6">
                            <app-autocomplete-region
                              name="region"
                              ngDefaultControl
                              [libelle]="'Région'"
                              [regions]="regions"
                              [regionId]="form.get('regionId').value"
                              [required]="true"
                              [disabled]="form.get('regionId').disabled"
                              (regionChange)="onChangeRegion($event)">
                            </app-autocomplete-region> 
                        </div> 
                     
                        <div class="col-sm-6">
                            <app-autocomplete-societe
                              name="societe"
                              ngDefaultControl 
                              [libelle]="'Société'"
                              [societes]="societes"
                              [societeId]="form.get('societeId').value"
                              [required]="true"
                              [disabled]="form.get('societeId').disabled"
                              (societeChange)="onChangeSociete($event)">
                            </app-autocomplete-societe> 
                        </div>

                        <div class="col-sm-6">
                            <mat-form-field class="full-width">
                                <mat-label>Code conso</mat-label>
                                <input matInput name="gdso-codeConso-demande" formControlName="codeConso" required/>
                                <mat-error *ngIf="form.get('codeConso').hasError('required')">
                                    Le code est obligatoire.
                                </mat-error>
                                <mat-error *ngIf="form.get('codeConso').hasError('maxlength')">
                                    Maximun 15 caractères.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-6">
                            <mat-form-field matTooltip="N° de SIRET"  class="full-width">
                                <mat-label>Siret</mat-label>
                                <input matInput name="gdso-siret-demande" formControlName="siret"/>
            
                            </mat-form-field>
                        </div>
                        
                          
                        <div class="col-sm-12">
                            <mat-form-field matTooltip="L'adresse de l'agence"  class="full-width">
                                <mat-label>Adresse de l'agence</mat-label>
                                <input matInput name="gdso-adresse-demande" formControlName="adresse" required/>
                                <mat-error *ngIf="form.get('adresse').hasError('required')">
                                    Le adresse est obligatoire.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-6">
                            <mat-form-field  class="full-width">
                                <mat-label>Code postal</mat-label>
                                <input matInput name="gdso-codePostal-demande" formControlName="codePostal" required/>
                                <mat-error *ngIf="form.get('codePostal').hasError('required')">
                                    Le code postale est obligatoire.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-6">
                            <mat-form-field  class="full-width">
                                <mat-label>Ville</mat-label>
                                <input matInput name="gdso-ville-demande" formControlName="ville" required/>
                                <mat-error *ngIf="form.get('ville').hasError('required')">
                                    Le ville est obligatoire.
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                </div>
                <div class="col-sm-7" style="padding: 0px;">
                    <div class="header-class">
                        Contacts
                    </div>
                    <div class="data-class row">
                        <div class="col-sm-6" style="padding: 0px;">
                            
                            <div class="col-sm-12">
                                <mat-form-field matTooltip="Exemple : +33123456789 / 0123456789"  class="full-width">
                                    <mat-label>Téléphone</mat-label>
                                    <input matInput name="gdso-tel-demande" formControlName="telephone"/>
                                    <mat-error *ngIf="form.get('telephone').hasError('pattern')">
                                        Le format du téléphone est incorrect.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12">
                                <mat-form-field matTooltip="Email"  class="full-width">
                                    <mat-label>Adresse email</mat-label>
                                    <input matInput name="gdso-email-demande" formControlName="email"/>
                                    <mat-error *ngIf="form.get('email').hasError('maxlength')">
                                        Maximun 120 caractères.
                                    </mat-error>
                                    <mat-error *ngIf="form.get('email').hasError('pattern')">
                                        L'email est incorrect.
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-sm-12">
                                <app-autocomplete-user [userId]="form.get('idDirecteurAgence').value"
                                    (updated)="onCollabChange($event, 'DirecteurAgence')" 
                                    libelle="Directeur Agence" 
                                    name="user"
                                    [disabled]="form.get('idDirecteurAgence').disabled"
                                    [parentForm]="form">
                                </app-autocomplete-user>
                            </div>
                            <div class="col-sm-12">
                                <app-autocomplete-user [userId]="form.get('idContact1').value"
                                (updated)="onCollabChange($event, 'ContactSite1')" 
                                libelle="Contact Site 1" 
                                name="user"
                                [disabled]="form.get('idContact1').disabled"
                                [parentForm]="form">
                                </app-autocomplete-user>
                            </div>

                            <div class="col-sm-12">
                                <app-autocomplete-user [userId]="form.get('idContact2').value"
                                (updated)="onCollabChange($event, 'ContactSite2')" 
                                libelle="Contact Site 2" 
                                name="user"
                                [disabled]="form.get('idContact2').disabled"
                                [parentForm]="form">
                                </app-autocomplete-user>
                            </div>
                        </div>
                        <div class="col-sm-6" style="padding-left: 0px;padding-top: 0.6em;">
                            <mat-form-field class="full-width">
                                <mat-label style="margin-left: 5px;">infos Directeur Agence</mat-label>
                                <textarea matInput name="gdso-infosDirecteurAgence" cdkTextareaAutosize cdkAutosizeMinRows="6"
                                    cdkAutosizeMaxRows="6" style="background-color: white;"
                                    formControlName="infosDirecteurAgence"></textarea>
                                <mat-error *ngIf="form.get('infosDirecteurAgence').hasError('maxlength')">
                                    Nombre maximum de caractère dépassé.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="full-width">
                                <mat-label style="margin-left: 5px;">infos Contact Site</mat-label>
                                <textarea matInput name="gdso-infosContact" cdkTextareaAutosize cdkAutosizeMinRows="6"
                                    cdkAutosizeMaxRows="6" style="background-color: white;"
                                    formControlName="infosContact"></textarea>
                                <mat-error *ngIf="form.get('infosContact').hasError('maxlength')">
                                    Nombre maximum de caractère dépassé.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </mat-card-content>
</mat-card>
