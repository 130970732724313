<mat-drawer-container class="container-position">

    <mat-drawer #sideNav mode="side" opened="false" position="end" class="drawer" style="Overflow-y:scroll;">
  
      <div>
        <br />
        <div class="header-class">
  
          <div class="col-6">
            <h6>Filtres</h6>
          </div>
          <div class="col-6">
            <button color="warn" mat-raised-button (click)="clearFiltre()">
              <mat-icon>cancel</mat-icon> Réinitialiser
            </button>
  
          </div>
        </div>
      </div>
      <br />
      <div class="data-class" *ngIf="filtreData">
        <br />
        <div class="row">
          <div class="col-sm-12 margin_bm_20">
            <app-custom-select-multiple-option 
              (selected)="onFiltreChange($event, 'Statut')" [InputvaluesList]="listStatus" 
              [InputSelectedValues]="listStatusUpdated" [title]="'Statut'">
            </app-custom-select-multiple-option>
          </div>
        </div>
        <div class="row">
           
        </div>
        <div class="row "> 
          <div class="col-sm-12 margin_bm_20">
            <mat-form-field class="full-width">
              <mat-label>Numéro de la demande</mat-label>
              <input [(ngModel)]="filtreData.numDemande" matInput (ngModelChange)="onChange($event)" name="numDemande"
                style="font-size: small;" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>
        </div>
        <div class="row "> 
          <div class="col-sm-12 margin_bm_20">
            <mat-form-field class="full-width">
              <mat-label>Code agence</mat-label>
              <input [(ngModel)]="filtreData.code" matInput (ngModelChange)="onChange($event)" name="code"
                style="font-size: small;" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>
        </div>
        <div class="row "> 
          <div class="col-sm-12 margin_bm_20">
            <app-autocomplete-region-multiple-select [untouchedAllRegions]="untouchedAllRegions"  [eventsSubjectRegionArray]="eventsSubjectRegionArray" (updated)="regionChange($event)">
            </app-autocomplete-region-multiple-select>
          </div>
        </div>
        <div class="row "> 
          <div class="col-sm-12 margin_bm_20">
            <app-autocomplete-societe-multiple-select [untouchedAllSocietes]="untouchedAllSocietes" [eventsSubjectSocieteArray]="eventsSubjectSocieteArray" (updated)="societeChange($event)">
            </app-autocomplete-societe-multiple-select>
          </div>
        </div>
        <div class="row"> 
          <div class="col-sm-12 margin_bm_20">
            <mat-form-field class="full-width">
              <mat-label>Libelle agence</mat-label>
              <input [(ngModel)]="filtreData.libelle" matInput (ngModelChange)="onChange($event)" name="libelle"
                style="font-size: small;" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <div>
        <div class="row">
          <div class="col-sm-10 margin_bm_20">
            <h3> {{title}} </h3>
          </div>
          <div *ngIf="isListAValide" class="col-sm-2" style="display: flex; justify-content: flex-end;">
            <button color="success" mat-raised-button type="button" (click)="onValidationDemandeAgences()" [disabled]="selectedDemandeAgencesChild.length === 0">
              <mat-icon>check</mat-icon>Valider des Demandes
            </button>
          </div>
        </div>
        <div class="col-sm-12">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Résultats :
              </mat-card-title>
              <span class="filter-button">
                <mat-card-title>
                  
                  <button color="primary" (click)="sideNav.toggle()" mat-stroked-button>
                    <mat-icon color="primary" id="icon">filter_list</mat-icon>filtres
                  </button>
                </mat-card-title>
              </span>
            </mat-card-header>
            <mat-card-content style="display: flex;
            flex-direction: column;
            min-width: 400px;
            height: 100%;
            margin: 0 20px;">
             
            <app-demande-agence-list style="flex: 1;min-height: 0;overflow: auto"[datasource]="demandeList" [ifListAValide]="isListAValide" 
              (selectedRowsEmit)="getSelectedDemandeAgences($event)" [(filtreData)]="filtreData" [simpleQueryDemandeAgenceResponse]="simpleQueryDemandeAgenceResponse" >
              </app-demande-agence-list>
             
              
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
    