import { SimpleQueryResponse } from '../../../shared/dtos/simpleQueryResponse';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UtilisateurModel } from '../../../shared/models/utilisateur/utilisateur.model';
import { UserService } from '../../../shared/services/user.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { StateService } from 'src/app/shared/services/state.service';
import { FiltreService } from '../../filtre.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalChargementComponent } from 'src/app/shared/components/modals/modal-chargement/modal-chargement.component';
import { BasicModel } from 'src/app/shared/models/basic/basic.model';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { GenericCrudService } from 'src/app/shared/services/basic/generic.crud.service';
import { RegionService } from 'src/app/shared/services/rsa/region.service';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { DemandeAgenceModel } from 'src/app/shared/models/agence-linked/demande/demande-agence.model';
import { DemandeAgenceService } from 'src/app/shared/services/agence-linked/demande-agence.service';
import { DemandeValidationModel } from 'src/app/shared/dtos/demandeValidationModel';
import { StatusDemande, getStatusDemande } from 'src/app/shared/constants/status';
import { FilterDemandeAgenceDTO } from 'src/app/shared/dtos/filterDemandeAgenceDTO';
import { SocieteModel } from 'src/app/shared/models/rsa/societe.model';
import { SocieteService } from 'src/app/shared/services/rsa/societe.service';


@Component({
  selector: 'app-list-demande-agence',
  templateUrl: './list-demande-agence.component.html',
  styleUrls: ['./list-demande-agence.component.scss']
})
export class ListDemandeAgenceComponent implements OnInit, OnDestroy {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  agences: AgenceModel[];
  demandesEnd: DemandeAgenceModel[];
  demandesStart: DemandeAgenceModel[];
  user: string;
  users: UtilisateurModel[] = [];
  validors: UtilisateurModel[] = [];
 
  myOpenOrderCount = 0;
  matDataSourceDemandeAgence: MatTableDataSource<DemandeAgenceModel>;
  selection = new SelectionModel<DemandeAgenceModel>(false, []);
  filtreData: FilterDemandeAgenceDTO ;
  demandeList: DemandeAgenceModel[] = [];
  simpleQueryDemandeAgenceResponse = new SimpleQueryResponse<DemandeAgenceModel>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  agence: AgenceModel;
  filteredOptions: Observable<string[]>;
  filteredSubscribe: Subscription;
  isListAValide = false;
  title = 'Liste des demandes agence';
  public options = [
    { value: 'None', id: 0 },
    { value: 'Some', id: 1 },
    { value: 'All', id: 2 },
  ];
  currentRoute: any;
  selectedDemandeAgencesChild: DemandeAgenceModel[] = [];
  listStatus = [getStatusDemande(StatusDemande.Brouillon),
    getStatusDemande(StatusDemande.AValider), 
    getStatusDemande(StatusDemande.Validate),
    getStatusDemande(StatusDemande.Traite),
    //getStatusDemande(StatusDemande.Refuse),
    //getStatusDemande(StatusDemande.Annule)
  ];
  listStatusUpdated = new BehaviorSubject<string[]>(this.listStatus);

  regionsIdsArray:Set<number>  = new Set();
  eventsSubjectRegionArray: BehaviorSubject<Set<number>> = new BehaviorSubject(this.regionsIdsArray);

  societesIdsArray:Set<number>  = new Set();
  eventsSubjectSocieteArray: BehaviorSubject<Set<number>> = new BehaviorSubject(this.societesIdsArray);

  regions: RegionModel[];
  societes: SocieteModel[];
  untouchedAllRegions: RegionModel[] = [];
  untouchedAllSocietes: SocieteModel[] = [];
  constructor(public dialog: MatDialog,
              private router: Router,
              private titleService: Title,
              private userService: UserService,
              public demandeService: DemandeAgenceService,
              private state: StateService,
              private filtreService: FiltreService,
              private regionService: RegionService,
              private societeService: SocieteService,
              private detector: ChangeDetectorRef) {
              }

  ngOnDestroy(): void {
    if(this.router.url != '/list-demande-agence' 
        && !this.router.url.includes("/validate-demande-agence/") 
        && !this.router.url.includes("/read-demande-agence/")){
      this.filtreData =  new FilterDemandeAgenceDTO();
      this.filtreData.status = [
        getStatusDemande(StatusDemande.AValider),
        getStatusDemande(StatusDemande.Validate),
        getStatusDemande(StatusDemande.Traite),
        getStatusDemande(StatusDemande.Refuse),
        getStatusDemande(StatusDemande.Annule)
      ];
      this.filtreData.order = 'DCreation';
      this.filtreData.descending = true;
      this.filtreData.type = '';
      this.filtreService.changeFiltreAgence(this.filtreData);
      this.filteredSubscribe.unsubscribe();
  }
  }

  onChange(event): void {
    this.filtreData.regions.forEach(x => this.regionsIdsArray.add(x.id));
    this.filtreData.societeObjs.forEach(x => this.societesIdsArray.add(x.id));
    this.filtreData.pageNumber = 0;
    this.filtreService.changeFiltreAgence(this.filtreData);
  }

  loadData(value: string, service: GenericCrudService<BasicModel>, model: string): void {
    if (!value) {
      eval(model + '=  [];');
    }
  }

  onFiltreChange(event, state): void {
    switch (state) {
      case 'Statut':
        this.filtreData.status = event;
        break;
      case 'Createur':
        this.filtreData.idCreateur = event;
        break;
      case 'Valideur':
        this.filtreData.idValidateur = event;
        break;
    }
    this.onChange(event);
  } 


  clearFiltre(): void {
    const type = this.filtreData.type;
    this.filtreData = new FilterDemandeAgenceDTO();
    this.filtreData.regions = [];
    this.filtreData.societeObjs = [];
    this.regionsIdsArray.clear();
    this.societesIdsArray.clear();
    this.regionsIdsArray = new Set();
    this.societesIdsArray = new Set();
    this.filtreData.pageNumber = 0;
    this.filtreData.type = type;
    this.filtreService.changeFiltreAgence(this.filtreData);
  }

  ngOnInit(): void {
   
    let index = this.selectedDemandeAgencesChild.length;
    if(index) {
      this.selectedDemandeAgencesChild.splice(0,index);
    }
    this.currentRoute = this.router.url;
    this.titleService.setTitle('GUS - Liste Demandes Agence ');

    this.detector.detectChanges();
    this.state.user.subscribe(result => {
      this.user = result;
    });
    this.filteredSubscribe = this.filtreService.filtreAgence.pipe(debounceTime(500)).subscribe(result => {
      this.filtreData = result;
      this.listStatusUpdated.next(result.status);
      this.title = 'Liste des demandes agence ';
      switch (this.filtreData.type) {
        // valideur
        case 'v_wait_validation_by_me': {
          this.title = 'Demandes agence à valider';
          this.isListAValide = false;
          break;
        }
        default: {
          this.title = this.title + '';
          this.isListAValide = false;
        }
      }
      this.regionsIdsArray.clear();
      this.filtreData.regions.forEach((region) => this.regionsIdsArray.add(region.id));
      this.eventsSubjectRegionArray.next(this.regionsIdsArray);

      this.societesIdsArray.clear();
      this.filtreData.societeObjs.forEach((societe) => this.societesIdsArray.add(societe.id));
      this.eventsSubjectSocieteArray.next(this.societesIdsArray);
    });
    this.regionService.getAll().subscribe(regions => {
      this.regions = regions.sort((a, b) => a.code.localeCompare(b.code));
      this.untouchedAllRegions = regions;
    });
    this.societeService.getAll().subscribe(societes => {
      this.societes = societes.sort((a, b) => a.code.localeCompare(b.code));
      this.untouchedAllSocietes = societes;
    });
  }

  getSelectedDemandeAgences(event: DemandeAgenceModel[]): void {
    this.selectedDemandeAgencesChild = event;
  }


  validation(): Observable<number> {
    let i = 0;
    const nb = new BehaviorSubject<number>(0);
    for (const demande of this.selectedDemandeAgencesChild) {
      const demandevalidator: DemandeValidationModel = new DemandeValidationModel(demande);
      this.demandeService.validateDemande(demandevalidator).subscribe(_ => {
        i++;
        nb.next(i);
      }, (error) => -1);
    }
    return nb.asObservable();
  }

  onValidationDemandeAgences(): void {
    const dialogRef = this.dialog.open(ModalChargementComponent, {
      disableClose: true,
      data: {
        observable: this.validation(),
        total: this.selectedDemandeAgencesChild.length,
        result: true
      }
    });
    dialogRef.afterClosed().subscribe(_ => {
      this.ngOnInit();
    });
  }


  stringifyBool(flags: boolean[]): string[] {
    const response = [];
    flags.forEach(flag => {
      if (flag) {
        response.push('Total');
      } else {
        response.push('Aucun');
      }
    });
    return response;
  }

  parseBool(flags: string[]): boolean[] {
    const response = [];
    flags.forEach(flag => {
      switch (flag) {
        case 'Total':
          response.push(true);
          break;
        case 'Aucun':
          response.push(false);
          break;
      }
    });
    return response;
  }

  regionChange(event){
    let array = event;
    if(array != undefined && array.length != 0){
      let regions : RegionModel[] = [];
      regions = this.regions.filter(item => array.indexOf(item.id) !== -1);
      this.filtreData.regions = [];
      this.filtreData.regions = regions;
      this.onChange(this.filtreData);
    }else{
      this.filtreData.regions = [];
      this.onChange(this.filtreData);
    }
  }

  societeChange(event){
    let array = event;
    if(array != undefined && array.length != 0){
      let societes : SocieteModel[] = [];
      societes = this.societes.filter(item => array.indexOf(item.id) !== -1);
      this.filtreData.societeObjs = [];
      this.filtreData.societeObjs = societes;
      this.onChange(this.filtreData);
    }else{
      this.filtreData.societeObjs = [];
      this.onChange(this.filtreData);
    }
  }

}



