<form class="form">
  
 <mat-form-field class="full-width">        
        <mat-label>{{libelle}}</mat-label>
        <input type="text"
                placeholder="Selectionner une agence"
                matInput
                [required]="required"
                (change)="changeEvent()"
                [formControl]="myControl"
                [matAutocomplete]="auto"
                >
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeEvent()">
            <mat-option *ngFor="let option of filteredAgences | async" [value]="option" [matTooltip]="option"> {{option}} </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
