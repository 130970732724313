import { Validators } from '@angular/forms';

export class FormInfo {
  validators: Validators;
  disabled: boolean;
  constructor(required: boolean, validator: Validators) {
    this.disabled = required;
    this.validators = validator;
  }
}

export const APPLICATIONDEMANDEVALIDATORS = {
  CREATE: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idDemande', new FormInfo(false, [])],
    ['idApplication', new FormInfo(false, [])],
    ['action', new FormInfo(false, [])],
  ]),
  BROUILLON: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idDemande', new FormInfo(false, [])],
    ['idApplication', new FormInfo(false, [])],
    ['action', new FormInfo(false, [])],
  ]),
  EDIT: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idDemande', new FormInfo(false, [])],
    ['idApplication', new FormInfo(false, [])],
    ['action', new FormInfo(false, [])],
  ]),
  VALIDATE: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idDemande', new FormInfo(false, [])],
    ['idApplication', new FormInfo(false, [])],
    ['action', new FormInfo(false, [])],
  ]),
  VISUALIZE: new Map<string, FormInfo>([
    ['id', new FormInfo(true, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idDemande', new FormInfo(true, [])],
    ['idApplication', new FormInfo(true, [])],
    ['action', new FormInfo(true, [])],
  ])
};
