<div class="row">
    <div class="col-sm-12 padding-0" style="display: flex; justify-content: space-between;">
        <h5 class="col-sm-6"> Traitement de la demande de {{typeDemande}} N° {{this.demande.code}} {{this.demande.agence ? '(' + this.demande.agence.libelle + ')' : ''}}</h5>
        <div class="col-sm-6" style=" justify-content: space-between;">
            
            <button color="default" mat-raised-button type="button" (click)="close()">
                <mat-icon>arrow_back</mat-icon>Retour
            </button>
            <button color="warn"  mat-raised-button type="button" (click)="retourDSI()">
                <mat-icon>not_interested</mat-icon>Retour pour complément
            </button>
            <button color="primary" mat-raised-button type="button" (click)="genFilesEmail()">
                Génération Email
            </button>
            <button color="success" mat-raised-button type="button" (click)="validate()" [disabled]="demande.etat === 0 || demande.email !== demandeForm.get('email').value">
                <mat-icon>check</mat-icon>Valider
            </button>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-9">
        <div class="col-sm-12">
            <app-demande-form [form]="demandeForm" [agence]="demande.agence"></app-demande-form>
        </div>
        
        <div class="col-sm-12">
            <app-cycle-validation-list [cyclesValidation]="cyclesValidation"></app-cycle-validation-list>
        </div>
    </div>
    <div class="col-sm-3">
        <app-application-fixed [untouchedApps]="allApplications" [form]="demandeForm" [collabApplications]="collabApplications"></app-application-fixed>
    </div>
</div>
