import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SocieteModel } from 'src/app/shared/models/rsa/societe.model';

@Component({
  selector: 'app-autocomplete-societe',
  templateUrl: './autocomplete-societe.component.html',
  styleUrls: ['./autocomplete-societe.component.scss']
})
export class AutocompleteSocieteComponent  {


  @Input() societes: SocieteModel[];
  @Input() societeId: number;
  @Input() libelle: string;
  @Input() required = false;
  @Input() disabled = false;
  @Output() societeChange = new EventEmitter<SocieteModel>();
  @Output() change = new EventEmitter();
  societeString: string[] = [];
  filteredSocietes: Observable<string[]>;
  myControl = new FormControl();
  selected: " "
  constructor() { }

  ngOnInit(): void {
  }

  isValid(): boolean {
    return this.societes.find(societe => societe.libelle === this.myControl.value) !== undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled) {
      this.myControl.disable();
    } else if (!this.disabled) {
      this.myControl.enable();
    }
    if (this.societes) {
      this.societeString = this.societes.map(societe => societe.libelle);
    }
    if (this.societes !== undefined && this.societeId !== undefined) {
      const selectedSociete = this.societes.find(societe => societe.id === this.societeId);
      if (selectedSociete !== undefined) {
        this.myControl.setValue(selectedSociete.libelle);
      }
    }
    this.filteredSocietes = this.myControl.valueChanges
    .pipe(
      startWith(null),
      map((societe: string | undefined | null) => societe ? this._filter(societe) : this.societeString.slice()));
  }

  changeEvent() {
    const validators = [];
    validators.push(forbiddenNamesValidator(this.societes));
    if (this.required) {
      validators.push(Validators.required);
    }
    this.myControl.setValidators(validators);
    if (this.isValid()) {
      const selectedSociete = this.societes.find(societe => societe.libelle === this.myControl.value);
      this.societeChange.emit(selectedSociete);
    } else {
      this.societeChange.emit(undefined);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.societeString = this.societes.map(societe => societe.libelle);
    return this.societeString.filter(societe => societe.toLowerCase().indexOf(filterValue) >= 0);
  }
}

export function forbiddenNamesValidator(societes: SocieteModel[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // below findIndex will check if control.value is equal to one of our options or not
    if (control.value) {
      const index = societes.findIndex(societe => {
        return (societe.libelle).localeCompare(control.value) === 0;
      });
      return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
    } else {
      return null;
    }
  }};
