<div class="mat-table">
    <div class="table_principal">
        <table mat-table  matSort [dataSource]="matDataSourceDemande" (matSortChange)="trieTab($event)" matSortActive="dCreation" 
            matSortDirection="asc" matSortDisableClear>
            <caption></caption>
            <ng-container matColumnDef="numero">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> N° demande </th>
                <td mat-cell *matCellDef="let element" class="centrer padding-0" (click)="$event.stopPropagation();">
                    <div *ngIf="element.code" style="    display: flex;flex-wrap: nowrap;align-items: center;justify-content: center;"> 
                        <mat-icon style="margin-right: 3px;" matTooltip="Copier le N° demande" [cdkCopyToClipboard]="element.codeDemande" >content_copy</mat-icon>
                        <div>{{element.codeDemande}}</div> 
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="typeDemande">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Type demande </th>
                <td mat-cell *matCellDef="let element" class="centrer padding-0">
                    <div *ngIf="element.typeDemande === 0"> Création d'agence </div>
                    <div *ngIf="element.typeDemande === 1"> Modification d'agence</div>
                    <div *ngIf="element.typeDemande === 2"> Suppression d'agence </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="agence">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Agence </th>
                <td mat-cell *matCellDef="let element" class="centrer padding-0">
                    {{element.code}}-{{element.libelle}}
                </td>
            </ng-container>
            <ng-container matColumnDef="societe">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Société </th>
                <td mat-cell *matCellDef="let element" class="centrer padding-0">
                    {{element.societeObj ? element.societeObj.libelle : ""}}
                </td>
            </ng-container>
            <ng-container matColumnDef="region">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Région </th>
                <td mat-cell *matCellDef="let element" class="centrer padding-0">
                    <div *ngIf="element.region"> {{element.region.code}}-{{element.region.libelle}}
                    </div>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="statut">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
                <td mat-cell *matCellDef="let element" class="centrer padding-0">
                    <div *ngIf="element.status"> {{element.status}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="dModification">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date modification </th>
                <td mat-cell *matCellDef="let element" class="centrer padding-0">
                    <div *ngIf="element.dModification">{{element.dModification| date:'dd/MM/yyyy'}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="retour">
                <th mat-header-cell *matHeaderCellDef matTooltip="Retournée"> Retournée </th>
                <td mat-cell *matCellDef="let element" class="align-cell">
                    <mat-icon class="align-icon warn" matTooltip="Retour Valideur" *ngIf="element.retour">check_circle
                    </mat-icon>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumnsDemande; sticky: true"></tr>
            <div *ngIf="!ifListAValide">
                <tr mat-row *matRowDef="let row; columns: displayedColumnsDemande;" (click)="editDemande(row); ">
                </tr>
            </div>
            <div *ngIf="ifListAValide">
                <tr mat-row *matRowDef="let row; columns: displayedColumnsDemande;" (click)="editDemande(row);">
                </tr>
            </div>
        </table>
        <div class="footer">
            <mat-paginator #paginator [length]="this.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                [pageSizeOptions]="[25, 50, 150]" (page)="changePage($event)">
            </mat-paginator>
        </div>
    </div>
</div>