import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DemandeComponentsService, DetailDemandeinfos } from '../demande-components.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { ApplicationUserModel } from 'src/app/shared/models/simple-types/application-user.model';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { BehaviorSubject } from 'rxjs';
import { CycleDemandeService } from 'src/app/shared/services/simple/cycle-demande.service';
import { CycleDemandeModel } from 'src/app/shared/models/simple-types/cycle-demande.model';
import { SessionService } from 'src/app/shared/services/session.service';
import { AddCommentModalComponent } from 'src/app/shared/components/modals/add-comment/add-comment-modal.component';
import { DemandeValidationModel } from 'src/app/shared/dtos/demandeValidationModel';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';

@Component({
  selector: 'app-read-demande',
  templateUrl: './read-demande.component.html',
  styleUrls: ['./read-demande.component.scss']
})
export class ReadDemandeComponent implements OnInit, OnDestroy  {
  demandeForm: UntypedFormGroup;
  demande: DemandeModel = new DemandeModel();
  idDemande: number;
  detailDemandeInfo: DetailDemandeinfos;
  childHeight: number = 0;
  parentWidth: number = 0;
  collabApplications: ApplicationUserModel[] = [];
  currentAgence: AgenceModel;
  observableDemande = new BehaviorSubject<DemandeModel>(null);
  typeDemande: string = "";

  cyclesValidation: CycleDemandeModel[] = [];

  showButtons = false;
  allApplications : ApplicationModel[] = [];


  constructor(private demandeService: DemandeService,
              private router: Router,
              private demandeComponentsService: DemandeComponentsService,
              private applicationService: ApplicationService,
              private route: ActivatedRoute,
              private toastrService: ToastrService,
              private title: Title,
              private agenceService: AgenceService,
              private collabService: CollaborateurService,
              public dialog: MatDialog,
              private cycleDemandeService: CycleDemandeService,
              private sessionService: SessionService) {
    this.route.params.subscribe((params: any) => {
      if (params.id === '0') {
        this.demandeService.demandeSubscriber.subscribe((demande: DemandeModel) => {
          if (demande !== null) {
            this.demande = Object.assign({}, demande);
          } else {
            this.toastrService.error('Erreur durant la récupération de la demande.', 'Erreur');
            this.router.navigate(['/']);
          }
        });
      } else 
      {
        //Affichage boutons abandonner, modifier si on est créateur et demande non validé
        this.demandeService.get(params.id).subscribe(demande => {
          this.sessionService.currentUser.subscribe(x=> {
            if(x.id == demande.idCreateur && (demande.status == 'Brouillon' || demande.status == 'En attente de validation' || demande.status =='En attente de traitement')){
              this.showButtons =true;
            }else{
              this.showButtons =false;
            }
          })
          this.demande = demande;
          this.title.setTitle('GUS - Visualisation demande ' + this.demande.code);
          switch (this.demande.typeDemande) {
            case 0:
              this.typeDemande = 'Création';
              break;
            case 1:
              this.typeDemande = 'Modification';
              this.collabService.get(this.demande.idCollaborateur).subscribe((collab: CollaborateurModel) => {
                if(collab){
                  this.collabApplications = collab.applications;
                }
              });
              break;
            case 2:
              this.typeDemande = 'Suppression';
              this.collabService.get(this.demande.idCollaborateur).subscribe((collab: CollaborateurModel) => {
                if(collab){
                  this.collabApplications = collab.applications;
                }
              });
              break;
          }
          this.detailDemandeInfo = new DetailDemandeinfos();
          this.demandeForm = this.demandeComponentsService.getVisualizeDemandeFormGroup(this.demande);
          this.demandeForm.get('adresseAgence').setValue(demande.agence.adresse);
          this.demandeForm.get('numeroAgence').setValue(demande.agence.telephone);
          this.agenceService.get(this.demande.idAgence).subscribe((res) => this.demande.agence = res);
          this.observableDemande.next(this.demande);

          // get cycles by demande ID
          this.cycleDemandeService.getAllFromDemande(this.demande.id).subscribe(res => {
            this.cyclesValidation = res;            
          })
        }, (error) => {
          toastrService.error('La demande n\'existe pas');
          this.router.navigate(['/not-found']);
        });
      }
    });
  }


  cancelDemande(){
    //Changer statut d ela demande en "Annulé"
    const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
      data: {
        title: 'Êtes-vous sûr de vouloir abandonner cette demande',
        color: 'danger',
        icon_color: 'accent',
       
      }
    });
    dialogConfirmation.afterClosed().subscribe(res => {
      if(res || res === '') {
        const obj = new DemandeValidationModel(this.demande);
        obj.commentaire = res;
         this.demandeComponentsService.annulerDemande(obj).subscribe(x => {
           this.router.navigate(['/list-demande/']);
         });  
      }
    });
  }
  
  updateDemande(){
    //Meme chose que le bouton retour pour complement mais sans le commentaire
    if(this.demande.status == "Brouillon"){//Si la demande est déja en brouillon
      this.router.navigate(['/edit-demande/'+this.demande.id]);
    }else{
      const obj = new DemandeValidationModel(this.demande);
      obj.commentaire = '';
      obj.isModif = true;
      this.demandeComponentsService.retourDemande(obj).subscribe(x => {
        this.router.navigate(['/edit-demande/'+this.demande.id]);
        });
    }
  }


  ngOnInit(): void {
    this.getChildHeight();
    this.getParentWidth();
    this.applicationService.getAll().subscribe((apps: ApplicationModel[]) => {
      this.allApplications = apps;
    });
  }
  
  close(): void {
    this.router.navigate(['/list-demande/']);
  }

  ngOnDestroy(): void {
    this.demandeComponentsService.purgeDetailDemandeInfo();
  }

  getChildHeight(): any {
    // setTimeout(() => {
    //   const childHeight = document.querySelector('#heigthChild').clientHeight;
    //   if (childHeight > 0) {
    //     this.childHeight = childHeight;
    //   }
    // }, 200);
  }

  getParentWidth(): any {
    // setTimeout(() => {
    //   const parentWidth = document.querySelector('#parent-container').clientWidth;
    //   if (parentWidth > 0) {
    //     this.parentWidth = parentWidth;
    //   }
    // }, 200);
  }
  
 
}
