<form class="myControl">
  
    <mat-form-field class="full-width">        
           <mat-label>{{libelle}}</mat-label>
           <input type="text"
                   placeholder="Selectionner une région"
                   matInput
                   [required]="required"
                   (change)="changeEvent()"
                   [formControl]="myControl"
                   [matAutocomplete]="auto">
           <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeEvent()">
               <mat-option *ngFor="let option of filteredRegions | async" [value]="option" [matTooltip]="option"> {{option}} </mat-option>
           </mat-autocomplete>
           <mat-error *ngIf="myControl.hasError('required')">
            La région est obligatoire.
        </mat-error>
       </mat-form-field>
   </form>
   