import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { BehaviorSubject, interval } from 'rxjs';
import { debounceTime, filter, throttle } from 'rxjs/operators';
import { FiltreRechercheCollaborateurDTO } from 'src/app/shared/dtos/filtreRechercheCollaborateurDTO';
import { SimpleQueryResponse } from 'src/app/shared/dtos/simpleQueryResponse';
import { SimpleQuery } from 'src/app/shared/dtos/simple_query';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';


@Component({
  selector: 'app-list-collaborateur-for-exports',
  templateUrl: './list-collaborateur-for-exports.component.html',
  styleUrls: ['./list-collaborateur-for-exports.component.scss']
})

export class ListCollaborateurForExportsComponent implements OnInit, OnChanges {


  @Input() reponseCollaborateur: SimpleQueryResponse<CollaborateurModel>;
  @Input() comingFrom: String;
  @Input() filtre: SimpleQuery<FiltreRechercheCollaborateurDTO>;
  @Input() filtreData: FiltreRechercheCollaborateurDTO;
  @Output() selectCollab = new EventEmitter<boolean>();

  collaborateurs: CollaborateurModel[] = [];
  displayedColumns: string[] = ['Nom', 'Email', 'region', 'TypeContrat', 'CDS', 'Responsable', 'applications', 'DModification', 'DDesactivation'];
  displayedSubColumns: string[] = ['ADP', 'Esker', 'myDSO', 'NTH', 'Jason', 'Sacha', 'Sage', 'Autres'];
  allColDef: string[] = ['Nom', 'Email', 'region', 'TypeContrat', 'CDS', 'Responsable','ADP', 'Esker', 'myDSO', 'NTH', 'Jason', 'Sacha', 'Sage', 'Autres', 'DModification', 'DDesactivation'];

  dataSource = new MatTableDataSource<CollaborateurModel>();

  length: number;
  pageIndex: number;
  pageSize = 25;
  pageEvent: PageEvent;
  regions: RegionModel[];
  agences: AgenceModel[];
  sortState: Sort = { active: 'nom', direction: 'asc' };
  icon = '<mat-icon>add</mat-icon>'
  filtreDataSubject: BehaviorSubject<SimpleQuery<FiltreRechercheCollaborateurDTO>> = new BehaviorSubject<SimpleQuery<FiltreRechercheCollaborateurDTO>>(new SimpleQuery<FiltreRechercheCollaborateurDTO>());
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  coutTotal: number = 0

  constructor(
    public router: Router,
    public collaborateurService: CollaborateurService,
  ) { }

  ngOnInit(): void {
    this.filtreDataSubject.pipe(filter(value => value.entry != null ), debounceTime(500)).subscribe(value => {
      this.collaborateurService.GetCollaborateurFromObjetWithPagination(value).subscribe((response: SimpleQueryResponse<CollaborateurModel>) => {
        this.collaborateurs = response.objets;
        this.length = response.count;
        this.pageSize = response.pageSize;
        this.pageIndex = response.pageNumber
        this.dataSource = new MatTableDataSource<CollaborateurModel>(this.collaborateurs);
        this.coutTotal = response.coutTotal;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filtreData.currentValue) {
      const fData = Object.assign({}, this.filtreDataSubject.value);
      fData.entry = this.filtreData;
      fData.pageNumber = this.filtreData.pageNumber;
      fData.pageSize = this.filtreData.pageSize;
      this.filtreDataSubject.next(fData);
    }
  }

  // TODO : make the comparison case-insensitive
  checkforApp(appName, object) {
    switch (appName) {
      case "ADP":
        return object.applications.some(x => x.application.nom == "ADP Decidium")
      case "Esker":
        return object.applications.some(x => x.application.nom == "ESKER")
      case "myDSO":
        return object.applications.some(x => x.application.nom == "My DSO Manager")
      case "NTH":
        return object.applications.some(x => x.application.nom == "NTH")
      case "Jason":
        return object.applications.some(x => x.application.nom == "Jason")
      case "Sacha":
        return object.applications.some(x => x.application.nom == "SACHA")
      case "Sage":
        const pattern = new RegExp('Sage\s[A-Za-z]+');
        return object.applications.some(x => pattern.test(x.application.nom))
      case "Autres":
        const pattern1 = new RegExp('Sage\s[A-Za-z]+');
        let appNames = ['ADP', 'Esker', 'myDSO', 'NTH', 'Jason', 'SACHA', 'Sage']
        let test = object.applications.filter(x => !appNames.includes(x.application.nom)).filter(x => !pattern1.test(x.application.nom))
        return test.length != 0;
      default:
        return false;
    }
  }

  trieTab(sort: Sort): void {
    const fData = Object.assign({}, this.filtreDataSubject.value);
    if (sort.direction !== 'asc') {
      fData.descending = false;
    } else {
      fData.descending = true;
    }
    this.sortState = sort;
    fData.order = sort.active;
    this.filtreDataSubject.next(fData);
  }

  openEvent(user: CollaborateurModel): void {
    if (!this.comingFrom) {
      this.router.navigate(["read-collaborateur/" + user.id]);
    } else {
      this.router.navigate(["create-demande"], { queryParams: { typeDemande: this.comingFrom, idCollab: user.id } });
    }
    this.selectCollab.emit(true);
  }

  changePage(event?: PageEvent): void {
    const fData = Object.assign({}, this.filtreDataSubject.value);
    fData.pageNumber = event.pageIndex;
    fData.pageSize = event.pageSize;
    this.filtreDataSubject.next(fData);
  }
}
