import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { Title } from '@angular/platform-browser';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';
import { ReferentielComponentsService } from '../referentiel-components.service';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { ResponsableAppliModel } from 'src/app/shared/models/simple-types/responsable-appli.model';
import { RESPONSABLEVALIDATORS } from 'src/app/shared/constants/responsable-validators';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { ModalAddResponsableComponent } from 'src/app/shared/components/modals/modal-add-responsable/modal-add-responsable.component';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { SimpleQueryUser } from 'src/app/shared/dtos/simpleQueryUser';
import { ModalInfoOkComponent } from 'src/app/shared/components/modals/info-ok/modal-info-ok.component';
import { ResponsableAppliService } from 'src/app/shared/services/agence-linked/responsableAppli.service';

@Component({
  selector: 'app-fiche-application',
  templateUrl: './fiche-application.component.html',
  styleUrls: ['./fiche-application.component.scss']
})
export class FicheApplicationComponent implements OnInit {

  application: ApplicationModel;
  agences: AgenceModel[] = [];
  collabs: CollaborateurModel[] = [];
  responsables: ResponsableAppliModel[];
  form: UntypedFormGroup;
  agence: AgenceModel;
  showDelay = new UntypedFormControl(0);
  hideDelay = new UntypedFormControl(0);
  responsablesForm: UntypedFormArray;
  displayedColumns: string[] = ['email','action'];
  matDataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource<AbstractControl>();

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private agenceService: AgenceService,
    private applicationService: ApplicationService,
    private toastr: ToastrService,
    private referentielService: ReferentielComponentsService,
    private responsableAppliService: ResponsableAppliService,
    private router: Router,
    private title: Title,
    private collaborateurService: CollaborateurService
  ) { 
    this.agenceService.getAll().subscribe(obj => {
      this.agences = obj;
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Fiche Application');
    this.route.params.subscribe((params: any) => {
      this.onLoadApplication(params.id).subscribe((application: ApplicationModel) => {});  

    });
  }

  updateFormControls() {
    const invisibleFormControl = this.form.get('invisible');
    const defaultFormControl = this.form.get('default');
    const cdsFormControl = this.form.get('cdsOn');
    if (cdsFormControl.value) {
      defaultFormControl.disable();
      invisibleFormControl.disable();
    } else 
    {
      defaultFormControl.enable();
      invisibleFormControl.enable();
    } if (invisibleFormControl.value) {
      defaultFormControl.setValue(true);
      defaultFormControl.disable();
      cdsFormControl.disable();
    } else if (defaultFormControl.value){
      cdsFormControl.disable();
    } else {
      cdsFormControl.enable();
    }
  }

  onLoadApplication(id: number): Observable<ApplicationModel> {
    const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
    return this.applicationService.get(id).pipe(tap((application: ApplicationModel) => {
      if (application !== null && application !== undefined) {    
        // distinct by idResponsable
        application.responsables = Array.from(application.responsables.reduce((m, t) => m.set(t.idResponsable, t), new Map()).values());
        this.application = Object.assign({}, application);
        this.form = this.referentielService.getEditApplicationFormGroup(application);
        const invisibleFormControl = this.form.get('invisible');
        const defaultFormControl = this.form.get('default');
        const cdsFormControl = this.form.get('cdsOn');
        if (cdsFormControl.value) {
          defaultFormControl.disable();
          invisibleFormControl.disable();
        } else if (invisibleFormControl.value) {
          defaultFormControl.disable();
        } if (defaultFormControl.value){
          cdsFormControl.disable();
        }
        this.onLoadResponsables();
      }
      const user = new SimpleQueryUser();
      user.query = "";
      user.responsables = this.responsables;
      // Chargement des collaborateurs
      this.collaborateurService.getFastQuery(user).subscribe((resp: CollaborateurModel[]) => {
        this.collabs = resp.filter(res =>  (res.nom && res.nom !== ''));
        dialogRefSpinner.close()
      },(error) => {
        dialogRefSpinner.close()
      });
    },(error) => {
      this.toastr.error("Erreur lors du chargement de l'application");
      this.router.navigate(['/gestion-application']);
    }));
  }

  onLoadResponsables(): void {
    this.responsablesForm = this.form.get('responsables') as UntypedFormArray;
    const mySort: Sort = { active: 'email', direction: 'asc' };
    this.sortData(mySort);
    this.matDataSource = new MatTableDataSource<AbstractControl>(this.responsablesForm.controls);
    this.responsables = this.form.get('responsables').value;
  }


  sortData(sort: Sort) {
    this.responsablesForm.controls.sort((a, b) => {
      var collabA = this.collabs.find(ag => ag.id === a.get('idResponsable').value);
      if (!collabA)
        collabA = this.application.responsables.find(x => x.idResponsable === a.get('idResponsable').value).responsable;
      var collabB = this.collabs.find(ag => ag.id === b.get('idResponsable').value);
      if (!collabB)
        collabB = this.application.responsables.find(x => x.idResponsable === b.get('idResponsable').value).responsable;
      if (collabA.dDesactivation && !collabB.dDesactivation)
        return -1;
      if (!collabA.dDesactivation && collabB.dDesactivation)
        return 1;
      const isAsc = sort.direction === 'asc';
      return compare(collabA.email, collabB.email, isAsc);
    });
    this.matDataSource = new MatTableDataSource<AbstractControl>(this.responsablesForm.controls);
  }

  getLibelleAgenceById(id: number){
    var agence =  this.agences.find(ag => ag.id === id);
    return agence ? agence.libelle : "Toutes";
  }

  getEmailResponsableById(id: number){
    var collab = this.collabs.find(ag => ag.id === id);
    if (collab)
      return collab.email;
    var resp = this.application.responsables.find(x => x.idResponsable === id);
    if (resp)
      return resp.responsable.email;
    return "";    
  }

  isResponsableByIdDisabled(id: number){
    var collab = this.collabs.find(ag => ag.id === id);
    if (collab)
      return collab.dDesactivation !== null;
    var resp = this.application.responsables?.find(x => x.idResponsable === id);
    if (resp)
      return resp.responsable.dDesactivation !== null;
    return true;    
  }

  memorise(): void {
    if(this.form.valid){
      const dialogRef = this.dialog.open(SpinnerModalComponent);
      this.application = this.form.getRawValue();
      this.application.responsables = this.form.get('responsables').value;
      if (this.form.get('categorie').value != null && this.form.get('categorie').value.length == 0) {
        this.application.categorie = null;
      }
  
      this.application.responsables.forEach(res => {
        res.application = null;
        res.agence = null;
        res.responsable = null;
      })
      this.applicationService.updateApplication(this.application).subscribe(res => {
        this.application = res;
        this.toastr.success('Enregitrement des modifications', 'Modification réussie.');
        dialogRef.close();
      }, (error) => {
        dialogRef.close();
      });
    }
    
  }

  addResponsable(): void {
    const dialogRef = this.dialog.open(ModalAddResponsableComponent, {
      autoFocus: false,
      disableClose: false,
      maxHeight: '90vh',
      data: {
        application: this.application,
        agences: this.agences,
        collabs: this.collabs,
        responsables: this.responsables,
      }
    })
    dialogRef.afterClosed().subscribe((responsables: ResponsableAppliModel[]) => {
      if(responsables && responsables.length > 0){
        (this.form.controls['responsables'] as UntypedFormArray).push(this.referentielService.getResponsableForm(responsables[0], RESPONSABLEVALIDATORS.EDIT));
        this.onLoadResponsables();
      }
    });
  }

  modifyResponsable(responsable: FormGroup): void {
    const dialogRef = this.dialog.open(ModalAddResponsableComponent, {
      autoFocus: false,
      disableClose: false,
      maxHeight: '90vh',
      data: {
        application: this.application,
        agences: this.agences,
        collabs: this.collabs,
        responsables: this.responsables,
        responsable: responsable.getRawValue()
      }
    })
    dialogRef.afterClosed().subscribe(responsables => {
      if(responsables && responsables.length > 0){
        responsable.get("idResponsable").setValue(responsables[0].idResponsable);
        this.onLoadResponsables();
      }
    });
  }

  deleteResponsable(event: FormGroup, index: number): void {
    const dialogConfirmation = this.dialog.open(ModalInfoOkComponent, {
      data: {
        title: 'Demande de suppression du responsable applicatif',
        type: 'Responsable',
        message: 'Souhaitez-vous supprimer ce responsable? ',
        icone: 'warning',
        iconColor: 'warn',
        color: 'danger',
      }
    });
    dialogConfirmation.afterClosed().subscribe((resultat) => {
      if (resultat) {
        this.responsableAppliService.deleteAll(this.application.id, event.get("idResponsable").value).subscribe((res: ResponsableAppliModel[]) => {
          this.toastr.success('', 'Modifications sauvegarder')
        });  
        (this.form.controls['responsables'] as UntypedFormArray).removeAt(index);
        this.onLoadResponsables();
      }
    });    
  }

  close(): void {
    this.router.navigate(['/gestion-application/']);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

 