import { Router } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import moment from 'moment';

export interface DialogData {
  icone: string;
  type: string;
  title: string;
  subTitle: string;
  message: string;
  color: string;
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  result: boolean;
  footer: string;
}

@Component({
  selector: 'app-modal-info-ok',
  templateUrl: './modal-info-ok.component.html'
})
export class ModalInfoOkComponent implements OnInit {
  dLivraison = new Date(Date.now());
  icone: string;
  title: string;
  subTitle: string;
  message: string;
  color: string;
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  type: string;
  footer: string = null;

  minDate: string = this.getValidateAllDemandeMinDates();
  maxDate: string = moment(new Date()).format('YYYY-MM-DD');


  constructor(public dialog: MatDialog,
              public router: Router,
              public dialogRef: MatDialogRef<Component>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.icone = this.data.icone !== undefined ? this.data.icone : '';
    this.title = this.data.title !== undefined ? this.data.title : '';
    this.subTitle = this.data.subTitle !== undefined ? this.data.subTitle : '';
    this.icon_color = this.data.icon_color !== undefined ? this.data.icon_color : '';
    this.dialogWidth = this.data.dialogWidth !== undefined ? this.data.dialogWidth : '';
    this.dialogHeight = this.data.dialogHeight !== undefined ? this.data.dialogHeight : '';
    this.message = this.data.message;
    this.color = this.data.color;
    this.type = this.data.type;
    this.footer = this.data.footer;
  }

  onModalCancel(): void {
    this.data.result = false;
    this.dialogRef.close(null);
  }

  onModalConfirm(): void {
    this.data.result = true;
    this.dialogRef.close(this.dLivraison);
  }

  getValidateAllDemandeMinDates(): string {
    const minDate = moment(new Date());

    if (minDate.day() === 2 || minDate.day() === 1) {
      return minDate.add(-4, 'days').format('YYYY-MM-DD');
    } else {
      return minDate.add(-2, 'days').format('YYYY-MM-DD');
    }
  }

  /* Prevent Saturday and Sunday for select. */
  weekendsDatesFilter = (minDate: Date): boolean => {
    if (minDate) {
      const day = minDate.getDay();
      return day !== 0 && day !== 6;
    }
  }
}
