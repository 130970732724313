import { AddCommentModalComponent } from '../../../shared/components/modals/add-comment/add-comment-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DemandeComponentsService,
  DetailDemandeinfos,
} from '../demande-components.service';
import { DemandeValidationModel } from 'src/app/shared/dtos/demandeValidationModel';
import { ToastrService } from 'ngx-toastr';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { ApplicationUserModel } from 'src/app/shared/models/simple-types/application-user.model';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { CycleDemandeModel } from 'src/app/shared/models/simple-types/cycle-demande.model';
import { CycleDemandeService } from 'src/app/shared/services/simple/cycle-demande.service';
import { FilterDemandeDTO } from 'src/app/shared/dtos/filterDemandeDTO';
import { FiltreService } from '../../filtre.service';
import { SimpleQueryResponse } from 'src/app/shared/dtos/simpleQueryResponse';
import { map } from 'rxjs/operators';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';

@Component({
  selector: 'app-validate-demande',
  templateUrl: './validate-demande.component.html',
  styleUrls: ['./validate-demande.component.scss'],
})
export class ValidateDemandeComponent implements OnInit, OnDestroy {
  collabApplications: ApplicationUserModel[] = [];
  demandeForm: UntypedFormGroup;
  observableDemande = new BehaviorSubject<DemandeModel>(null);
  demande: DemandeModel = new DemandeModel();
  idDemande: number;
  typeDemande: string = '';
  detailDemandeInfo: DetailDemandeinfos;
  childHeight: number = 0;
  parentWidth: number = 0;
  listDemandeForModal: DemandeModel[] = [];
  listDemandeCollab: DemandeModel[] = [];
  // Message d'alerte pour une demande validé ou traité sur le collaborateur entre la date de création de la demande et maintenant.
  alertMessage: string;
  cyclesValidation: CycleDemandeModel[] = [];

  autoNext: boolean = false;
  filtre: any;
  allApplications : ApplicationModel[] = [];

  constructor(
    private applicationService: ApplicationService,
    private demandeService: DemandeService,
    protected toastr: ToastrService,
    private router: Router,
    private dialog: MatDialog,
    private demandeComponentsService: DemandeComponentsService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private agenceService: AgenceService,
    private collabService: CollaborateurService,
    private cycleDemandeService: CycleDemandeService,
    private filtreService: FiltreService
  ) {
    this.filtreService.filtreDemande.subscribe((result) => {
      this.filtre = result;
    });

    this.route.params.subscribe((params: any) => {
      if (params.id === '0') {
        this.demandeService.demandeSubscriber.subscribe(
          (demande: DemandeModel) => {
            if (demande !== null) {
              this.demande = Object.assign({}, demande);
            } else {
              this.toastrService.error(
                'Erreur durant la récupération de la demande.',
                'Erreur'
              );
              this.router.navigate(['/']);
            }
          }
        );
      } else {
        this.demandeService.get(params.id).subscribe(
          (demande) => {
            this.demande = demande;
            switch (this.demande.typeDemande) {
              case 0:
                this.typeDemande = 'Création';
                break;
              case 1:
                this.typeDemande = 'Modification';
                this.collabService
                  .get(this.demande.idCollaborateur)
                  .subscribe((collab: CollaborateurModel) => {
                    if (collab) {
                      this.collabApplications = collab.applications;
                    }
                  });
                break;
              case 2:
                this.typeDemande = 'Suppression';
                this.collabService
                  .get(this.demande.idCollaborateur)
                  .subscribe((collab: CollaborateurModel) => {
                    if (collab) {
                      this.collabApplications = collab.applications;
                    }
                  });
                break;
            }

            this.demandeForm =
              this.demandeComponentsService.getValidateDemandeFormGroup(
                this.demande
              );
            this.demandeForm
              .get('adresseAgence')
              .setValue(demande.agence.adresse);
            this.demandeForm
              .get('numeroAgence')
              .setValue(demande.agence.telephone);
            this.agenceService
              .get(this.demande.idAgence)
              .subscribe((res) => (this.demande.agence = res));
            this.observableDemande.next(this.demande);

            // get cycles by demande ID
            this.cycleDemandeService
              .getAllFromDemande(this.demande.id)
              .subscribe((res) => {
                this.cyclesValidation = res;
              });
          },
          (error) => {
            toastrService.error("La demande n'existe pas");
            this.router.navigate(['/not-found']);
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.applicationService.getAll().subscribe((apps: ApplicationModel[]) => {
      this.allApplications = apps;
    });
    this.verifyDemandeStatusToRedirect();
    this.getChildHeight();
    this.getParentWidth();

    if (
      localStorage.getItem('autoNext') &&
      localStorage.getItem('autoNext') == 'true'
    ) {
      this.autoNext = true;
    } else {
      this.autoNext = false;
      localStorage.setItem('autoNext', JSON.stringify(false));
    }
  }

  autoChecked(event) {
    localStorage.setItem('autoNext', JSON.stringify(event.checked));
  }

  ngOnDestroy(): void {
    this.demandeComponentsService.purgeDetailDemandeInfo();
  }

  verifyDemandeStatusToRedirect(): void {
    if (this.observableDemande) {
      this.observableDemande.subscribe((demande: DemandeModel) => {
        if (demande) {
          if (demande.statusNum !== 2) {
            this.router.navigate(['/']);
            this.toastr.warning(
              'Cette demande est en brouillon ou a déjà été validée.',
              'Erreur'
            );
          }
        }
      });
    }
  }

  close(): void {
    this.router.navigate(['/list-demande/']);
  }

  retour(): void {
    const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
      data: {
        title: 'Commentaire de retour',
        color: 'primary',
        icon_color: 'accent',
        required: true
      },
    });
    dialogConfirmation.afterClosed().subscribe((res) => {
      if (res !== '' && res) {
        const dialogRef = this.dialog.open(SpinnerModalComponent);
        const obj = new DemandeValidationModel(this.demande);
        obj.commentaire = res;
        this.demandeComponentsService.retourDemande(obj).subscribe((x) => {
          dialogRef.close();
          this.router.navigate(['/list-demande/']);
        }, (error) => {
          this.toastrService.warning("Erreur survenue pendant le retour de la demande : " + error.Message);
          dialogRef.close();
        });
      } else if (res === '') {
        this.toastr.error('Commentaire obligatoire');
      }
    });
  }
  validate(): void {
    /*On récupére la list des demandes du collaborateur pour vérifier si il existe des demandes validé ou  traité 
         sur le même collaborateur entre la date de création de la demande et maintenant.*/

    var observable: Observable<DemandeModel[]>;
    if (!this.demande.idCollaborateur) {
      observable = of([]);
    } else {
      observable = this.demandeService
        .getAllDemandeByIdCollab(this.demande.idCollaborateur)
        .pipe(
          map((res) => {
            this.listDemandeCollab = res;
            this.listDemandeForModal = [];
            for (var demande of this.listDemandeCollab) {
              if (demande.code != this.demande.code) {
                // Ce if c'est pour ignoré la demande qu'on souhaite valider / traiter
                if (
                  (demande.status == 'En attente de traitement' ||
                    demande.status == 'Traité') &&
                  this.demande.dCreation < demande.dModification
                ) {
                  this.alertMessage =
                    "Une demande a été ( validé / traité ) sur le même collaborateur entre la date de création de la demande et aujourd'hui.";
                  this.listDemandeForModal.push(demande);
                }
              }
            }
            return this.listDemandeForModal;
          })
        );
    }
    observable.subscribe((listDemande) => {
      const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
        autoFocus: false,
        disableClose: false,
        maxHeight: '90vh',
        data: {
          title: 'Commentaire de validation',
          alertMessage: this.alertMessage,
          color: 'primary',
          icon_color: 'accent',
          listDemande: this.listDemandeForModal,
        },
      });

      dialogConfirmation.afterClosed().subscribe((res) => {
        if (res === '' || res) {
          const obj = new DemandeValidationModel(this.demande);
          obj.commentaire = res;
          this.demandeComponentsService.validateDemande(obj).subscribe((x) => {
            if (this.autoNext) {
              const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
              this.filtre = new FilterDemandeDTO();
              this.filtre.order = 'DCreation';
              this.filtre.descending = true;
              this.filtre.type = 'v_wait_validation_by_me';
              this.filtreService.changeFiltre(this.filtre);
              this.demandeService
                .getFiltredDemande(this.filtre)
                .subscribe((data: SimpleQueryResponse<DemandeModel>) => {
                  setTimeout(() => {
                    dialogRefSpinner.close();
                    if (data.objets[0]) {
                      this.router.navigate([
                        '/validate-demande/' + data.objets[0].id,
                      ]);
                    } else {
                      this.router.navigate(['/']);
                    }
                  }, 1000);
                });
            } else {
              this.router.navigate(['/list-demande/']);
            }
          });
        }
      });
    });
  }

  refus(): void {
    const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
      data: {
        title: 'Commentaire de refus',
        color: 'primary',
        icon_color: 'accent',
        required: true
      },
    });
    dialogConfirmation.afterClosed().subscribe((res) => {
      if (res !== '' && res) {
        const dialogRef = this.dialog.open(SpinnerModalComponent);
        const obj = new DemandeValidationModel(this.demande);
        obj.commentaire = res;
        this.demandeComponentsService.refusDemande(obj).subscribe((x) => {
          dialogRef.close();
          this.router.navigate(['/list-demande/']);
        }, (error) => {
          this.toastrService.warning("Erreur survenue pendant le refus de la demande : " + error.Message);
          dialogRef.close();
        });
      } else if (res === '') {
        this.toastr.error('Commentaire obligatoire');
      }
    });
  }

  getChildHeight(): any {
    // setTimeout(() => {
    //   const childHeight = document.querySelector('#heigthChild') !== null ? document.querySelector('#heigthChild').clientHeight : null;
    //   if (childHeight > 0) {
    //     this.childHeight = childHeight;
    //   }
    // }, 200);
  }

  getParentWidth(): any {
    // setTimeout(() => {
    //   // tslint:disable-next-line: max-line-length
    //   const parentWidth = document.querySelector('#validate-demande-container') !== null ? document.querySelector('#validate-demande-container').clientWidth : null;
    //   if (parentWidth > 0) {
    //     this.parentWidth = parentWidth;
    //   }
    // }, 200);
  }
}
