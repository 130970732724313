import { AgenceModel } from "../models/rsa/agence.model";
import { RegionModel } from "../models/rsa/region.model";
import { SocieteModel } from "../models/rsa/societe.model";

export class FiltreRechercheCollaborateurDTO {

    public nom: string = "";
    public prenom: string = "";
    public email: string = "";
    public agence: AgenceModel = null;
    public agences: AgenceModel[] = [];
    public idAgence: number = null;

    public societeLibelle: string = "";
    public societeId?: number = null;
    public societeObj: SocieteModel = null;
    public societeObjs: SocieteModel[] = [];

    public region: RegionModel = null;
    public regions: RegionModel[] = [];
    public idRegion: number = null;

    public typeContrat : string[] = ["CDI","Prestation","CDD / Stage / Alternance","Intérim"];
    public typeCompte : string[] = ["Workspace", "Gdrive seul (Cloud Identity)"];
    public compteActif : string[] = ["Actif", "Desactive"];  
    public descending = false;
    public pageNumber = 0;
    public pageSize = 25;
    public order = 'Nom';
}