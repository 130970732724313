import { ResponsableAppliModel } from "../models/simple-types/responsable-appli.model";

export class SimpleQueryUser {
    public query?: string;
    public type?: string;
    public descending: boolean;
    public pageNumber: number;
    public pageSize: number;
    public order?: string;
    public responsables: ResponsableAppliModel[];
  }