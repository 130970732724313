import { HostnameService } from 'src/app/shared/services/basic/hostname.service';
import { BasicModel } from './../models/basic/basic.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';


class DateAndBehaviorResponse {
  dateMaxValid: number;
  response: ReplaySubject<HttpEvent<BasicModel>>;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  public cache: Map<string, DateAndBehaviorResponse> = new Map<string, DateAndBehaviorResponse>();
  private urlsToCatched: string[] = [];
  helper: JwtHelperService;
  dureRetention = 100;
  dureRetentionRef = 3600000;
  constructor(
    private hostnameService: HostnameService) {
                this.urlsToCatched.push((this.hostnameService.getUrl() + 'api/agence/Get'));
              }

  public newRequest(request: HttpRequest<any>, httpResponse: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> {
    if (this.containsOrStartWith((request.url))) {
      httpResponse = this.saveInCache(request, httpResponse, this.dureRetentionRef);
      return httpResponse;
    } else if(request.method.localeCompare("GET") === 0) {
      httpResponse = this.saveInCache(request, httpResponse, this.dureRetention);
      return httpResponse;
    } else {
      return httpResponse;
    }
  }

  public getFromCache(request: HttpRequest<any>): Observable<HttpEvent<any>>{
    const url = (request.url);
    const cachedResponse: DateAndBehaviorResponse = this.cache.get(url);
    if (cachedResponse) {
      // si dateMaxValid < date du jour => suppression de l'enregistrement cache + return null;
      if (cachedResponse.dateMaxValid < Date.now()) {
        this.removeFromCache(request);
        return null;
      }
      return cachedResponse.response;
    } else {
      return null;
    }
  }

  public saveInCache(request: HttpRequest<any>,
                     httpResponse: Observable<HttpEvent<any>>,
                     dureRetention: number): Observable<HttpEvent<any>>{
    // enregistrement Date + durée vie requette;
    const sub: DateAndBehaviorResponse = {
      dateMaxValid: (Date.now() + dureRetention),
      response: new ReplaySubject<HttpEvent<any>>(1)
    };
    httpResponse.subscribe(sub.response);
    this.cache.set(request.url, sub);
    return sub.response;
  }

  public removeFromCache(request: HttpRequest<any>): void {
    this.cache.delete(request.url);
  }

  containsOrStartWith( url: string): boolean{
    if (this.urlsToCatched.includes(url)) {
      return true;
    }
    for (const element of this.urlsToCatched){
      if (url.startsWith(element)) {
        return true;
      }
    }
    return false;
  }
}
