import { Validators } from '@angular/forms';

export class FormInfo {
  validators: Validators;
  disabled: boolean;
  constructor(disabled: boolean, validator: Validators) {
    this.disabled = disabled;
    this.validators = validator;
  }
}

export const RESPONSABLEVALIDATORS = {
  CREATE : new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idApplication',  new FormInfo(false, [Validators.required])],
    ['idResponsable',  new FormInfo(false, [Validators.required])],
    ['agenceId', new FormInfo(false, [])],
  ]),
  EDIT: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idApplication',  new FormInfo(false, [Validators.required])],
    ['idResponsable',  new FormInfo(false, [Validators.required])],
    ['agenceId', new FormInfo(false, [])],
  ])
};
