import { APICONSTANTS } from '../../constants/api-constants';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HostnameService } from '../basic/hostname.service';
import { ApplicationModel } from '../../models/simple-types/application.model';
import { DocumentService } from './document.service';
import { DocumentModel } from '../../models/simple-types/document.model';
import { filter, switchMap } from 'rxjs/operators';
import { GenericCrudService } from '../basic/generic.crud.service';
import { SimpleQueryUser } from '../../dtos/simpleQueryUser';
import { SimpleQueryResponse } from '../../dtos/simpleQueryResponse';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApplicationService extends GenericCrudService<ApplicationModel> {
    private subscriptions: Subscription[] = [];

    constructor(http: HttpClient,
                protected toastrService: ToastrService,
                protected documentService: DocumentService,
                protected hostnameService: HostnameService) {
        super(http, toastrService, APICONSTANTS.ROUTEAPPLICATION, hostnameService);
  }
  deleteDocument(idAppli: number, idDoc: number): Observable<number> {
    const avancement = new BehaviorSubject<number>(0);
    this.subscriptions.push(this.documentService.deleteDocAppli(idDoc, idAppli).pipe(switchMap(doc => {
      avancement.next(80);
      return this.documentService.delete(idDoc);
    })).subscribe(sub => {
      avancement.next(100);
    }));
    return avancement.asObservable();
  }

  getDocumentById(idAppli: number, idDoc: number): Observable<Blob> {
    const avancement = new BehaviorSubject<number>(0);
    return this.documentService.getDocAppli(idDoc, idAppli);
  }

  sendDocument(filename: string, idAppli: number, file: File): Observable<number> {
    const avancement = new BehaviorSubject<number>(0);
    this.subscriptions.push(this.documentService.sendDocAppli(filename, idAppli, file).pipe(filter((doc: HttpEvent<unknown>) => {
      return doc.type === HttpEventType.Response;
    }),
      switchMap((doc: HttpEvent<unknown>) => {
        avancement.next(80);
        const document = new DocumentModel();
        document.libelle = filename;
        // add document type !!!
        document.idApplication = idAppli;
        return this.documentService.save(document);
      })).subscribe(sub => {
        avancement.next(100);
      }, error => {
        avancement.next(-1);
      }));
    return avancement.asObservable();
  }

  loadFromSimpleQueryRef(objet: SimpleQueryUser): Observable<SimpleQueryResponse<ApplicationModel>> {
    return this.http.post<SimpleQueryResponse<ApplicationModel>>(APICONSTANTS.URLAPI.value + this.route + 'loadFromSimpleQueryRef', objet)
      .pipe(catchError(err => this.requestCatchError(err, 'Le chargement')));
  }

  updateApplication(objet: ApplicationModel): Observable<ApplicationModel> {
    return this.http.put<ApplicationModel>(APICONSTANTS.URLAPI.value + this.route + 'updateApplication', objet, {})
    .pipe(catchError( err => this.requestCatchError(err, 'Update application & responsable')));
  }

  desactivation(id: number, objet: ApplicationModel): Observable<ApplicationModel> {
    objet.responsables = null;
    return this.http.put<ApplicationModel>(APICONSTANTS.URLAPI.value + this.route + 'desactivation/' + id, objet, {})
    .pipe(catchError( err => this.requestCatchError(err, 'Desactivation de l\'application')));
  }
}