import { BasicModel } from '../basic/basic.model';
import { AgenceModel } from '../rsa/agence.model';
import { ApplicationUserModel } from '../simple-types/application-user.model';

export class CollaborateurModel extends BasicModel {
    idAgence: number;
    agence: AgenceModel;
    // max length 30
    nom: string;
    // max length 30
    prenom: string;
    // max 50
    email: string;
    // max length 20
    tel: string;
    login: string = ""; // code sarp

    dateDebut: Date;
    dateFin: Date;

    typeContrat: string;

    cds: boolean;
    
    idResponsable: number;
    responsable: CollaborateurModel;

    applications: ApplicationUserModel[];

}
