import { SimpleQueryResponse } from '../../../shared/dtos/simpleQueryResponse';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UtilisateurModel } from '../../../shared/models/utilisateur/utilisateur.model';
import { UserService } from '../../../shared/services/user.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FilterDemandeDTO } from 'src/app/shared/dtos/filterDemandeDTO';
import { StateService } from 'src/app/shared/services/state.service';
import { FiltreService } from '../../filtre.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DemandeValidationModel } from 'src/app/shared/dtos/demandeValidationModel';
import { ModalChargementComponent } from 'src/app/shared/components/modals/modal-chargement/modal-chargement.component';
import { saveAs } from 'file-saver';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';
import { BasicModel } from 'src/app/shared/models/basic/basic.model';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { GenericCrudService } from 'src/app/shared/services/basic/generic.crud.service';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { RegionService } from 'src/app/shared/services/rsa/region.service';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { getStatusDemande, StatusDemande } from 'src/app/shared/constants/status';
import { SocieteModel } from 'src/app/shared/models/rsa/societe.model';
import { SocieteService } from 'src/app/shared/services/rsa/societe.service';
import { TypeDemande, getTypeDemande } from 'src/app/shared/constants/typeDemande';


@Component({
  selector: 'app-liste-demande',
  templateUrl: './liste-demande.component.html',
  styleUrls: ['./liste-demande.component.scss']
})

export class ListeDemandeComponent implements OnInit, OnDestroy {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  agences: AgenceModel[];
  demandesEnd: DemandeModel[];
  demandesStart: DemandeModel[];
  user: string;
  users: UtilisateurModel[] = [];
  validors: UtilisateurModel[] = [];
 
  myOpenOrderCount = 0;
  matDataSourceDemande: MatTableDataSource<DemandeModel>;
  selection = new SelectionModel<DemandeModel>(false, []);
  filtreData: FilterDemandeDTO = new FilterDemandeDTO();
  demandeList: DemandeModel[] = [];
  simpleQueryDemandeResponse = new SimpleQueryResponse<DemandeModel>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  agence: AgenceModel;
  filteredOptions: Observable<string[]>;
  filteredSubscribe: Subscription;
  isListAValide = false;
  title = 'Liste des demandes';
  public options = [
    { value: 'None', id: 0 },
    { value: 'Some', id: 1 },
    { value: 'All', id: 2 },
  ];
  currentRoute: any;
  selectedDemandesChild: DemandeModel[] = [];
  listStatus = [getStatusDemande(StatusDemande.Brouillon),
    getStatusDemande(StatusDemande.AValider), 
    getStatusDemande(StatusDemande.Validate),
    getStatusDemande(StatusDemande.Refuse),
    getStatusDemande(StatusDemande.ATraiterAD),
    getStatusDemande(StatusDemande.Traite),
    getStatusDemande(StatusDemande.Annule)];
  listStatusUpdated = new BehaviorSubject<string[]>(this.listStatus);

  listTypeDemande = [getTypeDemande(TypeDemande.Creation),
    getTypeDemande(TypeDemande.Modification), 
    getTypeDemande(TypeDemande.Suppression),
    getTypeDemande(TypeDemande.Transfert)];
  listTypeDemandeUpdated = new BehaviorSubject<string[]>(this.listTypeDemande);

  regionsIdsArray:Set<number>  = new Set();
  societesIdsArray:Set<number>  = new Set();
  agencesIdsArray:Set<number>  = new Set();

  eventsSubjectAgenceArray: BehaviorSubject<Set<number>> = new BehaviorSubject(this.agencesIdsArray);
  eventsSubjectRegionArray: BehaviorSubject<Set<number>> = new BehaviorSubject(this.regionsIdsArray);
  eventsSubjectSocieteArray: BehaviorSubject<Set<number>> = new BehaviorSubject(this.societesIdsArray);
  inputRegionsString : string = "";
  inputSocietesString : string = "";

  regions: RegionModel[];
  societes: SocieteModel[];
  untouchedAllAgences: AgenceModel[] = [];
  untouchedAllRegions: RegionModel[] = [];
  untouchedAllSocietes: SocieteModel[] = [];
  constructor(public dialog: MatDialog,
              private router: Router,
              private titleService: Title,
              private userService: UserService,
              public demandeService: DemandeService,
              private state: StateService,
              private filtreService: FiltreService,
              private agenceService: AgenceService,
              private regionService: RegionService,
              private societeService: SocieteService,
              private detector: ChangeDetectorRef) {
              }

  ngOnDestroy(): void {
    this.filteredSubscribe.unsubscribe();
  }

  onChange(event): void {
    this.filtreData.regions.forEach(x=> this.regionsIdsArray.add(x.id));
    this.inputRegionsString  = "";
    this.filtreData.regions.forEach(x=> this.inputRegionsString += x.id +',');

    this.filtreData.societeObjs.forEach(x=> this.societesIdsArray.add(x.id));
    this.inputSocietesString  = "";
    this.filtreData.societeObjs.forEach(x=> this.inputSocietesString += x.id +',');

    this.filtreData.agences.forEach(x=> this.agencesIdsArray.add(x.id))
    this.filtreData.pageNumber = 0;
    this.filtreService.changeFiltre(this.filtreData);
  }

  loadData(value: string, service: GenericCrudService<BasicModel>, model: string): void {
    if (!value) {
      eval(model + '=  [];');
    }
  }

  onFiltreChange(event, state): void {
    switch (state) {
      case 'Statut':
        this.filtreData.status = event;
        break;
      case 'Createur':
        this.filtreData.idCreateur = event;
        break;
      case 'Valideur':
        this.filtreData.idValidateur = event;
        break;
      case 'Type Demande':
        this.filtreData.typeDemande = event;
        break;
    }
    this.onChange(event);
  } 


  clearFiltre(): void {
    const type = this.filtreData.type;
    this.filtreData = new FilterDemandeDTO();
    this.filtreData.agences = [];
    this.filtreData.regions = [];
    this.filtreData.societeObjs = [];
    this.agencesIdsArray.clear();
    this.regionsIdsArray.clear();
    this.societesIdsArray.clear();
    this.agencesIdsArray = new Set();
    this.regionsIdsArray = new Set();
    this.societesIdsArray= new Set();
    this.filtreData.pageNumber = 0;
    this.filtreData.type = type;
    this.filtreService.changeFiltre(this.filtreData);
  }

  ngOnInit(): void {
   
    var index = this.selectedDemandesChild.length;
    if(index) {
      this.selectedDemandesChild.splice(0,index);
    }
    this.currentRoute = this.router.url;
    this.titleService.setTitle('GUS - Liste Demande ');

    this.detector.detectChanges();
    this.state.agence.subscribe(result => {
      this.agence = result;
      if (result) {
        this.filtreData.idAgence = this.agence.id;
      }
    });
    this.state.user.subscribe(result => {
      this.user = result;
    });
    this.filteredSubscribe = this.filtreService.filtreDemande.pipe(debounceTime(500)).subscribe(result => {
      this.filtreData = result;
      this.listStatusUpdated.next(result.status);
      this.listTypeDemandeUpdated.next(result.typeDemande);
      this.title = 'Liste des demandes ';
      switch (this.filtreData.type) {
        // Demandeur
        case 'd_my_retourne': {
          this.title = 'Mes demandes retournées';
          this.isListAValide = false;
          break;
        }
        case 'd_retournés': {
          this.title = this.title + 'retournées';
          this.isListAValide = false;
          break;
        }
        case 'd_my_refuse': {
          this.title = 'Mes demandes refusés (30 derniers jours)';
          this.isListAValide = false;
          break;
        }
        case 'd_refuse': {
          this.title = this.title + 'refusées (30 derniers jours)';
          this.isListAValide = false;
          break;
        }
        case 'd_mes_en_cours': {
          this.title = 'Mes demandes en cours';
          this.isListAValide = false;
          break;
        }
        case 'd_en_cours': {
          this.title = this.title + 'en cours';
          this.isListAValide = false;
          break;
        }
        case 'd_my_traite': {
          this.title = 'Mes demandes traitées (30 derniers jours)';
          this.isListAValide = false;
          break;
        }
        case 'd_traite': {
          this.title = this.title + 'traitées (30 derniers jours)';
          this.isListAValide = false;
          break;
        }
        // valideur
        case 'v_wait_validation_by_me': {
          this.title = 'Demandes à valider';
          this.isListAValide = false;
          break;
        }
        case 'v_valide': {
          this.title = 'Demandes validées à suivre';
          this.isListAValide = false;
          break;
        }
        // DSI
        case 'dsi_wait_traite': {
          this.title = 'Demandes à traiter';
          this.isListAValide = false;
          break;
        }
        case 'dsi_traite': {
          this.title = 'Demandes traitées (30 derniers jours)';
          this.isListAValide = false;
          break;
        }

        case 'dsi_returne': {
          this.title = 'Demandes retournées';
          this.isListAValide = true;
          break;
        }

        default: {
          this.title = this.title + '';
          this.isListAValide = false;
        }
      }

      this.regionsIdsArray.clear();
      this.filtreData.regions.forEach((region) => this.regionsIdsArray.add(region.id));
      this.inputRegionsString  = "";
      this.filtreData.regions.forEach(x => this.inputRegionsString += x.id +',');
      this.eventsSubjectRegionArray.next(this.regionsIdsArray);

      this.societesIdsArray.clear();
      this.filtreData.societeObjs.forEach((societe) => this.societesIdsArray.add(societe.id));
      this.inputSocietesString  = "";
      this.filtreData.societeObjs.forEach(x => this.inputSocietesString += x.id +',');
      this.eventsSubjectSocieteArray.next(this.societesIdsArray);

      this.agencesIdsArray.clear();
      this.filtreData.agences.forEach(x=> this.agencesIdsArray.add(x.id));
      this.eventsSubjectAgenceArray.next(this.agencesIdsArray);
      /*
      Fonction en Doublon @Camilia demander la raison de ce double appel  
      this.demandeService.getFiltredDemande(this.filtreData).subscribe((data: SimpleQueryResponse<DemandeModel>) => {
        this.simpleQueryDemandeResponse = data;
        this.demandeList = data.objets;
      });
      */
    });

   
    this.agenceService.getAll().subscribe(agences => {
      this.agences = agences.sort((a, b) => a.code.localeCompare(b.code));
      this.untouchedAllAgences = agences;
    });
    this.regionService.getAll().subscribe(regions => {
      this.regions = regions.sort((a, b) => a.code.localeCompare(b.code));
      this.untouchedAllRegions = regions;
    });
    this.societeService.getAll().subscribe(societes => {
      this.societes = societes.sort((a, b) => a.code.localeCompare(b.code));
      this.untouchedAllSocietes = societes;
    });
    
  }

  getSelectedDemandes(event: DemandeModel[]): void {
    this.selectedDemandesChild = event;
  }


  validation(): Observable<number> {
    let i = 0;
    const nb = new BehaviorSubject<number>(0);
    for (const demande of this.selectedDemandesChild) {
      const demandevalidator: DemandeValidationModel = new DemandeValidationModel(demande);
      this.demandeService.validateDemande(demandevalidator).subscribe(_ => {
        i++;
        nb.next(i);
      }, (error) => -1);
    }
    return nb.asObservable();
  }

  onValidationDemandes(): void {
    const dialogRef = this.dialog.open(ModalChargementComponent, {
      disableClose: true,
      data: {
        observable: this.validation(),
        total: this.selectedDemandesChild.length,
        result: true
      }
    });
    dialogRef.afterClosed().subscribe(_ => {
      this.ngOnInit();
    });
  }

  onExport(): void {
    const dialogRef = this.dialog.open(SpinnerModalComponent);
    this.demandeService.exportDemandes(this.filtreData).subscribe((res: BlobPart)  => {
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), res], {type: 'text/csv;charset=utf-8'});
      saveAs(blob, 'Export_Demande_' + new Date().toISOString() + '.csv');
      dialogRef.close();
    }, (error) => dialogRef.close());
  }

  stringifyBool(flags: boolean[]): string[] {
    const response = [];
    flags.forEach(flag => {
      if (flag) {
        response.push('Total');
      } else {
        response.push('Aucun');
      }
    });
    return response;
  }

  parseBool(flags: string[]): boolean[] {
    const response = [];
    flags.forEach(flag => {
      switch (flag) {
        case 'Total':
          response.push(true);
          break;
        case 'Aucun':
          response.push(false);
          break;
      }
    });
    return response;
  }

  regionChange(event){
    let array = event
    if(array != undefined && array.length != 0){
      let regions : RegionModel[] = [];
      regions = this.regions.filter(item => array.indexOf(item.id) !== -1);
      this.filtreData.regions = []
      this.filtreData.regions = regions
      let agences : AgenceModel[] = [];
      let agencesReg : AgenceModel[] = [];
      let agencesSoc : AgenceModel[] = [];
      agencesReg = this.filtreData.agences.filter(item => array.includes(item.regionId));
      agencesSoc = this.filtreData.agences.filter(item => this.societesIdsArray.has(item.societeId));
      for (let i = 0 ; i < agencesReg.length ; i++) {
        let agReg = agencesReg[i];
        if (agences.indexOf(agReg) == -1 && agencesSoc.indexOf(agReg) != -1) {
          agences.push(agReg);
        }
      }
      this.filtreData.agences = []
      this.filtreData.agences = agences
      this.onChange(this.filtreData)
    }else{
      this.filtreData.regions = []
      this.onChange(this.filtreData)
    }
  }

  societeChange(event){
    let array = event
    if(array != undefined && array.length != 0){
      let societes : SocieteModel[] = [];
      societes = this.societes.filter(item => array.indexOf(item.id) !== -1);
      this.filtreData.societeObjs = []
      this.filtreData.societeObjs = societes
      let agences : AgenceModel[] = [];
      let agencesReg : AgenceModel[] = [];
      let agencesSoc : AgenceModel[] = [];
      agencesReg = this.filtreData.agences.filter(item => this.regionsIdsArray.has(item.regionId));
      agencesSoc = this.filtreData.agences.filter(item => array.includes(item.societeId));
      for (let i = 0 ; i < agencesReg.length ; i++) {
        let agReg = agencesReg[i];
        if (agences.indexOf(agReg) == -1 && agencesSoc.indexOf(agReg) != -1) {
          agences.push(agReg);
        }
      }
      this.filtreData.agences = []
      this.filtreData.agences = agences
      this.onChange(this.filtreData)
    }else{
      this.filtreData.societeObjs = []
      this.onChange(this.filtreData)
    }
  }

  agenceChange(event){
    let array = event
    if(array != undefined && array.length !=0){
      let x : AgenceModel[] = [];
      x = this.agences.filter(item => array.indexOf(item.id) !== -1);
      this.filtreData.agences.splice(0,this.filtreData.agences.length)
      this.filtreData.agences = x
      this.onChange(this.filtreData)
    }else{
      this.filtreData.agences = []
      this.onChange(this.filtreData)
    }
  }
}