  
<div class="col-sm-12" style="display: flex; justify-content: space-between;">
  <h2 class="col-sm-7"> Rechercher un collaborateur</h2>
  <div class="col-sm-5">
    <button [color]="'primary'" mat-raised-button type="button" (click)="onSearchCollaborateur(filtreCollaborateur)">
      <mat-icon>search</mat-icon>
    </button>
    <button color="warn" mat-raised-button (click)="clearFiltre()">
      <mat-icon>cancel</mat-icon> Réinitialiser
    </button>             
               
  </div>
</div>
<div class="col-sm-12" style="display: flex; justify-content: space-between;">
  <div class="row">
    <div class="col-sm-4">
      <mat-form-field matTooltip="Nom">
          <mat-label>Nom</mat-label>
          <input matInput  name="nom" [(ngModel)]="filtreCollaborateur.nom"  (keyup.enter)="onSearchCollaborateur(filtreCollaborateur)"/> 
          
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field matTooltip="Prénom">
          <mat-label>Prénom</mat-label>
          <input matInput  name="prenom" [(ngModel)]="filtreCollaborateur.prenom" (keyup.enter)="onSearchCollaborateur(filtreCollaborateur)"/> 
          
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field matTooltip="Email">
          <mat-label>Email</mat-label>
          <input matInput  name="email" [(ngModel)]="filtreCollaborateur.email" (keyup.enter)="onSearchCollaborateur(filtreCollaborateur)"/> 
          
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <app-autocomplete-region
        name="region"
        ngDefaultControl 
          [libelle]="'Région'" 
          [regions]="regions" 
          [regionId]="filtreCollaborateur.idRegion"
          (regionChange)="onChangeRegion($event)">
      </app-autocomplete-region> 
    </div> 
    <div class="col-sm-4">
      <app-autocomplete-societe
        name="societe"
        ngDefaultControl 
          [libelle]="'Société'" 
          [societes]="societes" 
          [societeId]="filtreCollaborateur.societeId"
          (societeChange)="onChangeSociete($event)">
      </app-autocomplete-societe> 
    </div> 
    <div class="col-sm-4"> 
      <app-autocomplete-agence
        name="agence"
        ngDefaultControl 
          [libelle]="'Agence'" 
          [agences]="agences" 
          [agenceId]="filtreCollaborateur.idAgence"
          (agenceChange)="onChangeAgence($event)">
      </app-autocomplete-agence>
    </div> 
  </div>
  <div class="data-class row">
    
  </div> 

</div>
