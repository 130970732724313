import { CollaborateurModel } from './collaborateur.model';
import { DroitEffectifModel } from './droit.model';

export class UtilisateurModel extends CollaborateurModel {
    actif: boolean;
    password: string;
    webDeviceToken: string;
    mobileDeviceToken: string;
    droits: DroitEffectifModel[];
    notification: boolean;
}
