import { GlobalSettingsService } from './../gobal-settings.service';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { FiltreService } from 'src/app/core/filtre.service';
import { FilterDemandeDTO } from 'src/app/shared/dtos/filterDemandeDTO';
import { DashboardModel } from 'src/app/shared/dtos/dashboard.model';
import { StateService } from 'src/app/shared/services/state.service';
import { Title } from '@angular/platform-browser';
import { UtilisateurModel } from 'src/app/shared/models/utilisateur/utilisateur.model';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { ModalUpdateCollaborateurComponent } from 'src/app/shared/components/modals/modal-update-collaborateur/modal-update-collaborateur.component';
import { ModalInfoOkComponent } from 'src/app/shared/components/modals/info-ok/modal-info-ok.component';
import { getStatusDemande, StatusDemande } from 'src/app/shared/constants/status';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  myOpenOrderCount = 0;
  myOrderToPayCount = 2;
  agence: AgenceModel;
  displayedColumnsDemande: string[] = ['date', 'libelle', 'numDemande', 'total', 'statutDemande', 'nombre'];

  demandeList: DemandeModel[];
  demandeDashboard = new DashboardModel();
  countDemandeAValider = 0;
  countDemandeValideRecent = 0;
  countDemandeAReceptionner = 0;
  countDemandeTraitesRecemment = 0;
  countDemandeRefuses = 0;
  countDemandeRetournes = 0;
  countDemandeValidesASuivre = 0;
  countDemandeBloques = 0;
  filtre: any;

  libelleDemande = "Mes demandes";
  btnVal = "Tous";
  menuMesDemande = true;
  menuAllDemande = false;
  canEditorCreateDemande = false;
  canValidateDemande = false;
  canTraiteDemande = false;
  user : UtilisateurModel;
  constructor(public dialog: MatDialog,
              private router: Router,
              private title: Title,
              private state: StateService,
              private demandeService: DemandeService,
              private filtreService: FiltreService,
              private globalSettingsService: GlobalSettingsService) {
    this.state.agence.subscribe(result => {
      this.agence = result;
    });
    this.filtreService.filtreDemande.subscribe(result => {
      this.filtre = result;
    });
    const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
    this.user = JSON.parse(localStorage.getItem('CURRENT_USER'));
    const droitsDemande = user.droits.filter(droit =>
          (droit.niveau === 2) &&
          (droit.type === 'Demande')
        );
    const droitsValidationDemande = user.droits.filter(droit =>
          (droit.niveau === 3)
          &&
          (droit.type === 'Demande')
        );
    const droitsTraiteDemande = user.droits.filter(droit =>
          ( droit.niveau === 5)
          &&
          (droit.type === 'Demande')
        );
    if (droitsDemande && droitsDemande.length > 0) {
        this.canEditorCreateDemande = true;
      }
    if (droitsValidationDemande && droitsValidationDemande.length > 0) {
        this.canValidateDemande = true;
      }
    if (droitsTraiteDemande && droitsTraiteDemande.length > 0) {
        this.canTraiteDemande = true;
      }
      // Pour droit support DSI on affiche uniquement "Toutes les demandes"
      if(droitsDemande && droitsDemande.length == 0 && droitsTraiteDemande && droitsTraiteDemande.length > 0) {
        this.goToShowMyOrAllDemande();
      }
  }

  ngOnInit(): void {
    //this.matDataSourceDemande = new MatTableDataSource(this.dataDemande);
    this.demandeService.getDashboard().subscribe(value => {
      this.demandeDashboard = value;
    });

    this.title.setTitle('GUS - Accueil');
  }

  goToShowMyOrAllDemande(){
    this.menuAllDemande = false;
    this.menuMesDemande =  !this.menuAllDemande;
    if(this.btnVal == 'Mes demandes'){
      this.btnVal = 'Tous';
      this.libelleDemande="Mes demandes";
    }else{
      this.btnVal = 'Mes demandes'
      this.libelleDemande="Toutes les demandes";
      this.menuAllDemande = true;
      this.menuMesDemande = !this.menuAllDemande;
    }
  }

 // Demandeur
 goToDemandeurRetourneDemandeList(route: string): void {
  this.filtre = new FilterDemandeDTO();
  this.filtre.order = 'DCreation';
  this.filtre.descending = true;
  if(!this.menuMesDemande){
    this.filtre.idCreateur = null;
    this.filtre.type = 'd_retournés';
  } else {
    this.filtre.type = 'd_my_retourne';
    this.filtre.idCreateur = this.user.id;
  }
  this.filtreService.changeFiltre(this.filtre);
  this.router.navigate([route]);
}

goToDemandeurRefuseDemandeList(route: string): void {
  this.filtre = new FilterDemandeDTO();
  this.filtre.status = ['Refusé'];
  this.filtre.order = 'DCreation';
  this.filtre.descending = true;
  if(!this.menuMesDemande){
    this.filtre.idCreateur = null;
    this.filtre.type = 'd_refuse';
  } else {
    this.filtre.type = 'd_my_refuse';
    this.filtre.idCreateur = this.user.id;
  }
  this.filtreService.changeFiltre(this.filtre);
  this.router.navigate([route]);
}
goToDemandeurEnCoursDemandeList(route: string): void {
  this.filtre = new FilterDemandeDTO();
  this.filtre.order = 'DCreation';
  this.filtre.descending = true;
  this.filtre.status = [
    getStatusDemande(StatusDemande.AValider),
    getStatusDemande(StatusDemande.Validate)
  ];
  if(!this.menuMesDemande){
      this.filtre.idCreateur = null;
      this.filtre.type = 'd_en_cours';
  } else {
    this.filtre.idCreateur = this.user.id;
    this.filtre.type = 'd_mes_en_cours';
  }
  this.filtreService.changeFiltre(this.filtre);
  this.router.navigate([route]);
}

goToDemandeurTraiteDemandeList(route: string): void {
  this.filtre = new FilterDemandeDTO();
  this.filtre.status = ['Traité'];
  this.filtre.order = 'DCreation';
  this.filtre.descending = true;
  if(!this.menuMesDemande){
    this.filtre.idCreateur = null;
    this.filtre.type = 'd_traite';
  } else {
    this.filtre.idCreateur = this.user.id;
    this.filtre.type = 'd_my_traite';
  }
  this.filtreService.changeFiltre(this.filtre);
  this.router.navigate([route]);
}

 // Valideur

 goToValideurWaitValidationByMeDemandeList(route: string): void {
  this.filtre =  new FilterDemandeDTO();
  this.filtre.order = 'DCreation';
  this.filtre.descending = true;
  this.filtre.type = 'v_wait_validation_by_me';
  this.filtreService.changeFiltre(this.filtre);
  this.router.navigate([route]);
}

  goToValideurValideDemandeList(route: string): void {
    this.filtre =  new FilterDemandeDTO();
    this.filtre.type = 'v_valide';
    this.filtre.order = 'DCreation';
    this.filtre.descending = true;
    this.filtreService.changeFiltre(this.filtre);
    this.router.navigate([route]);
  }


 // DSI
 goToDSIWaitTraiteDemandeList(route: string): void {
    this.filtre = new FilterDemandeDTO();
    this.filtre.type = 'dsi_wait_traite';
    this.filtre.order = 'DCreation';
    this.filtre.descending = true;
    this.filtreService.changeFiltre(this.filtre);
    this.router.navigate([route]);
  }

  goToDSITraiteDemandeList(route: string): void {
    this.filtre = new FilterDemandeDTO();
    this.filtre.type = 'dsi_traite';
    this.filtre.order = 'DCreation';
    this.filtre.descending = true;
    this.filtreService.changeFiltre(this.filtre);
    this.router.navigate([route]);
  }

  goToDSIRetourneDemandeList(route: string): void {
    this.filtre = new FilterDemandeDTO();
    this.filtre.type = 'dsi_returne';
    this.filtre.order = 'DCreation';
    this.filtre.descending = true;
    this.filtreService.changeFiltre(this.filtre);
    this.router.navigate([route]);
  }

 
  // Suite

  addDemande(type: string): void {
    this.router.navigate(["create-demande"], {queryParams:  {typeDemande: type}});
  }

  updateDeleteCollaborateur(type: string) {
    if(type === "suppression"){
      const dialogConfirmation = this.dialog.open(ModalInfoOkComponent, {
        data: {
          title: 'Demande de suppression d\'un collaborateur ',
          type: 'Demande',
          message: 'Souhaitez-vous créer une demande de suppression d\'un collaborateur? ',
          icone: 'warning',
          iconColor: 'warn',
          color: 'danger',
        }
      });
      return dialogConfirmation.afterClosed().subscribe((resultat) => {
        if (resultat) {
          const dialogRef = this.dialog.open(ModalUpdateCollaborateurComponent, {
            autoFocus: false,
            disableClose: false,
            maxHeight: '90vh',
            data: {
              comingFrom: type
            }
          });
        }
      });
    }else {
      const dialogRef = this.dialog.open(ModalUpdateCollaborateurComponent, {
        autoFocus: false,
        disableClose: false,
        maxHeight: '90vh',
        data: {
          comingFrom: type
        }
      });
    }
  }

  updateAgence(): void {
    this.state.changeAgence(this.agence);
  }

}
