import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  transform(collection: any[], property: string): { [key: string]: any[] } {
    if(!collection) {
      return null;
    }
    const groupedCollection = collection.reduce((previous, current)=> {
      const key = current[property] === null || current[property] === '' ? null : current[property];
      if(!previous[key]) {
        previous[key] = [current];
      } else {
        previous[key].push(current);
      }
      return previous;
    }, {});
    return groupedCollection;
  }
}