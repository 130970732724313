import { Validators } from '@angular/forms';

export class FormInfo {
  validators: Validators;
  disabled: boolean;
  constructor(disabled: boolean, validator: Validators) {
    this.disabled = disabled;
    this.validators = validator;
  }
}

export const USERVALIDATORS = {
  CREATE : new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['code', new FormInfo(false, [])],
    ['nom',  new FormInfo(false, [Validators.required])],
    ['prenom',  new FormInfo(false, [Validators.required])],
    ['email',  new FormInfo(false, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
    ['tel',  new FormInfo(true, [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.maxLength(10), Validators.minLength(10)])],
    ['actif',  new FormInfo(false, [])],
    ['idAgence', new FormInfo(false, [Validators.required])],
    ['password', new FormInfo(false, [])],
    ['agence', new FormInfo(false, [])],
    ['notification', new FormInfo(false, [])],
  ]),
  EDIT: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['code', new FormInfo(true, [])],
    ['nom',  new FormInfo(true, [Validators.required])],
    ['prenom',  new FormInfo(true, [Validators.required])],
    ['email',  new FormInfo(true, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])],
    ['tel',  new FormInfo(true, [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.maxLength(10), Validators.minLength(10)])],
    ['actif',  new FormInfo(true, [])],
    ['idAgence', new FormInfo(true, [Validators.required])],
    ['password', new FormInfo(true, [])],
    ['agence', new FormInfo(true, [])],
    ['notification', new FormInfo(false, [])],
  ])
};

export const DROITVALIDATORS = {
  CREATE: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['type', new FormInfo(false, [Validators.required])],
    ['niveau', new FormInfo(false, [Validators.required])],
    ['idUser', new FormInfo(false, [])],
    ['idCreateur', new FormInfo(false, [])],
    ['dModification', new FormInfo(false, [])],
    ['idModifieur', new FormInfo(false, [])],
    ['dDesactivation', new FormInfo(false, [])],
    ['idDesativateur', new FormInfo(false, [])],
    ['agenceId', new FormInfo(false, [Validators.required])]
  ])
};

export const DROITDTOVALIDATORS = {
  CREATE: new Map<string, FormInfo>([
    ['idUser', new FormInfo(false, [Validators.required])],
    ['regionId', new FormInfo(false, [Validators.required])],
    ['agenceId', new FormInfo(false, [])],
    ['libelleRegion', new FormInfo(false, [Validators.required])],
    ['libelleSociete', new FormInfo(false, [])],
    ['libelleAgence', new FormInfo(false, [])],
    ['visualiseDemande', new FormInfo(false, [])],
    ['createDemande', new FormInfo(false, [])],
    ['valid1', new FormInfo(false, [])],
    ['valid2', new FormInfo(false, [])],
    ['usersManager', new FormInfo(false, [])],
    ['repositoriesManager', new FormInfo(false, [])],
    ['visualiseDemandeAgence', new FormInfo(false, [])],
    ['createDemandeAgence', new FormInfo(false, [])],
    ['validateDemandeAgence', new FormInfo(false, [])],
  ]),
  EDIT: new Map<string, FormInfo>([
    ['idUser', new FormInfo(false, [])],
    ['regionId', new FormInfo(false, [Validators.required])],
    ['agenceId', new FormInfo(false, [])],
    ['libelleRegion', new FormInfo(false, [Validators.required])],
    ['libelleSociete', new FormInfo(false, [])],
    ['libelleAgence', new FormInfo(false, [])],
    ['visualiseDemande', new FormInfo(false, [])],
    ['createDemande', new FormInfo(false, [])],
    ['valid1', new FormInfo(false, [])],
    ['valid2', new FormInfo(false, [])],
    ['usersManager', new FormInfo(false, [])],
    ['repositoriesManager', new FormInfo(false, [])],
    ['visualiseDemandeAgence', new FormInfo(false, [])],
    ['createDemandeAgence', new FormInfo(false, [])],
    ['validateDemandeAgence', new FormInfo(false, [])],
  ])
};
