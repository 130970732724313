import { Router } from '@angular/router';
import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
export interface DialogDataQuestion {
  
  title: string;
  question: string;
  footer: string;
  result: number;
  buttonOui: string;
  buttonNon: string;
}
@Component({
  selector: 'app-modal-question',
  templateUrl: './modal-question.component.html',
  styleUrls: ['./modal-question.component.scss']
})

export class ModalQuestionComponent implements OnInit {

  title: string;
  question: string;
  footer: string;
  buttonOui: string;
  buttonNon: string;

  constructor(public dialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<Component>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataQuestion) { }

  ngOnInit(): void {
    this.title = this.data.title !== undefined ? this.data.title : '';
    this.question = this.data.question !== undefined ? this.data.question : '';
    this.footer = this.data.footer !== undefined ? this.data.footer : '';
    this.buttonOui = this.data.buttonOui !== undefined ? this.data.buttonOui : 'Oui';
    this.buttonNon = this.data.buttonNon !== undefined ? this.data.buttonNon : 'Non';
  }

  
  onModalCancel(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }

  onModalNon(): void {
    this.data.result = -1;
    this.dialogRef.close(this.data);
  }

  onModalOui(): void {
    this.data.result = 1;
    this.dialogRef.close(this.data);
  }
}
