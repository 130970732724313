import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APICONSTANTS } from '../../constants/api-constants';
import { AgenceModel } from '../../models/rsa/agence.model';
import { HostnameService } from '../basic/hostname.service';
import { RSAService } from './rsa.service';
import { SocieteModel } from '../../models/rsa/societe.model';

@Injectable()
export class AgenceService extends RSAService<AgenceModel> {
  private subscriptions: Subscription[] = [];

  constructor(http: HttpClient,
              protected toastrService: ToastrService,
              protected hostnameService: HostnameService) {
      super(http, toastrService, APICONSTANTS.ROUTEAGENCE, hostnameService);
  }

  getAllManaged(id: number): Observable<AgenceModel[]> {
    return this.http.get<AgenceModel[]>(APICONSTANTS.URLAPI.value + this.route + 'GetAllManaged/' + id)
    .pipe(catchError(err => this.requestCatchError(err, 'GetAllManaged')));
  }

  getAllForDemande(): Observable<AgenceModel[]> {
    return this.http.get<AgenceModel[]>(APICONSTANTS.URLAPI.value + this.route + 'getAllForDemande/')
    .pipe(catchError(err => this.requestCatchError(err, 'GetAllForDemande')));
  }

  getAllFromAdmin(): Observable<AgenceModel[]> {
    return this.http.get<AgenceModel[]>(APICONSTANTS.URLAPI.value + this.route + 'GetAllFromAdmin/')
    .pipe(catchError(err => this.requestCatchError(err, 'GetAllFromAdmin')));
  }

  getForRefage(id: number): Observable<AgenceModel> {
    return this.http.get<AgenceModel>(APICONSTANTS.URLAPI.value + this.route + 'getForRefage/' + id)
    .pipe(catchError(err => this.requestCatchError(err, 'getForRefage')));
  }

  getSocietesByRegionId(regionId: number): Observable<SocieteModel[]> {
    return this.http.get<SocieteModel[]>(APICONSTANTS.URLAPI.value + this.route + 'getSocietesByRegionId?regionId=' + regionId)
    .pipe(catchError(err => this.requestCatchError(err, 'getSocietesByRegionId')));
  }
}
