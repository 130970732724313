<!------------ partie affichage All Demande   ----------------->

<div class="row">
    <div [ngClass]="'col-sm-12'">
        <mat-expansion-panel expanded="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                AIDE & Informations
              </mat-panel-title>
              <mat-panel-description>
                Bienvenue sur votre outil de gestion des utilisateurs SARP
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ul>
                <li> - <a class="link" href="https://docs.google.com/presentation/d/e/2PACX-1vQ3u3_bG5t4lBHw2rrUHGJY0PpQOLjGfR4rEpTqLX2QUshwDxkChpp8mapTgqxfv4qsqyv4c6v9hlmF/pub?start=false&loop=false&delayms=60000" target="_blank">Guide d'utilisation</a></li>
                <li> - <a class="link" href="https://docs.google.com/forms/d/e/1FAIpQLSfcLYf7ThlxJjTmdWOvguR-qU2kEi0595ihp1dPe2OcC-FKzA/viewform" target="_blank">J'ai une question</a></li>
            </ul>
        </mat-expansion-panel>
    </div>
</div>
<div class="row"> 

    <div class="col-sm-6" [ngClass]="'col-sm-12'">
        <mat-card class="card card-initial">
            <mat-card-header>
                <mat-card-title>{{libelleDemande}}</mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
                <div class="align-items-end" *ngIf="canEditorCreateDemande">
                    <button class="action-button bgorange" mat-raised-button (click)="addDemande('création')" *ngIf="canEditorCreateDemande">
                        <mat-icon>add</mat-icon> Création d'un collaborateur
                    </button>
                    <button class="action-button bgorange" mat-raised-button (click)="updateDeleteCollaborateur('modification')" *ngIf="canEditorCreateDemande">
                        <mat-icon>edit</mat-icon> Modification d'un collaborateur
                    </button>
                    <button class="action-button bgorange" mat-raised-button (click)="updateDeleteCollaborateur('suppression')" *ngIf="canEditorCreateDemande">
                        <mat-icon>delete</mat-icon> Suppression d'un collaborateur
                    </button>
                    <button mat-raised-button  class="action-button" [ngClass]="{'grey' : menuAllDemande, 'blue': !menuAllDemande}" (click)="goToShowMyOrAllDemande()" >
                        <mat-icon>filter_list</mat-icon> {{btnVal}}
                    </button>
                </div>
            </mat-card-header>

            <!--  MENU MES Demande -->
            <mat-card-content>
                <div class="button-row"  *ngIf="menuMesDemande">

                    <mat-card class="sous-card premier" (click)="goToDemandeurRetourneDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDemandeurMyRetourne}}</h1> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 3;"
                                        [innerText]="demandeDashboard.toDemandeurMyRetourne > 1 ? 'Retournées' : 'Retournée'"></span>
                                </span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                   
                    <mat-card class="sous-card premier" (click)="goToDemandeurRefuseDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">

                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDemandeurMyRefuse}}</h1> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 2;"
                                        [innerText]="demandeDashboard.toDemandeurMyRefuse > 1 ? 'Refusées' : 'Refusée'"></span>
                                    <br><span [innerText]="'(J-30)'"></span>
                                </span>
                            </div>
                        </mat-card-content>

                    </mat-card>

                    <mat-card class="sous-card premier" (click)="goToDemandeurEnCoursDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDemandeurMyEnCours}}</h1> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 1;"> En cours </span>
                                </span>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="sous-card premier" (click)="goToDemandeurTraiteDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDemandeurMyTraite}}</h1> <br>
                                        <ng-template #emptyDraft>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 2;"
                                        [innerText]="demandeDashboard.toDemandeurMyTraite > 1 ? 'Traitées' : 'Traitée'"></span>
                                    <br><span [innerText]="'(J-30)'"></span>
                                </span>
                            </div>
                        </mat-card-content>
                    </mat-card>

                </div>
                    

            <!--  MENU Toutes les Demandes -->

                <div class="button-row"  *ngIf="menuAllDemande">

                    <mat-card class="sous-card" [ngClass]="{'premier': !canEditorCreateDemande, 'second' : canEditorCreateDemande }" (click)="goToDemandeurRetourneDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDemandeurRetourne}}</h1> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 3;"
                                        [innerText]="demandeDashboard.toDemandeurRetourne > 1 ? 'Retournées' : 'Retournée'"></span>
                                </span>
                            </div>
                        </mat-card-content>
                    </mat-card>


                    <mat-card class="sous-card " [ngClass]="{'premier': !canEditorCreateDemande, 'second' : canEditorCreateDemande }" (click)="goToDemandeurRefuseDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">

                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDemandeurRefuse}}</h1> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 2;"
                                        [innerText]="demandeDashboard.toDemandeurRefuse > 1 ? 'Refusées' : 'Refusée'"></span>
                                    <br><span [innerText]="'(J-30)'"></span>
                                </span>
                            </div>
                        </mat-card-content>
                    </mat-card>


                    <mat-card class="sous-card " [ngClass]="{'premier': !canEditorCreateDemande, 'second' : canEditorCreateDemande }" (click)="goToDemandeurEnCoursDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDemandeurEnCours}}</h1> <br>
                                        <ng-template #emptyDraft>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 1;"> En cours </span>
                                </span>
                            </div>
                        </mat-card-content>
                    </mat-card>


                    <mat-card class="sous-card " [ngClass]="{'premier': !canEditorCreateDemande, 'second' : canEditorCreateDemande }" (click)="goToDemandeurTraiteDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDemandeurTraite}}</h1> <br>
                                        <ng-template #emptyDraft>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 2;"
                                        [innerText]="demandeDashboard.toDemandeurTraite > 1 ? 'Traitées' : 'Traitée'"></span>
                                    <br><span [innerText]="'(J-30)'"></span>
                                </span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <br>
</div>
<!---------------------- partie validation  ------------------------------------->

<div class="row" *ngIf="canValidateDemande">
    <div [ngClass]="'col-sm-12'"  >
        <mat-card class="card card-initial">
            <mat-card-header>
                <mat-card-title>Validations </mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="button-row">
                    

                    <mat-card class="sous-card premier" (click)="goToValideurWaitValidationByMeDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toValideurWaitValidationByMe}}</h1> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 1;"> En attente de <br/>validation</span>
                                </span>

                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="sous-card premier" (click)="goToValideurValideDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toValideurValide}}</h1> <br>
                                        <ng-template #emptyDraft>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 2;"
                                        [innerText]="demandeDashboard.toValideurValide > 1 ? 'Validées à suivre' : 'Validée à suivre'"></span>
                                </span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <br>
</div>

<!---------------------- partie Traitement  ------------------------------------->

<div class="row" *ngIf="canTraiteDemande">
    <div [ngClass]="'col-sm-12'"  >
        <mat-card class="card card-initial">
            <mat-card-header>
                <mat-card-title>Traitements </mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="button-row">
                    

                    <mat-card class="sous-card premier" (click)="goToDSIWaitTraiteDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDSIWaitTraite}}</h1> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 1;"> En attente de <br/>traitement</span>
                                </span>

                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="sous-card premier" (click)="goToDSITraiteDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDSITraite}}</h1> <br>
                                        <ng-template #emptyDraft>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 2;"
                                        [innerText]="demandeDashboard.toDSITraite > 1 ? 'Traitées' : 'Traitée'"></span>
                                        <br><span [innerText]="'(J-30)'"></span>
                                </span>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="sous-card premier" (click)="goToDSIRetourneDemandeList('list-demande')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toDSIRetourne}}</h1> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 3;"
                                        [innerText]="demandeDashboard.toDSIRetourne > 1 ? 'Retournées' : 'Retournée'"></span>
                                </span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <br>
</div>