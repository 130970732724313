import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SessionService } from '../../services/session.service';
import { TableDTO } from '../../dtos/tableDTO';
import {Type} from '../../constants/type';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { UtilisateurModel } from '../../models/utilisateur/utilisateur.model';

export class DroitModelDTO {
  name: string = "";
  columnProp: string = "";
  options: [];
}

@Component({
  selector: 'app-table-with-filter',
  templateUrl: './table-with-filter.component.html',
  styleUrls: ['./table-with-filter.component.scss']
})
export class TableWithFilterComponent implements OnInit, OnChanges,AfterViewInit {
 
  @Input() tableDTO: TableDTO;
  @Input() title: string = "";
  @Output() entityOutput: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('select') elements: QueryList<any>;     

  displayedColumns: string[];
  currentUser: UtilisateurModel;
  matDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  Type = Type;
  selection =  [];
  length = 0;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  filterSelectObj = [];
  filterValues = {};
  allSelected=false;
  initilaFiltre:string = "";
  listFiltered = [];
  defaultFilterPredicate?: (data: any, filter: string) => boolean;


  constructor(
    private sessionService: SessionService
  ) { }


  ngOnInit() {
  }

  ngAfterViewInit()
  {
    this.displayedColumns = this.tableDTO.columns.map(col => col.variableName);
    this.sessionService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.matDataSource.filterPredicate = this.createFilter();

    this.matDataSource.sort = this.sort;
    this.matDataSource.sortingDataAccessor = (item, property) => {
      if (typeof item[property] === 'object') {
        return item[property].value;
      } else {
        return item[property];
      }
      
    };
  }

  getFilterObject(fullObj, key) {
    const uniqChk = new Set();
    fullObj.forEach((obj) => {
      uniqChk.add(obj[key]);
    });
    return [...uniqChk];
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(this.tableDTO){
      this.selection = [];
      for(let col of this.tableDTO.columns){
        // init selected values
        var selectedRow = this.tableDTO.rows.filter(row => row[col.variableName].value)
        this.selection.push(new SelectionModel(true, selectedRow))
      }
      this.matDataSource = new MatTableDataSource(this.tableDTO.rows);
      this.matDataSource.filterPredicate = this.createFilter();
      this.length = this.tableDTO.rows.length;
      this.matDataSource.sort = this.sort;
      this.matDataSource.sortingDataAccessor = (item, property) => {
        if (typeof item[property] === 'object') {
          return item[property].value;
        } else {
          return item[property];
        }
      };
      this.filterSelectObj = Object.assign([],this.tableDTO.columns
      .filter(col => col.type === Type.TEXT)
      .map(col => ({
        columnProp: col.variableName,
        name: col.displayName
      })));
      
      this.filterSelectObj = this.filterSelectObj.map(o => ({
        ...o,
        options: this.getFilterObject(this.tableDTO.rows, o.columnProp)
      }));
    }
  }

  filterChange(filter, event,i) {
    let backup = this.filterValues[filter.columnProp];
    this.filterValues[filter.columnProp] = filter.modelValue.filter(item => item !== undefined);
    this.matDataSource.filter = JSON.stringify(this.filterValues)
    //si on a le modelValue vide et que c'est le filtre initial on vide la valeur
    if(!this.listFiltered.includes(filter.name)){
      this.listFiltered.push(filter.name);
    }

    if(this.initilaFiltre == "") {
      this.initilaFiltre = filter.name;
    }
    if(filter.name == this.initilaFiltre && filter.modelValue.length == 0)
    {
      this.initilaFiltre = "";
    }

    for (let i = 0; i < this.filterSelectObj.length; i++) {
      const o = this.filterSelectObj[i];
      if (o.name !== filter.name && o.name !== this.initilaFiltre && !this.listFiltered.includes(o.name)) {
        o.options = this.getFilterObject(this.matDataSource.filteredData, o.columnProp);
      }
      if (o.name === filter.name && filter.modelValue.length === 0) {
        o.options = this.getFilterObject(this.matDataSource.filteredData, o.columnProp);
        this.listFiltered.splice(this.listFiltered.indexOf(filter.name), 1);
      }
    }
  }

  toggleAllSelection(i, bool,colName) {
    if (!bool) {
      this.filterSelectObj[i].modelValue = [];
      this.filterChange(this.filterSelectObj[i],null,0);
    } else {
      this.filterSelectObj[i].modelValue = Object.assign([],this.filterSelectObj[i].options);
      this.filterChange(this.filterSelectObj[i],null,0);
    }
  }

  openSelect(i){
    const element = this.elements.find((e, index) => index === i);  
    element.open();
  }

  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }
      let nameSearch = () => {
        let found = true;
        if (isFilterSet) {
          for (const col in searchTerms) {
            if (!searchTerms[col].includes(data[col]) && isFilterSet) {
                found = false;
                break;
              }
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }

  isString(val): boolean { return typeof val === 'string'; }

  isBoolean(val): boolean { return typeof val === 'boolean'; }

  isAllSelected(colIndex: number) {
    var column = this.tableDTO.columns[colIndex].variableName;
    const allSelected = this.selection[colIndex].selected.filter(row => !row[column].disabled);
    const allFiltred = this.matDataSource.filteredData.filter(row => !row[column].disabled);
    const numSelected = allFiltred.filter(value => allSelected.includes(value)).length;
    const numFiltred = allFiltred.length;
    return (numSelected === numFiltred) && numFiltred !== 0;
  }

  hasValue(colIndex: number){
    var column = this.tableDTO.columns[colIndex].variableName;
    const allSelected = this.selection[colIndex].selected.filter(row => !row[column].disabled);
    return this.matDataSource.filteredData.find(value => allSelected.includes(value)) !== undefined;

  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(colIndex: number) {
    this.isAllSelected(colIndex) ?
    (this.matDataSource.filteredData.forEach(row => {
      var column = this.tableDTO.columns[colIndex].variableName;
      if(!row[column].disabled){
        this.selection[colIndex].deselect(row);
        row[column].value = false;
      }
    }) ):
       (this.matDataSource.filteredData.forEach(row => {
          var column = this.tableDTO.columns[colIndex].variableName;
          if(!row[column].disabled){
            this.selection[colIndex].select(row);
            row[column].value = true;
          }
      })) ;
  }

  onCheckboxChange(dataSource: any) {
    this.entityOutput.emit(dataSource.data)
  }
}
