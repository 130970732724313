import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleQueryResponse } from 'src/app/shared/dtos/simpleQueryResponse';
import { SimpleQueryUser } from 'src/app/shared/dtos/simpleQueryUser';
import { ReferentielComponentsService } from '../referentiel-components.service';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-gestion-agence',
  templateUrl: './gestion-agence.component.html',
  styleUrls: ['./gestion-agence.component.scss']
})
export class GestionAgenceComponent implements OnInit {
  agences: AgenceModel[] = [];
  displayedColumns: string[] = ['region', 'societe', 'code', 'codeConso', 'libelle', 'cp', 'ville', 'active'];
  dataSource: AgenceModel[];
  matDataSource = new MatTableDataSource<AgenceModel>();
  sortState: Sort = {active: 'Code', direction: 'desc'};
  length: number;
  pageSize = 25;
  pageEvent: PageEvent;
  onlyViewDroits: boolean = false;
  result: boolean;
  objet: SimpleQueryUser = new SimpleQueryUser();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private sessionService: SessionService,
    private agenceService: AgenceService,
    private gestionReferentielComponent: ReferentielComponentsService
    ) { 

      this.sessionService.currentUser.subscribe(x=> {
        if(x.droits.filter(x=> x.niveau == 2 && x.type=="REFAGE").length != 0)
        {
          this.onlyViewDroits = false
        }else{
          this.onlyViewDroits = true                
        }
      }); 
    }

  ngOnInit(): void {
    this.objet.query = '';
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.objet.order = 'Code';
    this.matDataSource.paginator = this.paginator;
    this.loadPage();
  }

  onCreate(): void {
    this.router.navigate(["create-demande-agence"], {queryParams:  {typeDemande: "création"}});
  }

  onModify(agence: AgenceModel): void {
    this.router.navigate(['fiche-agence/' + agence.id]);
  }


  orderUsers(agences: AgenceModel[]): AgenceModel[] {
    agences = agences.sort((a, b) => {
      if (a.code) {
        return a.code.localeCompare(b.code);
      } else {
        return -1;
      }
    });
    return agences;
  }

  changePage(event?: PageEvent): void {
    this.objet.pageNumber =  event.pageIndex;
    this.objet.pageSize = event.pageSize;
    this.loadPage();
  }

  search(): void {
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    this.loadPage();
  }

  loadPage(): void {
    var referentiel = new SimpleQueryUser();
    referentiel = this.objet;
    this.agenceService.loadFromSimpleQueryRef(referentiel).subscribe((query: SimpleQueryResponse<AgenceModel>) => {
      if (query.objets != null) {
        this.agences = query.objets;
      } else {
        this.agences = [];
      }
      this.length = query.count;
      this.matDataSource = new MatTableDataSource<AgenceModel>(this.agences);
      this.paginator.length = this.length;
    });
  }

  close(): void {
    this.router.navigate(['/gestion-referentiel']);
  }

  sortData(sort: Sort): void {
    if (sort.direction !== 'asc') {
      this.objet.descending = false;
    } else {
      this.objet.descending = true;
    }
    this.sortState = sort;
    this.objet.order = sort.active;
    this.loadPage();
  }

}


