import { Type } from "../constants/type";

export class TableDTO {
    libelle: string;
    columns:  ColumnDTO[];
    rows: any[];
}

export class ColumnDTO {
    variableName:  string;
    displayName: string;
    type: Type;
    droit: DroitDTO;
}

export class DroitDTO {
    type: string;
    niveau: number;
}

