import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-custom-select-multiple-option',
  templateUrl: './custom-select-multiple-option.component.html',
  styleUrls: ['./custom-select-multiple-option.component.scss']
})
export class CustomSelectMultipleOptionComponent implements OnInit, OnChanges {
  @Input() InputvaluesList: string[] = [];
  @Input() InputSelectedValues: BehaviorSubject<string[]>;
  @Input() title: string;
  @Input() required = false;
  @Output() selected: EventEmitter<string[]> = new EventEmitter<string[]>();
  @ViewChild('allSelected') private allSelected: MatOption;
  selectedListForm: UntypedFormGroup;
  emitedList = [];
  inputList = [];


  constructor(private fb: UntypedFormBuilder) {
    this.selectedListForm = this.fb.group({selectedList: new UntypedFormControl('')});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.required) {
      this.selectedListForm = this.fb.group({selectedList: new UntypedFormControl('', Validators.required)});
    }
  }

  ngOnInit(): void {
    this.InputSelectedValues.subscribe(val => {
      if (this.InputSelectedValues && val) {
        this.inputList = this.getSelectList(this.InputvaluesList);
        if (this.inputList.length < val.length) {
          this.inputList = this.getSelectList(val);
        }
        const tempSelectedList = this.inputList.filter(x => val.indexOf(x.value) > -1);
        if (this.inputList.length === val.length) {
          this.selectedListForm.controls.selectedList
            .patchValue([...tempSelectedList.map(item => item.key), 0]);
        } else {
          this.selectedListForm.controls.selectedList
            .patchValue([...tempSelectedList.map(item => item.key)]);
        }
      }
    });
  }

  getSelectList(list: string[]): any[] {
    const valuesList = [];
    let i = 1;
    list.forEach(element => {
      valuesList.push(
        {
          key: i, value: element
        });
      i++;
    });
    return valuesList;
  }

  ClickPerOne(): void {
    this.emitedList = [];
    if (this.allSelected.selected) {
      this.allSelected.deselect();

    }
    if (this.selectedListForm.controls.selectedList.value.length === this.inputList.length) { this.allSelected.select(); }
    this.selectedListForm.controls.selectedList.value.forEach(element => {
      if (element > 0) {
        this.emitedList.push(this.inputList[element - 1].value);
      }

    });
    this.selected.emit(this.emitedList);
  }

  toggleAllSelection(): void {
    this.emitedList = [];
    if (this.allSelected.selected) {
      this.selectedListForm.controls.selectedList
        .patchValue([...this.inputList.map(item => item.key), 0]);
      this.selectedListForm.controls.selectedList.value.forEach(element => {

        if (element > 0) {
          this.emitedList.push(this.inputList[element - 1].value);
        }
      });
    } else {
      this.selectedListForm.controls.selectedList.patchValue([]);
    }
    this.selected.emit(this.emitedList);
  }
}
