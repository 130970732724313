import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APICONSTANTS } from '../../constants/api-constants';
import { DroitModelDTO } from '../../dtos/droitModelDTO';
import { DroitEffectifModel } from '../../models/utilisateur/droit.model';
import { UtilisateurModel } from '../../models/utilisateur/utilisateur.model';
import { GenericCrudService } from '../basic/generic.crud.service';
import { HostnameService } from '../basic/hostname.service';

@Injectable()
export class DroitEffectifService extends GenericCrudService<DroitEffectifModel> {

  constructor(http: HttpClient,
              protected toastrService: ToastrService,
              protected hostnameService: HostnameService) {
    super(http, toastrService, APICONSTANTS.ROUTEDROIT, hostnameService);
  }

  getAllFromUserDTO(objet: UtilisateurModel): Observable<DroitModelDTO[]> {
    // tslint:disable-next-line
    return this.http.post<DroitModelDTO[]>(APICONSTANTS.URLAPI.value + this.route + 'GetAllFromUserDTO/', objet)
      .pipe(catchError(err => this.requestCatchError(err, 'Le chargement')));
  }

  export(type : string) {
    // tslint:disable-next-line: max-line-length
    return this.http.get(APICONSTANTS.URLAPI.value + this.route + 'export/'+type,{responseType: 'text'});
  }

}
