<div class="row">
  <div class="col-sm-12" style="display: flex; justify-content: flex-start; align-items: flex-start;">
    <div class="col-sm-8 text-center">
      <h5>Selectionner un responsable pour l'application : {{application.nom}}</h5>
    </div>
    <div class="col-sm-2 text-center">
      <button mat-raised-button color="default" (click)= "closeDialog()">Annuler</button>
    </div>
    <div class="col-sm-2 text-center">
      <button mat-raised-button color="success" (click)="submit()" [disabled]="isResponsableByIdDisabled(responsable.idResponsable)" matTooltip="Collaborateur désactivé, veuillez le remplacer" [matTooltipDisabled]="!isResponsableByIdDisabled(responsable.idResponsable)">Confirmer</button>
    </div>
  </div>
  <div class="col-sm-12">
    <app-autocomplete-user 
    [responsables]="responsables"
    [userId]="responsable.idResponsable"
    [required]="true"
    (updated)="onResponsableChange($event)" 
    libelle="Responsable" 
    name="user">
    </app-autocomplete-user>
  </div>

  <div class="col-md-12">
    <app-table-with-filter [tableDTO]="tableDTO" (entityOutput)="updateResponsable($event)"></app-table-with-filter>
  </div>
</div>