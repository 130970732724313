export class DashboardModel {
  // Demandeurs

  toDemandeurRetourne: number;
  toDemandeurMyRetourne: number;
  
  toDemandeurRefuse: number;
  toDemandeurMyRefuse: number;
  
  toDemandeurEnCours: number;
  toDemandeurMyEnCours: number;
  
  toDemandeurTraite: number;
  toDemandeurMyTraite: number;
  
  // Valideur
  
  toValideurWaitValidationByMe: number;
  toValideurValide: number;

  // DSI
  
  toDSIWaitTraite: number;
  toDSITraite: number;
  toDSIRetourne: number;
}

