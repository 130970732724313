import { MatSidenav } from '@angular/material/sidenav';
import { UtilisateurModel } from './shared/models/utilisateur/utilisateur.model';
import { SessionService } from './shared/services/session.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  user: UtilisateurModel;
  title = 'GUS';
  mobileQuery: MediaQueryList;
  isExpanded = false;
  isShowing = false;
  @ViewChild('snav') sidenav: MatSidenav;
  private mobileQueryListener: () => void;
  constructor(media: MediaMatcher,
              public router: Router,
              changeDetectorRef: ChangeDetectorRef,
              private sessionService: SessionService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.sessionService.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  mouseenter(): void {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave(): void {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
}
