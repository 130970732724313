import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FiltreRechercheCollaborateurDTO } from 'src/app/shared/dtos/filtreRechercheCollaborateurDTO';
import { SimpleQueryResponse } from 'src/app/shared/dtos/simpleQueryResponse';
import { SimpleQuery } from 'src/app/shared/dtos/simple_query';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { RegionService } from 'src/app/shared/services/rsa/region.service';
import { UserService } from 'src/app/shared/services/user.service';
import { SocieteModel } from 'src/app/shared/models/rsa/societe.model';
import { SocieteService } from 'src/app/shared/services/rsa/societe.service';

@Component({
  selector: 'app-filtre-recherche-collaborateur',
  templateUrl: './filtre-recherche-collaborateur.component.html',
  styleUrls: ['./filtre-recherche-collaborateur.component.scss']
})
export class FiltreRechercheCollaborateurComponent implements OnInit {

  collab: CollaborateurModel;
  types: string[];
  niveaux: string[];
  agences: AgenceModel[];
  regions: RegionModel[];
  societes: SocieteModel[];
  filtreCollaborateur =  new FiltreRechercheCollaborateurDTO();
  agence: AgenceModel;
  region: RegionModel;
  societe: SocieteModel;
  simpleQueryResponse =  new SimpleQueryResponse<CollaborateurModel>();
  collaborateursDataSource: CollaborateurModel[] = [];
  @Output() updateFiltre = new EventEmitter<SimpleQuery<FiltreRechercheCollaborateurDTO>>();
  @Output() collaborateurSeach = new EventEmitter<SimpleQueryResponse<CollaborateurModel>>();

  
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private agenceService: AgenceService,
    private regionService: RegionService,
    private societeService: SocieteService,
    private collaborateurService: CollaborateurService,
  ) { }

  ngOnInit(): void {
    this.filtreCollaborateur = new FiltreRechercheCollaborateurDTO();
    this.route.params.subscribe(params => {
      this.agenceService.getAll().subscribe(agences => {
        this.agences = agences.sort((a, b) => a.code.localeCompare(b.code));
      });
    });
   
    this.route.params.subscribe(params => {
      this.regionService.getAll().subscribe(regions => {
        this.regions = regions.sort((a, b) => a.code.localeCompare(b.code));
      });
      this.societeService.getAll().subscribe(societes => {
        this.societes = societes.sort((a, b) => a.code.localeCompare(b.code));
      });
    });
    this.onSearchCollaborateur(this.filtreCollaborateur);
  }

  ngOnChange() {

  }

  onChange(event): void {
    //this.filtreData.pageNumber = 0;
    //this.filtreService.changeFiltre(this.filtreData);
  }

  onChangeAgence(agence: AgenceModel): void {
    if (agence) {
      this.agence = this.agences.find((ag: AgenceModel) =>  ag.id === agence.id);  
      this.filtreCollaborateur.agence = agence
      this.filtreCollaborateur.idAgence = agence.id;
    }else{
      this.filtreCollaborateur.agence = null;
      this.filtreCollaborateur.idAgence = null;
    }
    this.onSearchCollaborateur(this.filtreCollaborateur);

  }

  onChangeRegion(region: RegionModel): void {
    if (region) {
      this.region = this.regions.find((reg: RegionModel) =>  reg.id === region.id);
      this.filtreCollaborateur.region = region
      this.filtreCollaborateur.idRegion = region.id;
    }else{
      this.filtreCollaborateur.region = null;
      this.filtreCollaborateur.idRegion = null;
    }
    this.onSearchCollaborateur(this.filtreCollaborateur);
  }

  onChangeSociete(societe: SocieteModel): void {
    if (societe) {
      this.societe = this.societes.find((soc: SocieteModel) =>  soc.id === societe.id);
      this.filtreCollaborateur.societeObj = societe
      this.filtreCollaborateur.societeId = societe.id;
    }else{
      this.filtreCollaborateur.societeObj = null;
      this.filtreCollaborateur.societeId = null;
    }
    this.onSearchCollaborateur(this.filtreCollaborateur);

  }

  clearFiltre() {
    this.filtreCollaborateur = new FiltreRechercheCollaborateurDTO();
    this.onSearchCollaborateur(this.filtreCollaborateur);
  }


  onSearchCollaborateur(col: FiltreRechercheCollaborateurDTO) {
    this.onCollaborateurChange(new SimpleQuery<FiltreRechercheCollaborateurDTO>(col));
  }

  onCollaborateurChange(query: SimpleQuery<FiltreRechercheCollaborateurDTO>) {
    this.collaborateurService.GetCollaborateurFromObjetWithPagination(query).subscribe((response: SimpleQueryResponse<CollaborateurModel>) => {
      this.collaborateursDataSource = response.objets;
      this.updateFiltre.emit(query);
      this.collaborateurSeach.emit(response);
      this.simpleQueryResponse = response;
    });
  }
}
