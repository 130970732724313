import { DocumentModel } from './../../models/simple-types/document.model';
import { APICONSTANTS } from '../../constants/api-constants';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GenericCrudService } from '../basic/generic.crud.service';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { HostnameService } from '../basic/hostname.service';

@Injectable()
export class DocumentService extends GenericCrudService<DocumentModel> {
  constructor(http: HttpClient, protected toastrService: ToastrService,
    protected hostnameService: HostnameService) {
    super(http, toastrService, APICONSTANTS.ROUTEDOCUMENT, hostnameService);
  }

  getDocAppli(idDoc, idAppli): Observable<Blob> {
    return this.http.get(APICONSTANTS.URLAPI.value + this.route + 'getDocAppli?idAppli=' + idAppli + '&idDoc=' + idDoc,
      { responseType: 'text' })
      .pipe(switchMap(filegeturl => {
        if (filegeturl) {
          return this.http.get(filegeturl, { responseType: 'blob' });
        }
        return of(null);
      }), catchError(err => this.requestCatchError(err, 'L\'enregistrement')));
  }

  deleteDocAppli(idDoc, idAppli): Observable<DocumentModel> {
    return this.http.get(APICONSTANTS.URLAPI.value + this.route + 'deleteDocAppli?idAppli=' + idAppli + '&idDoc=' + idDoc,
      { responseType: 'text' })
      .pipe(switchMap(filedeleteurl => {
        if (filedeleteurl) {
          return this.http.delete(filedeleteurl, { reportProgress: true });
        }
        return of(null);
      }), catchError(err => this.requestCatchError(err, 'L\'enregistrement')));
  }

  sendDocAppli(filename, idAppli: number, file: File): Observable<HttpEvent<{}>> {
    return this.http.get(APICONSTANTS.URLAPI.value + this.route + 'sendDocAppli?idAppli=' + idAppli + '&libelleDoc=' + filename,
      { responseType: 'text' })
      .pipe(first(), switchMap((fileuploadurl: string) => {
        const headers = new HttpHeaders({ 'Content-Type': file.type });
        if (fileuploadurl) {
          const req = new HttpRequest('PUT', fileuploadurl, file, { headers });
          return this.http.request(req);
        }
        return of(null);
      }), catchError(err => {
        return this.requestCatchError(err, 'L\'enregistrement');
      }));
  }
  
  getAllFromAppli(idAppli: number): Observable<DocumentModel[]> {
    return this.http.get<DocumentModel[]>(APICONSTANTS.URLAPI.value + this.route + 'getAllFromAppli?idAppli=' + idAppli)
      .pipe(catchError(err => this.requestCatchError(err, 'Le chargement des documents')));
  }
}
