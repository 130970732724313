import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalInfoOkComponent } from 'src/app/shared/components/modals/info-ok/modal-info-ok.component';
import { SimpleQueryResponse } from 'src/app/shared/dtos/simpleQueryResponse';
import { SimpleQueryUser } from 'src/app/shared/dtos/simpleQueryUser';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';
import { ReferentielComponentsService } from '../referentiel-components.service';
import { ModalAddApplicationComponent } from 'src/app/shared/components/modals/modal-add-application/modal-add-application.component';

@Component({
  selector: 'app-gestion-application',
  templateUrl: './gestion-application.component.html',
  styleUrls: ['./gestion-application.component.scss']
})
export class GestionApplicationComponent implements OnInit {
  applications: ApplicationModel[] = [];
  displayedColumns: string[] = ['nom', 'groupe', 'categorie', 'active', 'action'];
  dataSource: ApplicationModel[];
  matDataSource = new MatTableDataSource<ApplicationModel>();
  sortState: Sort = {active: 'Nom', direction: 'desc'};
  length: number;
  pageSize = 25;
  pageEvent: PageEvent;
  result: boolean;
  objet: SimpleQueryUser = new SimpleQueryUser();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private applicationService: ApplicationService,
    private gestionReferentielComponent: ReferentielComponentsService
    ) { }

  ngOnInit(): void {
    this.objet.query = '';
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.objet.order = 'Nom';
    this.matDataSource.paginator = this.paginator;
    this.loadPage();
  }

  onCreate(): void {
    const form = this.gestionReferentielComponent.getCreateApplicationFormGroup();
    const dialogRef = this.dialog.open(ModalAddApplicationComponent, {
        autoFocus: false,
        disableClose: true,
        data: {
          form,
          result: this.result
        }
      });

    dialogRef.afterClosed().subscribe(result => {
        //this.onUpdated.emit(true);
        if (result) {
          this.loadPage();
        }
      });

  }

  onModify(application: ApplicationModel): void {
    this.router.navigate(['fiche-application/' + application.id]);
  }


  onDelete(application: ApplicationModel): void {
    const dialogRef = this.dialog.open(ModalInfoOkComponent, {
      data: {
        title: 'désactivation de l\'application',
        message: 'Êtes-vous sur de vouloir désactiver ' + application.nom + ' ?',
        icone: 'warning_amber',
        iconColor: 'accent',
        color: 'accent'
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.applicationService.desactivation(application.id, application).subscribe((response: ApplicationModel) => {
          this.toastrService.success('La application a bien été désactivé');
          this.loadPage();
        });
      }
    });
  }


  orderUsers(applications: ApplicationModel[]): ApplicationModel[] {
    applications = applications.sort((a, b) => {
      if (a.nom) {
        return a.nom.localeCompare(b.nom);
      } else {
        return -1;
      }
    });
    return applications;
  }

  changePage(event?: PageEvent): void {
    this.objet.pageNumber =  event.pageIndex;
    this.objet.pageSize = event.pageSize;
    this.loadPage();
  }

  search(): void {
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    this.loadPage();
  }

  loadPage(): void {
    var referentiel = new SimpleQueryUser();
    referentiel = this.objet;
    this.applicationService.loadFromSimpleQueryRef(referentiel).subscribe((query: SimpleQueryResponse<ApplicationModel>) => {
      if (query.objets != null) {
        this.applications = query.objets;
      } else {
        this.applications = [];
      }
      this.length = query.count;
      this.matDataSource = new MatTableDataSource<ApplicationModel>(this.applications);
      this.paginator.length = this.length;
    });
  }

  close(): void {
    this.router.navigate(['/gestion-referentiel']);
  }

  sortData(sort: Sort): void {
    if (sort.direction !== 'asc') {
      this.objet.descending = false;
    } else {
      this.objet.descending = true;
    }
    this.sortState = sort;
    this.objet.order = sort.active;
    this.loadPage();
  }

}
