<div class="mat-table">
    <div class="table_principale">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" (matSortChange)="trieTab($event)" matSortActive="nom" 
      matSortDirection="asc" matSortDisableClear>
      <caption></caption>

        <ng-container matColumnDef="nom">
          <th mat-header-cell *matHeaderCellDef> Nom </th>
          <td mat-cell *matCellDef="let element"> {{element.nom}} {{element.prenom}} </td>
        </ng-container>

        <ng-container matColumnDef="dDesactivation">
          <th mat-header-cell *matHeaderCellDef> Actif </th>
          <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
          <div *ngIf="element.ddesactivation == null">
            <mat-icon [ngStyle]="{'color':'#17910c'}">check</mat-icon>
          </div>
          <div *ngIf="element.ddesactivation != null">
            <mat-icon [ngStyle]="{'color':'#b51621'}"  >close</mat-icon>
          </div>
          
        </ng-container>
    
        <ng-container matColumnDef="region">
          <th mat-header-cell *matHeaderCellDef> Région </th>
          <td mat-cell *matCellDef="let element"> 
            <div *ngIf="element.agence.region">{{element.agence.region.code}}-{{element.agence.region.libelle}}</div>    
        </td>
        </ng-container>
    
        <ng-container matColumnDef="agence">
          <th mat-header-cell *matHeaderCellDef> Agence </th>
          <td mat-cell *matCellDef="let element"> 
              <div *ngIf="element.agence">{{element.agence.societeObj ? element.agence.societeObj.libelle : ""}} / {{element.agence.code}}-{{element.agence.libelle}}</div> 
            </td>
        </ng-container>
  
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="mobile">
            <th mat-header-cell *matHeaderCellDef> Mobile </th>
            <td mat-cell *matCellDef="let element"> {{element.tel}} </td>
          </ng-container>

          <ng-container matColumnDef="typeContrat">
            <th mat-header-cell *matHeaderCellDef> Type de contrat </th>
            <td mat-cell *matCellDef="let element"> {{element.typecontrat}} </td>
          </ng-container>

          <ng-container matColumnDef="responsable">
            <th mat-header-cell *matHeaderCellDef> Responsable </th>
            <td mat-cell *matCellDef="let element"> 
              <div *ngIf="element.responsable">
                <p matTooltipClass="matToolTip-allow-cr"
                   matTooltip="{{element.responsable.email + '\n' + element.responsable.agence.societe + ' / ' + element.responsable.agence.code+ '-'+ element.responsable.agence.libelle}}">{{element.responsable.prenom}} {{element.responsable.nom}}</p>
              </div>
              <div *ngIf="!element.responsable">
                <mat-icon matTooltip="Pas d'information" color="accent">help_outline</mat-icon>
              </div>  
            </td>
          </ng-container>

    
        <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="openEvent(row)"></tr>
      </table>
    
      <div class="footer">
        <mat-paginator
          #paginator
          [length]="length"
          [pageIndex]="pageIndex"
          [pageSize]="pageSize"
          [pageSizeOptions]="[10, 25, 50]"
          (page)="changePage($event)">
        </mat-paginator>
      </div>
      
    </div>
  </div>