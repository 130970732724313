<div *ngIf="demandeForm" class="row" id="parent-brouillon">
    <form focusInvalidInput [formGroup]="demandeForm" (ngSubmit)="memorize()">
        <div class="row">
            <div class="col-sm-12" style="display: flex; justify-content: space-between;">
                <h5 class="col-sm-8"> Brouillon demande de {{typeDemande}} N° {{this.demande.code}} {{this.demande.agence ? '(' + this.demande.agence.libelle + ')' : ''}}</h5>
                <div class="col-sm-4">
                    
                    <button color="default" mat-raised-button type="button" (click)="close()">
                        <mat-icon>arrow_back</mat-icon>Retour
                    </button>
                    <button color="danger" mat-raised-button type="button" (click)="annuler()">
                        <mat-icon>clear</mat-icon>Annuler
                    </button>
                    <button color="success" mat-raised-button
                        type="submit">
                        <mat-icon>save</mat-icon>Enregistrer
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-9">
                <div class="col-sm-12">
                    <app-demande-form [form]="demandeForm" [agence]="currentAgence"></app-demande-form>
                </div>
                
                <div class="col-sm-12">
                    <app-cycle-validation-list [cyclesValidation]="cyclesValidation"></app-cycle-validation-list>
                </div>
            </div>
            <div class="col-sm-3">
                <app-application-form [untouchedApps]="allApplications" [form]="demandeForm" [collabApplications]="collabApplications" ></app-application-form>
            </div>
        </div>
    </form>
</div>
<div *ngIf="demandeForm === null">
    <div class="row">
        <div class="col-sm-12" style="display: flex; justify-content: space-between;">
            <h2 class="col-sm-6"> Demande de {{typeDemande}} d'un collaborateur</h2>
            <div class="col-sm-6">
                <button color="warn" mat-raised-button type="button" (click)="close()">
                    <mat-icon>cancel</mat-icon>Fermer
                </button>
                <button disabled>
                    <mat-icon>save</mat-icon>Enregistrer
                </button>
            </div>
        </div>
    </div>
    <div>
        <mat-spinner class="spinner" mode="indeterminate"></mat-spinner>
    </div>
</div>
