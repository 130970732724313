import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatLegacyListOption as MatListOption, MatLegacySelectionList as MatSelectionList, MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { Router } from '@angular/router';
import { ApplicationDemandeModel } from 'src/app/shared/models/simple-types/application-demande.model';
import { ApplicationUserModel } from 'src/app/shared/models/simple-types/application-user.model';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
import { SessionService } from 'src/app/shared/services/session.service';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';

@Component({
  selector: 'app-application-fixed',
  templateUrl: './application-fixed.component.html',
  styleUrls: ['./application-fixed.component.scss']
})
export class ApplicationFixedComponent implements OnInit, OnChanges {
  @Input() collabApplications: ApplicationUserModel[] = [];
  @Input() form: UntypedFormGroup;
  @Input() untouchedApps: ApplicationModel[];
  applicationsDemande: ApplicationDemandeModel[] = [];
  appToAdd: ApplicationModel[] = [];
  appToRemove: ApplicationModel[] = [];
  appExiste: ApplicationModel[] = [];
  applications : ApplicationModel[] = [];
  AllApplications : ApplicationModel[] = [];
  @Output() selectedChange = new EventEmitter<ApplicationModel[]>();
  @ViewChild('list') matSelectionList: MatSelectionList;

  constructor(
    private applicationService: ApplicationService,
    private sessionService: SessionService,
    public router: Router) { 
    }

  ngOnInit(): void {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    // all apps 
    this.AllApplications = Object.assign([],this.untouchedApps);
    this.applications = Object.assign([],this.untouchedApps);
    this.AllApplications = this.AllApplications.filter(app=>app.invisible === false); //Hide all invisible apps
    if(this.form) 
      this.applicationsDemande = (this.form.get('applications') as UntypedFormArray).getRawValue();
    this.appToAdd = this.AllApplications.filter(app => this.applicationsDemande.find(appDemande => appDemande.idApplication === app.id && appDemande.action));
    this.appToRemove = this.AllApplications.filter(app => this.applicationsDemande.find(appDemande => appDemande.idApplication === app.id && !appDemande.action));
    this.appExiste = this.AllApplications.filter(app => this.collabApplications.find(appCollab => appCollab.idApplication === app.id));
  }
}



