import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { SessionService } from './session.service';
import { DemandeService } from './agence-linked/demande.service';
import { map } from 'rxjs/operators';
import { DemandeAgenceService } from './agence-linked/demande-agence.service';
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private sessionService: SessionService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {        
        this.sessionService.setStateUrl(decodeURI(state.url));
        if (!this.sessionService.isLoggedIn()) {
            // not logged in
            this.router.navigate(['/login']);
            return false;
          }
          return true;
      }
}


@Injectable()
export class DroitAdminGuard implements CanActivate {
    droitUser = false;
    //referentiel = false;
    droitReferntiel = false;
    //referentielGroupe = false;
    constructor( private router: Router,
                 private route: ActivatedRoute) {
    }
    canActivate(
        route: ActivatedRouteSnapshot,
        router: RouterStateSnapshot
    ): boolean {
    
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        const droitUser = user.droits.filter(droit =>
            (droit.niveau === 1) &&
            (droit.type.toUpperCase() === 'ADMIN')
        );
        if (droitUser && droitUser.length > 0){
          this.droitUser = true;
        }
        const droitReferntiel = user.droits.filter(droit =>
            (droit.niveau === 2) &&
            (droit.type.toUpperCase() === 'ADMIN')
        );
        if (droitReferntiel && droitReferntiel.length > 0){
            this.droitReferntiel = true;
        }

        switch (route.url[0].path) {
            case 'gestion-referentiel':
                if (!this.droitReferntiel) {
                    this.router.navigate(['**']);
                }
                break;
            case 'gestion-application':
                if (!this.droitReferntiel) {
                    this.router.navigate(['**']);
                }
                break;
            case 'fiche-application':
                if (!this.droitReferntiel) {
                    this.router.navigate(['**']);
                }
                break;
            case 'gestion-region':
                if (!this.droitReferntiel) {
                    this.router.navigate(['**']);
                }
                break;
            case 'list-user':
                if (!this.droitUser) {
                    this.router.navigate(['**']);
                }
                break;
            case 'user-record':
                if (!this.droitUser) {
                    this.router.navigate(['**']);
                }
                break;
        }
        return true;
    }
}

@Injectable() 
export class DroitRefageGuard implements CanActivate {
    droitReferntiel = false;
    constructor( private router: Router,
                 private route: ActivatedRoute) {
    }
    canActivate(
        route: ActivatedRouteSnapshot,
        router: RouterStateSnapshot
    ): boolean {
    
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        const droitReferntiel = user.droits.filter(droit =>
            (droit.type.toUpperCase() === 'REFAGE')
        );
        if (droitReferntiel && droitReferntiel.length > 0){
            this.droitReferntiel = true;
        }
        if (!this.droitReferntiel) {
            this.router.navigate(['**']);
        }

        return true;
    }
}



@Injectable() 
export class DroitRefageAdminGuard implements CanActivate {
    droitReferntiel = false;
    constructor( private router: Router,
                 private route: ActivatedRoute) {
    }
    canActivate(
        route: ActivatedRouteSnapshot,
        router: RouterStateSnapshot
    ): boolean {
    
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        const droitReferntiel = user.droits.filter(droit =>
            ((droit.type.toUpperCase() === 'REFAGE') || (droit.type.toUpperCase() === 'ADMIN' && droit.niveau == 2))
        );
        if (droitReferntiel && droitReferntiel.length > 0){
            this.droitReferntiel = true;
        }
        if (!this.droitReferntiel) {
            this.router.navigate(['**']);
        }

        return true;
    }
}



@Injectable()
export class DroitDemandeGuard implements CanActivate {
    droitVisualise = false;
    droitCreate = false;
    droitValidate = false;
    droitDSI = false;
    constructor( private router: Router,
                 private route: ActivatedRoute,
                 private demandeService: DemandeService,
                 private demandeAgenceService: DemandeAgenceService) {
    }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const idDemande = route.paramMap.get('id');
        switch (route.url[0].path) {
            case 'create-demande':
                return this.checkDemandeCreate();
            case 'create-demande-agence':
                return this.checkDemandeAgenceCreate();
            case 'edit-demande':
                return this.checkDemandeEdit(Number(idDemande));
            case 'read-demande':
                return this.checkDemandeVisualise(Number(idDemande));
            case 'read-demande-agence':
                return this.checkDemandeAgenceVisualise(Number(idDemande));
            case 'validate-demande':
                return this.checkDemandeValidate(Number(idDemande));
            case 'validate-demande-agence':
                return this.checkDemandeAgenceValidate(Number(idDemande));
            case 'traiter-demande':
                return this.checkDemandeDSI(Number(idDemande));
        }
    }

    checkDemandeCreate(){
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        const droit = user.droits.find(droit => (droit.niveau === 2)  && (droit.type.trim() === 'Demande'));
        if(droit) {
            return true
        }else {
            this.router.navigate(['**']);
            return false;
        }
    }

    checkDemandeAgenceCreate(){
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
        const droit = user.droits.find(droit => (droit.niveau === 2)  && (droit.type.trim() === 'REFAGE'));
        if(droit) {
            return true
        }else {
            this.router.navigate(['**']);
            return false;
        }
    }

    checkDemandeEdit(idDemande: number){
        return this.demandeService.get(idDemande).pipe(
            map(demande => {
                const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
                const droit = user.droits.find(droit =>
                    (droit.niveau === 2) && (droit.agenceId === demande.idAgence) &&
                    (droit.type.trim() === 'Demande')
                );
                if(droit) {
                    return true
                }else {
                    this.router.navigate(['**']);
                    return false;
                }
            })
        );
    }

    checkDemandeVisualise(idDemande: number){
        return this.demandeService.get(idDemande).pipe(
            map(demande => {
                const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
                const droit = user.droits.find(droit =>
                    (droit.niveau > 0) && (droit.agenceId === demande.idAgence) &&
                    (droit.type.trim() === 'Demande')
                );
                if(droit) {
                    return true
                }else {
                    this.router.navigate(['**']);
                    return false;
                }
            })
        );
    }

    checkDemandeAgenceVisualise(idDemande: number){
        /*return this.demandeAgenceService.get(idDemande).pipe(
            map(demande => {
                
            })
        );*/
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
                const droit = user.droits.find(droit =>
                    (droit.niveau > 0) &&
                    (droit.type.trim() === 'REFAGE')
                );
                if(droit) {
                    return true
                }else {
                    this.router.navigate(['**']);
                    return false;
                }
    }

    checkDemandeValidate(idDemande: number){
        return this.demandeService.get(idDemande).pipe(
            map(demande => {
                const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
                const droit = user.droits.find(droit =>
                    (droit.niveau === 3) && (droit.agenceId === demande.idAgence) &&
                    (droit.type.trim() === 'Demande')
                );
                if(droit) {
                    return true
                }else {
                    this.router.navigate(['**']);
                    return false;
                }
            })
        );
    }

    checkDemandeAgenceValidate(idDemande: number){
        const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
                const droit = user.droits.find(droit =>
                    (droit.niveau === 5) &&
                    (droit.type.trim() === 'REFAGE')
                );
                if(droit) {
                    return true
                }else {
                    this.router.navigate(['**']);
                    return false;
                }
    }

    checkDemandeDSI(idDemande: number){
        return this.demandeService.get(idDemande).pipe(
            map(demande => {
                const user = JSON.parse(localStorage.getItem('CURRENT_USER'));
                const droit = user.droits.find(droit =>
                    (droit.niveau === 5) && (droit.agenceId === demande.idAgence) &&
                    (droit.type.trim() === 'Demande')
                );
                if(droit) {
                    return true
                }else {
                    this.router.navigate(['**']);
                    return false;
                }
            })
        );
    }
}

