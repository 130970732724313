import { Validators } from '@angular/forms';

export class FormInfo {
  validators: Validators;
  disabled: boolean;
  constructor(required: boolean, validator: Validators) {
    this.disabled = required;
    this.validators = validator;
  }
}

export const APPLICATIONVALIDATORS = {
  CREATE: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['nom', new FormInfo(false, [Validators.required])],
    ['groupe', new FormInfo(false, [])],
    ['path', new FormInfo(false, [])],
    ['default', new FormInfo(false, [])],
    ['invisible', new FormInfo(false, [])],
    ['cdsOn', new FormInfo(false, [])],
    ['linkFormApplicatif', new FormInfo(false, [])],
    ['categorie', new FormInfo(false, [])]
  ]),
  EDIT: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['nom', new FormInfo(false, [Validators.required])],
    ['groupe', new FormInfo(false, [])],
    ['path', new FormInfo(false, [])],
    ['default', new FormInfo(false, [])],
    ['invisible', new FormInfo(false, [])],
    ['cdsOn', new FormInfo(false, [])],
    ['linkFormApplicatif', new FormInfo(false, [])],
    ['categorie', new FormInfo(false, [])]
  ]),
  VISUALIZE: new Map<string, FormInfo>([
    ['id', new FormInfo(true, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['nom', new FormInfo(true, [])],
    ['groupe', new FormInfo(false, [])],
    ['path', new FormInfo(false, [])],
    ['default', new FormInfo(false, [])],
    ['invisible', new FormInfo(false, [])],
    ['cdsOn', new FormInfo(false, [])],
    ['linkFormApplicatif', new FormInfo(false, [])],
    ['categorie', new FormInfo(false, [])]
  ])
};
