import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';

export interface DialogData {
  icone: string;
  title: string;
  alertMessage: string;
  message: string;
  color: string;
  collab;
  listDemande: DemandeModel[];
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  result: string;
  annulation: boolean; // coming from annulation demande
  required: boolean;
}

@Component({
  selector: 'app-add-comment-modal',
  templateUrl: './add-comment-modal.component.html',
  styleUrls: ['./add-comment-modal.component.scss']
})

export class AddCommentModalComponent implements OnInit {

  icone: string;
  title: string;
  alertMessage: string;
  message: string;
  color: string;
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  commForm: UntypedFormGroup;
  required: boolean = false;

  @Input() idAgence: number;
  @Input() listDemande: DemandeModel[];

  constructor(public dialog: MatDialog,
              public router: Router,
              public dialogRef: MatDialogRef<Component>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
                this.commForm = this.getFormGroup();
                this.icone = this.data.icone !== undefined ? this.data.icone : '';
                this.title = this.data.title !== undefined ? this.data.title : '';
                this.alertMessage = this.data.alertMessage;
                this.icon_color = this.data.icon_color !== undefined ? this.data.icon_color : '';
                this.dialogWidth = this.data.dialogWidth !== undefined ? this.data.dialogWidth : '';
                this.dialogHeight = this.data.dialogHeight !== undefined ? this.data.dialogHeight : '';
                this.color = this.data.color;
                this.listDemande = this.data.listDemande;
                this.required = this.data.required;
              }

  ngOnInit(): void {
    
  }
  onModalCancel(): void {
    this.data.result = '';
    this.dialogRef.close(null);
  }

  onModalConfirm(): void {
    this.data.result = this.commForm.get('commentaires').value;
    if(this.data.result)
    {
      this.dialogRef.close(this.data.result);
    }
    else
    {
      this.dialogRef.close('');
    }
  }

  getFormGroup(): UntypedFormGroup {
    const demandeNumForm = new UntypedFormGroup({
      commentaires: new UntypedFormControl(''),

    });
    return demandeNumForm;
  }
}
