import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { APICONSTANTS } from 'src/app/shared/constants/api-constants';
import { GenericCrudService } from '../basic/generic.crud.service';
import { HostnameService } from '../basic/hostname.service';
import { SimpleQueryUser } from '../../dtos/simpleQueryUser';
import { SimpleQueryResponse } from '../../dtos/simpleQueryResponse';

@Injectable()
export abstract class RSAService<T> extends GenericCrudService<T> {
  constructor(http: HttpClient,
              protected toastrService: ToastrService,
              protected route: string,
              protected hostnameService: HostnameService) {
              super(http, toastrService, route, hostnameService);
  }
 
  loadFromSimpleQueryRef(objet: SimpleQueryUser): Observable<SimpleQueryResponse<T>> {
    return this.http.post<SimpleQueryResponse<T>>(APICONSTANTS.URLAPI.value + this.route + 'loadFromSimpleQueryRef', objet)
      .pipe(catchError(err => this.requestCatchError(err, 'Le chargement')));
  }


  desactivation(id: number, objet: T): Observable<T> {
    return this.http.put<T>(APICONSTANTS.URLAPI.value + this.route + 'desactivation/' + id, objet, {})
    .pipe(catchError( err => this.requestCatchError(err, 'Desactivation ')));
  }
}
