<div>
  <div class="test" style="text-align: center">
    <div class="col-sm-12">
      <h3>{{title}}</h3>
    </div>
    <div class="col-sm-12">
      <h4>{{subTitle}}</h4>
    </div>
    <div class="col-sm-12" *ngIf="icone === ''">
      <h5 [innerHTML]="message"></h5>
    </div>
    <div class="col-sm-12" *ngIf="icone !== ''">
      <div class="col-sm-12" >
        <h5 [innerHTML]="message" style="text-align: left;" ></h5>
      </div>
    </div>
    <div class="col-sm-12">
      <mat-divider></mat-divider>
    </div>
    <div class="col-sm-12">
      <mat-divider></mat-divider>
    </div>
    <div class="col-sm-12" style="margin-top: 20px;" *ngIf="this.footer != null">
      <h6>{{footer}}</h6>
    </div>
    <div class="row">
      <div class="col-sm-6" style="margin-top: 20px;">
        <button mat-raised-button color="default" (click)="onModalCancel()">Annuler</button>
      </div>
      <div class="col-sm-6" style="margin-top: 20px;" >
        <button mat-raised-button [color]="color" (click)="onModalConfirm()">Confirmer </button>
      </div>
    </div>
  </div>
</div>