<div>
    <div *ngIf="icone === ''">
      <h5>{{message}}</h5>
    </div>
    <div class="col-md-12" style="display: flex; justify-content: space-between;" *ngIf="icone !== ''">
      <mat-icon class="col-md-2" [color]="iconColor">{{icone}}</mat-icon>
      <h5>{{message}}</h5>
    </div>
    <div class="col-md-12">
      <mat-divider></mat-divider>
    </div>
    <div class="col-md-12" style="margin-top: 20px; display: flex; justify-content: space-between;">
      <button mat-raised-button color="default" (click)="onModalCancel()">Retour</button>
      <button mat-raised-button color="danger" (click)="onModalConfirm()" style="margin-left: 40px;"> Annulation définitive </button>
    </div>
  </div>