export class ResponsableDTO {
    idApplication: number;
    regionId: number;
    societeId: number;
    agenceId: number = null;
    
    libelleRegion: string;
    libelleSociete: string = "";
    libelleAgence: string = "";
  
    isResponsable: CellDTO;
  
  }
  
  export class CellDTO{
  
    value: boolean
    disabled: boolean
  }