<div class="col-md-12" class="header">
    <h2 class="col-md-8"> Modification d'application</h2>
    <div class="col-md-1">
      <button  class="pull-right" mat-raised-button type="submit" color="success" (click)="memorise()">
        <mat-icon>save</mat-icon> Mémoriser
      </button>
    </div>
    <div class="col-md-1">
      <button class="pull-right" color="warn" mat-raised-button type="button" (click)="close()">
        <mat-icon>cancel</mat-icon> Fermer
      </button>
    </div>
  </div>
  <div class="col-md-12" *ngIf="form" class="content">
    <form focusInvalidInput [formGroup]="form">
      <div class="row">
        <div class="col-md-6">
          <mat-card> 
            <mat-card-header>
              <mat-card-title>Fiche application</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Nom</mat-label>
                  <input matInput placeholder="Ex: GUS" formControlName="nom" required>
                  <mat-error *ngIf="form.get('nom').hasError('required')">
                    Le nom est obligatoire.
                </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Groupe AD</mat-label>
                  <input matInput placeholder="Ex: GRP-SAOS-APPLICATION-PRD-Users"  formControlName="groupe" [required]="form.get('invisible').value === false">
                  <mat-error *ngIf="form.get('groupe').hasError('required')">
                    Le groupe est obligatoire.
                </mat-error>
                </mat-form-field>
              </div>
              
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Chemin du groupe AD</mat-label>
                  <input matInput placeholder="Ex : groupe-sarposis.net/groupe-sarposis/Groupes/Applications/ %" formControlName="path" [required]="form.get('invisible').value === false">
                  <mat-error *ngIf="form.get('path').hasError('required')">
                    Le chemin est obligatoire.
                </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Lien formulaire applicatif</mat-label>
                  <input matInput placeholder="Lien du formulaire applicatif" formControlName="linkFormApplicatif">
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Catégorie de l’application</mat-label>
                  <input matInput placeholder="Catégorie de l’application" formControlName="categorie">
                </mat-form-field>
              </div>
              
              <div class="col-md-12">
                <div class="row"> 
                <div class="col-md-6">
                  <div class="row">
                    <div class="left" *ngIf="!form.get('dDesactivation').value "><mat-icon class="green-icon">check</mat-icon></div> 
                    <div class="left" *ngIf="form.get('dDesactivation').value "><mat-icon class="red-icon">close</mat-icon></div> 
                    <h6>{{!form.get('dDesactivation').value ? " Application Activé": " Application désactivé"}}</h6>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <mat-checkbox formControlName="default"  (change)="updateFormControls()" class="example-margin" ><div class="text-wrap">Application par défaut</div></mat-checkbox>
                  </div>
                  <div class="row">
                    <mat-checkbox formControlName="invisible" (change)="updateFormControls()" class="example-margin" ><div class="text-wrap">Application non visible</div></mat-checkbox>
                  </div>
                  <div class="row">
                    <mat-checkbox formControlName="cdsOn" (change)="updateFormControls()" class="example-margin" ><div class="text-wrap">Application disponible pour compte Gdrive seul (Cloud Identity)</div></mat-checkbox>
                  </div>
                </div>
                </div>
                
              </div> 
            </mat-card-content>
          </mat-card>
          
        </div>
        <div class="col-md-6">
          <mat-card> 
            
            <div class="row">
              <mat-card-header>
                <mat-card-title>Responsables</mat-card-title>
                <div class="col-md-8"></div>
                <div class="col-md-4">              
                  <button  class="pull-right" mat-mini-fab matTooltip="Ajouter un responsable" color="primary" (click)="addResponsable()" [disabled]="!this.application">         
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </mat-card-header>
              
            </div>

            <table #table mat-table matSort [dataSource]="matDataSource" formArrayName="responsables" (matSortChange)="sortData($event)">
              <caption></caption>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="centrer"> Email </th>
                <td mat-cell  *matCellDef="let element; let i = index" [formGroupName]="i" class="centrer" matTooltip="Collaborateur désactivé" matTooltipPosition="above" [matTooltipDisabled]="!isResponsableByIdDisabled(element.get('idResponsable').value)">
                  <span style="display: flex; align-items: center;">
                    <mat-icon *ngIf="isResponsableByIdDisabled(element.get('idResponsable').value)">not_interested</mat-icon>
                    {{(collabs && collabs.length > 0) ? 
                        getEmailResponsableById(element.get('idResponsable').value)
                    : ""}}
                  </span>
                </td>
              </ng-container>
      
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="centrer no-padding" (click)="$event.stopPropagation()">
                    <button mat-button >
                      <mat-icon class="red-icon" (click)="deleteResponsable(element, i)">delete</mat-icon>
                    </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns ;sticky: true" ></tr>
              <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;" (click)="modifyResponsable(row);"></tr>
            </table>
          </mat-card>
        </div>
      </div>
    </form>
  </div>
 
  