import { UserComponentsService } from './user/user-components.service';
import { DroitAdminGuard, DroitDemandeGuard, DroitRefageAdminGuard, DroitRefageGuard } from './../shared/services/auth.guard';
import { GlobalSettingsService } from './gobal-settings.service';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { DemandeComponentsService } from './demande/demande-components.service';
import { AuthGuard } from '../shared/services/auth.guard';
import { JwtHelper } from '../shared/services/jwtHelper';
import { DemandeModule } from './demande/demande.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthentificationComponent } from './authentification/authentification.component';
import { UserRecordComponent } from './user/user-record/user-record.component';
import { ListUserComponent } from './user/list-user/list-user.component';
import { ModalAddUserComponent } from './user/modal-add-user/modal-add-user.component';
import { GoogleApiService } from '../shared/services/gapiServices/googleApiService';
import { CycleDemandeService } from '../shared/services/simple/cycle-demande.service';
import { GestionReferentielComponent } from './gestion-referentiel/gestion-referentiel.component';
import { GestionApplicationComponent } from './gestion-referentiel/gestion-application/gestion-application.component';
import { FicheApplicationComponent } from './gestion-referentiel/fiche-application/fiche-application.component';
import { ReferentielComponentsService } from './gestion-referentiel/referentiel-components.service';
import { GestionRegionComponent } from './gestion-referentiel/gestion-region/gestion-region.component';
import { GestionAgenceComponent } from './gestion-referentiel/gestion-agence/gestion-agence.component';
import { GestionSocieteComponent } from './gestion-referentiel/gestion-societe/gestion-societe.component';
import { FicheAgenceComponent } from './gestion-referentiel/fiche-agence/fiche-agence.component';

@NgModule({
  declarations: [
    LandingPageComponent,
    DashboardComponent,
    AuthentificationComponent,
    UserRecordComponent,
    ListUserComponent,
    ModalAddUserComponent,
    GestionReferentielComponent,
    GestionApplicationComponent,
    FicheApplicationComponent,
    GestionRegionComponent,
    GestionSocieteComponent,
    GestionAgenceComponent,
    FicheAgenceComponent
  ], 
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatCardModule,
    DemandeModule
  ],
  providers:  [
    DemandeComponentsService,
    ReferentielComponentsService,
    UserComponentsService,
    AuthGuard,
    DroitAdminGuard,
    DroitRefageAdminGuard,
    DroitRefageGuard,
    DroitDemandeGuard,
    JwtHelper,
    GoogleApiService,
    GlobalSettingsService,
    CycleDemandeService
  ]
})
export class CoreModule { }
