<div *ngIf="form">
    <div fxLayout="column" fxLayoutAlign="start start" style="min-height: min-content;">
      <h5>Applications</h5>
      <mat-divider style="align-self: stretch;"></mat-divider>  
      <mat-list class="liste">
        <div mat-subheader *ngIf="appToAdd.length > 0">Applications à ajouter</div>
        <mat-list-item *ngFor="let app of appToAdd">
          <mat-icon matListItemIcon [ngStyle]="{'color':'green'}">add</mat-icon>
          <div matListItemTitle>{{app.nom}}</div>
        </mat-list-item>
        <mat-divider *ngIf="appToAdd.length > 0"></mat-divider>
        <div mat-subheader *ngIf="appToRemove.length > 0">Applications à supprimer</div>
        <mat-list-item *ngFor="let app of appToRemove">
          <mat-icon matListItemIcon [ngStyle]="{'color':'red'}">delete</mat-icon>
          <div matListItemTitle>{{app.nom}}</div>
        </mat-list-item>
        <mat-divider  *ngIf="appToRemove.length > 0"></mat-divider>
        <div mat-subheader>Applications existantes</div>
          <mat-list-item *ngFor="let app of appExiste">
            <mat-icon matListItemIcon>done</mat-icon>
            <div matListItemTitle>{{app.nom}}</div>
          </mat-list-item>
      </mat-list>
    </div>
  </div>
  
  