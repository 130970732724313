import { DroitEffectifService } from './../../shared/services/simple/droit.service';
import { GlobalSettingsService } from './../../core/gobal-settings.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/shared/services/session.service';
import { UtilisateurModel } from 'src/app/shared/models/utilisateur/utilisateur.model';
import { MatSidenav } from '@angular/material/sidenav';
import { HostnameService } from 'src/app/shared/services/basic/hostname.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public envName = environment.envName;
  canUseMock = false;
  mockEnabled = false;
  user: UtilisateurModel;
  @Input() snav: MatSidenav;
  droitAdminUser  = false;  // pour savoir si on a les doits admin user
  droitAdminRefAndInterface  = false;  // pour savoir si on a les doits admin referentiel

  constructor(
    public router: Router,
    private sessionService: SessionService,
    private globalSettingsService: GlobalSettingsService,
    private hostnameService: HostnameService,
    private droitService: DroitEffectifService
  ) {
  }

  ngOnInit(): void {
    this.sessionService.currentUser.subscribe(user => {
      this.user = user;
      if (user) {
        const droitsAdmin = user.droits.filter(droit =>
          (droit.niveau === 1) &&
          (droit.type.toUpperCase() === 'ADMIN')
        );
        if (droitsAdmin && droitsAdmin.length > 0){
          this.droitAdminUser = true;
        }
        const droitsAdminRef = user.droits.filter(droit => (((droit.niveau === 2) && (droit.type.toUpperCase() === 'ADMIN')) || droit.type.toUpperCase() === 'REFAGE'));
        if (droitsAdminRef && droitsAdminRef.length > 0){
          this.droitAdminRefAndInterface = true;
        }
      }
    });
  }

  logout(): void {
    this.sessionService.logout().subscribe();
    this.user = undefined;
  }
  manager_user(): void {
    this.router.navigate(['list-user']);
  }

  manager_referentiel(): void {
    this.router.navigate(['gestion-referentiel']);
  }
}
