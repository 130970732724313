<div class="row">
    <div class="col-md-12 padding-0" style="display: flex; justify-content: space-between;">
        <div class="col-md-8">
            <h5 > Visualisation de la demande de {{typeDemande}} N° {{this.demande.codeDemande}}</h5>
        </div>
        <div class="col-md-4">
            <button color="danger" mat-raised-button type="button" *ngIf="showButtons" (click)="cancelDemande()">
                <mat-icon>cancel</mat-icon>Annuler 
            </button>

            <button color="success" mat-raised-button type="button" matTooltip="Fonctionnalité A venir" 
            *ngIf="showButtons" (click)="updateDemande()" disabled>
                <mat-icon>edit</mat-icon>Modifier
            </button>
            <button color="default" mat-raised-button type="button" (click)="close()">
                <mat-icon>arrow_back</mat-icon>Retour à la liste
            </button>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <div class="col-sm-12">
            <app-demande-agence-form [form]="demandeForm" [agence]="demande.agence"></app-demande-agence-form>
        </div>
        
        <div class="col-sm-12">
            <app-cycle-validation-list [cyclesValidation]="cyclesValidation"></app-cycle-validation-list>
        </div>
    </div>

</div>

