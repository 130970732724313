<br/>
<mat-card>
    <mat-card-content>
        <div class="mat-table">
            <div class="table_principal">
                <table mat-table matSort [dataSource]="matDataSourceDemande" matSortDirection="desc" matSortDisableClear matSortActive="Quand">
                    <caption>Cycle de validation</caption>
                    <ng-container matColumnDef="Qui">
                        <th mat-header-cell *matHeaderCellDef> Qui </th>
                        <td mat-cell *matCellDef="let element" class="padding-0" >
                            <div >
                                <div *ngIf="element.user">
                                    {{element.user.nom + ' ' + element.user.prenom}}
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Étape initiale">
                        <th mat-header-cell *matHeaderCellDef> Étape initiale </th>
                        <td mat-cell *matCellDef="let element" class="padding-0">
                            <div>
                                {{element.statutOld}}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Étape finale">
                        <th mat-header-cell *matHeaderCellDef> Étape finale </th>
                        <td mat-cell *matCellDef="let element" class="padding-0">
                            <div>
                                {{element.statutNew}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Quand">
                        <th mat-header-cell *matHeaderCellDef> Quand </th>
                        <td mat-cell *matCellDef="let element" class="padding-0"> 
                            <div *ngIf="element.dAction !== '0001-01-01T00:00:00Z'">
                                {{element.dAction| date:'dd/MM/yyyy HH:mm'}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Commentaire">
                        <th mat-header-cell *matHeaderCellDef> Commentaire </th>
                        <td mat-cell *matCellDef="let element" class="padding-0" >
                            <div [innerHTML]="element.commentaire">
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsDemande; sticky: true"></tr>
                    <div>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsDemande;"
                            >
                        </tr>
                    </div>
                </table>
            </div>
        </div>
    </mat-card-content>
</mat-card>