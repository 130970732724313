<div class="mat-table">
  <div class="table_principale">
    <table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" (matSortChange)="trieTab($event)" matSortActive="Nom" 
        matSortDirection="desc" matSortDisableClear>
        
        <caption></caption>

      <ng-container matColumnDef="Nom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.rowspan]="2"> Nom </th>
        <td mat-cell *matCellDef="let element"> {{element.nom}} {{element.prenom}} </td>
      </ng-container>

      <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.rowspan]="2"> Mail </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="region" >
        <th mat-header-cell *matHeaderCellDef  [attr.rowspan]="2"> Région - Société - Agence </th>
        <td mat-cell *matCellDef="let element"> 
          <div *ngIf="element.agence.region">{{element.agence.region.code}} - {{element.agence.societeObj ? element.agence.societeObj.libelle : ""}} - {{element.agence.code}}</div>    
      </td>
      </ng-container>

      
      <ng-container matColumnDef="TypeContrat" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.rowspan]="2"> Type de contrat </th>
        <td mat-cell *matCellDef="let element"> {{element.typecontrat}} </td>
      </ng-container>

      <ng-container matColumnDef="CDS" >
        <th mat-header-cell *matHeaderCellDef  mat-sort-header [attr.rowspan]="2"> Type compte Google </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
        <div *ngIf="element.cds">
          <p >Gdrive seul (Cloud Identity)</p>
        </div>
        <div *ngIf="!element.cds">
          <p >Workspace</p>
        </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Responsable" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.rowspan]="2"> Responsable </th>
        <td mat-cell *matCellDef="let element"> 
          <div *ngIf="element.responsable">
            <p matTooltipClass="matToolTip-allow-cr"
               matTooltip="{{element.responsable.email + '\n' + element.responsable.agence.societe + ' / ' + element.responsable.agence.code+ '-'+ element.responsable.agence.libelle}}">{{element.responsable.prenom}} {{element.responsable.nom}}</p>
          </div>
          <div *ngIf="!element.responsable">
            <mat-icon matTooltip="Pas d'information" color="accent">help_outline</mat-icon>
          </div>  
        </td>
      </ng-container>


      <ng-container matColumnDef="applications">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="8">Applications</th>
        <td mat-cell></td>
      </ng-container>

      <ng-container matColumnDef="ADP">
        <th mat-header-cell *matHeaderCellDef> ADP </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
        <div *ngIf="checkforApp('ADP',element)">
          <mat-icon [ngStyle]="{'color':'#17910c'}">check</mat-icon>
        </div>
      </ng-container>

      <ng-container matColumnDef="Esker">
        <th mat-header-cell *matHeaderCellDef> Esker </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
        <div *ngIf="checkforApp('Esker',element)">
          <mat-icon [ngStyle]="{'color':'#17910c'}">check</mat-icon>
        </div>
      </ng-container>

      <ng-container matColumnDef="myDSO">
        <th mat-header-cell *matHeaderCellDef> myDSO </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
        <div *ngIf="checkforApp('myDSO',element)">
          <mat-icon [ngStyle]="{'color':'#17910c'}">check</mat-icon>
        </div>
      </ng-container>

      <ng-container matColumnDef="NTH">
        <th mat-header-cell *matHeaderCellDef> NTH </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
        <div *ngIf="checkforApp('NTH',element)">
          <mat-icon [ngStyle]="{'color':'#17910c'}">check</mat-icon>
        </div>
      </ng-container>

      <ng-container matColumnDef="Jason">
        <th mat-header-cell *matHeaderCellDef> Jason </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
        <div *ngIf="checkforApp('Jason',element)">
          <mat-icon [ngStyle]="{'color':'#17910c'}">check</mat-icon>
        </div>
      </ng-container>

      <ng-container matColumnDef="Sacha">
        <th mat-header-cell *matHeaderCellDef> Sacha </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
        <div *ngIf="checkforApp('Sacha',element)">
          <mat-icon [ngStyle]="{'color':'#17910c'}">check</mat-icon>
        </div>
      </ng-container>

      <ng-container matColumnDef="Sage">
        <th mat-header-cell *matHeaderCellDef> Sage </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
        <div *ngIf="checkforApp('Sage',element)">
          <mat-icon [ngStyle]="{'color':'#17910c'}">check</mat-icon>
        </div>
      </ng-container>

      <ng-container matColumnDef="Autres">
        <th mat-header-cell *matHeaderCellDef> Autres </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
        <div *ngIf="checkforApp('Autres',element)">
          <mat-icon [ngStyle]="{'color':'#17910c'}">check</mat-icon>
        </div>
      </ng-container>

      <ng-container matColumnDef="DDesactivation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.rowspan]="2"> Compte actif </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;"> 
        <div *ngIf="element.ddesactivation == null">
          <mat-icon [ngStyle]="{'color':'#17910c'}">check</mat-icon>
        </div>
        <div *ngIf="element.ddesactivation != null">
          <mat-icon [ngStyle]="{'color':'#b51621'}"  >close</mat-icon>
        </div>
      </ng-container>
    
      <ng-container matColumnDef="DModification">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.rowspan]="2"> Date modification </th>
        <td mat-cell *matCellDef="let element"> 
            <div >{{element.dmodification | date: 'dd/MM/yyyy HH:mm' }}</div> 
          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedSubColumns, sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: allColDef;" class="element-row" (click)="openEvent(row)"></tr>
    </table>
  
    <div class="footer">
      <mat-paginator
        #paginator
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[10, 25, 50]"
        (page)="changePage($event)">
      </mat-paginator>
    </div>
    
  </div>
</div>