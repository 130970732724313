
  <div class="col-md-12">
    <div class="row">
        <h2 class="col-md-8"> Gestion Référentiel </h2>
        <div class="col-md-2">
            <button class="pull-right" color="warn" mat-raised-button type="button" (click)="close()">
                <mat-icon>cancel</mat-icon> Fermer
            </button>
        </div>
    </div>
    
  </div>

    <div class="col-sm-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Référentiel </mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="button-row">
                    
                    <mat-card *ngIf="viewReferentiel" class="sous-card premier" (click)="goToGestion('gestion-application')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">apps
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h5>Applications</h5> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>Appli</h1> <br>
                                        </ng-template>
                                    </div>
                                </span>

                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card *ngIf="viewReferentiel" class="sous-card premier" (click)="goToGestion('gestion-region')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">map
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h5>Régions</h5> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>région</h1> <br>
                                        </ng-template>
                                    </div>
                                </span>

                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card *ngIf="viewReferentiel" class="sous-card premier" (click)="goToGestion('gestion-societe')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">map
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h5>Sociétés</h5> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>société</h1> <br>
                                        </ng-template>
                                    </div>
                                </span>

                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card *ngIf="viewAgence" class="sous-card premier" (click)="goToGestion('gestion-agence')" >
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">business
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h5>Agences</h5> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>Agence</h1> <br>
                                        </ng-template>
                                    </div>
                                </span>

                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="sous-card premier" (click)="goToWaitValidationByMe('list-demande-agence')">
                        <mat-card-content>
                            <div class="resume">
                                <mat-icon style="height: 0px !important;">event_note
                                </mat-icon>
                                <div class="vl"></div>
                                <span class="text">
                                    <div>
                                        <h1>{{demandeDashboard.toValideurWaitValidationByMe}}</h1> <br>
                                        <ng-template #emptyToValidate>
                                            <h1>{{myOpenOrderCount}}</h1> <br>
                                        </ng-template>
                                    </div>
                                    <span style="line-height: 1;"> En attente de <br/>validation</span>
                                </span>

                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

