import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { DemandeComponentsService, DetailDemandeinfos } from '../demande-components.service';
import { ActivatedRoute,  Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';
import { DEMANDEVALIDATORS } from 'src/app/shared/constants/demande-validators';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { ApplicationUserModel } from 'src/app/shared/models/simple-types/application-user.model';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { CycleDemandeModel } from 'src/app/shared/models/simple-types/cycle-demande.model';
import { CycleDemandeService } from 'src/app/shared/services/simple/cycle-demande.service';
import { AddCommentModalComponent } from 'src/app/shared/components/modals/add-comment/add-comment-modal.component';
import { DemandeValidationModel } from 'src/app/shared/dtos/demandeValidationModel';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';
import { ModalInfoOkComponent } from 'src/app/shared/components/modals/info-ok/modal-info-ok.component';
import { CustomValidators } from 'src/app/shared/constants/util';
import { TypeDemande } from 'src/app/shared/constants/typeDemande';


@Component({
  selector: 'app-edit-demande',
  templateUrl: './edit-demande.component.html',
  styleUrls: ['./edit-demande.component.scss']
})
export class EditDemandeComponent implements OnInit {

  collabApplications: ApplicationUserModel[] = [];
  selectedApplications: ApplicationModel[] = [];
  typeDemande: string;
  demandeForm: UntypedFormGroup;
  currentAgence: AgenceModel;
  demande: DemandeModel = new DemandeModel();
  detailDemandeInfo: DetailDemandeinfos = new DetailDemandeinfos();
  observableDemande = new BehaviorSubject<DemandeModel>(new DemandeModel());
  selectedAgence: AgenceModel;
  idCollbaorateur: number;
  collaborateur = new CollaborateurModel();
  allApplications : ApplicationModel[] = [];
  demandeLinesKeys = Object.keys;

  cyclesValidation: CycleDemandeModel[] = [];

  cds = false;
  titre = 'ATTENTION : Transformer un compte workspace en compte Gdrive seul (Cloud identity) peut engendrer une perte de données !';
  message = `
 <p>Rappel restrictions du compte :</p>
  <ul>
    <li>Mon Drive Limite 15Go</li>
    <li>Drives partagés en lecture seulement, pas de création possible</li>
    <li>Droit “Editeur” par document dans les drives partagés</li>
    <li>Impossible de partager des documents hors Veolia</li>
  </ul>`;
  constructor(
    private demandeService: DemandeService,
              private router: Router,
              private applicationService: ApplicationService,
              private demandeComponentsService: DemandeComponentsService,
              private route: ActivatedRoute,
              private toastrService: ToastrService,
              private title: Title,
              private agenceService: AgenceService,
              private collabService: CollaborateurService,
              private cycleDemandeService: CycleDemandeService,
              public dialog: MatDialog) {
      this.route.params.subscribe((params: any) => {
        if (params.id === '0') {
          this.demandeService.demandeSubscriber.subscribe((demande: DemandeModel) => {
            if (demande !== null) {
              this.demande = Object.assign({}, demande);
            } else {
              this.toastrService.error('Erreur durant la récupération de la demande.', 'Erreur');
              this.router.navigate(['/']);
            }
          });
        } else {
          this.demandeService.get(params.id).subscribe(demande => {
            this.demande = demande;
            this.title.setTitle('GUS - Brouillon demande ' + this.demande.code);
            switch (this.demande.typeDemande) {
              case 0:
                this.typeDemande = 'Création';
                this.demandeForm = this.demandeComponentsService.getEditDemandeFormGroup(this.demande, DEMANDEVALIDATORS.DEMANDEADD);
                break;
              case 1:
                this.typeDemande = 'Modification';
                this.demandeForm = this.demandeComponentsService.getEditDemandeFormGroup(this.demande, DEMANDEVALIDATORS.DEMANDEUPDATE);
                this.cds = demande.cds ? true : false;

                // On supprime la deuxième partie du commentaire
                let tempCommentaire = this.demandeForm.get('commentaire').value
                const indexFinCom = tempCommentaire.indexOf("Les Modifications suivantes ont été réalisées");
                if (indexFinCom !== -1) {
                  tempCommentaire = tempCommentaire.substring(0, indexFinCom);              
                }
                this.demandeForm.get('commentaire').setValue(tempCommentaire);

                this.collabService.get(this.demande.idCollaborateur).subscribe((collab: CollaborateurModel) => {
                  if(collab){
                    this.collabApplications = collab.applications;
                  } 
                });
                break;
              case 2:
                this.typeDemande = 'Suppression';
                this.demandeForm = this.demandeComponentsService.getEditDemandeFormGroup(this.demande, DEMANDEVALIDATORS.DEMANDEDELETE);
                this.collabService.get(this.demande.idCollaborateur).subscribe((collab: CollaborateurModel) => {
                  if(collab){
                    this.collabApplications = collab.applications;
                  }
                });
                break;
            }
            this.detailDemandeInfo = new DetailDemandeinfos();
            if(demande.typeContrat === "CDI"){
              this.demandeForm.get('dateFin').setValidators([]);
              this.demandeForm.get('dateFin').disable();
              this.demandeForm.get('dateFin').setValue(null);
            }else {
              this.demandeForm.get('dateFin').setValidators([Validators.required, CustomValidators.dateFinValidator]);
              this.demandeForm.get('dateFin').enable();
            }
            this.demandeForm.get('adresseAgence').setValue(demande.agence.adresse);
            this.demandeForm.get('numeroAgence').setValue(demande.agence.telephone);
            this.agenceService.get(this.demande.idAgence).subscribe((res) => this.demande.agence = res);
            this.observableDemande.next(this.demande);

            // get cycles by demande ID
          this.cycleDemandeService.getAllFromDemande(this.demande.id).subscribe(res => {
            this.cyclesValidation = res;            
          })
          }, (error) => {
            toastrService.error('La demande n\'existe pas');
            this.router.navigate(['/not-found']);
          });
        }
      });
  }

  ngOnInit(): void {
    this.verifyDemandeStatusToRedirect();
    this.applicationService.getAll().subscribe((apps: ApplicationModel[]) => {
      this.allApplications = apps;
    });
  }

  verifyDemandeStatusToRedirect(): void {
    if (this.observableDemande) {
      this.observableDemande.subscribe((demande: DemandeModel) => {
        if (demande) {
          if (demande.statusNum !== 1) {
            this.router.navigate(['/']);
            this.toastrService.warning('Cette demande n\'est pas en brouillon.', 'Erreur');
          }
        }
      });
    }
  }

  setValuesToDemande(): void {
    let tmpDateDebut = this.demande.dateDebut;
    this.demande = this.demandeForm.getRawValue();
    if(this.demande.dateFin != null)
      this.demande.dateFin =  new Date(new Date(this.demande.dateFin).getTime() - new Date(this.demande.dateFin).getTimezoneOffset() * 60000);
    if(this.demande.dateDebut != tmpDateDebut)
      this.demande.dateDebut =  new Date(new Date(this.demande.dateDebut).getTime() - new Date(this.demande.dateDebut).getTimezoneOffset() * 60000);
    this.demande.applications = (this.demandeForm.get('applications') as UntypedFormArray).getRawValue();
   // this.demande.idAgence = this.currentAgence.id;
    this.demande.code = this.demandeForm.controls.code.value;
    this.demande.agence = this.currentAgence;
  }


  memorize(): void {
    if (this.demandeForm.valid) {
      if (this.demandeForm.get('email').value) {
        if (!this.demandeForm.get('idCollaborateur').value && this.demandeForm.get('typeDemande').value !== 0) {
          this.toastrService.warning('Le collaborateur est obligatoire');
        }else {
          if(this.demandeForm.get('typeDemande').value === TypeDemande.Modification && this.demandeForm.get('cds').value === true && this.cds === false){
            const dialogRef = this.dialog.open(ModalInfoOkComponent, {
              data: {
                title: this.titre,
                type: 'Demande',
                message: this.message,
                icone: 'warning',
                iconColor: 'warn',
                color: 'success',
                showConfirmation: true,
              },
              autoFocus: false,
              maxHeight: '90vh',
              maxWidth: '100vh'

            });
            dialogRef.afterClosed().subscribe(res => {
              if (res){
                this.memorizeDemande();
              }
            });
          } else {
            this.memorizeDemande();
          } 
        }
      } else {
        this.toastrService.warning('Le champ email est obligatoire');
      }
    } else {
      this.displayErrors();
    }
  }

  memorizeDemande() {
    this.setValuesToDemande();
      const dialogRef = this.dialog.open(SpinnerModalComponent);
      this.demandeComponentsService.memorizeDemande(this.demande).subscribe((data: any) => {
        this.demande = data;
        dialogRef.close();
        this.router.navigate(['list-demande/']);
      }, (error) => dialogRef.close());
  }

  displayErrors(): void {
    for (const demande in this.demandeForm.controls) {
      if (demande) {
        this.demandeForm.controls[demande].markAsTouched();
      }
    }
  }
  
  close(): void {
    this.router.navigate(['/list-demande/']);
  }
  ngOnDestroy(): void {
    this.demandeComponentsService.purgeDetailDemandeInfo();
  }

  annuler(): void {
    const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
      data: {
        title: 'Êtes-vous sûr de vouloir annuler cette demande',
        color: 'danger',
        icon_color: 'accent',
       
      }
    });
    dialogConfirmation.afterClosed().subscribe(res => {
      if(res || res === '') {
        const obj = new DemandeValidationModel(this.demande);
        obj.commentaire = res;
         this.demandeComponentsService.annulerDemande(obj).subscribe(x => {
           this.router.navigate(['/list-demande/']);
         });  
      }
    });
  }
}

