<mat-card [ngStyle]="{'border': '5px solid '+ borderColor}">
    <mat-card-content>
        <div *ngIf="form">
            <div class="row" style="padding: 0px;"  [formGroup]="form">
                <div class="col-sm-5" style="padding: 0px;">
                    <div class="header-class">
                        Demande
                    </div>
                    <div class="data-class row">
                        <div class="col-sm-6">
                            <mat-form-field class="full-width">
                                <mat-label>Nom</mat-label>
                                <input matInput name="gdso-nom-demande" formControlName="nom" [value]="onNomChange()" required/>
                                <mat-error *ngIf="form.get('nom').hasError('required')">
                                    Le nom est obligatoire.
                                </mat-error>
                                <mat-error *ngIf="form.get('nom').hasError('maxlength')">
                                    Maximun 30 caractères.
                                </mat-error>
                                <mat-error *ngIf="form.get('nom').hasError('pattern')">
                                    Le format du nom est incorrect.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        
                        
                        <div class="col-sm-6">
                            <mat-form-field class="full-width">
                                <mat-label>Prénom</mat-label>
                                <input matInput name="gdso-prenom-demande" formControlName="prenom" [value]="onNameChange()" required/>
                                <mat-error *ngIf="form.get('prenom').hasError('required')">
                                    Le prenom est obligatoire.
                                </mat-error>
                                <mat-error *ngIf="form.get('prenom').hasError('maxlength')">
                                    Maximun 30 caractères.
                                </mat-error>
                                <mat-error *ngIf="form.get('prenom').hasError('pattern')">
                                    Le format du prénom est incorrect.
                                </mat-error>
                            </mat-form-field>
                        </div>
                       
                        <div class="col-sm-12">
                            <mat-form-field matTooltip="Email du collaborateur"  class="full-width">
                                <mat-label>Adresse email</mat-label>
                                <input matInput name="gdso-email-demande" formControlName="email" required/>
                                <mat-error *ngIf="form.get('email').hasError('required')">
                                    L'email est obligatoire.
                                </mat-error>
                                <mat-error *ngIf="form.get('email').hasError('maxlength')">
                                    Maximun 120 caractères.
                                </mat-error>
                                <mat-error *ngIf="form.get('email').hasError('pattern')">
                                    L'email est incorrect.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12">
                            <app-autocomplete-agence
                              name="agence"
                              ngDefaultControl
                              [required]="true"
                              [libelle]="'Agence'"
                              [agences]="agences"
                              [agenceId]="form.get('idAgence').value"
                              [disabled]="form.get('idAgence').disabled"
                              (agenceChange)="onChangeAgence($event)">
                            </app-autocomplete-agence>
                          </div>
                         
                          <div class="col-sm-12">
                            <mat-form-field matTooltip="Le numero standard de l\'agence"  class="full-width">
                                <mat-label>Numero standard de l'agence</mat-label>
                                <input matInput name="gdso-numero-demande" formControlName="numeroAgence"/>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-12">
                            <mat-form-field matTooltip="L\'adresse de l\'agence"  class="full-width">
                                <mat-label>Adresse de l'agence</mat-label>
                                <input matInput name="gdso-adresse-demande" formControlName="adresseAgence"/>
                            </mat-form-field>
                          </div>
                        <div class="col-sm-12">
                            <mat-form-field matTooltip="Exemple : +33123456789 / 0123456789"  class="full-width">
                                <mat-label>Téléphone</mat-label>
                                <input matInput name="gdso-tel-demande" formControlName="tel"/>
                                <mat-error *ngIf="form.get('tel').hasError('pattern')">
                                    Le format du téléphone est incorrect.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-sm-7" style="padding: 0px;">
                    <div class="header-class">
                        Observation
                    </div>
                    <div class="data-class row">
                        <div class="col-sm-6" style="padding: 0px;">
                            <div class="col-sm-12" *ngIf="form.get('login').value !== '' && form.get('login').value">
                                <mat-form-field matTooltip="Code sarp du collaborateur"  class="full-width">
                                    <mat-label>Code sarp</mat-label>
                                    <input matInput name="gdso-login-demande" formControlName="login" />
                                </mat-form-field>
                            </div> 
                            <div class="col-sm-12">
                                <app-autocomplete-user [userId]="form.get('idResponsableCollaborateur').value"
                                (updated)="onFiltreChange($event, 'Responsable')" 
                                libelle="Responsable" 
                                [matTooltip]="'Ce champ permet de spécifier le responsable hiérarchique du collaborateur de la demande. Lorsque la demande est traitée, le responsable sera automatiquement notifié par e-mail de la fin du traitement.'"
                                name="user"
                                [disabled]="form.get('idResponsableCollaborateur').disabled"
                                [parentForm]="form">
                                </app-autocomplete-user>
                            </div>
                            <div class="col-sm-12">
                                <mat-form-field matTooltip="Type contrat du collaborateur"  class="full-width">
                                    <mat-label>Type contrat</mat-label>
                                    <mat-select formControlName="typeContrat" name="gdso-typeContrat-demande" required>
                                        <mat-option [value]="type" *ngFor="let type of typesContrat">{{type}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="form.get('typeContrat').hasError('required')">
                                        La type de contrat est obligatoire.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12">
                                <mat-form-field matTooltip="Date de debut du contrat"  class="full-width">
                                    <mat-label>Date de debut</mat-label>
                                    <input matInput [matDatepicker]="pickerD" name="gdso-date-debut" formControlName="dateDebut">
                                    <mat-datepicker-toggle matSuffix [for]="pickerD"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerD></mat-datepicker>
                                    <mat-error *ngIf="form.get('dateDebut').hasError('required')">
                                        La date de debut est obligatoire.
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field matTooltip="Date de fin du contrat"  class="full-width">
                                    <mat-label>Date de fin</mat-label>
                                    <input matInput [matDatepicker]="pickerF" [min]="form.get('dateDebut').value" name="gdso-date-fin" formControlName="dateFin" required>
                                    <mat-datepicker-toggle matSuffix [for]="pickerF"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerF></mat-datepicker>
                                    <mat-error *ngIf="form.get('dateFin').hasError('required')">
                                        La date de fin est obligatoire.
                                    </mat-error>
                                </mat-form-field>
                            </div> 
                           
                        </div>
                        <div class="col-sm-6" style="padding-left: 0px;padding-top: 0.6em;">
                            <mat-form-field class="full-width">
                                <mat-label style="margin-left: 5px;">Commentaire</mat-label>
                                <textarea matInput name="gdso-commentaire" cdkTextareaAutosize cdkAutosizeMinRows="10.5"
                                    cdkAutosizeMaxRows="11.5" style="background-color: white;"
                                    formControlName="commentaire"></textarea>
                                <mat-error *ngIf="form.get('commentaire').hasError('maxlength')">
                                    Nombre maximum de caractère dépassé.
                                </mat-error>
                            </mat-form-field>
                                   
                            <div class="col-sm-12 cds" >
                                <label class="container">Compte GDrive seul (Cloud Identity)
                                    <mat-icon matTooltip="Une fois coché, la demande est pour créer un compte opérateur qui permet uniquement 
                                    d’avoir accès à Google Drive et télécharger les applications mobiles comme TrackDéchet mobile, Praxedo mobile, Skillup…">info</mat-icon>
                                    <input type="checkbox" formControlName="cds" (click)="isCdsChecked()">
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <div class="col-sm-12" *ngIf="this.form.get('dDesactivation').value != null && this.form.get('typeDemande').value !== 0" style="display: flex;align-items: center;">
                                <mat-icon [ngStyle]="{'color':'#b51621'}" style="margin-right: 13px;">cancel</mat-icon>
                                <div style="color: #b51621; font-weight: bolder;">Compte desactivé</div>
                            </div>

                            <div class="col-sm-12" *ngIf="this.form.get('dDesactivation').value == null && this.form.get('typeDemande').value !== 0" style="display: flex;align-items: center;">
                                <mat-icon [ngStyle]="{'color':'#17910c'}" style="margin-right: 13px;">check_circle</mat-icon>
                                <div style="color: #17910c; font-weight: bolder;">Compte actif</div>
                            </div>
                           
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </mat-card-content>
</mat-card>
