import { AbstractControl, ValidationErrors } from '@angular/forms';

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
    if (abstractControl && abstractControl.validator) {
        const validator = abstractControl.validator({}as AbstractControl);
        if (validator && validator.required) {
        return true;
        }
    }
    return false;
};

export class CustomValidators {
    static dateFinValidator(control: AbstractControl): ValidationErrors | null {
      const dateFin = new Date(control.value);
      const today = new Date();
      today.setHours(0, 0, 0, 0); 
  
      if (dateFin < today) {
        return { dateFinInvalide: true };
      }
      return null;
    }
  }

export const roundToDecimal = (nb: number, decimal: number): string => {
    const power = Math.pow(10, decimal);
    return (Math.round(nb * power) / power).toFixed(decimal);
};

