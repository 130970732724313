import { BasicModel } from "../basic/basic.model";
import { RegionModel } from "./region.model";
import { SocieteModel } from "./societe.model";

export class EntiteSARPModel extends BasicModel {
    public code: string;
    public libelle: string;
    public type?: string;
    public groupe_win: string;
}

export class EntiteSARPNode extends BasicModel {
    public libelle: string;
    public code: string;
    public region: RegionModel;
    public societeLibelle: string;
    public societeId?: number;
    public societeObj: SocieteModel;
    public type: string;
    public childrens?: EntiteSARPNode[];
    public visualiseDemande:  boolean;
    public createDemande:boolean;
    public valid1:boolean;
    public valid2:boolean;
    public usersManager: boolean;
    public repositoriesManager:boolean;
}
