import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalInfoOkComponent } from 'src/app/shared/components/modals/info-ok/modal-info-ok.component';
import { SimpleQueryResponse } from 'src/app/shared/dtos/simpleQueryResponse';
import { SimpleQueryUser } from 'src/app/shared/dtos/simpleQueryUser';
import { ReferentielComponentsService } from '../referentiel-components.service';
import { SocieteModel } from 'src/app/shared/models/rsa/societe.model';
import { SocieteService } from 'src/app/shared/services/rsa/societe.service';
import { UntypedFormGroup } from '@angular/forms';
import { ModalAddSocieteComponent } from 'src/app/shared/components/modals/modal-add-societe/modal-add-societe.component';

@Component({
  selector: 'app-gestion-societe',
  templateUrl: './gestion-societe.component.html',
  styleUrls: ['./gestion-societe.component.scss']
})
export class GestionSocieteComponent implements OnInit {
  societes: SocieteModel[] = [];
  displayedColumns: string[] = ['code', 'libelle', 'siret', 'active', 'action'];
  dataSource: SocieteModel[];
  matDataSource = new MatTableDataSource<SocieteModel>();
  sortState: Sort = {active: 'Code', direction: 'desc'};
  length: number;
  pageSize = 25;
  pageEvent: PageEvent;
  result: boolean;
  objet: SimpleQueryUser = new SimpleQueryUser();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private societeService: SocieteService,
    private gestionReferentielComponent: ReferentielComponentsService
    ) { }

  ngOnInit(): void {
    this.objet.query = '';
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.objet.order = 'Code';
    this.matDataSource.paginator = this.paginator;
    this.loadPage();
  }

  onCreate(): void {
    const form = this.gestionReferentielComponent.getCreateSocieteFormGroup();
    this.openModal(form);
  }

  onModify(societe: SocieteModel): void {
    const form = this.gestionReferentielComponent.getEditSocieteFormGroup(societe);
    this.openModal(form);
  }

  openModal(form: UntypedFormGroup): void{
    const dialogRef = this.dialog.open(ModalAddSocieteComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        form,
        result: this.result
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadPage();
      }
    });
  }

  onDelete(societe: SocieteModel): void {
    var message = this.getAgencesActive(societe);
    if(message !== ""){
      const dialogRef = this.dialog.open(ModalInfoOkComponent, {
        data: {
          title: 'Impossible de désactiver cette société car les agences suivantes sont actives',
          message: message,
          icone: 'warning_amber',
          iconColor: 'accent',
          color: 'accent'
        }
      });
    }else {
      const dialogRef = this.dialog.open(ModalInfoOkComponent, {
        data: {
          title: 'désactivation de la société ' + societe.libelle,
          message: 'Êtes-vous sur de vouloir désactiver la société ?',
          icone: 'warning_amber',
          iconColor: 'accent',
          color: 'accent'
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.societeService.desactivation(societe.id, societe).subscribe((response: SocieteModel) => {
            this.toastrService.success('La societe a bien été désactivé');
            this.loadPage();
          });
        }
      });
    }
    
  }

  getAgencesActive(societe: SocieteModel): string{
    var agencesActive = "";
    for (const agence of societe.agences) {
      if(!agence.dDesactivation){
        agencesActive += agence.libelle + ", "
      }
    }
    return agencesActive;
  }


  orderUsers(societes: SocieteModel[]): SocieteModel[] {
    societes = societes.sort((a, b) => {
      if (a.code) {
        return a.code.localeCompare(b.code);
      } else {
        return -1;
      }
    });
    return societes;
  }

  changePage(event?: PageEvent): void {
    this.objet.pageNumber =  event.pageIndex;
    this.objet.pageSize = event.pageSize;
    this.loadPage();
  }

  search(): void {
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.paginator.pageIndex = 0;
    this.loadPage();
  }

  loadPage(): void {
    var referentiel = new SimpleQueryUser();
    referentiel = this.objet;
    this.societeService.loadFromSimpleQueryRef(referentiel).subscribe((query: SimpleQueryResponse<SocieteModel>) => {
      if (query.objets != null) {
        this.societes = query.objets;
      } else {
        this.societes = [];
      }
      this.length = query.count;
      this.matDataSource = new MatTableDataSource<SocieteModel>(this.societes);
      this.paginator.length = this.length;
    });
  }

  close(): void {
    this.router.navigate(['/gestion-referentiel']);
  }

  sortData(sort: Sort): void {
    if (sort.direction !== 'asc') {
      this.objet.descending = false;
    } else {
      this.objet.descending = true;
    }
    this.sortState = sort;
    this.objet.order = sort.active;
    this.loadPage();
  }

}

