<mat-card id="heigthChild"> 
    <mat-card-content>
        <div id="cycleDemandes">
            <div *ngIf="cycles && cycles.length !== 0"  class="my-row">
                <div class="row">
                    <div class="col-sm-12">
                        <span style="font-weight: bold;">{{title}}
                        </span>
                    </div>
                </div>
                <div class="row" *ngFor="let item of getSortedArrayByDate(); let fst = first;let lst = last;">
                    <div class="row" style="width: 100%;">
                        <div class="col-sm-4">
                            <span> {{item.dAction| date:'dd/MM/yyyy HH:mm'}}</span>
                        </div>
                    <div  class="col-sm-2"></div>
                        <div class="col-sm-3">
                            <span matTooltip="{{item.user.prenom}} {{item.user.nom}}"> 
                                {{item.user.prenom.substring(0,1).toUpperCase()+item.user.nom.substring(0,2).toUpperCase()}}
                            </span>
                        </div>
                        <div class="col-sm-2" *ngIf="item.libelle">
                            <mat-icon matTooltip="{{item.libelle}}"> format_quote</mat-icon> 
                        </div>
                        <div class="col-sm-12" *ngIf="fst">
                            <span *ngIf="item.libelle.length>1">{{item.libelle}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </mat-card-content>
</mat-card>