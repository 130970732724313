import { ModalAnnulationConfirmationComponent } from './../../../../shared/components/modals/modal-annulation-confirmation/modal-annulation-confirmation.component';
import { SimpleQueryResponse } from './../../../../shared/dtos/simpleQueryResponse';
import { FilterDemandeAgenceDTO } from './../../../../shared/dtos/filterDemandeAgenceDTO';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { UtilisateurModel } from 'src/app/shared/models/utilisateur/utilisateur.model';
import { DemandeAgenceModel } from 'src/app/shared/models/agence-linked/demande/demande-agence.model';
import { DemandeAgenceService } from 'src/app/shared/services/agence-linked/demande-agence.service';
import { ModalUpdateConfirmationAgenceComponent } from 'src/app/shared/components/modals/modal-update-confirmation-agence/modal-update-confirmation-agence.component';
registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-demande-agence-list',
  templateUrl: './demande-agence-list.component.html',
  styleUrls: ['./demande-agence-list.component.scss']
})
export class DemandeAgenceListComponent implements OnInit, OnChanges {
  // tslint:disable-next-line: max-line-length
  displayedColumnsDemande: string[] = ['numero','typeDemande', 'agence','region','societe','statut','dModification', 'retour'];
  // tslint:disable-next-line
  @Input() ifListAValide: boolean = false;
  @Input() filtreData: FilterDemandeAgenceDTO;
  @Input() simpleQueryDemandeAgenceResponse: SimpleQueryResponse<DemandeAgenceModel> = null;
  matDataSourceDemande: MatTableDataSource<DemandeAgenceModel> = new MatTableDataSource<DemandeAgenceModel>();
  @Output() selectedRowsEmit = new EventEmitter<DemandeAgenceModel[]>();
  selectedDemandeAgences: DemandeAgenceModel[] = [];
  selection = new SelectionModel<DemandeAgenceModel>(true, []);
  length: number;
  pageIndex: number;
  pageSize = 25;
  sortState: Sort = { active: 'dModification', direction: 'asc' };
  dataSource: any[];
  users: UtilisateurModel[] = [];
  public width = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() datasource: DemandeAgenceModel[];


  constructor(
    public dialog: MatDialog,
    private router: Router,
    private demandeService: DemandeAgenceService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line: max-line-length
    if (changes.datasource && changes.datasource.currentValue && changes.simpleQueryDemandeAgenceResponse && changes.simpleQueryDemandeAgenceResponse.currentValue) {
      this.dataSource = changes.datasource.currentValue.slice();
      this.matDataSourceDemande = new MatTableDataSource(this.dataSource);
      this.length = this.simpleQueryDemandeAgenceResponse.count;
      this.pageIndex = this.simpleQueryDemandeAgenceResponse.pageNumber;
      this.pageSize = this.simpleQueryDemandeAgenceResponse.pageSize;
    }
    this.loadPage();
  }

  ngOnInit(): void {
    this.width = window.innerWidth; 
  }


  changePage(event?: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.filtreData.pageNumber = event.pageIndex;
    this.filtreData.pageSize = event.pageSize;
    this.loadPage();
  }

  trieTab(sort: Sort): void {
    if (sort.direction !== 'asc') {
      this.filtreData.descending = false;
    } else {
      this.filtreData.descending = true;
    }
    this.sortState = sort;
    this.filtreData.order = sort.active;
    this.loadPage();
  }

  loadPage(): void {
    if(this.filtreData) {
       this.demandeService.getFiltredDemande(this.filtreData).subscribe((data: SimpleQueryResponse<DemandeAgenceModel>) => {
    
      this.simpleQueryDemandeAgenceResponse = data;
      this.datasource = data.objets;
      this.length = this.simpleQueryDemandeAgenceResponse.count;
      this.pageIndex = this.simpleQueryDemandeAgenceResponse.pageNumber;
      // tslint:disable-next-line: max-line-length
      const datas = this.datasource.slice();
      this.matDataSourceDemande = new MatTableDataSource(datas.slice(0, this.pageSize));


      this.matDataSourceDemande.data.forEach(row => {
        // Lors du chargement des pages reselectionner les lignes 
        if (this.selectedDemandeAgences.find(line => line.id === row.id)) {
          this.selection.select(row);
        }
      });
    });
    }
   
  }

  editDemande(demande: DemandeAgenceModel): void {
    const dialogRef = this.dialog.open(ModalUpdateConfirmationAgenceComponent, {
      data: {
        title: 'Action sur la demande ' + demande.codeDemande,
        code: demande.codeDemande,
        type: 'Agence',
        statut: demande.status,
        message: 'Que souhaitez-vous réaliser sur cette demande ?',
        icone: 'warning_amber',
        iconColor: 'info',
        color: 'accent',
        idAgence: demande.idAgence,
        nivStatus: demande.statusNum,
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        switch (res) {
          case 'Voir': {
            this.router.navigate(['read-demande-agence/' + demande.id]);
            break;
          }
          case 'Valider': {
            this.router.navigate(['validate-demande-agence/' + demande.id]);
            break;
          }
          case 'Annuler': {
            const dialogConfirmation = this.dialog.open(ModalAnnulationConfirmationComponent, {
              data: {
                title: 'Annulation de la demande ' + demande.code,
                type: 'DemandeAgence',
                statut: demande.status,
                message: 'Souhaitez-vous annuler cette demande? ',
                icone: 'warning',
                iconColor: 'warn',
                color: 'danger',
                idAgence: demande.idAgence,
              }
            });
            return dialogConfirmation.afterClosed().subscribe((resultat) => {
              if (resultat) {
                /*this.demandeService.annulerDemande(demande).subscribe((otherdemande) =>
                {
                  this.datasource = this.datasource.filter(b => b.id !== otherdemande.id);
                  //if(otherdemande.status !== "Brouillon")
                  //  this.datasource.push(otherdemande);

                  // tslint:disable-next-line: max-line-length
                  const datas = this.datasource.slice();
                  this.matDataSourceDemande = new MatTableDataSource(datas.slice(0, this.pageSize));
                  this.toastr.success('Annulation de la demande ' + demande.code +  ' réussie');

                });*/
              }
            });
          }
        }
      }
    });
  }

  compareRow(row: DemandeAgenceModel): boolean {
    return false;
    /*if (row) {
      const currentDemandeAgence = this.selectedDemandeAgences.find(line => line.position === row.position && line.id === row.id);
      if (currentDemandeAgence && this.selectedDemandeAgences.length > 0) {
        for (const demande of this.selectedDemandeAgences) {
          if (demande) {
            if (demande.id === currentDemandeAgence.id) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }*/
  }

  reload(): void {
    this.matDataSourceDemande = new MatTableDataSource<DemandeAgenceModel>(this.dataSource);
    this.matDataSourceDemande.paginator = this.paginator;
    this.matDataSourceDemande.data.forEach(row => this.selection.select(row));
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
 /* masterToggle(): void {

    if (this.isAllSelected()) {
      this.deleteDemandeAgenceRows();
      this.selection.clear();
    }
    else {
      this.matDataSourceDemande.data.forEach(row => {
        // Si on ne trouve pas dans la sélection la ligne, on l'ajoute.
        if (!this.selectedDemandeAgences.find(line => line === row)) {
          this.selection.select(row);
          this.setRowToDemandeAgences(row);
        }
      });
    }
    this.selectedRowsEmit.emit(this.selectedDemandeAgences);
  }

  selectRow(row: DemandeAgenceModel): void {
    // On passe si la ligne est censée être désactivée
    this.selection.toggle(row);
    let currentDemandeAgence: DemandeAgenceModel;
    if (row) {
      currentDemandeAgence = this.selectedDemandeAgences.find(model => model === row);
    }
    if (!currentDemandeAgence) {
      this.setRowToDemandeAgences(row);
    } else {
      this.deleteDemandeAgenceRow(row);
    }
    this.selectedRowsEmit.emit(this.selectedDemandeAgences);
  }
*/
  /** Whether the number of selected elements matches the total number of rows. */
/*  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    if (numSelected === 0) {
      return false; // Si il n'y a pas de lignes sélectionnées, on retourne faux ici
    }
    // On compte le nombre de lignes qui ne sont pas désactivées, et on les compare au nb de lignes sélectionnées
    if (this.ifCreateBp) {
      let i = 0;
      for (const row of this.matDataSourceDemande.data) {
        
      }
      return numSelected === i;
    }
    return this.length === numSelected;
  }
*/
  /** The label for the checkbox on the passed row */
  /*checkboxLabel(row?: DemandeAgenceModel): string {
    if (!row) {
      return `${!this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    //return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }*/

  /* Remove all checked items in demandeArray  */
  deleteDemandeAgenceRows(): void {
    this.selectedDemandeAgences = [];
  }

  /* Remove item in demandeArray */
  deleteDemandeAgenceRow(row: DemandeAgenceModel): void {
    this.selectedDemandeAgences.splice(this.selectedDemandeAgences.findIndex(r => r === row), 1);
  }

  /* Add item into demandeArray */
  setRowToDemandeAgences(row: DemandeAgenceModel): void {
    this.selectedDemandeAgences.push(row);
  }

  /**
   * Vérifie si une ligne est à desactiver ou non
   * @param row La ligne à tester
   * @returns vrai si la ligne doit être désactivée
   */
  isRowDisabled(row: DemandeAgenceModel): boolean {
    return false;
    //return !(this.selectedDemandeAgences.length === 0 || this.selectedDemandeAgences.find(r => r.codeFourn === row.codeFourn && r.codeCa1 === row.codeCa1));
  }

}


