import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StateService } from 'src/app/shared/services/state.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { CycleDemandeService } from 'src/app/shared/services/simple/cycle-demande.service';
import { ModalInfoOkComponent } from 'src/app/shared/components/modals/info-ok/modal-info-ok.component';
import { DemandeComponentsService, DetailDemandeinfos } from '../../demande/demande-components.service';
import { DemandeAgenceModel } from 'src/app/shared/models/agence-linked/demande/demande-agence.model';
import { DEMANDEAGENCEVALIDATORS } from 'src/app/shared/constants/demande-validators';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { TypeDemande } from 'src/app/shared/constants/typeDemande';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';

@Component({
  selector: 'app-create-demande-agence',
  templateUrl: './create-demande-agence.component.html',
  styleUrls: ['./create-demande-agence.component.scss']
})
export class CreateDemandeAgenceComponent implements OnInit, OnDestroy {
  display = false;
  typeDemande: string;
  demandeForm: UntypedFormGroup;
  currentAgence: AgenceModel;
  demande: DemandeAgenceModel = new DemandeAgenceModel();
  detailDemandeInfo: DetailDemandeinfos = new DetailDemandeinfos();
  observableDemande = new BehaviorSubject<DemandeAgenceModel>(new DemandeAgenceModel());
  selectedAgence: AgenceModel;
  idAgence: number;
  agence = new AgenceModel();
  demandeLinesKeys = Object.keys;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private title: Title,
    private demandeComponentsService: DemandeComponentsService,
    private state: StateService,
    protected toastr: ToastrService,
    private route: ActivatedRoute,
    private agenceService: AgenceService,
    private cycleDemandeService: CycleDemandeService,
    private el: ElementRef) {
      this.route.queryParams
      .subscribe(params => {
        this.idAgence = params.idAgence;
        this.typeDemande = params.typeDemande;
      }
    );
      
  }

  ngOnInit(): void {
    this.observableDemande.subscribe(obj => {
      this.demande = obj;
      this.demande.agence = this.currentAgence;
      switch (this.typeDemande) {
        case 'création':
          this.demandeForm = this.demandeComponentsService.getCreateDemandeAgenceFormGroup(DEMANDEAGENCEVALIDATORS.DEMANDEADD);
          this.demandeForm.patchValue(this.demande);
          this.demandeForm.get('typeDemande').setValue(0);
          break;
        case 'modification':
          this.demandeForm = this.demandeComponentsService.getCreateDemandeAgenceFormGroup(DEMANDEAGENCEVALIDATORS.DEMANDEUPDATE);
          this.initWithCollabInfo();
          break;
        case 'suppression':
          this.demandeForm = this.demandeComponentsService.getCreateDemandeAgenceFormGroup(DEMANDEAGENCEVALIDATORS.DEMANDEDELETE);
          this.initWithCollabInfo();
          break;
      }
    });
    this.title.setTitle('GUS - Créer une demande agence');
  } 

  initWithCollabInfo(){
    this.agenceService.getForRefage(this.idAgence).subscribe((agence: AgenceModel) => {
      if(agence){
        this.currentAgence = agence;
        this.agence = agence;
        this.demande.idAgence = agence.id;
        this.demande.code = agence.code;
        this.demande.libelle = agence.libelle;
        this.demande.type = agence.type;
        this.demande.email = agence.email;
        this.demande.societeId = agence.societeId; 
        this.demande.regionId = agence.regionId;
        this.demande.societeId = agence.societeId;
        this.demande.adresse = agence.adresse;
        this.demande.codePostal = agence.codePostal;
        this.demande.ville = agence.ville;
        this.demande.telephone = agence.telephone;
        this.demande.siret = agence.siret;
        this.demande.dDesactivation = this.agence.dDesactivation;
        this.demande.codeConso = agence.codeConso;
        this.demande.idDirecteurAgence = agence.idDirecteurAgence;
        this.demande.idContact1 = agence.idContact1;
        this.demande.idContact2 = agence.idContact2;
        this.demande.infosDirecteurAgence = agence.infosDirecteurAgence;
        this.demande.infosContact = agence.infosContact;
        this.demandeForm.patchValue(this.demande);
        switch (this.typeDemande) {
          case 'modification':
            this.demandeForm.get('typeDemande').setValue(TypeDemande.Modification);
            break;
          case 'suppression':
            this.demandeForm.get('typeDemande').setValue(TypeDemande.Suppression);
            break;
        }
      }
    });
  }


  setValuesToDemande(): void {
    this.demande = this.demandeForm.getRawValue();
    this.demande.code = this.demandeForm.controls.code.value;
  }


  memorize(): void {
    if (!this.demandeForm.invalid) {
      this.setValuesToDemande();
        let dialogRefModifs;
        const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
        this.demandeComponentsService.getModificationsAgence(this.demande).subscribe((modifs: string) => {
          dialogRefSpinner.close();
          if (this.demandeForm.get('typeDemande').value === TypeDemande.Creation) {
            dialogRefModifs = this.dialog.open(ModalInfoOkComponent, {
              data: {
                title: 'Confirmez-vous la création de la demande ?',
                footer: 'Je déclare avoir fait attention à la séparation des taches',
                type: 'Demande',
                message: modifs,
                icone: 'warning',
                iconColor: 'warn',
                color: 'success',
              },
              autoFocus: false,
              maxHeight: '90vh'
            });
          } else if (this.demandeForm.get('typeDemande').value === TypeDemande.Suppression) {
            dialogRefModifs = this.dialog.open(ModalInfoOkComponent, {
              data: {
                title: 'Confirmez-vous la suppression de l\'agence ?',
                type: 'Demande',
                message: 'Confirmez-vous la demande de suppression de l\'agence '+ this.demandeForm.get('code').value + ' - ' + this.demandeForm.get('libelle').value,
                icone: 'warning',
                iconColor: 'warn',
                color: 'success',
              },
              autoFocus: false,
              maxHeight: '90vh'
            });
          } else if (this.demandeForm.get('typeDemande').value === TypeDemande.Modification) {
            dialogRefModifs = this.dialog.open(ModalInfoOkComponent, {
              data: {
                title: 'Confirmez-vous la demande de modification suivante ?',    
                footer:'Je déclare avoir fait attention à la séparation des taches',           
                type: 'Demande',
                message: modifs,
                icone: 'warning',
                iconColor: 'warn',
                color: 'success',
              },
              autoFocus: false,
              maxHeight: '90vh'
            });
          }
          dialogRefModifs.afterClosed().subscribe((success) => {
            if (success) {
              const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
              this.demandeComponentsService.memorizeDemandeAgence(this.demande).subscribe((data: any) => 
              {
                this.demande = data;
                dialogRefSpinner.close();
                this.router.navigate(['read-demande-agence/' + data.id]);
              }, (error) => dialogRefSpinner.close());  
            }
          });
        }, (error) => dialogRefSpinner.close());
    } else {
      this.displayErrors();
    }
  }

  displayErrors(): void {
    for (const demande in this.demandeForm.controls) {
      if (demande) {
        this.demandeForm.controls[demande].markAsTouched();
        this.display = true;
      }
    }
  }

  close(): void {
    this.router.navigate(['/gestion-agence/']);
  }

  ngOnDestroy(): void {
    this.demandeComponentsService.purgeDetailDemandeInfo();
  }

}

