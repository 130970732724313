import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserResponse } from 'src/app/shared/dtos/userResponse';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-authentification',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentification.component.scss']
})
export class AuthentificationComponent implements OnInit, OnDestroy {

  @Input() loginId: string;
  @Input() loginPwd: string;
  error = false;
  isLoggedInGoogle = false;
  isLoggedInApi = false;
  fatalError = false;
  returnUrl: string;
  private subscription: Subscription;

  private loggedIn: boolean;
  public user;
  firebaseToken: string;

  constructor(
    private srvSession: SessionService) {
    }

  ngOnInit() {
    this.subscription = this.srvSession.userResponseSubject.pipe(filter(user => user !== null)).subscribe((userR: UserResponse) => {
    });
    this.srvSession.errorSubject.pipe(filter(error => error !== null)).subscribe((error) => {
      if (error.status === 401) {
        this.error = true;
      } else {
        this.fatalError = true;
      }
    });
  }

  signOut(): void {
    this.srvSession.logout().subscribe();
    this.user = false;
    this.error = false;
    this.fatalError = false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
