<div>
    <div class="test" style="text-align: center">
      <div class="col-sm-12">
        <h3>{{title}}</h3>
      </div>
           
      <div class="col-sm-12" [formGroup]="commForm">
        <mat-icon class="col-sm-2" style="bottom: auto;" [color]="icon_color">{{icone}}</mat-icon>
        <mat-form-field class="full-width">

          <mat-label style="margin-left: 5px;">Commentaire</mat-label>
          <textarea [required]="required" matInput name="nth-ref-obs" cdkTextareaAutosize cdkAutosizeMinRows="8"
              cdkAutosizeMaxRows="10" style="background-color: white;"
              formControlName="commentaires"></textarea>
        </mat-form-field>
      </div> 
      <div class="col-sm-12">
        <mat-divider></mat-divider>
      </div>
      <div class="col-sm-12" style="margin-top: 20px; color: #df1e2b;" *ngIf="this.alertMessage != null">
        <h6>{{alertMessage}}</h6>
      </div>
      <div class="col-sm-12"  *ngIf="this.alertMessage != null">
          <app-historique-demandes [listDemande]="listDemande"></app-historique-demandes>
      </div>
      <div class="row">
        <div class="col-sm-6" style="margin-top: 40px;">
          <button mat-raised-button color="default" (click)="onModalCancel()">Annuler</button>
        </div>
        <div class="col-sm-6" style="margin-top: 40px;">
          <button mat-raised-button [color]="color" (click)="onModalConfirm()" >Confirmer</button>
        </div>
      </div>
      
    </div>
  </div>