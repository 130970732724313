import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { UtilisateurModel } from './../../../models/utilisateur/utilisateur.model';
import { ValidationErrors } from '@angular/forms';
import { SimpleQueryUser } from 'src/app/shared/dtos/simpleQueryUser';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ResponsableAppliModel } from 'src/app/shared/models/simple-types/responsable-appli.model';

@Component({
  selector: 'app-autocomplete-user',
  templateUrl: './autocomplete-user.component.html',
  styleUrls: ['./autocomplete-user.component.scss']
})
export class AutocompleteUserComponent implements OnInit, OnChanges {

  @Input() responsables: ResponsableAppliModel[];
  @Input() userId: number;
  @Input() disabled: boolean = false;
  @Input() required = false;
  @Input() libelle: string;
  @Input() matTooltip: string;
  @Input() parentForm: UntypedFormGroup;
  @Output() updated = new EventEmitter<number>();
  realisateursString: string[] = [];
  valideRealisateurString = "";
  filteredRealisateur: Observable<UtilisateurModel[]>;
  myControl = new UntypedFormControl('', [this.forbiddenNameValidator()]);
  @ViewChild('matInput') matInput: ElementRef<HTMLInputElement>;
  listResp: UtilisateurModel[];
  loading = false;
  resps: CollaborateurModel = new CollaborateurModel();
  firstInit: number = -1;
  constructor(private collabService: CollaborateurService,
    ) { }

  ngOnInit(): void {
    this.resps.id = 0;
    this.myControl.valueChanges.pipe(
      filter((searchValue: string) => {
        if(searchValue && searchValue.length > 2){
          return true;
        }else{
          this.listResp = [];
          return false;
        } 
      }),
      debounceTime(300),
      map((emittedValue : string) => {
        return emittedValue.trim();
      }),
      distinctUntilChanged(),
    ).subscribe((inputValue : string) => {
      this.rechercheResponsable(inputValue);
    });
   }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.disabled) {
      this.myControl.disable();
    }
    if(this.required) {
      this.myControl.setValidators([Validators.required, this.forbiddenNameValidator()]);
    }
    if (this.userId && this.firstInit !== this.userId) {
      this.firstInit = this.userId;
      this.collabService.get(this.userId).subscribe(res => {
        if (res){
          this.myControl.setValue(res.nom+ ' ' +res.prenom);
          this.valideRealisateurString = res.nom + ' ' + res.prenom;
          this.myControl.updateValueAndValidity(); 
        }
      });
    } else {
      if (this.firstInit !== -1 && !this.userId) {
        this.myControl.setValue("");
        this.myControl.updateValueAndValidity(); 
      }
    }
    if (this.parentForm && !this.parentForm.contains("stringresponsableCollaborateur")) {
      this.parentForm.addControl("stringresponsableCollaborateur", this.myControl);
    }

  }

  // Recherche dynamique 
  async rechercheResponsable(value: string)  {
    const user = new SimpleQueryUser();
    if (typeof(value) === 'string') {
      user.query = value;
     } else {
      user.query = this.getLibelleById(value);
     }
       // get les collab qui sont responsable sur aucune agence
     if(this.responsables){
      user.responsables = this.responsables;
     }
     this.collabService.getFastQuery(user).subscribe((resp: UtilisateurModel[]) => { 
        this.loading = true; 
        // supprimer les libelle null ou vide 
        this.listResp = resp.filter(lresp => (lresp.nom && lresp.nom !== '')); 
        this.loading = false; 
     }); 
   }

   getLibelleById(id: number): string {
    if(this.listResp){
     if (this.listResp.find(lresp => lresp.id === id) !== undefined) {
       return this.listResp.find(lresp => lresp.id === id).nom;
     }
    }
     this.resps.nom = '';
     this.resps.prenom = '';
     return '';
    }


  deleteIfEmptyOrNotFound(): void {
    this.myControl.setValue('');
    this.userId = null;
    this.valideRealisateurString = "";
    this.updated.emit(null);
  }

  /* Add or selected with enterKey */
  intervenantChange(value: UtilisateurModel): void {
    let user;
    if (!value) {
      user = null;
      this.userId = null;          
    } else {
        this.myControl.setValue(value.nom+ ' ' + value.prenom);
        this.valideRealisateurString = value.nom+ ' ' + value.prenom;
        this.myControl.updateValueAndValidity();
    }
    this.updated.emit(value.id);
    this.valideRealisateurString = value.nom + ' ' + value.prenom;

  }

  forbiddenNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      var forbidden = (control && control.value) ? this.valideRealisateurString !== control.value : this.valideRealisateurString != "";
      return forbidden ? {forbiddenName: {value: control.value}} : null;
    };
  }

  
}
