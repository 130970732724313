import { TypeDemande } from "src/app/shared/constants/typeDemande";
import { CollaborateurModel } from "../../utilisateur/collaborateur.model";
import { getStatusDemande, StatusDemande } from "src/app/shared/constants/status";
import { RegionModel } from "../../rsa/region.model";
import { CycleDemandeAgenceModel } from "../../simple-types/cycle-demande-agence.model";
import { BasicModel } from "../../basic/basic.model";
import { AgenceModel } from "../../rsa/agence.model";
import { SocieteModel } from "../../rsa/societe.model";

export class DemandeAgenceModel extends BasicModel {

    idAgence: number;
    agence: AgenceModel;

    codeDemande: string = "*";
    typeDemande: TypeDemande; // create type enumeration

    createur: CollaborateurModel;

    code: string;
    libelle: string;

    type = "Agence";

    public societeLibelle: string;
    public societeId?: number;
    public societeObj: SocieteModel;
    public region: RegionModel;
    public regionId: number;
    public adresse: string;
    public codePostal: string;
    public ville: string;
    public telephone: string;
    public email: string;
    public siret?: string;
    // nouveaux champs
    public codeConso: string;
    public idDirecteurAgence: string;
    public directeurAgence: CollaborateurModel;
    public idContact1: string;
    public contact1: CollaborateurModel;
    public idContact2: string;
    public contact2: CollaborateurModel;
    public infosDirecteurAgence: string;
    public infosContact: string;

    status: string = getStatusDemande(StatusDemande.Brouillon); // create status enumeration
    statusNum: number = 1;
    etat: number = 0;

    retour: boolean = false;
    annule: boolean = false;
    modification: string;

    demandeCycles: CycleDemandeAgenceModel[] = [];

}


