import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.scss']
})
export class SpinnerModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SpinnerModalComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }
}
