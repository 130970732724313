import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GlobalSettingsService } from 'src/app/core/gobal-settings.service';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { getStatusDemande, StatusDemande } from 'src/app/shared/constants/status';

export interface DialogData {
  icone: string;
  title: string;
  message: string;
  color: string;
  icon_color: string;
  dialogWidth: string;
  dialogHeight: string;
  type: string;
  statut: string;
  result: boolean;
  idAgence: number;
  nivStatus: number;
  bBloquer: boolean;
}

@Component({
  selector: 'app-modal-annulation-confirmation',
  templateUrl: './modal-annulation-confirmation.component.html',
  styleUrls: ['./modal-annulation-confirmation.component.scss']
})
export class ModalAnnulationConfirmationComponent implements OnInit {
    icone: string;
    title: string;
    message: string;
    color: string;
    iconColor: string;
    dialogWidth: string;
    dialogHeight: string;
    ValidationStatus: string[] = [
      getStatusDemande(StatusDemande.AValider)];
    currentRoute: any;
    constructor(public dialog: MatDialog,
                private toastr: ToastrService,
                public router: Router,
                private demandeService: DemandeService,
                public dialogRef: MatDialogRef<Component>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private globalSettingsService: GlobalSettingsService) {
    }
  
    ngOnInit(): void {
      this.currentRoute = this.router.url;
      this.icone = this.data.icone !== undefined ? this.data.icone : '';
      this.title = this.data.title !== undefined ? this.data.title : '';
      this.iconColor = this.data.icon_color !== undefined ? this.data.icon_color : '';
      this.dialogWidth = this.data.dialogWidth !== undefined ? this.data.dialogWidth : '';
      this.dialogHeight = this.data.dialogHeight !== undefined ? this.data.dialogHeight : '';
      this.message = this.data.message;
      this.color = this.data.color;
    }
  
    onModalCancel(): void {
      this.dialogRef.close(false);
    }
  
    onModalConfirm(): void {
      this.data.result = true;
      this.dialogRef.close(true);
    }
  
  }
  
