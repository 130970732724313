import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GenericCrudOperations } from './generic-crud-operations.interface';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { APICONSTANTS } from '../../constants/api-constants';
import { HostnameService } from './hostname.service';

export abstract class GenericCrudService<T> implements GenericCrudOperations<T> {

  constructor(
    protected http: HttpClient,
    protected toastrService: ToastrService,
    protected route: string,
    protected hostnameService: HostnameService
  ) {
    APICONSTANTS.URLAPI.next(this.hostnameService.getUrl());
  }

  save(t: T): Observable<T> {
    return this.http.post<T>(APICONSTANTS.URLAPI.value + this.route + 'Post/', t).pipe(catchError( err => this.requestCatchError(err, 'L\'enregistrement')));
  }

  update(id: number, t: T): Observable<T> {
    // tslint:disable-next-line: max-line-length
    return this.http.put<T>(APICONSTANTS.URLAPI.value + this.route + 'Update/' + id, t, {}).pipe(catchError( err => this.requestCatchError(err, 'Update')));
  }

  get(id: number): Observable<T> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<T>(APICONSTANTS.URLAPI.value + this.route + 'Get/' + id).pipe(catchError( err => this.requestCatchError(err, 'get')));
  }
  // Retourne Tous les collaborateurs (sur qui ont a les droits sur l'agence)
  getAll(): Observable<T[]> {
    return this.http.get<T[]>(APICONSTANTS.URLAPI.value + this.route + 'GetAll/').pipe(catchError( err => this.requestCatchError(err, 'GetAll')));
  }
  // Retourne Tous les collaborateurs (sans prendre en considération les droits sur l'agence)
  getAllForFiltre(): Observable<T[]> {
    return this.http.get<T[]>(APICONSTANTS.URLAPI.value + this.route + 'getAllForFiltre/').pipe(catchError( err => this.requestCatchError(err, 'getAllForFiltre')));
  }

  getAllbyId(request: string, id: number): Observable<T[]> {
    return this.http.get<T[]>(APICONSTANTS.URLAPI.value + this.route + request + id).pipe(catchError( err => this.requestCatchError(err, 'Le chargement')));
  }
  getAllFromObjet(request: string, objet: any): Observable<T[]> {
    return this.http.post<T[]>(APICONSTANTS.URLAPI.value + this.route + request, objet).pipe(catchError( err => this.requestCatchError(err, 'Le chargement')));
  }
  delete(id: number): Observable<T> {
    return this.http.delete<T>(APICONSTANTS.URLAPI.value + this.route + 'Delete/' + id).pipe(catchError( err => this.requestCatchError(err, 'La suppression')));
  }

  requestCatchError(error, origineError): Observable<any> {
    let errorMessage = '';
    if (error.status === 400) {
      this.toastrService.error(error.error, 'Erreur client');
      errorMessage = `Error: ${error.error.message}`;
      return throwError(errorMessage);
    }
    if (error.error instanceof ErrorEvent) {
        // client-side error
        this.toastrService.error(origineError + '  a échoué.', 'Erreur client');
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        this.toastrService.error(origineError + '  a échoué.', 'Erreur serveur');
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
