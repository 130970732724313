
    <form [formGroup]="selectedListForm" fxFlex fxLayout="column" autocomplete="off">
        <mat-form-field class="full-width" appearance="outline" style="margin-bottom: -1.34375em;">
            <mat-label>{{title}}</mat-label>
            <mat-select placeholder="{{title}}" formControlName="selectedList" multiple>      
                <mat-option #allSelected (click)="toggleAllSelection()" [value]="0" >Tous</mat-option>
                <mat-option *ngFor="let filters of inputList" [value]="filters.key" (click)="ClickPerOne()">
                    {{filters.value}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="selectedListForm.get('selectedList').hasError('required')">
                Veuillez selectionner une  {{title}}
            </mat-error>
        </mat-form-field>
    </form> 
