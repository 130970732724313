import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { DemandeComponentsService } from '../demande-components.service';
import { ExtendedTitleCasePipe } from 'src/app/shared/customPipes/titleCase-extended';
import { UpperCasePipe } from '@angular/common';


@Component({
  selector: 'app-demande-form',
  templateUrl: './demande-form.component.html',
  styleUrls: ['./demande-form.component.scss']
})
export class DemandeFormComponent implements OnChanges {
   // applications du collaborateur au quel il a les droits
  @Input() form: UntypedFormGroup;
  @Input() agence: AgenceModel;
  @Output() changeEmit = new EventEmitter<any>();
  typesContrat: string[] = ['CDI', 'Prestation', 'CDD / Stage / Alternance', 'Intérim'];
  agences: AgenceModel[] = [];
  regions: RegionModel[] = [];
  collabs: CollaborateurModel[] = [];
  change: any = null;
  idDemande: number;
  cdsCheck: boolean = false;
  isDisabled: boolean = false;
  isCds: boolean = true;
  borderColor: string;
  today = new Date();
  Validators = Validators;
  /* Prevent Saturday and Sunday for select. */
  weekendsDatesFilter = (d: Date): boolean => {
    if (d) {
      const day = d.getDay();
      return day !== 0 && day !== 6;
    }
  }

  constructor(
    public dialog: MatDialog,
    private agenceService: AgenceService,
    private collaborateurService: CollaborateurService,
    private demandeComponentsService: DemandeComponentsService,
    public router: Router) {
      if(window.location.href.includes("read-demande")){
        this.agenceService.getAll().subscribe(obj => {
          this.agences = obj;
        });
      }else {
        this.agenceService.getAllForDemande().subscribe(obj => {
          this.agences = obj;
        });
      }  
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.form && this.form.get('cds')) {
      this.cdsCheck = this.form.get('cds').value;
      this.demandeComponentsService.cdsCheck(this.cdsCheck);
    }

    if (this.form && this.form.get('id').value) {
      this.idDemande = this.form.get('id').value;
      this.cdsCheck = this.form.get('cds').value;
      this.isDisabled = this.form.get('dDesactivation').value;
      if(this.form.get('typeDemande').value === 1){
        this.isCds = this.form.get('cds').value;
        //!this.isCds ? this.form.get('cds').disable() : null;
      }
    } else if(this.form?.get('idCollaborateur').value != null){
      this.isCds = this.form.get('cds').value;
      //!this.isCds ? this.form.get('cds').disable() : null;
    }
    
    if(this.form && this.form.get('typeDemande').value != null){
      switch (this.form.get("typeDemande").value) {
        case 0:
          this.borderColor = '#3db67c';
          break;
        case 1:
          this.borderColor = '#f09727';
          break;
        case 2:
          this.borderColor = '#b51621bd';
          break;
      }
    }
  }

  onChangeAgence(agence: AgenceModel): void {
    if (agence) {
      this.agence = this.agences.find((soc: AgenceModel) =>  soc.id === agence.id);
      this.form.get('idAgence').setValue(agence.id);
      this.form.get('adresseAgence').setValue(agence.adresse + ", " + agence.ville + ", " + agence.codePostal);
      this.form.get('numeroAgence').setValue(agence.telephone);
      this.agence = agence;
    }else {
      this.agence = null;
      this.form.get('idAgence').setValue(-1);
      this.form.get('adresseAgence').setValue("");
      this.form.get('numeroAgence').setValue("");
    }
  }

  isCdsChecked() {  
    this.cdsCheck = !this.cdsCheck;   
    this.demandeComponentsService.cdsCheck(this.cdsCheck);
  }

  onFiltreChange(event, state): void {
    this.form.get('idResponsableCollaborateur').setValue(event);
  }

  onNameChange() {
    var pipe = new ExtendedTitleCasePipe();
    var val = this.form.get('prenom').value;
    this.form.get('prenom').setValue(pipe.transform(val),{onlySelf: true, emitEvent: false});
  }

  onNomChange() {
    var pipe = new UpperCasePipe();
    var val = this.form.get('nom').value;
    this.form.get('nom').setValue(pipe.transform(val),{onlySelf: true, emitEvent: false});
  }
}
