import { UserService } from 'src/app/shared/services/user.service';
import { TokenInterceptor } from './services/token-interceptor';
import { AddCommentModalComponent } from './components/modals/add-comment/add-comment-modal.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteUserComponent } from './components/autocomplete/autocomplete-user/autocomplete-user.component';
import { MaterialModule } from '../material/material.module';
import { ModalUpdateConfirmationComponent } from './components/modals/modal-update-confirmation/modal-update-confirmation.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { APP_DATE_FORMATS, CustomDateAdapter } from './custom-date-adapter';
import { TrieTabService } from './services/utilities/trie-tab.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalInfoOkComponent } from './components/modals/info-ok/modal-info-ok.component';
import { CurrencyPipe} from '@angular/common';
import { SelectAgenceModalComponent } from './components/modals/select-agence-modal/select-agence-modal.component';
import { FormDirective } from './formDirective';
import { SpinnerModalComponent } from './components/modals/spinner/spinner-modal.component';
import { DocumentService } from './services/simple/document.service';
import { FileUploadComponent } from './components/app-file-upload.component';
import { ModalChargementComponent } from './components/modals/modal-chargement/modal-chargement.component';
import { CustomSelectMultipleOptionComponent } from './components/select/custom-select-multiple-option/custom-select-multiple-option.component';
import { CacheService } from './services/cache.service';
import { InputTelephoneComponent } from './components/input/input-telephone/input-telephone.component';
import { WINDOW_PROVIDERS } from '../window.provider';
import { HostnameService } from './services/basic/hostname.service';
import { DroitEffectifService } from './services/simple/droit.service';
import { ModalAnnulationConfirmationComponent } from './components/modals/modal-annulation-confirmation/modal-annulation-confirmation.component';
import { DemandeService } from './services/agence-linked/demande.service';
import { AutoCompleteAgenceComponent } from './components/autocomplete/autocomplete-select/autocomplete.agence.component';
import { AgenceService } from './services/rsa/agence.service';
import { RegionService } from './services/rsa/region.service';
import { SocieteService } from './services/rsa/societe.service';
import { CollaborateurService } from './services/collaborateur.service';
import { AutocompleteSocieteComponent } from './components/autocomplete/autocomplete-societe/autocomplete-societe/autocomplete-societe.component';
import { AutocompleteRegionComponent } from './components/autocomplete/autocomplete-region/autocomplete.region.component';
import { ModalSelectCollaborateurComponent } from './components/modals/modal-select-collaborateur/modal-select-collaborateur.component';
import { ApplicationService } from './services/simple/application.service';
import { ModalUpdateCollaborateurComponent } from './components/modals/modal-update-collaborateur/modal-update-collaborateur.component';
import { FiltreRechercheCollaborateurComponent } from '../core/recherche/filtre-recherche-collaborateur/filtre-recherche-collaborateur/filtre-recherche-collaborateur.component';
import { ListCollaborateurComponent } from '../core/recherche/list-collaborateur/list-collaborateur/list-collaborateur.component';
import { ModalAddApplicationComponent } from './components/modals/modal-add-application/modal-add-application.component';
import { ModalAddResponsableComponent } from './components/modals/modal-add-responsable/modal-add-responsable.component';
import { ModalAddRegionComponent } from './components/modals/modal-add-region/modal-add-region.component';
import { ModalAddSocieteComponent } from './components/modals/modal-add-societe/modal-add-societe.component';
import { DemandeAgenceService } from './services/agence-linked/demande-agence.service';
import { CycleDemandeAgenceService } from './services/simple/cycle-demande-agence.service';
import { ModalUpdateConfirmationAgenceComponent } from './components/modals/modal-update-confirmation-agence/modal-update-confirmation-agence.component';
import { HistoriqueDemandesComponent } from '../core/user/historique-demandes/historique-demandes.component';
import { AutocompleteRegionMultipleSelectComponent } from './components/autocomplete/autocomplete-region-multiple-select/autocomplete-region-multiple-select.component';
import { AutocompleteAgenceMultipleSelectComponent } from './components/autocomplete/autocomplete-agence-multiple-select/autocomplete-agence-multiple-select.component';
import { ModalQuestionComponent } from './components/modals/modal-question/modal-question.component';
import { AutocompleteSocieteMultipleSelectComponent } from './components/autocomplete/autocomplete-societe-multiple-select/autocomplete-societe-multiple-select.component';
import { ExtendedTitleCasePipe } from './customPipes/titleCase-extended';
import { TableWithFilterComponent } from './components/table-with-filter/table-with-filter.component';
import { ResponsableAppliService } from './services/agence-linked/responsableAppli.service';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { GroupByPipe } from './customPipes/groupBy';

@NgModule({
  declarations: [
    GroupByPipe, 
    ExtendedTitleCasePipe,
    AutocompleteUserComponent,
    AutoCompleteAgenceComponent,
    AutocompleteRegionComponent,
    AutocompleteSocieteComponent,
    FileUploadComponent,
    SelectAgenceModalComponent,
    ModalUpdateConfirmationComponent,
    ModalSelectCollaborateurComponent,
    ModalInfoOkComponent,
    FormDirective,
    SpinnerModalComponent,
    AddCommentModalComponent,
    ModalChargementComponent,
    CustomSelectMultipleOptionComponent,
    ModalChargementComponent,
    InputTelephoneComponent,
    ModalAnnulationConfirmationComponent,
    SelectAgenceModalComponent,
    ModalUpdateCollaborateurComponent,
    FiltreRechercheCollaborateurComponent,
    ListCollaborateurComponent,
    ModalAddApplicationComponent,
    ModalAddResponsableComponent,
    ModalAddRegionComponent,
    ModalAddSocieteComponent,
    ModalUpdateConfirmationAgenceComponent,
    HistoriqueDemandesComponent,
    AutocompleteRegionMultipleSelectComponent,
    AutocompleteSocieteMultipleSelectComponent,
    AutocompleteAgenceMultipleSelectComponent,
    ModalQuestionComponent,
    TableWithFilterComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    MatDialogModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    GroupByPipe,
    ExtendedTitleCasePipe,
    AutocompleteUserComponent,
    AutoCompleteAgenceComponent,
    AutocompleteRegionComponent,
    AutocompleteSocieteComponent,
    FileUploadComponent,
    SelectAgenceModalComponent,
    FormDirective,
    SpinnerModalComponent,
    AddCommentModalComponent,
    ModalInfoOkComponent,
    ModalChargementComponent,
    CustomSelectMultipleOptionComponent,
    InputTelephoneComponent,
    SelectAgenceModalComponent,
    AutocompleteUserComponent,
    AutoCompleteAgenceComponent,
    AutocompleteRegionComponent,
    AutocompleteSocieteComponent,
    FiltreRechercheCollaborateurComponent,
    ListCollaborateurComponent,
    HistoriqueDemandesComponent,
    AutocompleteRegionMultipleSelectComponent,
    AutocompleteSocieteMultipleSelectComponent,
    AutocompleteAgenceMultipleSelectComponent,
    TableWithFilterComponent
  ],
  providers:  [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    DemandeService,
    DemandeAgenceService,
    CycleDemandeAgenceService,
    DocumentService,
    TrieTabService,
    UserService,
    CurrencyPipe,
    CacheService,
    WINDOW_PROVIDERS,
    HostnameService,
    DroitEffectifService,
    AgenceService,
    RegionService,
    SocieteService,
    CollaborateurService,
    ApplicationService,
    ResponsableAppliService
  ]
})
export class SharedModule { }
