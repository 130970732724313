import { APICONSTANTS } from '../../constants/api-constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { HostnameService } from '../basic/hostname.service';
import { CycleValidationDTO } from '../../dtos/cycleValidationDTO';
import { catchError } from 'rxjs/operators';
import { GenericCrudService } from '../basic/generic.crud.service';
import { CycleDemandeAgenceModel } from '../../models/simple-types/cycle-demande-agence.model';

@Injectable()
export class CycleDemandeAgenceService extends GenericCrudService<CycleDemandeAgenceModel> {
    constructor(http: HttpClient,
                protected toastrService: ToastrService,
                protected hostnameService: HostnameService) {
        super(http, toastrService, APICONSTANTS.ROUTECYCLEDEMANDEAGENCE, hostnameService);
  }
  getAllFromDemande(idDemande: number): Observable<CycleDemandeAgenceModel[]> {
    return this.getAllbyId('getFromDemande/', idDemande);
  }

  getLastValideurs(idDemande: number): Observable<CycleDemandeAgenceModel[]> {
    return this.getAllbyId('getLastValideurs/', idDemande);
  }

  getAllValidationFromDemande(idDemande: number, idAgence: number) {
    return this.http.get<CycleValidationDTO[]>(APICONSTANTS.URLAPI.value + this.route + 'getAllValidationFromDEMANDE?idDemande=' + idDemande + '&idAgence=' + idAgence)
    .pipe(catchError(err => this.requestCatchError(err, 'Cycles de validation')));
  }

}