<div class="col-md-12" style="display: flex; justify-content: flex-start; align-items: flex-start;">
    <div class="col-md-11 body-class">
      <h1>Gestion des agences</h1>
    </div>
    <div class="col-md-1 body-class" style="padding-top: 15px;">
      <button class="pull-right" color="warn" mat-raised-button type="button" (click)="close()">
        <mat-icon>cancel</mat-icon> Fermer
      </button>
    </div>
  </div>
  <div class="col-md-12" style="display: flex; justify-content: flex-start; align-items: flex-start;">
    <div class="col-md-8">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Rechercher une agence</mat-label>
        <input matInput  [(ngModel)]="objet.query" (change)="search()" (keydown.enter)="search()" placeholder="Nom de l'agence">
      </mat-form-field>
    </div>
    <div class="col-md-1 top">
      <button type="button" matTooltip="Créer une agence" [disabled]="onlyViewDroits"
      mat-raised-button color="primary" (click)="onCreate()" mat-icon-button><mat-icon>add</mat-icon></button>
    </div>
    <div class="col-md-1 top">
      <button mat-raised-button matTooltip="Rechercher une agence" color="primary" type="button" (click)="search()" style="color: white;"mat-icon-button><mat-icon>search</mat-icon></button>
    </div>
  </div>  
  <div class="col-md-12">
    <div class="mat-table">
      <div class="table_principale">
        <table mat-table matSort [dataSource]="matDataSource" multiTemplateDataRows class="mat-elevation-z8" (matSortChange)="sortData($event)" matSortActive="code">
          <caption> Liste des agences</caption>

          <ng-container matColumnDef="region">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Région </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> {{element.region ? element.region.libelle : ''}} </td>
          </ng-container>

          <ng-container matColumnDef="societe">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Société </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> {{element.societeObj ? element.societeObj.libelle : ""}} </td>
          </ng-container>

          <ng-container matColumnDef="code">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Code </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> {{element.code}} </td>
          </ng-container>

          <ng-container matColumnDef="codeConso">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Code Conso </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> {{element.codeConso}} </td>
          </ng-container>
      
          <ng-container matColumnDef="libelle">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Libelle </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> <div class="left">{{element.libelle}}</div> </td>
          </ng-container>

          <ng-container matColumnDef="cp">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Code postale </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> <div class="left">{{element.codePostal}}</div> </td>
          </ng-container>

          <ng-container matColumnDef="ville">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Ville </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> <div class="left">{{element.ville}}</div> </td>
          </ng-container>

          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef> Active </th>
            <td mat-cell *matCellDef="let element" (click)="onModify(element)"> 
              <div class="left" *ngIf="element.dDesactivation === null "><mat-icon class="green-icon">check</mat-icon></div> 
              <div class="left" *ngIf="element.dDesactivation !== null "><mat-icon class="red-icon">close</mat-icon></div> 
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></tr>
        </table>
      
        <div class="footer">
          <mat-paginator
            #paginator
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 25, 50]"
            (page)="changePage($event)">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
  