import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { RegionService } from 'src/app/shared/services/rsa/region.service';

@Component({
  selector: 'app-autocomplete-region-multiple-select',
  templateUrl: './autocomplete-region-multiple-select.component.html',
  styleUrls: ['./autocomplete-region-multiple-select.component.scss']
})
export class AutocompleteRegionMultipleSelectComponent implements OnInit {
  @Input() regionsIdsArray;
  @Input() firstLoadId: any;
  @Input() untouchedAllRegions : RegionModel[];


  _val: BehaviorSubject<Set<number>>;
  @Input()
  set eventsSubjectRegionArray(val: BehaviorSubject<Set<number>>) {
    this._val = val;
  }
  
  
  @Output() updated = new EventEmitter<number[]>();
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  regionCtrl = new UntypedFormControl();
  filteredRegions: Observable<string[]>;




// Input / Output
// toute les regions
  selectableRegionsString: string[] = [];
  allRegions: RegionModel[] = [];
  selectedRegionString: string[] = [];

  inputRegionArray = new Set();
  eventsSubscription: Subscription;

  
 
  
  @ViewChild('regionInput', { static: true }) regionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor(    private regionService: RegionService,
    ) {
   }

   ngOnDestroy(){
    this.eventsSubscription.unsubscribe()
   }

   ngOnInit() {
    this.loadRegions(Object.assign([],this.untouchedAllRegions));

    this.eventsSubscription = this._val.subscribe((x) => {
      this.inputRegionArray = x;
      this.selectableRegionsString  = [];
      this.allRegions = [];
      if(x.size == 0){
        this.selectedRegionString = [];
      }
        this.loadRegions(Object.assign([],this.untouchedAllRegions));
   });


  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectableRegionsString  = Object.assign([],[]);
    this.allRegions = Object.assign([],[]);
    this.selectedRegionString = Object.assign([],[]);
    this.loadRegions(Object.assign([],this.untouchedAllRegions));
  }

  /* Charger les regions  */
  loadRegions(regions: RegionModel[]) {
    this.allRegions = regions;
    this.regionsIdsArray = [...this.inputRegionArray];
    if(this.regionsIdsArray.length != 0){
      this.regionsIdsArray.forEach(idInput=>{
        if(regions.find(region=>region.id ==idInput) != undefined){
          let regionFound = regions.find(region=>region.id ==idInput)
          if(!this.selectedRegionString.find(libelle => libelle == this.regionName(regionFound))){
            this.selectedRegionString.push(this.regionName(regionFound))
          }
        }
      })
      let tempRegions = Object.assign([],regions);
      tempRegions = tempRegions.filter(region=>!this.regionsIdsArray.includes(region.id))
  
      tempRegions.forEach(region => {
      if(!this.selectableRegionsString.find(libelle => libelle == this.regionName(region))){
        this.selectableRegionsString.push(this.regionName(region))
      }})
    }else{
        regions.forEach(region=>{
          if(!this.selectableRegionsString.find(libelle => libelle == this.regionName(region))){
            this.selectableRegionsString.push(this.regionName(region))
          }
        })
    }
    this.selectableRegionsString.sort();
    this.selectedRegionString.sort();
    this.filteredRegions = this.regionCtrl.valueChanges.pipe(
      startWith(null),
      map((region: string | null) => region ? this._filter(region) : this.selectableRegionsString.slice()));
}

addElement(inputValue,inputIndex){
  const value = inputValue;
  const index = inputIndex;
  this.regionService.getAll().subscribe((regions: RegionModel[]) => {

    if ((value || '').trim() && regions.find(x=>  this.regionName(x)  == value) != undefined) {
      this.selectedRegionString.push(value.trim());
      this.selectableRegionsString.splice(index, 1);
      this.selectableRegionsString.sort();
      this.regionsIdsArray.push(regions.find(region=>this.regionName(region) == value).id)
    }
  
  this.regionCtrl.setValue(null);
  this.updated.emit(this.regionsIdsArray);
  });

  this.filteredRegions = this.regionCtrl.valueChanges.pipe(
    startWith(null),
  map((region: string | null) => region ? this._filter(region) : this.selectableRegionsString.slice()));
  this.updated.emit(this.regionsIdsArray);
  this.regionInput.nativeElement.blur();
}



/* Supression d'une region*/
  remove(region: string): void {
    let id;
    const index = this.selectedRegionString.indexOf(region);
    if (index >= 0) {
      this.selectableRegionsString.push(this.selectedRegionString[index]);
      this.selectableRegionsString.sort();
      this.selectedRegionString.splice(index, 1);
    }

    this.regionService.getAll().subscribe((regions: RegionModel[]) => {
    this.regionsIdsArray.splice(this.regionsIdsArray.indexOf(regions.find(x=>this.regionName(x) == region).id),1)
    this.updated.emit(this.regionsIdsArray);
    });
    this.filteredRegions = this.regionCtrl.valueChanges.pipe(
      startWith(null),
      map((myRegion: string | null) => myRegion ? this._filter(myRegion) : this.selectableRegionsString.slice()));

  }

  /* Ajout d'une region avec Entrer */
  add(event: MatChipInputEvent): void {
    const input = event.input;
    this.addElement(event.value,this.selectableRegionsString.indexOf(event.value))
    
    if (input) {
      input.value = '';
    }
  }

  /* Ajout d'une region avec selection */
  selected(event: MatAutocompleteSelectedEvent): void {
      this.addElement(event.option.value,this.selectableRegionsString.indexOf(event.option.value))
      this.regionInput.nativeElement.value = '';
      this.regionInput.nativeElement.blur();
    }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const stringFolded = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return this.selectableRegionsString.filter(region => region.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf( stringFolded) >= 0);
  }

  regionName(reg: RegionModel) {
    return (reg.code + ' - ' + reg.libelle).trim();
  }
}
