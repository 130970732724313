<div *ngIf="form">
    <div fxLayout="column" fxLayoutAlign="start start" style="min-height: min-content;">
      <h5>Applications</h5>
      <mat-divider style="align-self: stretch;"></mat-divider>
      <div class="liste" #accordionContainer>
        <mat-accordion multi>
          <mat-expansion-panel *ngFor="let category of applications | groupBy:'categorie' | keyvalue; let first = first"
                               [expanded]="expandedCategories[category.key]"
                               (expandedChange)="toggleCategory(category.key, $event)"
                               [class.first-panel]="first">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{category.key != 'null' ? category.key : "Autres"}}
              </mat-panel-title>
              <mat-panel-description>
                Selectionné&nbsp;:&nbsp; <span [style.font-weight]="getSelectedCountForCategory(category.key) > 0 ? 'bold' : 'normal'">
                  {{getSelectedCountForCategory(category.key)}}
                </span>/{{category.value.length}} 
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div *ngFor="let app of category.value">
                <button class="custom-button" *ngIf="isChecked(app.id) === 'default-app'"
                  mat-raised-button 
                  type="button" 
                  color="info"
                  class="default-app"
                  matTooltipPosition="left"
                  matTooltip="La création de compte {{app.nom}} est obligatoire pour tous les collaborateurs de la SARP."
                  [disabled]="form.get('typeDemande').value === 2 || (cdsChecked === true && !isCDS(app.id)) || lockAppsForModif"
                  (click)="$event.stopPropagation()">
                    <mat-icon>info</mat-icon>{{ app.nom}}
                </button>
                <button *ngIf="isChecked(app.id) === 'warn'"
                  mat-raised-button 
                  type="button" 
                  (click)="delete(app, $event)" 
                  color="warn"
                  [disabled]="form.get('typeDemande').value === 2 || (cdsChecked === true && !isCDS(app.id)) || lockAppsForModif">
                    <mat-icon>check</mat-icon>{{ app.nom}}
                </button>
                <button *ngIf="isChecked(app.id) === 'default'"
                  mat-raised-button 
                  type="button" 
                  (click)="add(app, $event)" 
                  color="default"
                  [disabled]="form.get('typeDemande').value === 2 || (cdsChecked === true && !isCDS(app.id)) || lockAppsForModif">
                    {{ app.nom}}
                </button>
                <button *ngIf="isChecked(app.id) === 'green'"
                  mat-raised-button 
                  type="button" 
                  (click)="delete(app, $event)" 
                  color="success"
                  [disabled]="form.get('typeDemande').value === 2 || (cdsChecked === true && !isCDS(app.id)) || lockAppsForModif">
                  <mat-icon>add</mat-icon>{{ app.nom}}
                </button>
                <button *ngIf="isChecked(app.id) === 'red'"
                  mat-raised-button 
                  type="button" 
                  (click)="add(app, $event)" 
                  color="danger"
                  [disabled]="form.get('typeDemande').value === 2 || (cdsChecked === true && !isCDS(app.id)) || lockAppsForModif">
                  <mat-icon>delete</mat-icon>{{ app.nom}}
                </button>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <mat-divider style="align-self: stretch;"></mat-divider>
    </div>
  </div>
  