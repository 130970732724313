import { AgenceLinkedModel } from "../agence-linked.model";
import { CycleDemandeModel } from "../../simple-types/cycle-demande.model";
import { UtilisateurModel } from "../../utilisateur/utilisateur.model";
import { ApplicationDemandeModel } from "../../simple-types/application-demande.model";
import { TypeDemande } from "src/app/shared/constants/typeDemande";
import { CollaborateurModel } from "../../utilisateur/collaborateur.model";
import { getStatusDemande, StatusDemande } from "src/app/shared/constants/status";


export class DemandeModel extends AgenceLinkedModel {

    code: string = "*";
    typeDemande: TypeDemande; // create type enumeration

    idCollaborateur: number;
    collaborateur: CollaborateurModel;

    idResponsableCollaborateur: number;
    responsableCollaborateur: CollaborateurModel;

    createur: CollaborateurModel;

    login: string = ""; // code sarp
    nom: string = "";
    prenom: string = "";
    email: string = "";
    status: string = getStatusDemande(StatusDemande.Brouillon); // create status enumeration
    statusNum: number = 1;
    etat: number = 0;
    tel: string;
    dateDebut: Date = new Date();
    dateFin: Date = null;
    typeContrat: string = "";
    commentaire: string = "";

    cds : boolean = false;
    retour: boolean = false;
    retourDSI: boolean = false;
    annule: boolean = false;
    traiteAd: boolean = false;
    traiteGmail: boolean = false;

    applications: ApplicationDemandeModel[] = [];
    demandeCycles: CycleDemandeModel[] = [];

    modification: string;

}


