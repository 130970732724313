  <div class="col-md-12" class="header">
    <h2 class="col-md-6"> Fiche agence</h2>
    <div class="col-md-2">
      <button color="success" mat-raised-button type="button" [disabled]="onlyViewDroits" (click)="updateAgence()">
        <mat-icon>edit</mat-icon>Modification
      </button>
    </div>
    <div class="col-md-2">
      <button color="danger" mat-raised-button type="button"  [disabled]="onlyViewDroits || this.agence.dDesactivation != null" (click)="deleteAgence()">
          <mat-icon>clear</mat-icon>Suppression
      </button>
    </div>
    <div class="col-md-2">
      <button class="pull-right" color="warn" mat-raised-button type="button" (click)="close()">
        <mat-icon>cancel</mat-icon> Fermer
      </button>
    </div>
  </div>
  <div class="col-md-12" *ngIf="form">
    <form focusInvalidInput [formGroup]="form">
        <mat-card> 
          <div class="data-class row">
            <div class="col-sm-3">
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Code</mat-label>
                  <input matInput placeholder="Ex: SP000" formControlName="code" required>
                  <mat-error *ngIf="form.get('code').hasError('required')">
                    Le code est obligatoire.
                </mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-12">
                <app-autocomplete-societe
                  name="societe"
                  ngDefaultControl 
                  [libelle]="'Société'"
                  [societes]="societes"
                  [societeId]="form.get('societeId').value"
                  [disabled]="form.get('societeId').disabled"
                  [required]="true">
                </app-autocomplete-societe> 
              </div>
             
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email">
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Adresse</mat-label>
                  <input matInput formControlName="adresse">
                </mat-form-field>
              </div>

             

              <div class="col-sm-12">
                <app-autocomplete-user [userId]="form.get('idDirecteurAgence').value"
                (updated)="onCollabChange($event, 'DirecteurAgence')" 
                libelle="Directeur d'agence" 
                name="user"
                [disabled]="form.get('idDirecteurAgence').disabled"
                [parentForm]="form">
                </app-autocomplete-user>
              </div>




            </div>
            <div class="col-sm-3">
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Libelle</mat-label>
                  <input matInput placeholder="Ex: IDF" formControlName="libelle" required>
                  <mat-error *ngIf="form.get('libelle').hasError('required')">
                    Le libelle est obligatoire.
                </mat-error>
                </mat-form-field>
              </div>

              
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Code conso</mat-label>
                  <input matInput formControlName="codeConso">
                </mat-form-field>
              </div>
              
              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Téléphone</mat-label>
                  <input matInput formControlName="telephone">
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Code postale</mat-label>
                  <input matInput formControlName="codePostal">
                </mat-form-field>
              </div>

              <div class="col-sm-12">
                <app-autocomplete-user [userId]="form.get('idContact1').value"
                (updated)="onCollabChange($event, 'ContactSite1')" 
                libelle="Contact site 1" 
                name="user"
                [disabled]="form.get('idContact1').disabled"
                [parentForm]="form">
                </app-autocomplete-user>
              </div>
            </div>
            
            <div class="col-sm-3">
              <div class="col-sm-12">
                <app-autocomplete-region
                  name="region"
                  ngDefaultControl 
                  [libelle]="'Région'"
                  [regions]="regions"
                  [regionId]="form.get('regionId').value"
                  [disabled]="form.get('regionId').disabled"
                  [required]="true">
                </app-autocomplete-region> 
              </div> 

              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Siret</mat-label>
                  <input matInput formControlName="siret">
                </mat-form-field>
              </div>


              <div class="col-md-12"> 
                <div class="row">
                  <div class="left" *ngIf="!form.get('dDesactivation').value "><mat-icon class="green-icon">check</mat-icon></div> 
                  <div class="left" *ngIf="form.get('dDesactivation').value "><mat-icon class="red-icon">close</mat-icon></div> 
                  <h6>{{!form.get('dDesactivation').value ? " Agence Activé": " Agence désactivé"}}</h6>
                </div>
              </div> 

              <div class="col-md-12">
                <mat-form-field class="full-width">
                  <mat-label>Ville</mat-label>
                  <input matInput formControlName="ville">
                </mat-form-field>
              </div>

              <div class="col-sm-12">
                <app-autocomplete-user [userId]="form.get('idContact2').value"
                  (updated)="onCollabChange($event, 'ContactSite2')" 
                  libelle="Contact site 2" 
                  name="user"
                  [disabled]="form.get('idContact2').disabled"
                  [parentForm]="form">
                </app-autocomplete-user>
              </div>


            </div>
            <div class="col-sm-3">
              <mat-form-field class="full-width">
                <mat-label style="margin-left: 5px;">infos Directeur Agence</mat-label>
                <textarea matInput name="gdso-infosDirecteurAgence" cdkTextareaAutosize cdkAutosizeMinRows="6"
                    cdkAutosizeMaxRows="6" style="background-color: white;"
                    formControlName="infosDirecteurAgence"></textarea>
              </mat-form-field>

              <mat-form-field class="full-width">
                  <mat-label style="margin-left: 5px;">infos Contact Site</mat-label>
                  <textarea matInput name="gdso-infosContact" cdkTextareaAutosize cdkAutosizeMinRows="6"
                      cdkAutosizeMaxRows="6" style="background-color: white;"
                      formControlName="infosContact"></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-card>

        <mat-card style="margin-top: 20px;">
          <mat-card-content>    
              <div class="mat-table">
                  <div class="table_principal">
                      <table mat-table matSort [dataSource]="matDataSourceDemande" matSortDirection="desc" matSortDisableClear >
                        <caption>Historique des demandes</caption>
                          <ng-container matColumnDef="Action">
                              <th mat-header-cell *matHeaderCellDef> Action </th>
                              <td mat-cell *matCellDef="let element" class="padding-0" >
                                  <div *ngIf="element.typeDemande === 0"> Création d'agence </div>
                                  <div *ngIf="element.typeDemande === 1"> Modification d'agence </div>
                                  <div *ngIf="element.typeDemande === 2"> Suppression d'agence </div>
                              </td>
                          </ng-container>
                          <ng-container matColumnDef="Demandeur">
                              <th mat-header-cell *matHeaderCellDef> Demandeur </th>
                              <td mat-cell *matCellDef="let element" class="padding-0">
                                  <div *ngIf="element.createur">
                                        {{element.createur.nom}}  {{element.createur.prenom}}
      
                                  </div>
                              </td>
                          </ng-container>
                          <ng-container matColumnDef="Date">
                              <th mat-header-cell *matHeaderCellDef> Date </th>
                              <td mat-cell *matCellDef="let element" class="padding-0"> 
                                  <div *ngIf="element.dCreation !== '0001-01-01T00:00:00Z'">
                                       {{element.dCreation| date:'dd/MM/yyyy'}}  
                                  </div>
                              </td>
                              
                          </ng-container>
              
                          <ng-container matColumnDef="Modification">
                              <th mat-header-cell *matHeaderCellDef> Modification </th>
                              <td mat-cell *matCellDef="let element" class="padding-0">
                                  <div *ngIf="element.modification" [innerHtml]="element.modification">
                                  </div>
                              </td>
                          </ng-container>
              
                          
                          <tr mat-header-row *matHeaderRowDef="displayedColumnsDemande; sticky: true"></tr>
                          <div >
                              <tr mat-row *matRowDef="let row; columns: displayedColumnsDemande;" (click)="editDemande(row);">
                              </tr>
                          </div>
                          
                      </table>
                  </div>
              </div>
          </mat-card-content>
      </mat-card>
    </form>
  </div>
 
  