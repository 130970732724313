import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidators } from './util';

export class FormInfo {
  validators: Validators;
  disabled: boolean;
  constructor(required: boolean, validator: Validators) {
    this.disabled = required;
    this.validators = validator;
  }
}

export const DEMANDEVALIDATORS = {
  DEMANDEADD: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(false, [])],
    ['idCreateur', new FormInfo(false, [])],
    ['dModification', new FormInfo(false, [])],
    ['idModifieur', new FormInfo(false, [])],
    ['dDesactivation', new FormInfo(false, [])],
    ['idAgence', new FormInfo(false, [])],
    ['code', new FormInfo(false, [Validators.maxLength(15)])],
    ['typeDemande', new FormInfo(false, [Validators.required])],
    ['idCollaborateur', new FormInfo(false, [])],
    ['idResponsableCollaborateur', new FormInfo(false, [Validators.required])],
    ['login', new FormInfo(true, [])],
    ['nom', new FormInfo(false, [Validators.required,Validators.maxLength(30), Validators.pattern(/^[A-Za-z-.éèêëàäâãîïùüûçñ'’;,:!%*()@?ŒœÆæÉÈÊËÀÄÂÃÎÏÙÜÛÇÑŒÆ ]*$/)])], 
    ['prenom', new FormInfo(false, [Validators.required,Validators.maxLength(30), Validators.pattern(/^[A-Za-z-.éèêëàäâãîïùüûçñ'’;,:!%*()@?ŒœÆæÉÈÊËÀÄÂÃÎÏÙÜÛÇÑŒÆ ]*$/)])],
    ['email', new FormInfo(true, [Validators.required,Validators.maxLength(120), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
    ['status', new FormInfo(false, [])],
    ['statusNum', new FormInfo(false, [])],
    ['etat', new FormInfo(false, [])],
    ['tel', new FormInfo(false, [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")])],
    ['dateDebut', new FormInfo(false, [])],
    ['dateFin', new FormInfo(false, [Validators.required, CustomValidators.dateFinValidator])],
    ['typeContrat', new FormInfo(false, [Validators.required])],
    ['commentaire', new FormInfo(false, [])],
    ['cds', new FormInfo(false, [])],
    ['retour', new FormInfo(false, [])],
    ['annule', new FormInfo(false, [])],
    ['traiteAd', new FormInfo(false, [])],
    ['traiteGmail', new FormInfo(false, [])],
  ]),
  DEMANDEUPDATE: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(false, [])],
    ['idCreateur', new FormInfo(false, [])],
    ['dModification', new FormInfo(false, [])],
    ['idModifieur', new FormInfo(false, [])],
    ['dDesactivation', new FormInfo(false, [])],
    ['idAgence', new FormInfo(false, [])],
    ['code', new FormInfo(false, [Validators.maxLength(15)])],
    ['typeDemande', new FormInfo(false, [Validators.required])],
    ['idCollaborateur', new FormInfo(false, [Validators.required])],
    ['idResponsableCollaborateur', new FormInfo(false, [Validators.required])],
    ['login', new FormInfo(true, [])],
    ['nom', new FormInfo(false, [Validators.required, Validators.maxLength(30),Validators.pattern(/^[A-Za-z-.éèêëàäâãîïùüûçñ'’;,:!%*()@?ŒœÆæÉÈÊËÀÄÂÃÎÏÙÜÛÇÑŒÆ ]*$/)])],
    ['prenom', new FormInfo(false, [Validators.required,Validators.maxLength(30), Validators.pattern(/^[A-Za-z-.éèêëàäâãîïùüûçñ'’;,:!%*()@?ŒœÆæÉÈÊËÀÄÂÃÎÏÙÜÛÇÑŒÆ ]*$/)])],
    ['email', new FormInfo(true, [Validators.required,Validators.maxLength(120), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
    ['status', new FormInfo(false, [])],
    ['statusNum', new FormInfo(false, [])],
    ['etat', new FormInfo(false, [])],
    ['tel', new FormInfo(false, [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")])],
    ['dateDebut', new FormInfo(false, [])],
    ['dateFin', new FormInfo(false, [CustomValidators.dateFinValidator])],
    ['typeContrat', new FormInfo(false, [Validators.required])],
    ['commentaire', new FormInfo(false, [])],
    ['cds', new FormInfo(false, [])],
    ['retour', new FormInfo(false, [])],
    ['annule', new FormInfo(false, [])],
    ['traiteAd', new FormInfo(false, [])],
    ['traiteGmail', new FormInfo(false, [])],
  ]),
  DEMANDEDELETE: new Map<string, FormInfo>([
    ['id', new FormInfo(true, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idAgence', new FormInfo(true, [])],
    ['code', new FormInfo(true, [])],
    ['typeDemande', new FormInfo(true, [Validators.required])],
    ['idCollaborateur', new FormInfo(true, [Validators.required])],
    ['idResponsableCollaborateur', new FormInfo(true, [])],
    ['login', new FormInfo(true, [])],
    ['nom', new FormInfo(true, [])],
    ['prenom', new FormInfo(true, [])],
    ['email', new FormInfo(true, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
    ['status', new FormInfo(true, [])],
    ['statusNum', new FormInfo(true, [])],
    ['etat', new FormInfo(true, [])],
    ['tel', new FormInfo(true, [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")])],
    ['dateDebut', new FormInfo(true, [])],
    ['dateFin', new FormInfo(true, [])],
    ['typeContrat', new FormInfo(true, [Validators.required])],
    ['commentaire', new FormInfo(false, [])],
    ['cds', new FormInfo(true, [])],
    ['retour', new FormInfo(true, [])],
    ['annule', new FormInfo(true, [])],
    ['traiteAd', new FormInfo(true, [])],
    ['traiteGmail', new FormInfo(true, [])],
  ]),
  VALIDATE: new Map<string, FormInfo>([
    ['id', new FormInfo(true, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idAgence', new FormInfo(true, [])],
    ['code', new FormInfo(true, [Validators.maxLength(15)])],
    ['typeDemande', new FormInfo(true, [Validators.required])],
    ['idCollaborateur', new FormInfo(true, [Validators.required])],
    ['idResponsableCollaborateur', new FormInfo(true, [])],
    ['login', new FormInfo(true, [])],
    ['nom', new FormInfo(true, [Validators.required])], 
    ['prenom', new FormInfo(true, [Validators.required])],
    ['email', new FormInfo(true, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
    ['status', new FormInfo(true, [])],
    ['statusNum', new FormInfo(true, [])],
    ['etat', new FormInfo(true, [])],
    ['tel', new FormInfo(true, [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")])],
    ['dateDebut', new FormInfo(true, [])],
    ['dateFin', new FormInfo(true, [])],
    ['typeContrat', new FormInfo(true, [Validators.required])],
    ['commentaire', new FormInfo(true, [])],
    ['cds', new FormInfo(true, [])],
    ['retour', new FormInfo(true, [])],
    ['annule', new FormInfo(true, [])],
    ['traiteAd', new FormInfo(true, [])],
    ['traiteGmail', new FormInfo(true, [])],
  ]),
  VISUALIZE: new Map<string, FormInfo>([
    ['id', new FormInfo(true, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idAgence', new FormInfo(true, [])],
    ['code', new FormInfo(true, [Validators.maxLength(15)])],
    ['typeDemande', new FormInfo(true, [Validators.required])],
    ['idCollaborateur', new FormInfo(true, [Validators.required])],
    ['idResponsableCollaborateur', new FormInfo(true, [Validators.required])],
    ['login', new FormInfo(true, [])],
    ['nom', new FormInfo(true, [Validators.required])],
    ['prenom', new FormInfo(true, [Validators.required])],
    ['email', new FormInfo(true, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
    ['status', new FormInfo(true, [])],
    ['statusNum', new FormInfo(true, [])],
    ['etat', new FormInfo(true, [])],
    ['tel', new FormInfo(true, [])],
    ['dateDebut', new FormInfo(true, [Validators.required])],
    ['dateFin', new FormInfo(true, [Validators.required])],
    ['typeContrat', new FormInfo(true, [Validators.required])],
    ['commentaire', new FormInfo(true, [])],
    ['accesDistant', new FormInfo(true, [])],
    ['cds', new FormInfo(true, [])],
    ['retour', new FormInfo(true, [])],
    ['annule', new FormInfo(true, [])],
    ['traiteAd', new FormInfo(true, [])],
    ['traiteGmail', new FormInfo(true, [])],
  ]),
  TRAITER: new Map<string, FormInfo>([
    ['id', new FormInfo(true, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idAgence', new FormInfo(true, [])],
    ['code', new FormInfo(true, [Validators.maxLength(15)])],
    ['typeDemande', new FormInfo(true, [Validators.required])],
    ['idCollaborateur', new FormInfo(true, [Validators.required])],
    ['idResponsableCollaborateur', new FormInfo(true, [Validators.required])],
    ['login', new FormInfo(true, [])],
    ['nom', new FormInfo(true, [Validators.required])],
    ['prenom', new FormInfo(true, [Validators.required])],
    ['email', new FormInfo(false, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
    ['status', new FormInfo(true, [])],
    ['statusNum', new FormInfo(true, [])],
    ['etat', new FormInfo(true, [])],
    ['tel', new FormInfo(true, [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")])],
    ['dateDebut', new FormInfo(true, [])],
    ['dateFin', new FormInfo(true, [])],
    ['typeContrat', new FormInfo(true, [Validators.required])],
    ['commentaire', new FormInfo(true, [])],
    ['cds', new FormInfo(true, [])],
    ['retour', new FormInfo(true, [])],
    ['annule', new FormInfo(true, [])],
    ['traiteAd', new FormInfo(true, [])],
    ['traiteGmail', new FormInfo(true, [])],

  ])
};


export const DEMANDEAGENCEVALIDATORS = {
  DEMANDEADD: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(false, [])],
    ['idCreateur', new FormInfo(false, [])],
    ['dModification', new FormInfo(false, [])],
    ['idModifieur', new FormInfo(false, [])],
    ['dDesactivation', new FormInfo(false, [])],
    ['idAgence', new FormInfo(false, [])],
    
    ['codeDemande', new FormInfo(false, [Validators.maxLength(15)])],
    ['typeDemande', new FormInfo(false, [Validators.required])],
    

    ['type', new FormInfo(true, [Validators.required])],
    ['code', new FormInfo(false, [Validators.required, Validators.maxLength(30)])],
    ['libelle', new FormInfo(false, [Validators.required, Validators.maxLength(30)])],

    ['regionId', new FormInfo(false, [Validators.required])],
    ['societeId', new FormInfo(false, [Validators.required])],
    ['societeLibelle', new FormInfo(false, [Validators.required])],
    ['adresse', new FormInfo(false, [Validators.required])], 
    ['codePostal', new FormInfo(false, [Validators.required])],
    ['ville', new FormInfo(false, [Validators.required])],
    ['telephone', new FormInfo(false, [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")])],
    ['email', new FormInfo(false, [Validators.maxLength(120), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
    ['siret', new FormInfo(false, [])],    

    ['codeConso', new FormInfo(false, [Validators.maxLength(15)])],
    ['idDirecteurAgence', new FormInfo(false, [])],
    ['idContact1', new FormInfo(false, [])],
    ['idContact2', new FormInfo(false, [])],
    ['infosDirecteurAgence', new FormInfo(false, [Validators.maxLength(255)])],
    ['infosContact', new FormInfo(false, [Validators.maxLength(255)])],

    ['status', new FormInfo(false, [])],
    ['statusNum', new FormInfo(false, [])],
    ['etat', new FormInfo(false, [])],
    ['retour', new FormInfo(false, [])],
    ['annule', new FormInfo(false, [])],
    
  ]),
  DEMANDEUPDATE: new Map<string, FormInfo>([
    ['id', new FormInfo(false, [])],
    ['dCreation', new FormInfo(false, [])],
    ['idCreateur', new FormInfo(false, [])],
    ['dModification', new FormInfo(false, [])],
    ['idModifieur', new FormInfo(false, [])],
    ['dDesactivation', new FormInfo(false, [])],
    ['idAgence', new FormInfo(false, [])],
    
    ['codeDemande', new FormInfo(false, [Validators.maxLength(15)])],
    ['typeDemande', new FormInfo(false, [Validators.required])],
    

    ['type', new FormInfo(true, [Validators.required])],
    ['code', new FormInfo(false, [Validators.required, Validators.maxLength(30)])],
    ['libelle', new FormInfo(false, [Validators.required, Validators.maxLength(30)])],
    
    ['regionId', new FormInfo(false, [Validators.required])],
    ['societeId', new FormInfo(false, [Validators.required])],
    ['societeLibelle', new FormInfo(false, [])],
    ['adresse', new FormInfo(false, [Validators.required])], 
    ['codePostal', new FormInfo(false, [Validators.required])],
    ['ville', new FormInfo(false, [Validators.required])],
    ['telephone', new FormInfo(false, [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")])],
    ['email', new FormInfo(false, [Validators.maxLength(120), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
    ['siret', new FormInfo(false, [])],    


    ['codeConso', new FormInfo(false, [Validators.maxLength(15)])],
    ['idDirecteurAgence', new FormInfo(false, [])],
    ['idContact1', new FormInfo(false, [])],
    ['idContact2', new FormInfo(false, [])],
    ['infosDirecteurAgence', new FormInfo(false, [Validators.maxLength(255)])],
    ['infosContact', new FormInfo(false, [Validators.maxLength(255)])],

    ['status', new FormInfo(true, [])],
    ['statusNum', new FormInfo(true, [])],
    ['etat', new FormInfo(true, [])],
    ['retour', new FormInfo(true, [])],
    ['annule', new FormInfo(true, [])],

  ]),
  DEMANDEDELETE: new Map<string, FormInfo>([
    ['id', new FormInfo(true, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idAgence', new FormInfo(true, [])],
    
    ['codeDemande', new FormInfo(true, [])],
    ['typeDemande', new FormInfo(true, [])],
    

    ['type', new FormInfo(true, [])],
    ['code', new FormInfo(true, [])],
    ['libelle', new FormInfo(true, [])],

    ['regionId', new FormInfo(true, [])],
    ['societeId', new FormInfo(true, [])],
    ['societeLibelle', new FormInfo(false, [])],
    ['adresse', new FormInfo(true, [])], 
    ['codePostal', new FormInfo(true, [])],
    ['ville', new FormInfo(true, [])],
    ['telephone', new FormInfo(true, [])],
    ['email', new FormInfo(true, [])],
    ['siret', new FormInfo(true, [])],    


    ['codeConso', new FormInfo(true, [])],
    ['idDirecteurAgence', new FormInfo(true, [])],
    ['idContact1', new FormInfo(true, [])],
    ['idContact2', new FormInfo(true, [])],
    ['infosDirecteurAgence', new FormInfo(true, [])],
    ['infosContact', new FormInfo(true, [])],

    ['status', new FormInfo(true, [])],
    ['statusNum', new FormInfo(true, [])],
    ['etat', new FormInfo(true, [])],
    ['retour', new FormInfo(true, [])],
    ['annule', new FormInfo(true, [])],
  ]),
  VALIDATE: new Map<string, FormInfo>([
    ['id', new FormInfo(true, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idAgence', new FormInfo(true, [])],
    
    ['codeDemande', new FormInfo(true, [Validators.maxLength(15)])],
    ['typeDemande', new FormInfo(true, [Validators.required])],
    

    ['type', new FormInfo(true, [Validators.required])],
    ['code', new FormInfo(true, [Validators.required])],
    ['libelle', new FormInfo(true, [Validators.required])],

    ['regionId', new FormInfo(true, [Validators.required])],
    ['societeId', new FormInfo(true, [Validators.required])],
    ['societeLibelle', new FormInfo(false, [])],
    ['adresse', new FormInfo(true, [])], 
    ['codePostal', new FormInfo(true, [])],
    ['ville', new FormInfo(true, [])],
    ['telephone', new FormInfo(true, [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")])],
    ['email', new FormInfo(true, [Validators.maxLength(120), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
    ['siret', new FormInfo(true, [])],    


    ['codeConso', new FormInfo(true, [])],
    ['idDirecteurAgence', new FormInfo(true, [])],
    ['idContact1', new FormInfo(true, [])],
    ['idContact2', new FormInfo(true, [])],
    ['infosDirecteurAgence', new FormInfo(true, [])],
    ['infosContact', new FormInfo(true, [])],

    ['status', new FormInfo(true, [])],
    ['statusNum', new FormInfo(true, [])],
    ['etat', new FormInfo(true, [])],
    ['retour', new FormInfo(true, [])],
    ['annule', new FormInfo(true, [])],
  ]),
  VISUALIZE: new Map<string, FormInfo>([
    ['id', new FormInfo(true, [])],
    ['dCreation', new FormInfo(true, [])],
    ['idCreateur', new FormInfo(true, [])],
    ['dModification', new FormInfo(true, [])],
    ['idModifieur', new FormInfo(true, [])],
    ['dDesactivation', new FormInfo(true, [])],
    ['idAgence', new FormInfo(true, [])],
    
    ['codeDemande', new FormInfo(true, [Validators.maxLength(15)])],
    ['typeDemande', new FormInfo(true, [Validators.required])],
    

    ['type', new FormInfo(true, [Validators.required])],
    ['code', new FormInfo(true, [Validators.required])],
    ['libelle', new FormInfo(true, [Validators.required])],
    
    ['regionId', new FormInfo(true, [Validators.required])],
    ['societeId', new FormInfo(true, [Validators.required])],
    ['societeLibelle', new FormInfo(false, [])],
    ['adresse', new FormInfo(true, [])], 
    ['codePostal', new FormInfo(true, [])],
    ['ville', new FormInfo(true, [])],
    ['telephone', new FormInfo(true, [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")])],
    ['email', new FormInfo(true, [Validators.maxLength(120), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
    ['siret', new FormInfo(true, [])],    


    ['codeConso', new FormInfo(true, [])],
    ['idDirecteurAgence', new FormInfo(true, [])],
    ['idContact1', new FormInfo(true, [])],
    ['idContact2', new FormInfo(true, [])],
    ['infosDirecteurAgence', new FormInfo(true, [])],
    ['infosContact', new FormInfo(true, [])],

    ['status', new FormInfo(true, [])],
    ['statusNum', new FormInfo(true, [])],
    ['etat', new FormInfo(true, [])],
    ['retour', new FormInfo(true, [])],
    ['annule', new FormInfo(true, [])],
  ]),
};
