import { ApplicationModel } from "../models/simple-types/application.model";
import { CollaborateurModel } from "../models/utilisateur/collaborateur.model";
import { ResponsableDTO } from "./responsableDTO";

export class ResponsableModelDTO {

    oldResponsableId: number;
    
    application: ApplicationModel;
   
    responsable: CollaborateurModel;

    responsablesDTO: ResponsableDTO[];
  }
  
