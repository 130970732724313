import { SimpleQueryResponse } from './../../../shared/dtos/simpleQueryResponse';
import { SimpleQueryUser } from './../../../shared/dtos/simpleQueryUser';
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import { UtilisateurModel } from 'src/app/shared/models/utilisateur/utilisateur.model';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserService } from 'src/app/shared/services/user.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { Title } from '@angular/platform-browser';
import { DroitEffectifService } from 'src/app/shared/services/simple/droit.service';
import { saveAs } from 'file-saver';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {
  users: UtilisateurModel[] = [];
  displayedColumns: string[] = ['nom', 'prenom', 'tel', 'agence', 'email'];
  dataSource = new MatTableDataSource<UtilisateurModel>();
  length: number;
  pageSize = 25;
  objet: SimpleQueryUser = new SimpleQueryUser();
  pageEvent: PageEvent;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor( 
    public router: Router, 
    public dialog: MatDialog,
    private title: Title,
    public collaborateurService: CollaborateurService, 
    private droitService: DroitEffectifService,
    private userService: UserService) {}
  ngOnInit(): void {
    this.title.setTitle('GUS - Gestion utilisateurs');
    this.objet.query = '';
    this.objet.pageNumber = 0;
    this.objet.pageSize = this.pageSize;
    this.objet.order = 'Nom';
    this.dataSource.paginator = this.paginator;
    this.load_user();
  }

  load_user(): void {
    this.userService.GetFromSimpleQuery(this.objet).subscribe((query: SimpleQueryResponse<UtilisateurModel>) => {
      if (query.objets != null) {
        this.users = this.orderUsers(query.objets);
      } else {
        this.users = [];
      }
      this.length = query.count;
      this.dataSource = new MatTableDataSource<UtilisateurModel>(this.users);
    });
  }

  export(type){
    const dialogRef = this.dialog.open(SpinnerModalComponent);
    this.droitService.export(type).subscribe((res: BlobPart)  => {
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), res], {type: 'text/csv;charset=utf-8'});
      if(type=="user"){
      saveAs(blob, 'GUS-Droit_User_' + new Date().toISOString().split('T')[0] + '.csv');
      }else{
      saveAs(blob, 'GUS-Droit_Zone_' + new Date().toISOString().split('T')[0] + '.csv');
      }
      dialogRef.close();
    }, (error) => dialogRef.close());
  }

  orderUsers(users: UtilisateurModel[]): UtilisateurModel[] {
    users = users.sort((a, b) => {
      if (a.nom) {
        return a.nom.localeCompare(b.nom);
      } else {
        return -1;
      }
    });
    return users;
  }

 /*  PAS DE CREATION UTILISATEUR !!!
  create_user(): void {
    const dialogRef = this.dialog.open(ModalAddUserComponent, {
      width: '60%',
      data: {email: null}
    });

    
  }
  */


  openEvent(user: UtilisateurModel): void {
    this.router.navigate(['/user-record/' + user.id]);
  }

  changePage(event?: PageEvent): void {
    this.objet.pageNumber =  event.pageIndex;
    this.objet.pageSize = event.pageSize;
    this.loadPage();
  }

  search(): void {
      this.objet.pageNumber = 0;
      this.objet.pageSize = this.pageSize;
      this.paginator.pageIndex = 0;
      this.loadPage();
  }

  loadPage(): void {
    this.userService.GetFromSimpleQuery(this.objet).subscribe((query: SimpleQueryResponse<UtilisateurModel>) => {
      if (query.objets != null) {
        this.users = query.objets;
      } else {
        this.users = [];
      }
      this.length = query.count;
      this.dataSource = new MatTableDataSource<UtilisateurModel>(this.users);
      this.paginator.length = this.length;
    });
  }
}
