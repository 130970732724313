import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { DemandeComponentsService } from '../../demande/demande-components.service';
import { RegionService } from 'src/app/shared/services/rsa/region.service';
import { SocieteService } from 'src/app/shared/services/rsa/societe.service';
import { SocieteModel } from 'src/app/shared/models/rsa/societe.model';

@Component({
  selector: 'app-demande-agence-form',
  templateUrl: './demande-agence-form.component.html',
  styleUrls: ['./demande-agence-form.component.scss']
})
export class DemandeAgenceFormComponent implements OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() agence: AgenceModel;
  @Output() changeEmit = new EventEmitter<any>();
  @Input() display = false;
  societesLibelle: string[] = [];
  societes: SocieteModel[] = [];
  agences: AgenceModel[] = [];
  regions: RegionModel[] = [];
  change: any = null;
  idDemande: number;
  isDisabled: boolean = false;
  borderColor: string;


  /* Prevent Saturday and Sunday for select. */
  weekendsDatesFilter = (d: Date): boolean => {
    if (d) {
      const day = d.getDay();
      return day !== 0 && day !== 6;
    }
  }

  constructor(
    public dialog: MatDialog,
    private agenceService: AgenceService,
    private regionService: RegionService,
    private societeService: SocieteService,
    private collaborateurService: CollaborateurService,
    private demandeComponentsService: DemandeComponentsService,
    public router: Router) {
      if(window.location.href.includes("read-demande")){
        this.agenceService.getAll().subscribe(obj => {
          this.agences = obj;
        });
      }else {
        this.agenceService.getAllForDemande().subscribe(obj => {
          this.agences = obj;
        });
      }
      this.regionService.getAll().subscribe(obj => {
        this.regions = obj;
      });

      this.societeService.getAll().subscribe(obj => {
        this.societes = obj;
        this.societesLibelle = [];
          this.societes.forEach(societe => {
            this.societesLibelle.push(societe.libelle);
          });
      });
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (this.form){
      if (this.form.get('id').value) {
        this.idDemande = this.form.get('id').value;
        this.isDisabled = this.form.get('dDesactivation').value;
      }
      if(this.form.get('typeDemande').value != null){
        switch (this.form.get("typeDemande").value) {
          case 0:
            this.borderColor = '#3db67c';
            break;
          case 1:
            this.borderColor = '#f09727';
            break;
          case 2:
            this.borderColor = '#b51621bd';
            break;
        }
      }
    }
  }

  onChangeRegion(region: RegionModel): void {
    if (region) {
      this.form.get('regionId').setValue(region.id);
    } else {
      this.form.get('regionId').setValue(null);
    }
  }

  onChangeSociete(societe: SocieteModel): void {
    if (societe) {
      this.form.get('societeId').setValue(societe.id);
      this.form.get('societeLibelle').setValue(societe.libelle);
    } else {
      this.form.get('societeId').setValue(null);
      this.form.get('societeLibelle').setValue(null);
    }
  }


  onCollabChange(id, state): void {
    switch (state) {
      case 'DirecteurAgence' :     
      this.form.get('idDirecteurAgence').setValue(id);
      break;
      case 'ContactSite1' :     
      this.form.get('idContact1').setValue(id);
      break;
      case 'ContactSite2' :     
      this.form.get('idContact2').setValue(id);
      break;
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.societesLibelle.filter(option => option.toLowerCase().includes(filterValue));
  }
}



