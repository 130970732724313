
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HostnameService } from '../basic/hostname.service';
import { APICONSTANTS } from '../../constants/api-constants';
import { RegionModel } from '../../models/rsa/region.model';
import { EntiteSARPNode } from '../../models/rsa/entite-sarp.model';
import { catchError } from 'rxjs/operators';
import { RSAService } from './rsa.service';

@Injectable()
export class RegionService extends RSAService<RegionModel> {
    private subscriptions: Subscription[] = [];

    constructor(http: HttpClient,
                protected toastrService: ToastrService,
                protected hostnameService: HostnameService) {
        super(http, toastrService, APICONSTANTS.ROUTEREGION, hostnameService);
    }
    getAllWithSocieteAgence(): Observable<EntiteSARPNode[]> {
      return this.http.get<EntiteSARPNode[]>(APICONSTANTS.URLAPI.value + this.route + 'getAllWithSocieteAgence/')
      .pipe(catchError(err => this.requestCatchError(err, 'getAllWithSocieteAgence')));
    }

  }