import { DemandeModel } from "../models/agence-linked/demande/demande.model";

export class DemandeValidationModel {
     demande: any;
     commentaire: string;
     isModif : boolean;

     constructor(demande: any) {
          this.demande = demande;
          this.commentaire = '';
          this.isModif = false;
     }
}
