<div>
    <div class="test" style="text-align: center">
      <div class="col-sm-12">
        <h3>{{title}}</h3>
      </div>
      <div class="col-sm-12">
        <h4>{{question}}</h4>
      </div>
      <div class="col-sm-12" style="margin-top: 20px; color: #df1e2b; " *ngIf="this.footer != null">
        <h6>{{footer}}</h6>
      </div>
      <div class="col-sm-12">
        <mat-divider></mat-divider>
      </div>

      <div class="row">
        <div class="col-sm-4" style="margin-top: 20px;">
          <button mat-raised-button color="default" (click)="onModalCancel()">Annuler</button>
        </div>
        <div class="col-sm-8" style="display: flex;align-items: flex-end;justify-content: space-between;flex-wrap: wrap;gap: 10px;">
          <button style="white-space: normal;" mat-raised-button color="primary" (click)="onModalNon()">{{buttonNon}}</button>
          <button style="white-space: normal;" mat-raised-button color="success" (click)="onModalOui()" >{{buttonOui}}</button>
      </div>

      </div>
    </div>
  </div>