import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GestionRegionComponent } from 'src/app/core/gestion-referentiel/gestion-region/gestion-region.component';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { RegionService } from 'src/app/shared/services/rsa/region.service';
import { SpinnerModalComponent } from '../spinner/spinner-modal.component';

export interface DialogData {
  form: UntypedFormGroup;
  result: boolean;
}

@Component({
  selector: 'app-modal-add-region',
  templateUrl: './modal-add-region.component.html',
  styleUrls: ['./modal-add-region.component.scss']
})
export class ModalAddRegionComponent implements OnInit {
  
  region: RegionModel;
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<GestionRegionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private regionService: RegionService,
    public dialog: MatDialog,
    private router: Router,
    private toastService: ToastrService) { }

  ngOnInit(): void {
    this.form = this.data.form
  }

  closeDialog(val: boolean): void {
    this.dialogRef.close(val);
  }

  onModalConfirm(): void {
    if (this.form.valid) {
      const region = this.form.getRawValue();
      region.type = "Region"
      // Création
      if (!this.form.get('id').value){
        const dialogRef = this.dialog.open(SpinnerModalComponent);
          this.regionService.save(region).subscribe((a: RegionModel) => {
            this.toastService.success('', 'Création de la région ' + a.libelle + ' réussie');
            this.dialogRef.close(true);
            dialogRef.close();
          }, (error) => {
            dialogRef.close();
          });
      }else { // Modification
        const dialogRef = this.dialog.open(SpinnerModalComponent);
        this.regionService.update(region.id, region).subscribe((a: RegionModel) => {
          this.toastService.success('', 'Modification de la région ' + a.libelle + ' réussie');
          this.dialogRef.close(true);
          dialogRef.close();
        }, (error) => {
          dialogRef.close();
        });
      }
    } else {
      this.displayErrors();
      this.toastService.warning('', 'Veuillez remplir les champs obligatoire');
    }
  }

  displayErrors(): void {
    for (const index in this.form.controls) {
      if (index) {
        this.form.controls[index].markAsTouched();
      }
    }
  }
}

