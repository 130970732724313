<div>
    <div class="test" style="text-align: center">
      <div class="col-sm-12">
        <h3>{{title}}</h3>
      </div>
      
      <div class="col-sm-12" >
        <mat-icon class="col-sm-2" style="bottom: auto;" [color]="icon_color">{{icone}}</mat-icon>
        <div>
          <app-autocomplete-agence
            name="agence" 
            [libelle]="'Agence'" 
            [agences]="agenceList" 
            (agenceChange)="updateAgence($event)">
        </app-autocomplete-agence>

        </div>
      </div>
      <div class="col-sm-12">
        <mat-divider></mat-divider>
      </div>
      <div class="row">
        <div class="col-sm-6" style="margin-top: 40px;">
          <button mat-raised-button color="default" (click)="onModalCancel()">Annuler</button>
        </div>
        <div class="col-sm-6" style="margin-top: 40px;">
          <button mat-raised-button [color]="color" [disabled]="disabled" (click)="onModalConfirm()" >Confirmer</button>
        </div>
      </div>
    </div>
  </div>