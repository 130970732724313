<div class="col-md-12" style="display: flex; justify-content: space-between;">
  <h2 class="col-md-8"> Modification utilisateur </h2>
  <div class="col-md-1">
    <button  class="pull-right" mat-raised-button type="submit" color="success" (click)="validate()">
      Enregistrer
    </button>
  </div>
  <div class="col-md-1">
    <button class="pull-right" color="warn" mat-raised-button type="button" (click)="close()">
      <mat-icon>cancel</mat-icon>Fermer
    </button>
  </div>
</div>
<div class="col-md-12" *ngIf="form">
  <form focusInvalidInput [formGroup]="form">
    <div class="col-md-12" style="display: inline-flex; flex-direction: row; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap;">
      <div class="col-md-4">
        <mat-form-field class="full-width">
          <mat-label>Nom</mat-label>
          <input matInput placeholder="Ex: Dupont" formControlName="nom">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="full-width">
          <mat-label>Prénom</mat-label>
          <input matInput placeholder="Ex: Jean-Baptiste" formControlName="prenom">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <app-input-telephone [(phoneNumber)]="user.tel" libelle="Téléphone" (phoneNumberChange)="this.form.get('tel').setValue($event);" [parent]="form">
        </app-input-telephone>
      </div>
      <div class="col-md-4">
        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Jean-Baptiste.Dupont@veolia.com" formControlName="email">
        </mat-form-field>
      </div>
      
      <div class="col-md-4">
        <app-autocomplete-agence
            [libelle]="'Agence de l\'utilisateur'" 
            [agences]="agences" 
            [agenceId]="user.idAgence"
            [disabled]="true"
            (agenceChange)="onChangeAgence($event)">
        </app-autocomplete-agence>
      </div>

      <div class="col-md-4"> 
        <mat-form-field floatLabel="always" appearance="none" class="full-width">
          <mat-label>{{this.form.get('notification').value ? "Notifications de fin de contrat activées": "Notifications de fin de contrat desactivées"}}</mat-label>
          <mat-slide-toggle formControlName="notification" (change)="onChangeNotif($event)"></mat-slide-toggle>
          <textarea matInput hidden></textarea>
        </mat-form-field>
      </div>

    </div>
    <div class="row">
      <app-table-with-filter [tableDTO]="tableDTO" (entityOutput)="updateDroit($event)"></app-table-with-filter>
    </div>
   
  </form>
</div>
