import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { Title } from '@angular/platform-browser';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReferentielComponentsService } from '../referentiel-components.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { RegionService } from 'src/app/shared/services/rsa/region.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { ModalInfoOkComponent } from 'src/app/shared/components/modals/info-ok/modal-info-ok.component';
import { DemandeAgenceModel } from 'src/app/shared/models/agence-linked/demande/demande-agence.model';
import { DemandeAgenceService } from 'src/app/shared/services/agence-linked/demande-agence.service';
import { SocieteModel } from 'src/app/shared/models/rsa/societe.model';
import { SocieteService } from 'src/app/shared/services/rsa/societe.service';

@Component({
  selector: 'app-fiche-agence',
  templateUrl: './fiche-agence.component.html',
  styleUrls: ['./fiche-agence.component.scss']
})
export class FicheAgenceComponent implements OnInit {
  onlyViewDroits = true;
  agence: AgenceModel;
  regions: RegionModel[] = [];
  societes: SocieteModel[] = [];
  form: UntypedFormGroup;
  showDelay = new UntypedFormControl(0);
  hideDelay = new UntypedFormControl(0);
  responsablesForm: UntypedFormArray;
  matDataSourceDemande: MatTableDataSource<DemandeAgenceModel> = new MatTableDataSource<DemandeAgenceModel>();
  displayedColumnsDemande: string[] = ['Action','Demandeur','Date', 'Modification']

  listDemande: DemandeAgenceModel[] = [];
  matDataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource<AbstractControl>();

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private agenceService: AgenceService,
    private regionService: RegionService,
    private societeService: SocieteService,
    private toastr: ToastrService,
    private demandeAgenceService: DemandeAgenceService,
    private referentielService: ReferentielComponentsService,
    private router: Router,
    private title: Title,
    private sessionService: SessionService,
    private collaborateurService: CollaborateurService
  ) { 
    this.regionService.getAll().subscribe(obj => {
      this.regions = obj;
    });
    this.societeService.getAll().subscribe(obj => {
      this.societes = obj;
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Fiche Agence');
    this.route.params.subscribe((params: any) => {
      this.onLoadAgence(params.id).subscribe((agence: AgenceModel) => {});    
    });
  }

  onCollabChange(id, state): void {
    switch (state) {
      case 'DirecteurAgence' :     
      this.form.get('idDirecteurAgence').setValue(id);
      break;
      case 'ContactSite1' :     
      this.form.get('idContact1').setValue(id);
      break;
      case 'ContactSite2' :     
      this.form.get('idContact2').setValue(id);
      break;
    }
  }

  onLoadAgence(id: number): Observable<AgenceModel> {
    return this.agenceService.getForRefage(id).pipe(tap((agence: AgenceModel) => {
      if (agence !== null && agence !== undefined) {
        this.agence = Object.assign({}, agence);
        this.form = this.referentielService.getVisualizeAgenceFormGroup(agence);     
        this.demandeAgenceService.getAllDemandeByIdAgence(this.agence.id).subscribe(res => {
          this.listDemande = res;    
          this.matDataSourceDemande = new MatTableDataSource(this.listDemande);  
         
          this.matDataSourceDemande.data.forEach(row => { 
            this.collaborateurService.get(row.idCreateur).subscribe(res => {
              row.createur = res;
            })
          });

        });
        this.sessionService.currentUser.subscribe(x=> {
          if(x.droits.filter(x=> x.niveau == 2 && x.type=="REFAGE").length != 0)
          {
            this.onlyViewDroits = false
          }else{
            this.onlyViewDroits = true                
          }
        })    
      }
    }));
  }

  deleteAgence() {
    this.collaborateurService.getAllByAgenceId(this.agence.id).subscribe((collabs: CollaborateurModel[]) => {
      if(collabs && collabs.length > 0){
        var message = ""
        collabs.forEach(col => {
          message += col.nom + " " + col.prenom + ", "
        });
        const dialogRef = this.dialog.open(ModalInfoOkComponent, {
          data: {
            title: 'Impossible de supprimer cette agence car les personnes suivantes sont encore rattaché a l\'agence',
            message: message,
            icone: 'warning_amber',
            iconColor: 'accent',
            color: 'accent'
          }
        });
      }else {
        const dialogConfirmation = this.dialog.open(ModalInfoOkComponent, {
          data: {
            title: 'Demande de suppression de l\'agence ',
            type: 'Demande',
            message: 'Souhaitez-vous créer une demande de suppression de cette agence? ',
            icone: 'warning',
            iconColor: 'warn',
            color: 'danger',
          }
        });
        return dialogConfirmation.afterClosed().subscribe((resultat) => {
          if (resultat) {
            this.router.navigate(["create-demande-agence"], {queryParams:  {typeDemande: 'suppression', idAgence: this.agence.id}});
          }
        });
      }
    });
    
  }


  updateAgence(){
    this.router.navigate(["create-demande-agence"], {queryParams:  {typeDemande: "modification", idAgence: this.agence.id}});
  }

  editDemande(demande: DemandeAgenceModel): void {
    this.router.navigate(['read-demande-agence/' + demande.id]);
  }

  memorise(): void {
    if(this.form.valid){
      const dialogRef = this.dialog.open(SpinnerModalComponent);
      this.agence = this.form.getRawValue();
      this.agenceService.update(this.agence.id, this.agence).subscribe(res => {
        this.agence = res;
        this.form = this.referentielService.getEditAgenceFormGroup(this.agence);
        this.toastr.success('Enregitrement des modifications', 'Modification réussie.');
        dialogRef.close();
      });
    }
    
  }

  close(): void {
    this.router.navigate(['/gestion-agence/']);
  }

}

 


