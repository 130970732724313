import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CycleValidationDTO } from 'src/app/shared/dtos/cycleValidationDTO';
import { CycleDemandeService } from 'src/app/shared/services/simple/cycle-demande.service';


export interface DialogData {
  idDemande: number;
  idAgence: number;
}

@Component({
  selector: 'app-modal-cycle-validation',
  templateUrl: './modal-cycle-validation.component.html',
  styleUrls: ['./modal-cycle-validation.component.scss']
})
export class ModalCycleValidationComponent implements OnInit {

  cyclesValidation: CycleValidationDTO[] = [];

  constructor(public dialog: MatDialog
    , public dialogRef: MatDialogRef<Component>
    , @Inject(MAT_DIALOG_DATA) public data: DialogData
    , private cycleDemandeService: CycleDemandeService) { }
  
  ngOnInit(): void {
    this.cycleDemandeService.getAllValidationFromDemande(this.data.idDemande, this.data.idAgence).subscribe(x => {
      this.cyclesValidation = x;
    })
  }
}
