<div *ngIf="demandeForm" class="row" id="parent-brouillon">
    <form focusInvalidInput [formGroup]="demandeForm" (ngSubmit)="memorize()">
        <div class="row">
            <div class="col-sm-12" style="display: flex; justify-content: space-between;">
                <h4 class="col-sm-8"> Demande de {{typeDemande}} d'un collaborateur {{currentAgence ? ' sur ' + currentAgence.libelle :''}}</h4>
                <div class="col-sm-4">
                    
                    <button color="default" mat-raised-button type="button" (click)="close()">
                        <mat-icon>cancel</mat-icon>Fermer
                    </button>

                    <button color="success" mat-raised-button *ngIf="typeDemande !== 'suppression'"
                        type="submit">
                        <mat-icon>save</mat-icon>Enregistrer
                    </button>

                    <button color="danger" mat-raised-button *ngIf="typeDemande === 'suppression'"
                        type="submit">
                        <mat-icon>save</mat-icon>Demander la suppression
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-9">
                <app-demande-form [form]="demandeForm" [agence]="currentAgence"></app-demande-form>
            </div>
            <div class="col-sm-3">
                <app-application-form-grouped [untouchedApps]="allApplications" [form]="demandeForm" [collabApplications]="collabApplications" ></app-application-form-grouped>
            </div>
        </div>
    </form>
</div>
<div *ngIf="demandeForm === null">
    <div class="row">
        <div class="col-sm-12" style="display: flex; justify-content: space-between;">
            <h4 class="col-sm-6"> Demande de {{typeDemande}} d'un collaborateur</h4>
            <div class="col-sm-6">
                <button color="warn" mat-raised-button type="button" (click)="close()">
                    <mat-icon>cancel</mat-icon>Fermer
                </button>
                <button disabled>
                    <mat-icon>save</mat-icon>Enregistrer
                </button>
            </div>
        </div>
    </div>
    <div>
        <mat-spinner class="spinner" mode="indeterminate"></mat-spinner>
    </div>
</div>
