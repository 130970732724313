import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { RegionModel } from 'src/app/shared/models/rsa/region.model';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-region',
  templateUrl: './autocomplete.region.component.html',
  styleUrls: ['./autocomplete.region.component.scss']
})
export class AutocompleteRegionComponent implements OnInit {
  @Input() display = false;
  @Input() regions: RegionModel[];
  @Input() regionId: number;
  @Input() libelle: string;
  @Input() required = false;
  @Input() disabled = false;
  @Output() regionChange = new EventEmitter<RegionModel>();
  @Output() change = new EventEmitter();
  regionString: string[] = [];
  filteredRegions: Observable<string[]>;
  myControl = new UntypedFormControl();

  constructor() { }

  ngOnInit(): void {
  }

  isValid(): boolean {
    return this.regions.find(region => region.libelle === this.myControl.value) !== undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled) {
      this.myControl.disable();
    } else if (!this.disabled) {
      this.myControl.enable();
    }

    if(this.required) {
      this.myControl.setValidators(Validators.required);
    }

    if(this.display){
      this.myControl.markAsTouched();
    }
    if (this.regions) {

      this.regionString = this.regions.map(region => region.libelle);
    }
    if (this.regions && this.regionId) {
      const selectedRegion = this.regions.find(region => region.id === this.regionId);
      if (selectedRegion) {
        this.myControl.setValue(selectedRegion.libelle);
      } 
    }
    this.filteredRegions = this.myControl.valueChanges
    .pipe(
      startWith(null),
      map((region: string | undefined | null) => region ? this._filter(region) : this.regionString.slice()));
  }

  changeEvent() {
    const validators = [];
    validators.push(forbiddenNamesValidator(this.regions));
    if (this.required) {
      validators.push(Validators.required);
    }
    this.myControl.setValidators(validators);
    if (this.isValid()) {
      const selectedRegion = this.regions.find(region => region.libelle === this.myControl.value);
      this.regionChange.emit(selectedRegion);
    } else {
      this.regionChange.emit(undefined);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.regionString = this.regions.map(region => region.libelle);
    return this.regionString.filter(region => region.toLowerCase().indexOf(filterValue) >= 0);
  }
}

export function forbiddenNamesValidator(regions: RegionModel[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // below findIndex will check if control.value is equal to one of our options or not
    if (control.value) {
      const index = regions.findIndex(region => {
        return (region.libelle).localeCompare(control.value) === 0;
      });
      return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
    } else {
      return null;
    }
  }};