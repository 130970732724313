import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AgenceLinkedModel } from '../../models/agence-linked/agence-linked.model';
import { DemandeModel } from '../../models/agence-linked/demande/demande.model';

@Injectable()
export class TrieTabService {

  sortData(sort: Sort, dataSource: DemandeModel[], matDataSource, pageIndex, pageSize): MatTableDataSource<DemandeModel> {
    if (!sort.active || sort.direction === '') {
      const data = this.loadPage(dataSource, pageIndex, pageSize);
      matDataSource = new MatTableDataSource(data);
      return matDataSource;
    }

    const data = dataSource.sort((a, b) => {

      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'agence':
          return this.compareString(`${a.agence.code}-${a.agence.libelle}`, `${b.agence.code}-${b.agence.libelle}`, isAsc);
        case 'NDemande':
          return this.compareString(a.code, b.code, isAsc);
        case 'Date':
          return this.compareDate(a.dCreation, b.dCreation, isAsc);


        default:
          return 0;
      }
    });
    matDataSource = new MatTableDataSource<AgenceLinkedModel>(this.loadPage(data, pageIndex, pageSize));
    return matDataSource;
  }

 
  loadPage(dataSource: any[], pageIndex, pageSize): any[] {
    return dataSource.slice(pageIndex * pageSize, (pageIndex * pageSize) + pageSize);
  }
  compareString(a: string, b: string, isAsc: boolean) {
    if (a !== undefined && a !== null) {
      return a.toString().localeCompare(b) * (isAsc ? 1 : -1);
    }
    return 0;
  }
  compareDate(a: Date, b: Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}


