import { Component, Input } from '@angular/core';
import { CycleDemandeModel } from 'src/app/shared/models/simple-types/cycle-demande.model';

@Component({
  selector: 'app-cycles-demande',
  templateUrl: './cycles-demande.component.html',
  styleUrls: ['./cycles-demande.component.scss']
})
export class CyclesDemandeComponent {

  @Input() title:string;
  @Input() cycles:CycleDemandeModel[];


  getSortedArrayByDate(): CycleDemandeModel[] {
    const arraySorted = this.cycles.sort((a: CycleDemandeModel, b: CycleDemandeModel) => {
      return +new Date(b.dAction) - +new Date(a.dAction);
    });
    return arraySorted;
  }

}
