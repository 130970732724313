
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './core/landing-page/landing-page.component';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { AuthentificationComponent } from './core/authentification/authentification.component';
import { AuthGuard, DroitAdminGuard, DroitDemandeGuard, DroitRefageAdminGuard, DroitRefageGuard } from './shared/services/auth.guard';
import { ListeDemandeComponent } from './core/demande/liste-demande/liste-demande.component';
import { ListUserComponent } from './core/user/list-user/list-user.component';
import { UserRecordComponent } from './core/user/user-record/user-record.component';
import { CreateDemandeComponent } from './core/demande/create-demande/create-demande.component';
import { RechercheCollaborateurComponent } from './core/recherche/recherche-collaborateur/recherche-collaborateur/recherche-collaborateur.component';
import { ReadDemandeComponent} from './core/demande/read-demande/read-demande.component';
import { ValidateDemandeComponent } from './core/demande/validate-demande/validate-demande.component';
import { EditDemandeComponent } from './core/demande/edit-demande/edit-demande.component';
import { ReadCollaborateurComponent } from './core/user/read-collaborateur/read-collaborateur.component';
import { TraitementDsiDemandeComponent } from './core/demande/traitement-dsi-demande/traitement-dsi-demande.component';
import { GestionReferentielComponent } from './core/gestion-referentiel/gestion-referentiel.component';
import { GestionApplicationComponent } from './core/gestion-referentiel/gestion-application/gestion-application.component';
import { FicheApplicationComponent } from './core/gestion-referentiel/fiche-application/fiche-application.component';
import { GestionRegionComponent } from './core/gestion-referentiel/gestion-region/gestion-region.component';
import { GestionSocieteComponent } from './core/gestion-referentiel/gestion-societe/gestion-societe.component';
import { GestionAgenceComponent } from './core/gestion-referentiel/gestion-agence/gestion-agence.component';
import { FicheAgenceComponent } from './core/gestion-referentiel/fiche-agence/fiche-agence.component';
import { CreateDemandeAgenceComponent } from './core/demande-agence/create-demande-agence/create-demande-agence.component';
import { ReadDemandeAgenceComponent } from './core/demande-agence/read-demande-agence/read-demande-agence.component';
import { ValidateDemandeAgenceComponent } from './core/demande-agence/validate-demande-agence/validate-demande-agence.component';
import { ListDemandeAgenceComponent } from './core/demande-agence/list-demande-agence/list-demande-agence.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'},
  { path: 'login', component: AuthentificationComponent},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'list-user', component: ListUserComponent, canActivate: [AuthGuard, DroitAdminGuard] },
  { path: 'user-record/:id', component: UserRecordComponent, canActivate: [AuthGuard, DroitAdminGuard] },
  { path: 'recherche-collaborateur', component: RechercheCollaborateurComponent, canActivate: [AuthGuard] },
  { path: 'read-collaborateur/:id', component: ReadCollaborateurComponent, canActivate: [AuthGuard] },
  { path: 'list-demande', component: ListeDemandeComponent, canActivate: [AuthGuard] },
  { path: 'create-demande', component: CreateDemandeComponent, canActivate: [AuthGuard, DroitDemandeGuard] },
  { path: 'validate-demande/:id', component: ValidateDemandeComponent, canActivate: [AuthGuard, DroitDemandeGuard] },
  { path: 'edit-demande/:id', component: EditDemandeComponent, canActivate: [AuthGuard, DroitDemandeGuard] },
  { path: 'traiter-demande/:id', component: TraitementDsiDemandeComponent, canActivate: [AuthGuard, DroitDemandeGuard] },
  { path: 'read-demande/:id', component: ReadDemandeComponent, canActivate: [AuthGuard, DroitDemandeGuard] },
  { path: 'gestion-referentiel', component: GestionReferentielComponent, canActivate: [AuthGuard, DroitRefageAdminGuard] },
  { path: 'gestion-application', component: GestionApplicationComponent, canActivate: [AuthGuard, DroitAdminGuard]},
  { path: 'fiche-application/:id', component: FicheApplicationComponent, canActivate: [AuthGuard, DroitAdminGuard]},
  { path: 'gestion-region', component: GestionRegionComponent, canActivate: [AuthGuard, DroitAdminGuard]},
  { path: 'gestion-societe', component: GestionSocieteComponent, canActivate: [AuthGuard, DroitAdminGuard]},
  { path: 'gestion-agence', component: GestionAgenceComponent, canActivate: [AuthGuard, DroitRefageGuard]},
  { path: 'fiche-agence/:id', component: FicheAgenceComponent, canActivate: [AuthGuard, DroitRefageGuard]},
  { path: 'create-demande-agence', component: CreateDemandeAgenceComponent, canActivate: [AuthGuard, DroitDemandeGuard] },
  { path: 'read-demande-agence/:id', component: ReadDemandeAgenceComponent, canActivate: [AuthGuard, DroitDemandeGuard] },
  { path: 'validate-demande-agence/:id', component: ValidateDemandeAgenceComponent, canActivate: [AuthGuard, DroitDemandeGuard] },
  { path: 'list-demande-agence', component: ListDemandeAgenceComponent, canActivate: [AuthGuard, DroitRefageGuard] },
  { path: 'not-found', component: FourOhFourComponent},
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
