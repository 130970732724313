export const environment = {
  production: true,
  envName: 'production',
  API_END_POINT_URL: 'https://gus.sarpnet.fr/',
  logoutUrl: 'https://gus.sarpnet.fr/login',
  CAN_USE_MOCK: false,

  refresh_google_when_expire_in: 15,
  refresh_jwt_when_expire_in: 1,
  google_analytics_code:  "G-09MNSLCVY2",
  // Clé de prod non existante
  google_client_id: '267060907829-ninkuueudr144qm51eq78hdrms0f5428.apps.googleusercontent.com',
  APP_ID: '267060907829',
  DEV_KEY: 'AIzaSyCtc4PvWM_MtmkfSam-hvFN1SaQT7A3Gbs',
  datadog_env: {
    applicationId: '82ec8d59-9e98-41cf-af35-6f77c374c1e4',
    clientToken: 'pub93802ea057cde15b61b9b9476afbce17',
    site: 'datadoghq.eu',
    service: 'gus',
    env: 'production',
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: '1.7.0792',
    sampleRate: 100,
    allowedTracingOrigins: ['https://gus.sarpnet.fr'],
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  }
};
