import { BehaviorSubject } from 'rxjs';

export const APICONSTANTS = {
    URLAPI: new BehaviorSubject<string>(''),
    ROUTEDEMANDE: 'api/demande/',
    ROUTEDOCUMENT: 'api/document/',
    ROUTEREGION: 'api/region/',
    ROUTESOCIETE: 'api/societe/',
    ROUTEAGENCE: 'api/agence/',
    ROUTEUSER: 'api/utilisateur/',
    ROUTECOLLABORATEUR: 'api/collaborateur/',
    ROUTECYCLEDEMANDE: 'api/cycleDemande/', 
    ROUTEGOOGLELOGIN: 'api/Account/Google/',
    ROUTEDROIT: 'api/droitEffectif/',
    ROUTEAPPLICATION: 'api/application/',
    ROUTEAPPLICATIONUSER: 'api/applicationUser/',
    ROUTEAPPLICATIONDEMANDE: 'api/applicationDemande/',
    ROUTERESPONSABLEAPPLI: 'api/responsableAppli/',
    ROUTEDEMANDEAGENCE: 'api/demandeAgence/',
    ROUTECYCLEDEMANDEAGENCE: 'api/cycleDemandeAgence/', 

};