<mat-card class="row">
    <mat-card-content>    
        <div class="mat-table">
            <div class="table_principal">
                <table mat-table matSort [dataSource]="matDataSourceDemande"  matSortActive="dModification"  (matSortChange)="sortData($event)"
                matSortDirection="desc" matSortDisableClear>
                    <caption>Historique des demandes</caption>

                    <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element" class="padding-0" >
                            <div *ngIf="element.typeDemande === 0"> Création de compte </div>
                            <div *ngIf="element.typeDemande === 1"> Modification de compte </div>
                            <div *ngIf="element.typeDemande === 2"> Supression de compte </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Demandeur">
                        <th mat-header-cell *matHeaderCellDef> Demandeur </th>
                        <td mat-cell *matCellDef="let element" class="padding-0">
                            <div *ngIf="element.createur">
                                  {{element.createur.nom}}  {{element.createur.prenom}}

                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Date de création">
                        <th mat-header-cell *matHeaderCellDef> Date de création </th>
                        <td mat-cell *matCellDef="let element" class="padding-0"> 
                            <div *ngIf="element.dCreation !== '0001-01-01T00:00:00Z'">
                                 {{element.dCreation| date:'dd/MM/yyyy HH:mm'}}  
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="dModification">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Date de traitement
                          </th>
                        <td mat-cell *matCellDef="let element" class="padding-0"> 
                            <div *ngIf="element.dModification !== '0001-01-01T00:00:00Z' && element.status === 'Traité'">
                                 {{element.dModification| date:'dd/MM/yyyy HH:mm'}}  
                            </div>
                        </td>
                    </ng-container>
        
                    <ng-container matColumnDef="Statut">
                        <th mat-header-cell *matHeaderCellDef> Statut </th>
                        <td mat-cell *matCellDef="let element" class="padding-0">
                            <div *ngIf="element.status" [innerHtml]="element.status">
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Modification">
                        <th mat-header-cell *matHeaderCellDef> Modification </th>
                        <td mat-cell *matCellDef="let element" class="padding-0">
                            <div *ngIf="element.modification" [innerHtml]="element.modification">
                            </div>
                        </td>
                    </ng-container>
        
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsDemande; sticky: true"></tr>
                    <div >
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsDemande;" (click)="editDemande(row);"
                            [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}">
                        </tr>
                    </div>
                    
                </table>
            </div>
        </div>
    </mat-card-content>
</mat-card>