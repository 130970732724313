import { MatSidenav } from '@angular/material/sidenav';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FiltreService } from 'src/app/core/filtre.service';
import { FilterDemandeDTO } from 'src/app/shared/dtos/filterDemandeDTO';
import pkg from 'package.json';
import { FilterDemandeAgenceDTO } from 'src/app/shared/dtos/filterDemandeAgenceDTO';
import { getStatusDemande, StatusDemande } from 'src/app/shared/constants/status';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public version = pkg.version;
  public versionSmall = pkg.version.substr(0, 5);
  filtre: FilterDemandeDTO;
  filtreAgence: FilterDemandeAgenceDTO;
  viewDemandesAgence: boolean = false;
  @Input() isExpanded: boolean;
  @Input() isShowing: boolean;
  @ViewChild('sidenav') sidenav: MatSidenav;
  constructor(public router: Router,
              private filtreService: FiltreService,
              private sessionsService: SessionService) {
  }

  ngOnInit(): void {

    this.sessionsService.currentUser.subscribe(user => {
      if (user) {
        const droitRefage = user.droits.filter(droit =>
          (droit.niveau > 0) &&
          (droit.type.toUpperCase() === 'REFAGE')
      );
      if (droitRefage && droitRefage.length > 0){
        this.viewDemandesAgence = true;
      }
      }
    });
  }

  list_demande(): void {
    this.filtre = new FilterDemandeDTO();
    this.filtre.type = '';
    this.filtreService.changeFiltre(this.filtre);
  }

  list_demande_agence(): void {
    this.filtreAgence =  new FilterDemandeAgenceDTO();
    this.filtreAgence.status = [
      getStatusDemande(StatusDemande.AValider),
      getStatusDemande(StatusDemande.Validate),
      getStatusDemande(StatusDemande.Traite),
      getStatusDemande(StatusDemande.Refuse),
      getStatusDemande(StatusDemande.Annule)
    ];
    this.filtreAgence.order = 'DCreation';
    this.filtreAgence.descending = true;
    this.filtreAgence.type = '';
    this.filtreService.changeFiltreAgence(this.filtreAgence);
    this.router.navigate(['/list-demande-agence']);
  }
  
}
