export class DroitModelDTO {
  idUser: number;
  regionId: number;
  agenceId: number = null;
  
  libelleRegion: string;
  libelleSociete: string = "";
  libelleAgence: string = "";

  visualiseDemande: CellDTO;
  createDemande: CellDTO;
  valid1: CellDTO;
  valid2: CellDTO;
  usersManager: CellDTO;
  repositoriesManager: CellDTO;
  visualiseDemandeAgence: CellDTO;
  createDemandeAgence: CellDTO;
  validateDemandeAgence: CellDTO;

}

export class CellDTO{

  value: boolean
  disabled: boolean
}