import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl, AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';

@Component({
    selector: 'app-autocomplete-agence',
    templateUrl: './autocomplete.agence.component.html',
    styleUrls: ['./autocomplete.agence.component.scss']
  })
export class AutoCompleteAgenceComponent implements OnInit, OnChanges {

  @Input() agences: AgenceModel[];
  @Input() value: AgenceModel;
  @Input() agenceId: number;
  @Input() libelle: string;
  @Input() required = false;
  @Input() disabled = false;
  @Output() agenceChange = new EventEmitter<AgenceModel>();
  @Output() change = new EventEmitter();
  agencesString: string[] = [];
  filteredAgences: Observable<string[]>;
  myControl = new UntypedFormControl();
  isLoad = false;
  constructor(private agenceService: AgenceService) { }

  ngOnInit(): void {
  }

  isValid(): boolean {
    return this.agences.find(agence => (agence.societeObj ? agence.societeObj.libelle : "") + " / " + agence.code + "-" + agence.libelle === this.myControl.value) !== undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled) {
      this.myControl.disable();
    } else if (!this.disabled) {
      this.myControl.enable();
    }
    if (this.agences) {
     
      this.agencesString = this.agences.map(agence => (agence.societeObj ? agence.societeObj.libelle : "") + " / " + agence.code + "-" + agence.libelle);
    }
    if (this.agences && this.agenceId) {
      const selectedAgence = this.agences.find(agence => agence.id === this.agenceId);
      if (selectedAgence) {
        this.myControl.setValue((selectedAgence.societeObj ? selectedAgence.societeObj.libelle : "") + " / " + selectedAgence.code + "-" + selectedAgence.libelle);   
      }else{
        if(!this.isLoad && this.agenceId !== -1){
          this.isLoad = true;
          this.agenceService.get(this.agenceId).subscribe(agence =>{
            this.myControl.setValue((agence.societeObj ? agence.societeObj.libelle : "") + " / " + agence.code + "-" + agence.libelle);   
          })
        }
      }
    }
    if(!this.agenceId) {
      this.myControl.setValue("")

    }

    this.filteredAgences = this.myControl.valueChanges
    .pipe(
      startWith(null),
      map((agence: string | undefined | null) => agence ? this._filter(agence) : this.agencesString.slice()));
  }

  changeEvent() {
    const validators = [];
    validators.push(forbiddenNamesValidator(this.agences));
    if (this.required) {
      validators.push(Validators.required);
    }
    this.myControl.setValidators(validators);
    if (this.isValid()) {
      const selectedAgence = this.agences.find(agence => (agence.societeObj ? agence.societeObj.libelle : "") + " / " + agence.code + "-" + agence.libelle === this.myControl.value);
      this.agenceChange.emit(selectedAgence);
    } else {
      this.agenceChange.emit(undefined);
    }
  }
  

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.agencesString = this.agences.map(agence => (agence.societeObj ? agence.societeObj.libelle : "") + " / " + agence.code + "-" + agence.libelle);
    return this.agencesString.filter(agence => agence.toLowerCase().indexOf(filterValue) >= 0);
  }
}

export function forbiddenNamesValidator(agences: AgenceModel[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // below findIndex will check if control.value is equal to one of our options or not
    if (control.value) {
      const index = agences.findIndex(agence => {
        return ((agence.societeObj ? agence.societeObj.libelle : "") + " / " + agence.code + "-" + agence.libelle).localeCompare(control.value) === 0;
      });
      return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
    } else {
      return null;
    }
  }};