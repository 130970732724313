import { HttpClient } from '@angular/common/http';
import { SimpleQueryResponse } from '../../dtos/simpleQueryResponse';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APICONSTANTS } from '../../constants/api-constants';
import { DashboardModel } from '../../dtos/dashboard.model';
import { HostnameService } from '../basic/hostname.service';
import { AgenceLinkedService } from './agence-linked.service';
import { DemandeValidationModel } from '../../dtos/demandeValidationModel';
import { FilterDemandeDTO } from '../../dtos/filterDemandeDTO';
import { DemandeModel } from '../../models/agence-linked/demande/demande.model';

@Injectable()
export class DemandeService extends AgenceLinkedService<DemandeModel> {
    observer = new BehaviorSubject<DemandeModel>(null);
    public demandeSubscriber = this.observer.asObservable();

    constructor(protected http: HttpClient,
                protected toastr: ToastrService,
                protected hostnameService: HostnameService) {
        super(http, toastr, APICONSTANTS.ROUTEDEMANDE, hostnameService);
    }

    getFiltredDemande(fitredto: FilterDemandeDTO): Observable<SimpleQueryResponse<DemandeModel>> {
        return super.getAllFromObjet('GetfiltredData/', fitredto).pipe(catchError(err => this.requestCatchError(err, 'Le chargement')));
    }

    saveDemande(t: DemandeModel, action: string): Observable<DemandeModel> {
        return this.http.post<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'PostDemande?action=' + action, t)
            .pipe(catchError(err => this.requestCatchError(err, 'L\'enregistrement')));
    }

    getModifications(demande: DemandeModel): Observable<string> {
        return this.http.post<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'GetDemandeModifications', demande)
            .pipe(catchError(err => this.requestCatchError(err, 'La recherche de modification')));
    }
    verifyWorkflowDemande(t: DemandeModel): Observable<string> {
        return this.http.post<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'verifyWorkflowDemande', t)
            .pipe(catchError(err => this.requestCatchError(err, 'Verification workflow')));
    }
    toAValider(t: DemandeModel): Observable<DemandeModel> {
        return this.http.post<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'toAValider', t)
            .pipe(catchError(err => this.requestCatchError(err, 'L\'envoie en validation')));
    }
    getAuthorisation(idAgence: number): Observable<number[]> {
        // tslint:disable-next-line: max-line-length
        return this.http.get<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'getAuthorisation?idAgence=' + idAgence)
            .pipe(catchError(err => this.requestCatchError(err, 'Le chargement des droits')));
    }
    validateDemande(demandeForValidation: DemandeValidationModel): Observable<DemandeModel> {
        return this.http.put<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'Validate', demandeForValidation)
            .pipe(catchError(err => this.requestCatchError(err, 'La validation')));
    }
    traiterDemande(demandeForTraitement: DemandeValidationModel): Observable<DemandeModel> {
        return this.http.put<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'GenFilesAD', demandeForTraitement)
            .pipe(catchError(err => this.requestCatchError(err, 'Le traitement')));
    }
    refusDemande(demandeForRefus: DemandeValidationModel): Observable<DemandeModel> {
        return this.http.put<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'Refus', demandeForRefus)
            .pipe(catchError(err => this.requestCatchError(err, 'Le refus')));
    }

    annulerDemande(demandeForAnnulation: DemandeValidationModel): Observable<DemandeModel> {
        return this.http.put<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'Annuler', demandeForAnnulation)
            .pipe(catchError(err => this.requestCatchError(err, 'L\'annulation')));
    }

    retourDemande(demandeForRetour: DemandeValidationModel): Observable<DemandeModel> {
        return this.http.put<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'Retour', demandeForRetour)
            .pipe(catchError(err => this.requestCatchError(err, 'Le retour')));
    }

    retourDSIDemande(demandeForRetourDSI: DemandeValidationModel): Observable<DemandeModel> {
        return this.http.put<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'RetourDSI', demandeForRetourDSI)
            .pipe(catchError(err => this.requestCatchError(err, 'Le retour DSI')));
    }

    getDashboard(): Observable<DashboardModel> {
        return this.http.get<DashboardModel>(APICONSTANTS.URLAPI.value + this.route + 'GetDashboard')
            .pipe(catchError(err => this.requestCatchError(err, 'L\'affichage')));
    }
    
    cancel(t: DemandeModel): Observable<DemandeModel> {
        return this.http.post<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'annulation', t)
            .pipe(catchError(err => this.requestCatchError(err, 'L\'annulation')));
    }

    updateDemande(t: DemandeValidationModel): Observable<DemandeModel> {
        // tslint:disable-next-line: max-line-length
        return this.http.put<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'updateDemande',t).pipe(catchError( err => this.requestCatchError(err, 'Update')));
    }

    exportDemandes(filtre: FilterDemandeDTO) {
        // tslint:disable-next-line: max-line-length
        return this.http.post(APICONSTANTS.URLAPI.value + this.route + 'exportDemandes', filtre, {responseType: 'text'}).pipe(catchError(err => this.requestCatchError(err, 'Export')));
    }

    genFilesAD(demande: DemandeValidationModel)  {
        // tslint:disable-next-line: max-line-length
        return this.http.put(APICONSTANTS.URLAPI.value + this.route + 'GenFilesAD', demande, {responseType: 'text'}).pipe(catchError(err => this.requestCatchError(err, 'Generate')));
    }

    genFilesEmail(demande: DemandeModel)  {
        // tslint:disable-next-line: max-line-length
        return this.http.put(APICONSTANTS.URLAPI.value + this.route + 'GenFilesEmail', demande, {responseType: 'text'}).pipe(catchError(err => this.requestCatchError(err, 'Generate')));
    }

    getAllDemandeByIdCollab(id: number): Observable<DemandeModel[]> {
          return this.http.get<DemandeModel>(APICONSTANTS.URLAPI.value + this.route + 'GetAllDemandeByIdCollab?id=' + id)
              .pipe(catchError(err => this.requestCatchError(err, 'GetAllDemandeByIdCollab')));
     }
   
}
