import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  DemandeComponentsService,
  DetailDemandeinfos,
} from '../demande-components.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DemandeModel } from 'src/app/shared/models/agence-linked/demande/demande.model';
import { DemandeService } from 'src/app/shared/services/agence-linked/demande.service';
import { ApplicationUserModel } from 'src/app/shared/models/simple-types/application-user.model';
import { AgenceModel } from 'src/app/shared/models/rsa/agence.model';
import { AgenceService } from 'src/app/shared/services/rsa/agence.service';
import { CollaborateurService } from 'src/app/shared/services/collaborateur.service';
import { CollaborateurModel } from 'src/app/shared/models/utilisateur/collaborateur.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CycleDemandeModel } from 'src/app/shared/models/simple-types/cycle-demande.model';
import { CycleDemandeService } from 'src/app/shared/services/simple/cycle-demande.service';
import { AddCommentModalComponent } from 'src/app/shared/components/modals/add-comment/add-comment-modal.component';
import { DemandeValidationModel } from 'src/app/shared/dtos/demandeValidationModel';
import { saveAs } from 'file-saver';
import { TypeDemande } from 'src/app/shared/constants/typeDemande';
import { SpinnerModalComponent } from 'src/app/shared/components/modals/spinner/spinner-modal.component';
import { map } from 'rxjs/operators';
import { ApplicationService } from 'src/app/shared/services/simple/application.service';
import { ApplicationModel } from 'src/app/shared/models/simple-types/application.model';

@Component({
  selector: 'app-traitement-dsi-demande',
  templateUrl: './traitement-dsi-demande.component.html',
  styleUrls: ['./traitement-dsi-demande.component.scss'],
})
export class TraitementDsiDemandeComponent implements OnInit {
  demandeForm: UntypedFormGroup;
  demande: DemandeModel = new DemandeModel();
  idDemande: number;
  detailDemandeInfo: DetailDemandeinfos;
  childHeight: number = 0;
  parentWidth: number = 0;
  collabApplications: ApplicationUserModel[] = [];
  currentAgence: AgenceModel;
  observableDemande = new BehaviorSubject<DemandeModel>(null);
  typeDemande: string = '';

  cyclesValidation: CycleDemandeModel[] = [];
  listDemandeCollab: DemandeModel[] = [];
  listDemandeForModal: DemandeModel[] = [];
  // Message d'alerte pour une demande validé ou traité sur le collaborateur entre la date de création de la demande et maintenant.
  alertMessage: string;
  allApplications : ApplicationModel[] = [];
 
  constructor(private demandeService: DemandeService,
    private router: Router,
    private demandeComponentsService: DemandeComponentsService,
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private title: Title,
    private agenceService: AgenceService,
    private collabService: CollaborateurService,
    private cycleDemandeService: CycleDemandeService,
    public dialog: MatDialog
  ) {
    this.route.params.subscribe((params: any) => {
      if (params.id === '0') {
        this.demandeService.demandeSubscriber.subscribe(
          (demande: DemandeModel) => {
            if (demande !== null) {
              this.demande = Object.assign({}, demande);
            } else {
              this.toastrService.error(
                'Erreur durant la récupération de la demande.',
                'Erreur'
              );
              this.router.navigate(['/']);
            }
          }
        );
      } else {
        this.demandeService.get(params.id).subscribe(
          (demande) => {
            this.demande = demande;
            this.title.setTitle(
              'GUS - Traitement demande ' + this.demande.code
            );
            switch (this.demande.typeDemande) {
              case 0:
                this.typeDemande = 'Création';
                break;
              case 1:
                this.typeDemande = 'Modification';
                this.collabService
                  .get(this.demande.idCollaborateur)
                  .subscribe((collab: CollaborateurModel) => {
                    if (collab) {
                      this.collabApplications = collab.applications;
                    }
                  });
                break;
              case 2:
                this.typeDemande = 'Suppression';
                this.collabService
                  .get(this.demande.idCollaborateur)
                  .subscribe((collab: CollaborateurModel) => {
                    if (collab) {
                      this.collabApplications = collab.applications;
                    }
                  });
                break;
            }

            this.detailDemandeInfo = new DetailDemandeinfos();
            this.demandeForm =
              this.demandeComponentsService.getTraiterDemandeFormGroup(
                this.demande
              );
            this.demandeForm
              .get('adresseAgence')
              .setValue(demande.agence.adresse);
            this.demandeForm
              .get('numeroAgence')
              .setValue(demande.agence.telephone);
            this.agenceService
              .get(this.demande.idAgence)
              .subscribe((res) => (this.demande.agence = res));
            this.observableDemande.next(this.demande);

            // get cycles by demande ID
            this.cycleDemandeService
              .getAllFromDemande(this.demande.id)
              .subscribe((res) => {
                this.cyclesValidation = res;
              });
          },
          (error) => {
            toastrService.error("La demande n'existe pas");
            this.router.navigate(['/not-found']);
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.verifyDemandeStatusToRedirect();
    this.applicationService.getAll().subscribe((apps: ApplicationModel[]) => {
      this.allApplications = apps;
    });
  }

  retourDSI(): void {
    const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
      data: {
        title: 'Commentaire de retour',
        color: 'primary',
        icon_color: 'accent',
      },
    });
    dialogConfirmation.afterClosed().subscribe((res) => {
      if (res !== '' && res) {
        const obj = new DemandeValidationModel(this.demande);
        obj.commentaire = res;
        this.demandeComponentsService.retourDSIDemande(obj).subscribe((x) => {
          this.router.navigate(['/list-demande/']);
        });
      } else if (res === '') {
        this.toastrService.error('commentaire obligatoire');
      }
    });
  }

  close(): void {
    this.router.navigate(['/list-demande/']);
  }

  showModal() {
    const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
      autoFocus: false,
      disableClose: false,
      maxHeight: '90vh',
       data: {
         title: 'Commentaire de traitement',
         alertMessage: this.alertMessage,
         color: 'primary',
         icon_color: 'accent',
         listDemande: this.listDemandeForModal,
       }  
     });
        dialogConfirmation.afterClosed().subscribe(res => {
          if (res === '' || res) {
            const dialogRef = this.dialog.open(SpinnerModalComponent);
            const obj = new DemandeValidationModel(this.demande);
            obj.commentaire = res;
            this.demandeComponentsService.traiterDemande(obj).subscribe(x => {
              dialogRef.close();
            this.router.navigate(['/list-demande/']);
          }), (error) => dialogRef.close();
          }
        });
  }
  
  validate(): void {
      var observable: Observable<DemandeModel[]>;
      if (!this.demande.idCollaborateur) {
        observable = of([]);
      } else {
        observable = this.demandeService
          .getAllDemandeByIdCollab(this.demande.idCollaborateur)
          .pipe(
            map((res) => {
              this.listDemandeCollab = res;
              this.listDemandeForModal = [];
              for (var demande of this.listDemandeCollab) {
                if (demande.code != this.demande.code) {
                  // Ce if c'est pour ignoré la demande qu'on souhaite valider / traiter
                  if (
                    (demande.status == 'En attente de traitement' ||
                      demande.status == 'Traité') &&
                    this.demande.dCreation < demande.dModification
                  ) {
                    this.alertMessage =
                      "Une demande a été ( validé / traité ) sur le même collaborateur entre la date de création de la demande et aujourd'hui.";
                    this.listDemandeForModal.push(demande);
                  }
                }
              }
              return this.listDemandeForModal;
            })
          );
      }
      observable.subscribe((listDemande) => {
        const dialogConfirmation = this.dialog.open(AddCommentModalComponent, {
          autoFocus: false,
          disableClose: false,
          maxHeight: '90vh',
          data: {
            title: 'Commentaire de traitement',
            alertMessage: this.alertMessage,
            color: 'primary',
            icon_color: 'accent',
            listDemande: this.listDemandeForModal,
          },
        });
        dialogConfirmation.afterClosed().subscribe((res) => {
          if (res === '' || res) {
            const dialogRef = this.dialog.open(SpinnerModalComponent);
            const obj = new DemandeValidationModel(this.demande);
            obj.commentaire = res;
            this.demandeComponentsService.traiterDemande(obj).subscribe((x) => {
              dialogRef.close();
              this.router.navigate(['/list-demande/']);
            }),
              (error) => dialogRef.close();
          }
        });
      });
  }
  displayErrors(): void {
    for (const demande in this.demandeForm.controls) {
      if (demande) {
        this.demandeForm.controls[demande].markAsTouched();
      }
    }
  }

  verifyDemandeStatusToRedirect(): void {
    if (this.observableDemande) {
      this.observableDemande.subscribe((demande: DemandeModel) => {
        if (demande) {
          if (demande.statusNum !== 3) {
            this.router.navigate(['/']);
            this.toastrService.warning(
              "Cette demande est n'est pas validée ou déja traitée.",
              'Erreur'
            );
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.demandeComponentsService.purgeDetailDemandeInfo();
  }

  genFilesEmail(): void {
    let filename: string;
    this.demande.email = this.demandeForm.get('email').value;
    this.demandeService
      .genFilesEmail(this.demande)
      .subscribe((res: BlobPart) => {
        const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res], {
          type: 'text/csv;charset=utf-8',
        });
        if (this.demande.typeDemande === TypeDemande.Creation)
          filename = 'C' + this.demande.code;
        else if (this.demande.typeDemande === TypeDemande.Modification)
          filename = 'M' + this.demande.code;
        else if (this.demande.typeDemande === TypeDemande.Suppression)
          filename = 'S' + this.demande.code;
        saveAs(blob, filename + '.csv');
        this.demandeService
          .get(this.demande.id)
          .subscribe((dem) => (this.demande = dem));
      });
  }
}
